"use client";

import { Button, C2A } from "@atoms/button/";
import { Image } from "@atoms/image/Image";
import Typography from "@atoms/typography/Typography";
import { LogoLooper } from "@molecules/logo-looper/LogoLooper";
import { cn, tw } from "@utils/tailwind";
import Link from "next/link";
import { FC, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import mountains4k from "./components/homepage_header_mountain_min.svg";
import MountainsMobile from "./components/MountainsMobile";
import { HeaderHomepageProps } from "./HeaderHomepageSection.types";
import { Logos } from "./logos";

export const HeaderHomepageSection: FC<HeaderHomepageProps> = ({
  headline,
  subheadline,
  cta,
  underHeader,
  anchorId,
  disableCta,
  className,
  logos,
  vimeoUrl,
  backgroundInit,
}) => {
  const beforeMountains = tw`to-transparent before:absolute before:bottom-0 before:left-0 before:block before:h-[400px] before:w-full before:bg-gradient-to-t before:from-content-primary-on-light before:from-30% before:content-['']`;
  const afterMountains = tw`to-transparent before:absolute before:bottom-0 before:left-0 before:z-10 before:block before:h-[200px] before:w-full before:bg-gradient-to-t before:from-content-primary-on-light before:from-20% before:content-['']`;

  const [playerPlaying, setPlayerPlaying] = useState<boolean>(false);
  const [playerReady, setPlayerReady] = useState<boolean>(false);
  const [windowWidth, setWindowWidth] = useState<number>(1920);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      setPlayerReady(windowWidth > 767);

      if (windowWidth > 1920) {
        setWindowWidth(window.innerWidth);
      } else {
        setWindowWidth(1920);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [vimeoUrl]);

  const onPlaying = () => {
    setPlayerPlaying(true);
  };

  return (
    <section
      id={anchorId}
      className={cn(
        "relative flex min-h-fit flex-col justify-end overflow-hidden bg-content-primary-on-light pt-24 md:min-h-dvh lg:pt-32 3xl:h-[1080px] 3xl:min-h-0",
        className
      )}
    >
      <Logos logos={logos} />

      {/* START - IMAGE DSKTP/MOBILE FULL SCREEN */}
      <div className={cn("absolute left-0 top-0 size-full")}>
        <div
          className={cn(
            "absolute left-1/2 top-0 hidden -translate-x-1/2 md:block 3xl:w-full",
            beforeMountains
          )}
        >
          {backgroundInit && (
            <Image
              imageData={backgroundInit}
              width={windowWidth}
              height={(windowWidth * 1080) / 1920}
              disableAutomaticAspectRatio
              className="w-[1920px] max-w-[initial] 3xl:w-full"
              pictureClassName={cn("w-[1920px] max-w-[initial] 3xl:w-full")}
            />
          )}
        </div>

        <div className="relative blur-sm md:hidden">
          <MountainsMobile className="absolute left-1/2 top-0 w-[768px] -translate-x-1/2" />
        </div>
      </div>
      {/* END - IMAGE DSKTP/MOBILE FULL SCREEN */}

      {/* START - VIDEO FULL SCREEN */}
      {playerReady && vimeoUrl && (
        <div
          className={cn(
            "pointer-events-none absolute left-1/2 top-0 hidden -translate-x-1/2 transition-opacity md:inline-block",
            playerPlaying ? "opacity-100" : "opacity-0"
          )}
        >
          <ReactPlayer
            url={vimeoUrl}
            loop
            controls={false}
            muted
            width={`${windowWidth}px`}
            height={`${(windowWidth * 1080) / 1920}px`}
            playing
            className={beforeMountains}
            onPlay={onPlaying}
            config={{
              vimeo: {
                playerOptions: {
                  dnt: true,
                  pip: false,
                },
              },
            }}
          />
        </div>
      )}
      {/* END - VIDEO FULL SCREEN */}

      <div className={cn("relative w-full")}>
        <div
          className={cn(
            "absolute bottom-16 left-1/2 hidden size-full -translate-x-1/2 md:block",
            afterMountains
          )}
        >
          <Image
            imageData={mountains4k.src}
            className="absolute bottom-8 right-0 aspect-[5/2] h-[70%] w-auto max-w-[initial] md:translate-x-[45%] lg:translate-x-[30%] xl:translate-x-[20%] 2xl:h-full 3xl:translate-x-[10%]"
            pictureClassName={cn("max-w-[initial]")}
          />
        </div>

        <div className="container-flexible relative pt-36 text-content-primary-on-dark md:pt-52 lg:pt-44">
          <div className="relative mb-12 md:mb-14 lg:mb-16 lg:w-10/12 xl:mb-24 xl:w-9/12 2xl:w-7/12">
            {headline && (
              <Typography
                tag="h1"
                variant="h1"
                weight="medium"
                className="mb-5 md:mb-6 xl:mb-8"
              >
                {headline}
              </Typography>
            )}
            {subheadline && (
              <Typography
                tag="div"
                wysiwygStyling
                dangerouslySetInnerHTML={{ __html: subheadline }}
              />
            )}

            {!disableCta && cta && cta.href && cta.buttonLabel && (
              <div className="mt-12 md:mt-14 lg:mt-16 xl:mt-24">
                <Button id="head-cta" className="max-md:w-full" asChild>
                  <Link href={cta.href}>
                    <span className="md:hidden">{cta.buttonLabel}</span>
                    <C2A className="max-md:hidden">{cta.buttonLabel}</C2A>
                  </Link>
                </Button>
              </div>
            )}
          </div>

          {underHeader?.title && (
            <div className="flex w-full items-center">
              {underHeader?.title && (
                <div className="relative flex-none text-xs font-medium uppercase text-content-primary-on-dark">
                  {underHeader?.title}
                </div>
              )}
              {underHeader?.title && underHeader.logos.length && (
                <div className={cn("grow", underHeader?.title && "pl-8")}>
                  <div className="h-px -translate-y-px bg-content-primary-on-dark" />
                </div>
              )}
            </div>
          )}
        </div>

        {underHeader?.logos && underHeader?.logos.length > 0 && (
          <div className="bg-gradient-to-t from-content-primary-on-light from-75% to-transparent ">
            <div className="py-8 2xl:container-flexible">
              <LogoLooper
                direction={underHeader.direction}
                speed={underHeader.speed}
                logos={underHeader.logos}
              />
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
