"use client";

import { Image } from "@atoms/image/Image";
import { MountainsHead } from "@sections/mountains/head/MountainsHead";
import { cn, tw } from "@utils/tailwind";
import {
  motion,
  useMotionTemplate,
  useScroll,
  useSpring,
  useTransform,
} from "motion/react";
import { CSSProperties, FC, useRef } from "react";
import { MountainsSectionProps } from "./MountainsSection.types";
import { MountainsBase } from "./base/MountainsBase";

const paddingStyles = tw`[--mountains-padding:3rem]
  md:[--mountains-padding:3.5rem]
  lg:[--mountains-padding:4rem]
  xl:[--mountains-padding:5rem]
  2xl:[--mountains-padding:6rem]`;

const imageHeightStyles = tw`h-[802px] md:h-[871px] lg:h-[768px] xl:h-[905px] 2xl:h-[947px]`;

export const MountainsSection: FC<MountainsSectionProps> = ({
  title,
  subtitle,
  base,
  image,
}) => {
  const headParentRef = useRef<HTMLDivElement>(null);
  const { scrollYProgress: y } = useScroll({
    target: headParentRef,
    offset: ["start", "center start"],
  });
  const smoothY = useSpring(y, { damping: 100, stiffness: 400 });
  const imageY = useTransform(smoothY, [0, 1], [0, 180]);
  const gradientStop = useTransform(smoothY, [0.8, 1], [0, 80]);
  const fadingValue = useTransform(smoothY, [0, 1], [1, 0]);
  const maskImage = useMotionTemplate`linear-gradient(45deg, transparent 0%, black ${gradientStop}%)`;
  const subtitleOpacity = useTransform(y, [0.8, 1], [1, 0]);

  return (
    <section className={cn("text-content-primary-on-light", paddingStyles)}>
      <div
        className={cn("relative pt-[--mountains-padding]", imageHeightStyles)}
        ref={headParentRef}
      >
        <motion.div
          style={
            {
              y: imageY,
              "--mask-image": maskImage,
              "--opacity": fadingValue,
            } as CSSProperties
          }
          className={cn(
            "absolute inset-0 opacity-[--opacity] lg:opacity-100 lg:[mask-image:var(--mask-image)]",
            imageHeightStyles
          )}
        >
          <Image
            unoptimized
            backgroundImage
            imageData={image}
            className="object-[bottom_0_right_-80px] sm:object-right-bottom"
          />
        </motion.div>
        <MountainsHead
          subtitleOpacity={subtitleOpacity}
          title={title}
          subtitle={subtitle}
          className="container-flexible"
        />
      </div>
      <MountainsBase {...base} className="pb-[--mountains-padding]" />
    </section>
  );
};
