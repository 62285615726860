import Typography from "@atoms/typography/Typography";
import { SectionHeader } from "@molecules/section-header/SectionHeader";
import { cn } from "@utils/tailwind";
import { motion } from "motion/react";
import { forwardRef } from "react";
import { MountainsHeadProps } from "./MountainsHead.types";

export const MountainsHead = forwardRef<HTMLElement, MountainsHeadProps>(
  ({ title, subtitle, className, subtitleOpacity }, ref) => (
    <div
      className={cn(
        "sticky top-[calc(var(--navbar-height)+var(--mountains-padding))]",
        className
      )}
    >
      <SectionHeader
        title={title}
        subtitle={
          <motion.div
            style={{ opacity: subtitleOpacity }}
            className="relative h-0 lg:col-span-10 xl:col-span-8 2xl:col-span-7"
          >
            <Typography
              ref={ref}
              wysiwygStyling
              tag="div"
              variant="base"
              dangerouslySetInnerHTML={{ __html: subtitle || "" }}
              className="absolute top-0"
            />
          </motion.div>
        }
      />
    </div>
  )
);

MountainsHead.displayName = "MountainsHeading";
