import Typography from "@atoms/typography/Typography";
import { cn } from "@utils/tailwind";
import { FC } from "react";
import { HorizontalStepProps } from "../HorizontalStepsSection.types";
import styles from "./Step.module.css";

interface HorizontalStepPropsExtended extends HorizontalStepProps {
  activeIndex: number;
  index: number;
  className?: string;
}

export const Step: FC<HorizontalStepPropsExtended> = ({
  title,
  description,
  activeIndex,
  index,
  className,
}) => (
  <div
    className={cn(
      activeIndex === index
        ? "cursor-default bg-[#d8dfe333]"
        : "cursor-pointer bg-content-primary-on-dark opacity-30",
      "h-full rounded-4xl border border-grey-faded px-7 py-8 transition-[background-color,opacity] duration-700 ease-out md:px-8 md:py-9 xl:p-10",
      className
    )}
  >
    {title && (
      <Typography tag="h3" variant="h4">
        {title}
      </Typography>
    )}
    {description && (
      <Typography
        tag="div"
        variant="base"
        className={cn(
          activeIndex !== index ? styles.inactiveSlide : "",
          styles.description,
          "mt-4 md:mt-5"
        )}
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    )}
  </div>
);
