"use client";

import { Image } from "@atoms/image/Image";
import Typography from "@atoms/typography/Typography";
import {
  StatementsCardProps,
  SuccessStoriesCardProps,
} from "@sections/success-stories/SuccessStoriesSection.types";
import { DesktopOverlay } from "@sections/success-stories/overlays/DesktopOverlay";
import { MobileOverlay } from "@sections/success-stories/overlays/MobileOverlay";
import { cn } from "@utils/tailwind";
import { cva } from "class-variance-authority";
import Link from "next/link";
import { FC, useState } from "react";
import { DesktopInvisibleStatements } from "../invisible-statements/DesktopInvisibleStatements";
import { MobileInvisibleStatements } from "../invisible-statements/MobileInvisibleStatements";
import { StatementsCard } from "./StatementsCard";

const rootStyles = cva(`grid max-xl:[grid-template-areas:"mobile-overlay"]`, {
  variants: {
    variant: {
      wide: "",
      narrow: "xl:row-span-2 xl:grid-rows-subgrid",
    },
  },
});

const cardStyles = cva(
  `group/card
  relative
  grid
  min-h-[21.875rem]
  w-full
  cursor-pointer
  content-between
  overflow-hidden
  rounded-[1.25rem]
  bg-content-primary-on-dark
  max-xl:[grid-area:mobile-overlay]
  md:min-h-[20.75rem]
  md:cursor-default
  md:grid-cols-2
  md:content-stretch
  lg:min-h-[18.75rem]`,
  {
    variants: {
      variant: {
        wide: "xl:grid-cols-1",
        narrow:
          "xl:row-span-2 xl:mb-8 xl:grid-cols-1 xl:grid-rows-subgrid xl:content-between",
      },
    },
  }
);

const linkStyles = cva(
  `pointer-events-none
  min-h-48
  p-6
  text-content-primary-on-light
  md:pointer-events-auto
  md:p-8`,
  {
    variants: {
      variant: {
        wide: "xl:grid xl:grid-cols-[293fr_346fr_361fr] xl:gap-x-8 xl:p-0 xl:pl-8 2xl:xl:grid-cols-[302fr_456fr_424fr] 2xl:gap-x-10 2xl:pl-10",
        narrow: "2xl:p-10",
      },
    },
  }
);

const imageWrapperStyles = cva(
  `relative
  h-40
  w-full
  overflow-hidden
  md:h-full`,
  {
    variants: {
      variant: {
        wide: "xl:hidden",
        narrow: "xl:min-h-[19.375rem] 2xl:min-h-[16.875rem]",
      },
    },
  }
);
export const SuccessStoriesCard: FC<SuccessStoriesCardProps> = ({
  image,
  label,
  title,
  statements,
  url,
  variant = "narrow",
}) => {
  const [showMobileOverlay, setShowMobileOverlay] = useState(false);
  const statementsProps: StatementsCardProps = { statements, url };

  const groupHoverTitle =
    "link-animated-base group-hover/link:link-animated-base-hover group-focus/link:link-animated-base-hover";
  const Comp = url ? Link : "div";
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const linkProps = url ? { href: url } : ({} as any);

  const labelStyles = cn(
    "mb-2 py-1 uppercase opacity-50 empty:hidden md:mb-7",
    variant === "narrow" && "xl:mb-3 2xl:mb-6",
    variant === "wide" && "xl:mb-4 xl:pt-8 2xl:mb-5 2xl:pt-10"
  );

  const titleWrapperStyles = cn(
    "transition-transform duration-300",
    variant === "wide" && "xl:row-start-2 xl:pb-12 2xl:pb-14"
  );

  return (
    <div className={rootStyles({ variant })}>
      <div
        className={cardStyles({ variant })}
        onClick={() => setShowMobileOverlay((prev) => !prev)}
      >
        <MobileOverlay {...statementsProps} show={showMobileOverlay} />

        {/* 👇 Link */}
        <Comp
          {...linkProps}
          className={linkStyles({ variant, className: url && "group/link" })}
        >
          <Typography
            tag="p"
            variant="sm"
            weight="medium"
            className={labelStyles}
          >
            {label}
          </Typography>
          <Typography tag="h3" variant="h4" className={titleWrapperStyles}>
            <span className={groupHoverTitle}>{title}</span>
          </Typography>

          {variant === "wide" && (
            <>
              {/* 👇 Mid statements */}
              <StatementsCard
                {...statementsProps}
                url={undefined}
                className="row-start-2 max-xl:hidden xl:pb-12 xl:pl-2 xl:pr-[45px] 2xl:pb-14 2xl:pl-14 2xl:pr-[45px]"
              />

              {/* 👇 Right image */}
              <div className="relative col-start-3 row-span-2 hidden overflow-hidden xl:block">
                <Image
                  backgroundImage
                  maxKnownWidth={1200}
                  imageData={image}
                  className="transition-transform duration-500 xl:group-hover/card:scale-110"
                />
                {!image && (
                  <div className="absolute left-0 top-0 size-full bg-blue-light/50" />
                )}
              </div>
            </>
          )}
        </Comp>
        <div className={imageWrapperStyles({ variant })}>
          <DesktopOverlay
            {...statementsProps}
            className={cn(variant === "wide" && "xl:hidden")}
          />
          <DesktopInvisibleStatements
            {...statementsProps}
            className={cn(variant === "wide" && "xl:hidden")}
          />
          <Image
            backgroundImage
            maxKnownWidth={500}
            imageData={image}
            className={cn(
              "border-content-primary-on-dark transition-transform duration-500 md:max-xl:border-l",
              showMobileOverlay && "max-md:scale-110"
            )}
          />
          {!image && (
            <div className="absolute left-0 top-0 size-full bg-blue-light/50" />
          )}
        </div>
      </div>
      <MobileInvisibleStatements {...statementsProps} />
    </div>
  );
};
