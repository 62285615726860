import Typography from "@atoms/typography/Typography";
import { Avatar } from "@molecules/avatar/Avatar";
import { SpeakerProps } from "@sections/events-list/EventsListSection.types";
import Link from "next/link";
import { FC } from "react";

export const Speaker: FC<SpeakerProps> = ({
  name,
  description,
  link,
  image,
}) => (
  <div className="grid grid-cols-[auto_1fr] gap-3.5">
    <Avatar
      image={image}
      alt={name}
      href={link?.href}
      iconWrapperClassName="hidden md:block"
    />

    <div className="text-balance text-content-primary-on-light">
      {name && (
        <Typography
          tag="p"
          variant="sm"
          weight="medium"
          className="tracking-[0.02em] md:tracking-normal"
        >
          {name}
        </Typography>
      )}
      {description && (
        <Typography tag="p" variant="xs" className="text-grey-mid">
          {description}
        </Typography>
      )}
      {link && (
        <Link
          href={link.href}
          target="_blank"
          rel="noopener noreferrer"
          className="mt-2 block text-xs md:hidden"
        >
          <Typography
            tag="span"
            variant="xs"
            weight="medium"
            className="tracking-[0.02em] underline"
          >
            {link.title}
          </Typography>
        </Link>
      )}
    </div>
  </div>
);
