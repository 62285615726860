"use client";

import { Button } from "@atoms/button";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { SectionHeader } from "@molecules/section-header/SectionHeader";
import { FC, useState } from "react";
import { EventsListSectionProps } from "./EventsListSection.types";
import { Event } from "./components/Event";

export const EventsListSection: FC<EventsListSectionProps> = ({
  title,
  subtitle,
  events,
  themeName,
  anchorId,
}) => {
  const maxEventsAmount = 2;
  const [showAllEvents, setShowAllEvents] = useState(
    events.length <= maxEventsAmount
  );

  // greyScaleOffBlack HSL: 137 10% 14%
  const overriddenColors = "lightBlue:[--twc-text-primary:137_10%_14%]";

  return (
    <ContainerSpacing
      withBackground
      id={anchorId}
      data-strapi="events-list"
      isTransparentBackground
      data-theme={themeName}
      className={overriddenColors}
    >
      <div className="container-flexible">
        <SectionHeader withMarginBottom title={title} subtitle={subtitle} />
        <div className="mb-2.5 grid">
          {events.map((event, ind) => (
            <Event
              key={event.id}
              className={
                !showAllEvents && ind >= maxEventsAmount ? "hidden" : ""
              }
              {...event}
            />
          ))}
        </div>
        {!showAllEvents && (
          <div className="mt-4 md:mt-8 xl:mt-9 2xl:mt-10">
            <Button
              variant="tertiary"
              onClick={() => setShowAllEvents(true)}
              className="mx-auto !px-0 underline decoration-transparent underline-offset-4 transition-colors hover:decoration-current max-md:w-full"
            >
              Load more
            </Button>
          </div>
        )}
      </div>
    </ContainerSpacing>
  );
};
