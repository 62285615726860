import {
  LegacyButtonProps,
  IconProps,
} from "@atoms/legacy-button/Button.types";
import { LegacyButton } from "@atoms/legacy-button/LegacyButton";
import { cn } from "@utils/tailwind";
import { FC } from "react";

export const PillButton: FC<LegacyButtonProps & IconProps> = (props) => (
  <LegacyButton
    {...props}
    icon="ArrowRightShort"
    size="small"
    className={cn("size-max px-4 py-2 sm:px-8 sm:py-3", props.className)}
    iconClassName={cn("w-auto", props.iconClassName)}
  />
);
