import Typography from "@atoms/typography/Typography";
import { FC } from "react";
import { EventProps, Period } from "../EventsListSection.types";

const getTitle = (place?: string, period?: Period) =>
  [place, period ? `${period.from} - ${period.to}` : ""]
    .filter((it) => !!it)
    .join(" ");

export const EventInfo: FC<EventProps> = ({
  location,
  period,
  description,
  href,
}) => (
  <div className="mb-8 mt-1.5 text-balance text-grey-scale-off-black md:my-5 lg:my-4 xl:my-5">
    <Typography
      tag="p"
      variant="sm"
      weight="medium"
      className="mb-1 uppercase tracking-[0.04em] md:mb-4 xl:mb-5"
    >
      {getTitle(location, period)}
    </Typography>
    {description && (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="block underline decoration-transparent underline-offset-4 outline-none transition-colors hover:decoration-current focus:decoration-current"
      >
        <Typography
          tag="span"
          variant="h4"
          weight="medium"
          className="font-termina"
        >
          {description}
        </Typography>
      </a>
    )}
  </div>
);
