import { range } from "lodash";

export const withoutEmpty = (items: (string | undefined | null)[]): string[] =>
  items.filter((it) => !!it) as string[];

export const notNil = <TValue>(
  value: TValue | null | undefined
): value is TValue => value !== null && value !== undefined;

/**
 * e.x. for arr = [1, 2, 3] and desiredSize = 5, result will be [1, 2, 3, 1, 2]
 */
export const padArray = <T>(arr: T[], desiredSize: number) => [
  ...arr,
  ...range(Math.max(0, desiredSize - arr.length)).map(
    (i) => arr[i % arr.length]
  ),
];

/**
 * e.x. for arr = [0, 1, 2, 3, 4] and startingInd = 2 and sliceSize = 5 it
 * returns an array [2, 3, 4, 0, 1]. If it goes out of bounds then it adds
 * elements from the beginning (circularly)
 */
export const circularSlice = <T>(
  arr: T[],
  startingInd: number,
  sliceSize: number
) => range(sliceSize).map((offset) => arr[(startingInd + offset) % arr.length]);
