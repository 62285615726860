import { cn } from "@utils/tailwind";
import { FC } from "react";
import { StatementsCardProps } from "../SuccessStoriesSection.types";
import { StatementsCard } from "../cards/StatementsCard";

/** Invisible statements to set correct height for the parent element */
export const DesktopInvisibleStatements: FC<StatementsCardProps> = ({
  className,
  ...props
}) => (
  <StatementsCard
    {...props}
    className={cn("invisible h-fit max-md:hidden", className)}
  />
);
