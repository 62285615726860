"use client";

import { Image } from "@atoms/image/Image";
import { ApiImage } from "@page-components/types";
import { screens } from "@tailwind/screens";
import { circularSlice, padArray } from "@utils/array/array-utils";
import { cn } from "@utils/tailwind";
import { FC, useEffect, useRef, useState } from "react";
import styles from "./Slideshow.module.css";

const calcColumnsAmount = (windowWidth: number) => {
  const isMd = windowWidth >= screens.md && windowWidth < screens.lg;
  const is2xl = windowWidth >= screens.xxl;
  return isMd || is2xl ? 3 : 2;
};

export type SlideshowProps = {
  logos: ApiImage[];
};

export const Slideshow: FC<SlideshowProps> = ({ logos }) => {
  const iconsAreaRef = useRef<HTMLDivElement>(null);
  const [tick, setTick] = useState(0);
  const [columns, setColumns] = useState(0);
  const isAnimating = columns > 0;
  const slidesAmount = columns * 2;

  const slides = circularSlice(
    padArray(logos, slidesAmount),
    tick * columns,
    slidesAmount
  );

  useEffect(() => {
    const handleResize = () => {
      const cols = calcColumnsAmount(window.innerWidth);
      const w = iconsAreaRef.current?.getBoundingClientRect()?.width;
      setColumns(cols);
      if (w) {
        iconsAreaRef.current.style.setProperty("--col-width", `${w / cols}px`);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      ref={iconsAreaRef}
      // --col-width will be changed dynamically
      className="w-full self-center overflow-hidden [--col-width:0px]"
    >
      <div
        key={tick}
        className={cn(
          "grid h-14 w-max md:h-20 lg:h-28",
          slidesAmount === 4
            ? "grid-cols-[repeat(4,var(--col-width))]"
            : "grid-cols-[repeat(6,var(--col-width))]",
          isAnimating &&
            (columns === 2 ? styles.animate2Cols : styles.animate3Cols)
        )}
        onAnimationEnd={() => setTick((prev) => prev + 1)}
      >
        {slides.map((slide, ind) => (
          <div
            key={`${tick}-${columns}-${ind}`}
            className={cn(
              "relative mx-2.5 flex h-full items-center md:mx-4 lg:mx-3 xl:mx-5 2xl:mx-8",
              isAnimating && styles.fadeOut
            )}
            onAnimationEnd={(e) => e.stopPropagation()}
            style={{ animationDelay: `${ind * 2}s` }}
          >
            <Image
              backgroundImage
              imageData={slide}
              loading="eager"
              maxKnownWidth={500}
              className="object-contain"
            />
          </div>
        ))}
      </div>
    </div>
  );
};
