import Icon from "@atoms/icon/Icon";
import { Image } from "@atoms/image/Image";
import { ApiImage } from "@page-components/types";
import { cn } from "@utils/tailwind";
import { cva } from "class-variance-authority";
import { FC } from "react";
import { twMerge } from "tailwind-merge";

const linkStyles = cva("group relative block rounded-full outline-none", {
  variants: {
    size: {
      small: "size-[3.688rem]",
      big: "size-32 md:size-[8.375rem] xl:size-36",
    },
  },
});

const linkedinIconStyles = cva(
  "absolute overflow-hidden rounded-full border-2 border-transparent text-grey-mid transition-transform duration-200 ease-in group-focus:border-green-accent-two",
  {
    variants: {
      size: {
        small:
          "-bottom-1.5 -right-0.5 size-6 group-hover:translate-y-[-0.313rem]",
        big: "-bottom-0.5 -right-0.5 size-9 group-hover:translate-y-[-6px] lg:size-[38px] xl:size-[42px]",
      },
    },
  }
);

export type AvatarProps = {
  image?: ApiImage;
  alt?: string;
  size?: "big" | "small";
  href?: string;
  className?: string;
  iconWrapperClassName?: string;
};

export const Avatar: FC<AvatarProps> = ({
  href,
  image,
  alt,
  className,
  iconWrapperClassName,
  size = "small",
}) => {
  const Wrapper = href ? "a" : "div";
  const props = href ? { href, target: "_blank", rel: "noreferrer" } : {};

  return image ? (
    <Wrapper {...props} className={twMerge(linkStyles({ size, className }))}>
      <Image
        backgroundImage
        alt={alt || ""}
        maxKnownWidth={size === "small" ? 360 : 500}
        imageData={image}
        className="rounded-full object-cover"
      />
      {href && (
        <div
          className={twMerge(
            linkedinIconStyles({ size, className: iconWrapperClassName })
          )}
        >
          <Icon
            name="LinkedInGray"
            className={cn("size-full", size === "small" && "scale-125")}
          />
        </div>
      )}
    </Wrapper>
  ) : null;
};
