"use client";

import Icon from "@atoms/icon/Icon";
import ContainerSpacing from "@layout/container-spacing/ContainerSpacing";
import { SectionHeader } from "@molecules/section-header/SectionHeader";
import { screens } from "@tailwind/screens";
import { cn } from "@utils/tailwind";
import { FC, useRef, useState } from "react";
import "swiper/css";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { HorizontalStepsSectionProps } from "./HorizontalStepsSection.types";
import { Step } from "./step/Step";

export const HorizontalStepsSection: FC<HorizontalStepsSectionProps> = ({
  title,
  description,
  steps,
  anchorId,
}) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef<SwiperCore>();

  const goToSlide = (index: number) => {
    swiperRef.current?.slideTo(index);
  };

  const nextSlide = () => {
    swiperRef.current?.slideNext();
  };

  const prevSlide = () => {
    swiperRef.current?.slidePrev();
  };

  const formatNumber = (num: number) => String(num + 1).padStart(2, "0");

  return (
    steps &&
    steps.length > 0 && (
      <ContainerSpacing
        id={anchorId}
        data-strapi="horizontal-steps"
        className="overflow-hidden"
      >
        <div className="container-flexible text-content-primary-on-light">
          <SectionHeader
            withMarginBottom
            title={title}
            subtitle={description}
          />
          <div className="grid grid-cols-6 gap-y-5 md:gap-x-5 lg:grid-cols-12 2xl:gap-x-10 2xl:gap-y-6">
            {steps.length === 1 ? (
              <Step
                {...steps[0]}
                activeIndex={0}
                index={0}
                className="col-span-6 lg:col-span-12"
              />
            ) : (
              <>
                {/* Pagination */}
                <div className="col-span-6 flex flex-wrap gap-x-6 gap-y-3 md:col-span-5 lg:col-span-6 xl:col-span-7 xl:gap-x-9 2xl:col-span-5">
                  {steps.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => goToSlide(index)}
                      className={cn(
                        "text-base font-medium transition-colors duration-700 ease-out xl:text-lg",
                        index === activeIndex
                          ? "text-green-accent-two"
                          : "text-content-primary-on-light"
                      )}
                    >
                      {formatNumber(index)}
                    </button>
                  ))}
                </div>
                {/* Navigation buttons */}
                <div className="col-span-6 hidden h-6 justify-between md:col-span-1 md:col-start-6 md:flex md:justify-end lg:col-start-7 xl:col-start-8 xl:h-7 2xl:col-start-6">
                  <button
                    onClick={prevSlide}
                    className={cn(
                      "translate-y-px rotate-180 transition-opacity duration-700 ease-out",
                      isBeginning && "opacity-35"
                    )}
                    disabled={isBeginning}
                  >
                    <Icon name="ArrowRight" className="h-auto w-6" />
                  </button>
                  <button
                    onClick={nextSlide}
                    className={cn(
                      "ml-[18px] transition-opacity duration-700 ease-out",
                      isEnd && "opacity-35"
                    )}
                    disabled={isEnd}
                  >
                    <Icon name="ArrowRight" className="h-auto w-6" />
                  </button>
                </div>
                {/* Swiper */}
                <div className="col-span-5 md:col-span-4 lg:col-span-7 xl:col-span-8 2xl:col-span-6 2xl:col-start-1">
                  <Swiper
                    spaceBetween={12}
                    speed={700}
                    breakpoints={{
                      [screens.md]: { spaceBetween: 20 },
                    }}
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                    onSlideChange={(swiper) => {
                      setActiveIndex(swiper.realIndex);
                      setIsBeginning(swiper.isBeginning);
                      setIsEnd(swiper.isEnd);
                    }}
                    className="!overflow-visible"
                  >
                    {steps.map((step, index) => (
                      <SwiperSlide
                        key={index}
                        onClick={() => swiperRef.current?.slideTo(index)}
                        className="!h-[initial]"
                      >
                        <Step
                          {...step}
                          activeIndex={activeIndex}
                          index={index}
                        />
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </>
            )}
          </div>
        </div>
      </ContainerSpacing>
    )
  );
};
