import Typography from "@atoms/typography/Typography";
import { ArrowIcon } from "@molecules/arrow-icon/ArrowIcon";
import { cn, tw } from "@utils/tailwind";
import Link from "next/link";
import { FC } from "react";
import { YourNeedsCardProps } from "./YourNeedsCard.types";

const rootStyles = tw`group/link
  relative
  flex
  translate-x-px
  translate-y-px
  flex-col
  border-b
  border-green-accent-two
  px-6
  py-8
  focus-within:bg-black/5
  focus:outline-none
  md:p-9
  lg:border-r
  lg:p-10`;

export const YourNeedsCard: FC<YourNeedsCardProps> = ({
  title,
  description,
  href,
  color,
  linkBlockPresent,
  linkPosition = "top",
}) => {
  const Comp = href ? Link : "div";
  const topLinkPresent = linkBlockPresent && linkPosition === "top";
  const bottomLinkPresent = linkBlockPresent && linkPosition === "bottom";

  return (
    <Comp
      className={cn(rootStyles, bottomLinkPresent && "justify-between")}
      target={href ? "_self" : undefined}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      href={href ? href : (undefined as any)}
    >
      <div
        className={cn(
          "absolute right-6 top-8 size-10 lg:relative lg:right-auto lg:top-auto lg:mb-7 lg:h-10 lg:w-full",
          !topLinkPresent && "hidden"
        )}
      >
        <ArrowIcon
          color={color}
          className={cn("absolute right-0", !href && "hidden")}
        />
      </div>
      <Typography
        tag="div"
        variant="xl"
        weight="medium"
        className={cn(
          "mb-4 font-termina md:mb-5",
          topLinkPresent && "pr-20 lg:pr-0",
          bottomLinkPresent && "md:pr-28 lg:pr-0"
        )}
      >
        {title}
      </Typography>
      <div className="flex items-end justify-between gap-4 md:gap-16 lg:gap-5">
        <Typography
          wysiwygStyling
          tag="p"
          variant="base"
          className={topLinkPresent ? "pr-20 lg:pr-0" : ""}
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <ArrowIcon
          color={color}
          className={href && bottomLinkPresent ? "flex-none" : "hidden"}
        />
      </div>
    </Comp>
  );
};
