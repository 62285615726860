import { SVGProps } from "react";

const MountainsMobile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 640 683"
    {...props}
  >
    <defs>
      <linearGradient
        id="linear-gradient"
        x1="-87.6"
        y1="1204.3"
        x2="-31.9"
        y2="1286.1"
        gradientTransform="translate(680 -1092)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#046123" />
        <stop offset="1" stopColor="#08190c" />
      </linearGradient>
      <linearGradient
        id="linear-gradient1"
        x1="-394.3"
        y1="1344.6"
        x2="-353.4"
        y2="1354.3"
        gradientTransform="translate(680 -1092)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#027b13" />
        <stop offset="1" stopColor="#0b4522" />
      </linearGradient>
    </defs>
    <path
      d="M438,491c2.2-2.1,3-1,3.9,1.7v16.9c4.9,2.4,6.3-1.2,4.1-10.8-.4-5.8,15.1-5,17.6-2.2.9,4.3,1.4,8.8,1.7,13.4,5,1.5,10.6,1.5,16.5,0,47.1.8,94,1,140.7.4-.8-12,2.4-16.3,9.7-13,.1,4.9,2.3,6.7,6.6,5.6l1.3,2v178H0v-171l.2-1.4c12.3-.5,24.9-.8,37.8-1,.5-10.8,11.9-10.1,12.4.3h26.9c1.8-5.9,3-8.7,10.4-8.2,7.4.4,14.9.5,22.3.3-.4-3.2.3-5.2,2.1-6,.8-3.1,15.5-2.6,16.9-1.4s1,1.2,1.2,1.9c-2,5.2,1.3,6.7,9.9,4.6,6.6-1,8.2,8.1,4,10.9,1.6-3.3,14.1-3.1,16,0-5.8-2.7,1.9-9,7-6l2-2.2c6.9-.5,13.8-.8,20.8-.8l2.2,2c3.7-2.1,7.6-.4,11.5,5.2,2.9-1,5.7.5,6.7,3.4,15.2,3.7,32,4.5,47.7,4.8,9,.2,16.2,0,24.4-3.5,13.7,1.1,26.6-.6,38.7-5,4.4-.8,17.8-1.9,17.1,4.2l7.7.4c6.3,4.1,16.9,5.7,23,1.7,7.3,2.2,14.9,1.7,22.9-1.5l21.4-.7c1.7-3.6,5.2-4.9,10.7-4.1,1.3-2.9,1.7-6.4,1.1-10.6.3-3.5,2.6-9.1,6.1-6.4-.4-3.2,5.1-4.4,7-2Z"
      style={{ fill: "#181818" }}
    />
    <path
      d="M480,416c.1-3.5,7.7-2.8,8.1.8.7,6.7,2.9,14.9,11.2,12.2,7.6-3.4,12.4-2.5,19.6,1l18.5-.6c6.7-.3,9.6,2.4,8.5,9,2.7,2.1,3.7,4.3,3.1,6.4.8-.5,1.5-1.5,2-3-4.9-1.5.2-4.7,4.1-4.2-.7-3.9,0-6.6,2.3-8.1l17.4-.6,1.3,1.8c1.2-.7,1.6-.2,1,1,1.4,3.3,4.3,5.4,8.6,6.3,2.9,10.5,4.5,8.7,4.8-5.5,1.2-4.9,18.1-3.3,18-.9s-.2,1.1-.4,1.6c3.2,1.3,3.1,12.9,0,14.5,1.6-3.3,14.1-3.1,16,0-3.3-1.6-3.1-14.1,0-16,4.6-2.9,11.6-3.1,16,0v33l-1.2,2.1h-11.5c0-.1-.3,8.4-.3,8.4-.5,7.5-14.9,7.7-12.9-1.1-6-.2-7.9-1.8-9.4-7.4-7-1.9-10.8-.2-11.4,5.1-3.4,3.2-31.3,5.1-27.2-6.4l-22.6.3-1.4-2c-8.2.5-16.4.7-24.6.6-13.7,3.6-29.3,3.1-20.9-11.2-1.1-2.2-1.2-4-.4-5.4-2.2-3.2-6.5-2.2-9.7-.8l-.9-.6c-.8-4.7-2.6-8.5-5.4-11.6-1-1.2-5.2-8.7-4.9-9.9s.5-.8.9-1.1c-3-2.9-1.5-5.4,2-6.8l2-1.2Z"
      style={{ fill: "#131716" }}
    />
    <path
      d="M640,89v113c.2,4.7-18.2,6.1-19.7,5.3s-1.5-1.1-1.6-2.3c.5-6.5.5-9.4-6.8-11-7.2,1.2-13.2.2-17.9-2.9-.9-.8-1.2-1.8-1-3-2.9-.6-3.6-2.3-1-4-2.6-1.6-4.2-1-6.4.9l-1.6-.9c-2.8,1.6-4.5,1.6-5,0-2.3.2-2.3-.8,0-3,1.4-4.9,4.4-12.4,10.7-13.6-.6-3.9,1.2-6.9,5.5-8.8l8.8-8.5c-.8-.8-.1-1.8,2-3-.6-1.9.4-2.3,2-1,1.1-1.9,1.1-2.6,0-2-1.9,2.3-4.1,2.3-6,0-2,2.1-7.8,3.9-10,2-2-1.1-2.2-2.9,0-4-2.1-.3-1.3-1.7,0-3-.9-.2-.9-.5,0-1-1.7-1.4-1.3-4.3,0-6-2-.3-.9-2.6,0-4-2,0-1.4-.8,0-2-3.2-1.5.8-11.1,4-8,.2-1.2.9-1.9,2-2,3-7.2,7-13.3,12.4-18.8l3.6-1.2c.2-.9.5-.9,1,0,1.1-3,7.1-3.1,9-1,2.2-.3,2.5,1,1.4,2.8,2.7-1.9,4.9-2.5,6.6-1.8.2-1.6,1.4-3.1,3.4-4.8.3-3.8,2.7-6.6,4.6-2.2Z"
      style={{ fill: "url(#linear-gradient)" }}
    />
    <path
      d="M469,460c1.1-.9,1.9-.9,2.9-.2,5.5-5.6,8.9,3.9,2,6.1.3,3.3-.7,6.6-3,9.7,1.4,3.5,2.8,6.9,4.3,10.2h82.6c2.9,1,4.5,3.4,4.7,7.2,9.5,1.6,19.5,1.8,29.9.6,3.7-.5,6.6.3,8.8,2.3,1.1,11,4.4,5.3,6.8-.6l1.6.6c0-.5.2-1,.5-1.3.8-.9,18.5-.9,21.1-.7l1.4,2c1.2-2.5,6.2.2,7.5,2v7c-2.7,0-7.1.9-8.5-2s.6-6.5-4-6,1.2,15.1-6,16c-16-.1-32,.2-48,0-30.2-.3-60.6-1.6-91-1.1s-17.7,3.7-19.3-1.7c-3.4-11.4,6.7-14.8-15.2-13.3-.6,8.8,5.2,17.3-8,15-.3-5.4.4-11.2,0-16.6s.2-3.7-2.1-4.4c-.9-1.6,0-3.5,2.9-5.6l5.2-11.6c.5-1.2,1.7-1,2.9-.7,3.7-2.2,8-3.1,12.9-2.9-.5-3.1.2-5.2,2.1-6.1-4.6-2.8,2.3-6.2,5-4Z"
      style={{ fill: "#171818" }}
    />
    <path
      d="M486,450l2.1,1.5c-1.8,8.4.5,12.8,6.9,13.2,1.8,7.1,2.9,8,10.5,7.5,5.5-.4,5.9-.8,6.8-6.3,9.7-1.8,19.6-2.1,29.7-.8,2.2,1.3,2.8,4.4,1.9,9.3,0,1.6.4,4.1,1.9,4.9,4.1,2.4,36.4,1.2,43.3.6s1.6-.8,2-1.5c1.9-9.3,9.3-10.4,10.3-.2,1.1,1.3,21.6,3.4,23.6,1l13.7-.3,1.3,2.2v17c-2.4,1.4-7-2-7.5-2h-23c-2.3,0,.3,7.9-5.9,8-6.4,0-1.7-6.7-5.2-7.8-10.3-1.6-25.1,1.7-34.8-.2s-1.6-7.1-6-8l-82.8-.2c-3.3-1-2.1-4.1-3-6s-3.1-2.5-2.9-6.3,2.4-3.5,2.9-5.2-.2-5.5.3-6.2c1-1.1,5.6.8,2.9-5.1l-3,4-3-3c-.6-1.7,3.2-7.7,4.7-9.2l2.3.2c1.5-.9,2.3-.4,3.4.6.7-3.7,5.4-5.5,6.6-1.6Z"
      style={{ fill: "#161817" }}
    />
    <path
      d="M68,464l.8-1.9c14.4-1.4,14.7,3.4,11.2,15.9h14.5s1.4,2,1.4,2c5.2,0,4.5,6.6,2,10-1.1,3.4,1.6,4,8.3,2,2.7,0,6.3.8,5.8,4-.2,1.9.7,6.4-.9,7.6-2.8,2-23-.9-28.4.6-4.3,1.3-.9,7-5.2,7.8s-26.5.4-27.9-.1c-2.8-1-.3-7.5-5.2-7.9s-3.7,5.3-4.9,7.1-34.2.3-39.6.9v-16c1.5-2.8,2.1-5.9,1.6-9.3,9.3-.8,18.7-1.1,28.3-.9-.6-6.9,2.5-8.2,8.9-7.9l19.7.3c3,.3,5.1-.5,7.3-2.6l.3-10.4,1.9-1.2Z"
      style={{ fill: "#151716" }}
    />
    <path
      d="M608,256c3.9-3.8,5.2,5.3,2,8-.2,2.2-.4,4.4-.6,6.6,4.1,7.2-1,8.8-7.4,8.4-3.5.2-3.5,4.3-2.6,7,7.1-.6,11.2-.4,10.8,8.3,7.9-1.8,12.8-.3,14.7,4.3,4.3-1.4,6.4-.9,7.9,3.6,4-.9,6.4-.3,7.1,1.8v32c-.6,2.8-2.4,2.6-4.3.7-2.4-3.3-4.5-4.2-5.5.3-3.7,2.4-19,1.7-23.1-.7-7.2,1.8-14.9,2.3-23.1,1.5-3.7,5.5-8,3.1-7.8-3.1,1.9-3.1,1.2-6-.2-6.8-4.6,2.9-7.3-3.4-1.9-5-2.3-1.9-4.1-2.4-7.1-2.6l-1-2.4c-2.8-.9,0-6,1.5-7.4l6.3-3.6c-.1-4.4,2.6-6,8.3-4.8l1.1-5.9c-1.6-4.7-1.9-12.8,4.3-8.6l2-1.7c4.8,0,6-3.9,3.6-11.9,3-2.6,5.6-5.7,7.6-9.3-1.4-1.3-1.2-2.3.2-3.6q4-1.4,0,0c-8.5-.2,3.2-11.1,7-5Z"
      style={{ fill: "#080f0b" }}
    />
    <path
      d="M624,210c-1.5-2.3,6.7-1.3,2.6.6.7,1.8.5,3.8-.6,5.4h1c-4.5-9.5,8.3-9,13-3v91c-2.1-.4-7.6.7-8.9-.1s-.4-3.7-1.7-3.9c-2.8-.6-4.6,2-4.9,2.1-1.6,0-.9-5.2-3.1-5.9s-12.5.8-13.4,0,1-6.2-.9-7.6c-3.7-2.6-10.5,3.7-10.1-5.9.4-9.2,11.3-3.3,12-7.1s-2-3.6-4-3.9c5.3-2.5,2.1-3.4,1-7.5,1.3-.2,2.8.2,4,0l1.5-3c-.6-5.2,1.1-7.6,5.1-6.9-.4-6.3,1.4-9,5.5-8.1.5-.7,0-9.2-3.1-8-3.7,1.8-2.9-4.4,1-3,1.2-3.8,3.9-2.7,8.1,3.2-2.6-4.2-4-8.4-4-12.7l-2.1-1.6c-3.9-.2-8.6-8.4-3-8,.5-1,.5-2.5.1-4.7l4.9-1.3Z"
      style={{ fill: "#060d09" }}
    />
    <path
      d="M5,0c5.2,2.7,6.2,8.5,3.3,13.6l.7,2.4,1.6,2.3c-1,6.9-.1,14.2,4.2,19.4-.9,2.5-.8,4.9.2,7.2l-.2,3.1c-5.1,4.1-5.4,5.6-1,4.6l1.4,1.7c8.2-1.5,5.9,9.4,3,13.8l3.9,2.4c-1,1-.5,1.8,1.4,2.4-.7,3.3-.5,6.2.6,8.8,3.8,1.8,1.8,4-1.9,4.7l.8,2.6c2.6,3.8,6.5,11.1,2.1,13.7l-.6,2.1c-4.6.6-5.5,4.3-.7,5.5,5,6.3-3.1,15.2-2.7,7.3-.5-2.4-.8-2.4-1,0,1,2.3,1.6,5,1.6,8.3.6-4.2,2.6-6.9,6.4-2.5,1.3-1.5,2.2-2.3,4.1-.8,2.9,1.1,2,4.8-1,5-1.3,4.6-13.3,8.7-16.6,4.2,1.1-4.9-4.7-11.2-4.9-3.8-2.5,2-5.7,3.2-9.5,3.6V0h5Z"
      style={{ fill: "#07140a" }}
    />
    <path
      d="M0,132c3.2-4.9,4-2.4,6.3-3.1s.4-6.2,3.2-7.9c.9,2,5.3,3.8,6,5,2.1,3.5-2.7,7.6,5.9,5.9s6.5-3.3,9.6-3.9c4.3,1.6,6.1,10.3,3,14.2,6.3,3.3,9.1,8.6,6.8,15.6,6.4,3,2.4,7.7-3.4,4.5l.6,1.6c6.9,3.4,0,10.7-6,10.4-.3,3.3-2.1,5.7-5.7,5,.8,3.8-1.2,10.4-4.2,5.7l-2.3-1.1c.7-11.3-10.7-6.2-7.7,3.2,0,3.7-1.9,6.1-5.5,7,8.5,3.3,0,20.8-6.5,14.9v-77Z"
      style={{ fill: "#08170b" }}
    />
    <path
      d="M85,0c2,.7,1.9,1.6,1.5,3.3l2.5-.3c.3-.9.6-.9,1,0,4,.3,3.6,6.4,0,7.9v5.2c3.6,2.6,3.2,4.7,1.1,8,2.4,2.1,3.5,4.1,3.4,5.9,1.2-2.2,4.3-2.9,9.3-2,4.1-.8,6.9-.1,8.2,2,3.6,2.2,2.5,9-.7,11.6l3.2,1.3c4.4-.3,5.3,3.7,5.3,7.7,5.7,2.9,3.3,9.3-3,6.4.2,2.7.1,4.4-2.8,4-.9,1.1-.2,1.5,1,2,6-3.5,5.3,4.2,4.5,8.5,2.3,1.8,3.6,5,1.2,7.2.8.7,1.2,1.8,1.2,3.3,3.5.4,2.6,7-1,7-.1,1.2.2,1.8,1,2-.1-5.2,6.1-1.9,2.9,2.2.8,4.6-.3,14-3.9,16.8-3.2,2.1-5.1-.4-5.7-7.6l-1.5-1.4c-8.4,9.5-11.5,5.6-9.4-4.9-6-1.4-7.1-8.3-6.2-14.4l-1.2-1.6c-1.9,3.6-4.8,2.1-5.1-2-6.5,1.6-6-8.4.4-8.3-1.2-2.6-2.4-5.2.9-6.8,4.6-2.8.9-.1-1.8.9-5.3,2-7.2-1.6-3.6-5.8-3.8-3.5-4.3-6.9-1.7-10.2-1.8-2.2-3.2-4.6-4.3-7.3,3.4-3.6-.3-6.4-3.5-8.3-.2-2.9-.3-5.7-.3-8.3-.9-.3-.9-.6,0-1-.9-.3-.9-.6,0-1l1-3c-2.4-1.4-2.7-5.4,0-6.8-2.8-2.7-3.8-5.1-3-7.2-.3-2.1,2.2-4.2,4-5h5Z"
      style={{ fill: "#0c110c" }}
    />
    <path
      d="M22,185l-.2,1.5c2.7-.7,3.7.2,4,3,5.6-.6,8.8,2.7,8,8.2,4.5-.6,9.2,2.6,4.2,5.2,5.7.5,9.4,14.2,3.4,14.9s-3.7-3.4-3.5-5.7c-4.9,3.3-6.6,5.3-5.1,11.3l-1.8,1.6c-.3.9-.6.9-1,0-3.8,2.7-6,.4-6.1-3.9-3.7.8-4.6-1.7-4.7-5l-3,.5c1,3.5.6,6-1.2,7.4-1.1.4-1.5,1.6-1.4,3.4-5.3,2.4-7.3,12.9-.6,14.2,1.1,6.7,5.5,6.9,5.2-.3l1.8-1.3c-.8-3.6,8.9-2.9,11.8-2.5s.5,1.6.2,2.5c-.3,1-.5,2,0,3,1.9.6,1.9,1.4,0,2-.5,1.2-1.1,3-1.7,5s-.4.1-1,0c2.6,5,1.6,10-4.8,7-1.9,1.6-4.6,1.9-6.4.1.7,7.7-1,9.3-8.2,10.6,4.7,9.5-4.5,9.4-9.9,5.2v-64c5.5.7,8.7-7.2,8.1-11.4s-5.6-2.1-4.1-5.6c11.9.8.5-14.4,10.6-15.9s5.7,3.3,7.4,8.9Z"
      style={{ fill: "#06190c" }}
    />
    <path
      d="M640,394v38c-5.3,0-10.7.1-16,0,0,.8-.2,1.9-.8,2-7.9.9-9.6,0-9.5-8.1l-17,.6c-4,.3-13.2-.7-8.7-5.5-1.9-.6-2-1.5,0-2l-1.4-2.2c4.9-2.9,2.4-3.8-7.4-2.7l-1.2-2.1c-2.1-2.4,1-3.7,3.5-3.9,8.1.5,9.5-.2,4.2-2-3,.1-9.1-.1-6.5-4.2,4.5.5,8.1-.1,10.8-1.8l.4-1.3c3.8,0,6.9-.6,9.4-1.7.3-4.8.5-5.3,5-6.6,7.7-2.2,21.6-.8,26.8,5.7,2.4-3.1,6.3-7,8.4-2Z"
      style={{ fill: "#0f1612" }}
    />
    <path
      d="M588,360c2.9-6.1,6.9,6.5,5.3,9.6l2.4.3c6.2-3,11.2-1.6,15.2,4.2,3.7-1.5,7.5-2.6,11.5-3.2.8-3.3,4.2-3.5,5.4-.3,3.1-2.8,9.2-7.6,12.1-2.5v26c-5.3-1.6-5.2,2.3-7,3.5-3.2,2.2-7.3-4.6-9.7-5.3s-20-.6-20.8.7.3,5.2-2.4,6.6-7.2.4-10,.5c-4.2,3.5-19.7,2.7-15-5-1.9-1.7-2.6-3.7-2.1-5.9l2.2-18.7,1.9-1.4c.9-2.5,8.6-10.5,9.4-10.5s1.3.7,1.6,1.5Z"
      style={{ fill: "#0c1410" }}
    />
    <path
      d="M0,432c.4-4.2,6.7-2.9,8.7.2,3,7.7,4.2,7.7,6.2-.4,1.9-2.2,30.7-2.9,32.9-1.8,3.1,1.7,3.5,5.7,1.2,8,2.8,1.5,1.2,6.6-.1,8.6,3.5-1.3,7.1-.9,10.1,1.4,1.9.3,1.7,1,0,2,3.6,1.3,1,5.5-1.1,7.7.5,5.6-.6,7.7-3.4,6.3,0,.8-.2,2-.7,2-7.6.5-30.1,1.3-36.6,0l-1.2-2c-2,0-2.6-1.9-2-5.5-.4,8.1-9,10.2-14,5.5v-32Z"
      style={{ fill: "#121514" }}
    />
    <path
      d="M122,0c3.2,3.8,4.2,8.5,2.9,14,7.4-1.8,6.6,13.3,3.1,16l-.4,1c2.4,4.4,4.4,14.2-1.5,16.2l.5,3.5c3.4.8,6.1,6.9,2.4,8.2-1.6,4.5-3.5,5.5-5.7,3l1,3c4.7.1,4.5,7.4-.9,6.3,1.1,2,1,3.6-.4,4.7,1.5,1.5,1.9,6-1,6l-5-3.5c-.4-3.6,5.9.2,4-4s-2.7-.4-3.6-1.4,1.2-6.3.6-8.4-2.1-.6-3-1.6c-1.8.7-2.7-.7-1-2,0-.4,1.5-2.6,1-3s-3.3,1-3-1c2.9,1.3,3.9-3,4.5-3s5.4,4.5,4.5.5-2.6-2.5-2.7-2.8c-1.3-2.7,1.8-4.5-4.3-3.7l4-3c-4.4.7-8.6-1.9-12-4.5,7.5.4,5.9-4.5,6-10.5h-17.5c-.3,0-4-3.1-4.5-3.6-1-1-.8-3-1.2-3.3-1-.8-5.5,1.3-6.8-3.7s5.5,1.8,6,1.9c4,1,3.9-2.4,0-4V6c1.5-.4.6,3.7,3,2s-1-4.4-1-5c-.7-1-.2-2.8,1-3h31Z"
      style={{ fill: "#0a0f0b" }}
    />
    <path
      d="M29,384c3.3-4.3,4.8.3,6,4,2.6-2.4,3.5,4.2,3.7,6.7l1.3-.7c.3-.9.6-.9,1,0l2.3.2c.9,2.9,2.9,4.6,6.2,5.1,0,11.5,2.5,11.3,13.3,10.8,3.7.7,5,5.1,1.2,6-.8,1.7-2.7,2.2-5.5,1.7-.6,5.3-2,10.4-4.3,15.4,7.3,7.5-1.9,12.3-5.2,4.9,0-.5-1.8-3.7,0-6-4.8.2-31-1.1-32.5,1s.3,7.1-4.8,6.7-1.2-10.8-11.6-7.7v-16c3.8-6.3,9.1,0,9.3,6.1,1.5-6.6,3.6-9.9,10.7-7.6-3.2-1.3-3.1-12.9,0-14.5l1-2.1c4.3,1.1,6.8-.2,7.4-4-2-3-2.8-8,.5-9.9Z"
      style={{ fill: "#0f1612" }}
    />
    <path
      d="M64,448c4,2.8,2.1,8.8-1.1,11.6,2.5,1.3,4.2,2.8,5.1,4.3.3,1.9-.5,10.4,0,11,1,1,7.9-1.2,5.9,5-2.4.7-4.3-3.8-5.5-3.8s-3.7,3.3-6.1,3.8c-4.9.9-28.5-1.3-30,1s.7,6-2.1,6.9H2c0,.1,3,4.6,3,4.6l-5,3.5v-32c2.6-.4,8.1.8,10-.5s.6-8.2,5.5-7.5.1,8,.5,8h38.5c2.1,0,1.2-5.8,1.5-7.3s2.8-.9,3-6.7c.3-.9,1.3-1.5,3-2,.6-1.9,1.4-1.9,2,0Z"
      style={{ fill: "#141616" }}
    />
    <path
      d="M640,207v6c-2.1-.2-5.4-1.1-7.3-2.1s-.2-2.3-1.7-1.9l-.5,3-3.5-2c-.7,2,1.6,2.6,2,3.5s-1.3.2-1.7.8c-.5.8-.2,1.6-.3,1.7h-1c.9-2.3-2.1-1.8-2-2.5s3.5-.8,2-4.5c-1.2-.4-1.9,1-2,1-3.3.3-4.9,2.3-5,6,0,.1-1.1.5-.6,2s3.1,3.9,3.6,6c-1.5,2.6-1.5,5.9,0,9.7l-1.9,1.3c-.1.7-1.4,1.8-2,2.5l1,.5c4.2.5,1,5-2.4,3.4-.7,4.7-2.9,7-7.2,8.8.4,2.8,0,4.8-1.4,5.8-3.1-.6-5.3.9-7.9,2.1-.9,1.5.6,2.3.9,2.9,1.5,3.7,1,1.9,3.3,3,3.3,1.6-1.7,2.1-2.8,2.9s-2.1,4-3.4,5.6-2.9,1.2-3.1,2.1c-.5,2.4,1.5,3.7,1.7,4.9s-1.8,7.2-2,7.5c-1.4,1.9-4.5.5-5.5,1s1.4,2.3-.5,3c-4.2,1.6-.8-4-4.5-3-1.3,5.7,3.7,4.9,8,5,1.2,5.6-4,.9-8,2l2,1c-2.2,1.5-1.7,7.7-2,8-.4.5-7.4-.5-8,0s.2,2.6-.6,3.9-5.4,3.8-7.2,4.3l-2.3,5.7c-1.7,4.3-8.7-.8-6-4-1.9-.6-1.9-1.4,0-2l.7-2.2c4.6-3,7.6-8,9.1-14.7,12.3-5.3,16-19,15.7-31.3l1-4.7c2.5-2.9,3.8-6.2,4.1-9.9,5.4-3.5,9-7,1-9.9-3-3.5-3.1-7.6,1.6-9.1s9,3.5,9.9,8c1.1-6.3,2.8-8.3,4.9-6.1,0-1.8,1.1-2.4,3.3-2l.3-6.4-10.6-18.5,1-2.1-1.5-2.4c8.5-8.2,12.6-4.9,16.1,5.6,6.9,1.9,14.2.8,21.3-.8l2.1,1.7Z"
      style={{ fill: "#07120c" }}
    />
    <path
      d="M542,464c3.6,0,25.1-1,26,0s-2.5,7.2,4.4,8.1,17.7.4,19.3-1.3.6-4.9,2.9-5.6,9.5-.4,10.7,0c2.2.8,1.7,5.3,3.6,6.4s6.7,0,7,.5c.5.6-1.2,7.7,2.5,8.1,10.7,1.1,4.3-12.4,7.5-14.6s11,.1,14.1-.4v16c-6.5-1.3-36.9,2.9-40.1-1.4s0-6.6-3.4-6.6-1.1,7.4-7.2,8.8c-12.7-1-29.6,2.1-41.7.2s-5-10.9-5.6-16.9c-.9-.3-.9-.6,0-1Z"
      style={{ fill: "#161817" }}
    />
    <path
      d="M576,328c.5.2,1.7-.6,2,.6.4,1.8-.6,9.9,1.5,10.4s1.8-2.6,3.1-2.9c4.7-1.1,14.4.5,19.9,0s3.2-1.7,4.1-1.9,2.6,1.7,4.7,1.9,14.7.5,16.9,0-.3-6.6,7-2.8,1.4,3.8,4.7,2.8v13l-2,2c-6.5,0-12.9,1.2-19.2,2.9-4.2,4.5-12.1,8.1-12.9-.6-1.4,6.6-4.1,14.5-8.3,4.1-3.4-.2-5.3-1.3-5.6-3.4l-2.2-.5c2.3-6.3,1-8.9-3.8-7.7,1.7,10.9-13,11.5-13.5.9-10.6-.8-8.3-12.8,1.4-14.1.3-2.2,1.1-3.8,2.2-4.6Z"
      style={{ fill: "#08100e" }}
    />
    <path
      d="M32,245c2.2,1,2.8,3.2,1.7,6.8l2.3,4.2c1.9-.8,3.2.2,2.8,2.4-1.1,4.5-2.9,8.3-5.2,11.5,16.3-5.1,4.3,7.5-3.4,10.7,1.5,2.8,2.5,5.8.4,8.9,1.6,1.7,1.8,3.9-.3,5.2,1.1,3.9-2.8,5.4-5.4,3.3-.9,1.5-2.3,1.9-4.3,1.3-7.4,3.8-7.3-3.3-5.9-8.8l-1.8-2.5c-3.4,2.8-10.4-1.7-13-5v-10c2.3-.8,7.5,1,9,.5,3-1-.9-1.7-1-2.2-1.8-9.5,6.6-4.3,7.9-8s.4-7.9,0-8.3-10.3-1.9-7.9-4.1,6.8-1.8,8-2l-3,2c-.4,2.3.5,2.4,2.5,2,3-.7,5.1-4.9,8.5-5,1.2,6-2,4.8-5,8,4.3,1.8,5.2-2.1,5.5-2,1,.4,2.2,2.9,4.5,2,.8-1.8-3.1-6.9-2-8s4.2,1.1,4-1c-2.1-.6-7.8,1.7-7-2h8Z"
      style={{ fill: "#061b0d" }}
    />
    <path
      d="M640,349v19c-5.5-1.3-11.2,2.3-11,8l-4.5-7,.5,5c-6.2-4-9.2,2.9-14.4,2s-4.4-5.4-6.2-5.9c-2.8-.8-8.5,4.1-13.4.4s1-1.8.8-3.8-1.9-6.2-2.1-6.4c-.4-.5-1.6-.1-1.7-.2-11.9-2.9-1.5-12.6,4-6,.7,0,7.1,1.7,7.4,1.9,1.8,1.3-1.4,5.3,3.6,4.1-2-6.6,6.5-16.4,5-4,5.3,2.4,8.3-3.4,9.6-3.8s7.1-1.7,9-2.1c4.1-.8,9.2-1.1,13.4-1.1Z"
      style={{ fill: "#0b120e" }}
    />
    <path
      d="M576,0c3-.4,2.9,5.5,0,6-7.3,3.7-15.5,5.4-24.5,5l-7.3,8.8c-2.7,3.5-8.6,8.8-10.2,1.9-4.1,6.4-8.9,8.2-7.9-1.7-2.2.3-3.9,0-5.3-1.8,0,6.4-8.3,3.6-3.7-.1-.8-1.1-1.7-1.4-3,0,3.8,1.1.7,5.3-2,2-2.5,3-4.6,1-6.5-6.1-4.5-2.3-4-5.5,1.4-9.6.9-1.6,1.9-3.1,3.1-4.3h66Z"
      style={{ fill: "#07150b" }}
    />
    <path
      d="M277,0c4.5.7,8.2,3.3,11,7.8,2.4-1,4.7-.9,6.5,1.2,2.4-2.2,7.5-3,8.8.7,0,3.1,3.4,5.2,6.6,6,2.6-4.2,4.5-4.6,8.2-1.3,4.4-3.7,8.5-5.1,14-3.3,1.4.3,2.7.3,4,0-6.4-2.3-4.1-9.5,2-11h17c1.9,2.6.2,4-5,4.2,1.9,4.3-1.4,7.3-9.8,8.7-.8,3.4-1.5,6.7-2.2,10-.8,3.8-10.3,10.2-14.2,6.2-2.8,7.2-10.7,4.5-14.6-.3-7.7-.1-14.3-3.3-20-9.6-7.8-1.9-10.1-5.1-13.2-11.3-2.5.2-9.2-7-4-8h5Z"
      style={{ fill: "#09170f" }}
    />
    <path
      d="M0,283c2.2-1.9,2.7.9,4,1.4,2.5,1,6.1,2.6,9,3.6,3.5.5,1.6,7-.3,9.2,4,3.9,2.3,6.7-2.8,7.3.8,1.3,2.1,2.1,3.4,2.8,6.3,1,10.5,3.6,4.5,8.9,2.9,2.5,2.9,4.3,0,5.6.9,3,1.1,5.7.5,8.2,5,4.6-1.7,7.5-6.3,9,2.5,2.2-1,6.2-4,6-3.4,2.6-6.1,3-8,1v-63Z"
      style={{ fill: "#07190d" }}
    />
    <path
      d="M338,0c-1.7,1-3.8,6.1-4,7.6s1.9.5,2,3.4c-.8.9-2.2.9-4,0-1.3,1.2-.3,3.4-1,5-3.2.8-2.7-3.7-3-4-.8-.8-8.8,2.4-10,5.5s2.3.6,2,2.4c-1.8-1.2-6.2,2.7-7,2-1.8-1.5,2.3-2.5,2.8-3.6s-.2-4-1.3-4.4c-2-.7-1.6,3-4.4,3.6s-8.3-2-6.1-4.6c-1.6-.7-.5,5.5-3,3s1.2-4.8,0-7-3.8.9-5,0l1,7c-1.7.4-.4-2-1.5-2s-7.4,1.9-5-1,5-1.3,1.5-3.5-3.6.9-5,0c-2.4-1.5-6-8.3-10-9.6h61Z"
      style={{ fill: "#09130e" }}
    />
    <path
      d="M8,345c.7,1.8,1.8,3.1,3.3,4.1l.4,2.7c3.5,2.8,4.8,5.9,0,7.8,6,5.2,8.9,10.8,8.7,16.8-3.5,1.7-3,4.4-1.4,7.5,5.8,3.6-3.6,16.5-5.3,4.8l-4.5,5.3c-.1,6-5.4,12.1-9.3,5.9v-54c3.2,0,4.7-3,8-1Z"
      style={{ fill: "#0a140f" }}
    />
    <path
      d="M29,384c.2,2.3-.5,5.4,0,7.3s2,.7,2,1.2c0,1.6-2.9,4.8-2.1,7.4-.5.5-9-.4-9,0v14.5c0,2.2-5.9,1.1-6.9,1.6s-1.7,12.6-5.6,6.9,1.9-8.7-7.5-7v-16c8.9,1.3,5.2-2.1,7.5-7s6.5-7.8,7-8c2.7-.4.6,3.4,1.4,5.6,1.8,5.3,3.3-4.3,3.1-6.6,1.8-2.8,8.4-3.1,10,0Z"
      style={{ fill: "#0e1510" }}
    />
    <path
      d="M510,0c2.2,4.4-6.2,8.9-6,10.5s2.4,1.2,3,2.2c1.2,1.8,1.4,4.5,2.5,6.2s2.2.8,2.4,1c4-1.3,1.9,12.8.4,13.9s-20.4.5-23.2.1l-1.3-2c-2,1.9-2.4,1.7-2-1l-1.3-2.1c.6-6.6,2.8-20.8,9.5-23-1.4-2.6-1.5-4.6-.2-6h16Z"
      style={{ fill: "#06180c" }}
    />
    <path
      d="M60,0c3.2.9,5.5,2.4,4.8,6.2.9.3,2,.8,2.4,1.6,2.7,5.3,3.6,12.4,2.8,18.2l.5,1.1c6.7-2.4,10,9.5,2.5,5.9l1,2c3.5.2,5.1,1.9,4.7,5,5.1,1.7,6.3,8.1,3.6,12.4,1.8,2.6,1.4,5.9-1.4,3.6-.8.1-1.8-.2-3-.9-5.6,2.1-9.2,1-8-5.7-5.6,0-7.3-2-5.2-5.6-6.2-1.7-8.4-4-4.3-9.9-2.4-1.1-2.3-3-.7-5-1.8-.4-3.1-.7-2.9-2.9-3,2.7-3.7-1.5-1.2-4.3l-6.3-16.3c-.2-1.9-.4-5,1.5-5.4h9Z"
      style={{ fill: "#0f1c0d" }}
    />
    <path
      d="M640,72v17c-4.1-1-2.5,1.8-3.9,3.5s-2.8,2.4-4.1,3.5c-2.3,1.7-3.3,1.4-3.3-1.5-1.6,1.2-3.2,1.4-4.7.5-2.4.9-6.3.9-9,1-1.9-.8-2-3-.1-6.7-2.3,1.5-5.4,2.4-6.9-.3-4.7,5.7-13,6.5-20,7-1.6,2-3.4,2-5,0-1.3,1.5-3.1,2.2-4,0-.5-.2-.9-.5-1.2-.8-2.8-3.4,8.6-6.6,11.2-6.7,2-1.8,3.7-2.3,5-1.5.7-.5,1-1.3.9-2.3,5.2-.6,10.2-3.3,14.2-6.4,10.3-.3,21.6-.9,29.2-8.2.9,0,1.5.9,1.7,1.9Z"
      style={{ fill: "#056128" }}
    />
    <path
      d="M392,0l2.6.6c-3.6,7.8-9.3,11.3-18.4,9.6-1.4,4.8-4.7,8.1-10.2,9.9-5.7,5.1-11.8,9.4-18.5,13l-2.6-1.1c-.2,2.9-3,6-6,5-.3,2.7-.9,2.4-2,0-3.1,1.9-8.5,2.4-9-2-1.9-3,1.2-3.4,3.8-2.3,1.6-3.4,4.9-4.2,9.8-2.4-1.2-4.8,0-7.5,3.4-8.3,1.2-6.8,3.8-9.4,11.1-7.8.5-1.6,7.1-12.6,7.8-13,2.1-1.1,5.5-1.2,6.9.6.5-1,1.6-1.6,3.2-1.9h18Z"
      style={{ fill: "#0a1d13" }}
    />
    <path
      d="M400,0c2.7.9,2.9,4.4,0,5,.2,1.6-1.5,2.3-3,2,.7,1.8-.4,2.9-3.1,3.4-.4,6.8-5,9.2-13.8,7.4-1.4,4.4-4.6,6.2-9.1,6-.3,2.6-1.3,4-2.9,4.2-6.1,1-11.3,3.6-15.8,8,6.6,2.5,0,8.9-4.3,7.1-1.1,4.3-4.9,2.5-5.2-1.2-2.6,2.9-5.9,4.9-9.7,6.1-1.1,2.9-4,2.8-5,0-4.6-3.9,4.1-10.8,9-11,.6.1,1.4-.1,2,0l2.4-1.2c1.2,1.6,2.4.4,3.6-3.8,11.4-4.3,14.2-10.7,22.4-15.1,4.1-2.2,5.3,1.4,3.6-4.9,3.8.6,3.2-.6,3-4,3.2.4,9.5.7,12.3,0s3.6-4.3,4.8-5.4,3,.3.9-2.6h8Z"
      style={{ fill: "#092114" }}
    />
    <path
      d="M214,0l2.1,1.6c1.7,7.2,4,14.3,6.7,21.2l-.8,1.2,2.6.8c6,12.1,9.1,23.3,7.3,37,1.2.2,1.8.9,2,2.2,2.1,2.3,2.5,5.9,0,8,5.2,8.7-2,15.4-6,5l-1.4-.2c-.6-5-2.8-7.5-6.4-7.5-1-2.1-2.2-4.1-4.2-5.4-2.7-.3-2.6-1.3,0-2,.5-2.7,2.8-5.2,6.9-7.4.6-1.3-.2-15.7-3.9-15.7-.2-7.8-2-11.7-5.9-18.3-.8-6.6-2-12.9-7-17.9-.8-1.4.3-2.2,1.8-2.7h6Z"
      style={{ fill: "#014a0f" }}
    />
    <path
      d="M640,36v9c-5.8,5-12.9,8.1-21.4,9.3-5.5,3.1-11.2,5.2-16.6,5.7.4,1.6-.6,2.1-2,2,.4,2.6-1.6,3.4-5.9,2.4-4.3,4.8-11.3,11.7-18.1,9.6-.4,1.9-2.3,2.7-5.6,2.3-1.7,2.6-4.9,2.7-7.8,2.6-1.6,2.4-3.8,4.1-5.6,1.1-3.3-5.4,6.6-9.8,11.3-10.8,3.9-1.4,6.4-3.7,7.5-6.9l8-.3c4.4-3.7,9.2-6.2,14.4-7.5-1.4-4,3.9-6.4,5.9-3.4,2.6-1.5,5.1-2.2,7.6-2,6.3-6.3,17.7-9.6,26.4-12-.9-2.8,0-3.2,2-1Z"
      style={{ fill: "#033f19" }}
    />
    <path
      d="M494,0c5.2,9.9-.6,6.7-2.5,10-3.8,6.6-2.5,14.4-5.5,21,.2,2-1,3.1-3.7,3.2,0,4.3-1.7,8.2-5,11.7,11.8,2.4.7,13.7-6.3,13.1-1.4,1.1-2.9.5-4.5-2-2.7,0-3.8-.6-2.8-3.5s4.7-3.8,6.2-3.5l-1-2c-3.7.4-3.9-4.4,0-4l-1.6-1.6c3.1-7.4,6.8-14.7,10.6-21.8l.2-7.6c4.7-3.1,5.7-6.9,6.6-12.4l2.2-.6h7Z"
      style={{ fill: "#041d0d" }}
    />
    <path
      d="M23,0c1.2,3.3,1.7,6.8,1.6,10.4,3,0,6.7,2.8,4.4,5.6,1.2,1.1.6,1.4-1.9,1,1.6-1.1,3.3.6,4.9,5,1.8,1,2.4,3,1.9,6,3.1,1.8,3.5,3.6,1.1,5.5,1.6,5.3,2.2,17.2.7,25,.5,1.5,1.5,11.7.5,12.9l-2.3.5c-2.2,1.7-1.9-1.1-1-3-3.5.1-6.3-3.4-4.4-6.5l-1.6-5.5c-1.7,2-2.2,1.7-2-1l-1.8-8c1.9-2,2.5-3.3,1.8-6.1-4.8-6.9-6.1-13.2-4.1-18.8-3.3-2.7-4.8-6.8-4-10.3,2.8-1.9,2.4-2.9.1-4.8-3.1,1.7-3.7-.2-1.6-5.8L16,0h7Z"
      style={{ fill: "#0e200f" }}
    />
    <path
      d="M47,0c4.4,1,3.2,6.2.3,8.7,3.3,3.3,4.6,12.9.7,15.3l-2.1,1c-4-1.6-8.3-1.9-12.9-1l-1-2-2-1.2c.4-2.2.5-2.8-1-4.8-.5-.3-1.5-2.8-3.1-3.5s-2.5.3-3,0c-1.9-1.3.4-3.6,0-6s-3.7-3.3,0-6.6h24Z"
      style={{ fill: "#152411" }}
    />
    <path
      d="M456,0c5.6,3.2-2.2,6.2-5.9,7.2.5,4.4-.2,7.3-2.1,8.8l-1.2,1.9c-3.5,0-5.6,2.8-6.3,8.3l-2.5.8c-1.7,2.3-2.2,1.8-2-1-2,.4-4-.3-6-2-2.3,0-5.5-1.4-5-4l-1.3-2,5.5-10.6c.4-3.2,1.3-5.7,2.8-7.4h24Z"
      style={{ fill: "#033814" }}
    />
    <path
      d="M402,0c4.5.2,4.4,3.5,4,7,2.2,2.8.4,7.6-2,10.1s-4.4,4.5-7.9,3.9c.4,7.2-9.7,7.4-15.1,6.3.7,5.1-7.8,10.1-11,6.6-1.8,2.3-2.4,1.7-2-1-2.8-.6-2.9-4.5,0-5,.7-10.1,4.2-3.9,8.6-7.9s1.1-3.9,2-4.1c2.7-.8,8.9,1.2,11.7-.8s.9-4.7,2.1-6.1,3-1.1,4.5-2l3-2c.2-1.6-.1-3.3,0-5h2Z"
      style={{ fill: "#072616" }}
    />
    <path
      d="M248,0c2.4.6,4.1,2.6,5.2,5.9,3.7-1,6.6,7.7,4.8,10.1l-2.5.3c-1.8-3.8-2.6-2.3-2.2,4.5,3.7,1.4,3.3,10.1-.3,11.2-.2,2.1-4.2,3.2-5.7,2.2s-3.4-8.9-3.6-9.5c2.2-.9-.5-4.7-.8-3.6-.3.9-.6.9-1,0-2.2,3.1-6.1,1.3-6-3-.2,1.9-1.4,1.9-2,0-1.9-.6-1.9-1.4,0-2,0-2.8,1-4.1,3-4-2.2-1.8-3.8-4.2-5-7-2.9-1.3-2.8-3.6,0-5h16Z"
      style={{ fill: "#032710" }}
    />
    <path
      d="M467,0c2.1,1.7-.5,7.7-3,8-.7,4.5-3.3,7.5-6.2,10.8-.9,9.4-2.8,15-7.8,22.8.7,3.5,0,5.6-2,6.4-.5,2.7-.8,2.5-2,0-3.5-5.4-3.7-12.1-.6-20.2l.6-10.3,2-1.5c0-2.4-.6-8.5.5-10s8.9-1.2,7.5-6h11Z"
      style={{ fill: "#023710" }}
    />
    <path
      d="M374,0l-3.5,7c-3.5-11.5-4.6-4.3-7.9.7s-1.9,1-2.5,1.9c-.9,1.4-1.9,6-2.3,6.3-1.5,1-7.1-.7-8.7.3s-1.3,4-2.1,5.2-2,.6-2,.7c-1.9,2.1-5.2,4.1-7,1-2.9-2.4-.2-7.7.5-11,1.1-2.5,9.4-2.9,10.5-4s-1.8-3.5-.6-5,5.7.1,6.6-2.9h19Z"
      style={{ fill: "#0b1a10" }}
    />
    <path
      d="M625,0c4.9,2.8-4.7,12.6-9,11-1.2,4.5-2.9,6.1-5,5-.7,4.6-2.8,6.1-7,8,2.1,2.2-6.1,8.5-7.9,9-2.8.9-6.6.2-7.1-3-2.4,3.7-7.8,1.6-5-2-.5-4.8,3.1-7.9,10.8-9.1l6.9-7.6c5.6-3.2,10.4-7,14.3-11.3h9Z"
      style={{ fill: "#02230e" }}
    />
    <path
      d="M603,0c2.8,1.7,1.6,4-3.6,6.8-.4,4.2-2.2,6.1-5.4,5.8,1.5,6.3-7.8,7.4-10,3.4-.9,2-2.1,2-3,0-2.2,3.4-8.3,1.3-5-2-5.3-.6-.2-10.7,2.3-6.8s-3.5.4-2.3-1.2V0h27Z"
      style={{ fill: "#021a0b" }}
    />
    <path
      d="M208,0c-1.4,4.9,5.1,8.8,5.7,11.7s-.1,5.7.5,8.5,5.5,7.4,5.7,8.4c.6,2.5-.6,6.2,0,8.6s2.4,1.2,3.1,2.1,2.3,14.1,1.7,15.9-7.1,5.6-8.8,6.7c-2.6,1.2-3.8-.8-2-3-2.1-1.1-2.4-2.1-1-3-2.2-2.4-2.2-3.1,1-2,0-4.3.1-7.7,4-10-3.8-12.8-7.9-25.5-12.2-38-4.3-2.1-5.5-4.1-3.8-6h6Z"
      style={{ fill: "#01510d" }}
    />
    <path
      d="M487,0c-1.4,4.4-1,7.6-4,11.6s-2.8,1.1-3,2.1c-.5,2.2.7,5.4-.1,7.7s-1.8,2.6-2.8,5.1c-2,4.9-6.9,13.1-8.1,17.5-1.2,2.8-6-.3-5-3-2.9-.1-2.6-3.1,0-4,.8-2.3,1.5-4.6,2.3-6.8-1.9-3.2,1.3-8.1,4.8-8.1-.5-2.7,0-5.1,1.5-7.1,1.8-5.3,4.3-11.1,7.5-15h7Z"
      style={{ fill: "#02230c" }}
    />
    <path
      d="M74,0c2,.9,3.8,3,2,5,1.7,2.7,5.8,5.6,5,8.4s-1.9.5-2,1.3c-.1,1.3,1.1,1.7,0,4.4.2.9-.3,2.2-1,3,0,.3,0,.7,0,1-.3,2.4-1.1,2.4-3,1,2.1,4.2-6.4,5.3-5,2l-3-2c3.7-3.3.3-4.2.3-5.5s2.1-1.8,1.7-2.5-1.6.3-2-.7c-1.2-3.7.8-8-6-7.3,4.2-4.8.7-3.9-1-8h14Z"
      style={{ fill: "#0d1409" }}
    />
    <path
      d="M594,191c2.2-.1,3.8.8,5.6.9,3.6.2,8.2-.1,11.9,0s7.6,2.6,8.9,5.2-1.5,9.2,2,9.6,3.4-1.4,5.1-1.6,4.7.3,6.7-.3,2.9-3.6,5.7-2.8v5c-5.3,1-19.7,2.4-24.4.9s-2.7-9.1-7.1-10.9-.2,1.4-.8,1.7c-2.2,1.1-5.6,0-5.7,4.3-4.5,1.5-7.7-5-8.5-9l-1.5-2c-2.5-2.3.7-4,2-1Z"
      style={{ fill: "#06190a" }}
    />
    <path
      d="M616,0c.3,6.7-7.9,8.6-12.3,12.2s-5.9,7.4-8.3,8.7-7.6,2.2-8.4,2.6-3.4,3.5-3,4.5c-.3.8-.7.8-1,0-3.2-1.2-2-10.8,1-12,1.5,0,7.7.3,8,0s-.5-3.5.5-5.1,2.8.3,3.6-.3c1.2-1,.8-3.3,1.5-4.6,1.3-2.3,5.5-2.4,5.5-6h13Z"
      style={{ fill: "#021f0d" }}
    />
    <path
      d="M155,0c3.7,2.3,7.7,9,7.6,13.8,3.8.1,6.9,9.1,8.1,12.9s2.3,7.3,2.6,9.4l-1.3,1.9c4.2.9,4.9,2.3,2,4-.3.4-.6.7-1,1-4.7,2.8-8.6-9.5-9-11-1.2,2.7-2.9,2.8-4,0-1.4-3.2-2.4-6.8-3-10.8-3.7-6.1-6.7-12.5-9-19.3l2-1.9h5Z"
      style={{ fill: "#012904" }}
    />
    <path
      d="M608,75c1.8-1.8,4.6-2.6,8.5-2.6,2.8-2.3,6.5-3.2,11.3-2.6,1.6-4.2,3.6-5.3,6.1-3.3,3.7-1.4,5.7-.9,6.1,1.5v4c-2.2-.7-2,1.8-3,2.4s-2.7.5-3.9,1.2c-3,1.7-1.4,2.5-7.7,3s-3.6,1-5,1.4c-3,.7-7.4-.7-10.6.2s-6.8,4.3-9.5,5.5-4.8.7-6.2,1.3c-1.7-1.1-1.3-3.9,0-5,0-4.3,7.6-5,11.3-4.5.5-1.2,1.4-2,2.7-2.5Z"
      style={{ fill: "#055a26" }}
    />
    <path
      d="M192,0c.9.7,1.4,1.9,1.5,3.5,4.9,5.6,5,9,.4,10,1.5,3.5-7.1,7-9,5.8s-.9-.7-1-1.3c-1.7-1.1-2.9-2.9-3.4-5.2-2.9-1.1-10.6-8.7-11.2-11.1s.2-1.1.5-1.7h22Z"
      style={{ fill: "#018602" }}
    />
    <path
      d="M640,24v12c-.9,0-1.4.7-2,1-2.6,1-5.1,1.7-7.9,1.1-3.4,3.2-11,6.7-15.1,4.9-1.6.7-2,.5-1-1-2.7-1.6-2-3.3,2-5-2.9-1.9,3.5-6.9,6.1-7,0-4.8,4.1-5.5,7.9-6-.8-3.6,9-2.8,10,0Z"
      style={{ fill: "#013713" }}
    />
    <path
      d="M132,0l2,1.9c0,7.8,1.3,15.4,3.4,22.8l-1.4,2.3c1.2,4.3,1,5.8-.5,4.5l-4.5.5c-1.2,4.2-5.7,1.1-3-2,.2-.7.5-13.5,0-14s-4.6.4-5,0c-1-1,1-9.4.7-10.6s-5-1.1-1.7-5.4h10Z"
      style={{ fill: "#09150a" }}
    />
    <path
      d="M136,0c1.5.7,2.6,2.2,3.3,4.6,2.2,1.6,3.8,3.4,4.7,5.4,2.7,4.2,2.7,8.7,0,13,3,2,2.8,6.9,0,9-.7,2.6-4.4,2.9-6.2,1.4s-3-6.3-1.8-6.4c-.9-3-2.4-9.5-2.9-12.6-.7-4.4-1-9.9-1.1-14.4h4Z"
      style={{ fill: "#081809" }}
    />
    <path
      d="M640,51v8c-.9,3.2-3.9,4.3-9.2,3.3-3.9,2.2-7.5,3.4-10.8,3.7-1.5,1.4-3.7,2-6.5,1.8l-9.3,3.6c-1.9,2-6.6,4.1-7.2.6-2.1-.5-1.7-1.9,1-4l1.5-2.9c5.7-2.3,11.5-4.7,17.5-7.1l1.3-2.2c7.1-.9,13.8-3.2,20.3-6.7l1.4,1.9Z"
      style={{ fill: "#044b1e" }}
    />
    <path
      d="M51,0c-1.2,5.9,7.5,19,7,23.4s-2.1.5-2.1,1.2,1.1,1.3,1.1,1.4c3.3.7,1.4,5.7,0,8,2.2,2.9.6,8-3.1,6-2.3,5.3-6.4-1.2-2-2-2-.6-2-2.5,0-3-3.7-.5-8.4-9.1-4-11-.1-3.2.5-7.6,0-10.5s-2.6-3-2.8-4.2c-.6-2.6,5.7-4.6,1.8-9.4h4Z"
      style={{ fill: "#12220d" }}
    />
    <path
      d="M227,0l1.8,1.4c1.4,5.3,2.5,10.7,3.3,16.2l1.9.4c.2,0,1.7,0,2,0,1.7,1.6,2.2,3.2,1.5,4.9l1.3,5.4c4.1,4.8,4.5,8.1,1.1,9.7-2.5.9-5.1-3.6-6-6-1.6,4.4-3.2-1.1-4-4-2.9,2.4-5.8-3-3-5-1.8.3-2.7-.2-3-2-1.6-3.4-2.5-7-2.6-10.9-3.6-.4-6.5-8.1-3.4-10.1h9Z"
      style={{ fill: "#013411" }}
    />
    <path
      d="M640,59v9c-1.9-1-7.6,1.2-7.9.9-.5-.5.6-2.3-1.1-1.9-.8,7.7-6.6,4-11.3,5s-.6,1.7-2.8,2.3-5.8.6-8.9.6c-1.9,1.7-2.2,1.5-2-1-1.1-4.5,8-6.8,11.9-6.4l2.1-1.6c.1-.3-.3-1.4.2-1.8,1.5-1.1,4.8,1.6,3.8-3.3,3.2,2.6,5-.5,6.7-.7,2.7-.3,5.8,1.2,9.3-1.3Z"
      style={{ fill: "#065423" }}
    />
    <path
      d="M194,0c3.6,4.7,6.8,11,8.9,16.9l-.9,1.1c2.7,3,3.4,5.7,2,8-.7,4.1-4.6,2.1-7.2.5-2.8,7.4-14.5-6.8-14-7.6s.7-.6,1.2-.9c2.2,1.9,4.3-1.8,4.8-2,.9-.4,2.9.3,3.2,0s-.4-2.9.1-3.8,4.7.4,3.9-3.6-6.7-3.6-4-8.6h2Z"
      style={{ fill: "#017f04" }}
    />
    <path
      d="M160,0c1.5,1.9,4.5,6,5.6,8.5s-.1.9-.6,1.5l2.5.9c3.1,7.5,6.9,14.5,11.5,21.1,1.5,2.1,1.5,3.5,0,4-1.3,3.2-4.4,5.7-7,2-1.8-3.5-2.1-8.6-3.9-12.8s-2.8-8.8-3.2-9.1c-.7-.6-3.7.1-4.9-.1,2.9-7.4-4.3-9.5-5-16h5Z"
      style={{ fill: "#023202" }}
    />
    <path
      d="M625,0h15v8c-.9,1.9-2.1,1.9-3,0-2.9,1.4-6.2,2.2-10.1,2.3-.1,10.3-18.8,12.2-10.9.7,3.5-2.9,9.2-6.4,9-11Z"
      style={{ fill: "#01260c" }}
    />
    <path
      d="M425,0c4.7,2.9-2.1,15.5-7,14,.6,4.5,0,7.8-2,10-.3.9-.6.9-1,0-1.9-.9-3.5-2.2-5-4-3.8-1.6-4.5-9-2-12,.9-1.4,2.7-2.2,5.3-2.3-.7-2.6-.5-4.5.7-5.7h11Z"
      style={{ fill: "#053015" }}
    />
    <path
      d="M640,45v6c-3.9.1-6.5,2.9-10.2,4.3s-8.2,2-12.8,2.7c-4.6,1.9-9.1,3.7-13.5,5.5l-.5-2.5c-1,.3-1.3,0-1-1,2-.8-.5-2.6,0-3,.9-.8,5.2,1.4,7.3.8s4.6-3.8,7.2-4.9c5.6-2.3,11.3-2.3,17.2-5.3s2.5-4,6.3-2.7Z"
      style={{ fill: "#03441b" }}
    />
    <path
      d="M150,0c-.4,2.5.9,4.5,1.9,6.6,1.7,3.8,2.6,6.3,4.8,9.7,3.5,5.5,3.9,9,3.4,15.7-2,2.3-3.5.8-5-1.4-3.7-8.1-7.4-15.9-11-23.6-3-.3-2.9-6.4,0-7h6Z"
      style={{ fill: "#032304" }}
    />
    <path
      d="M432,0c1.4,7.8-4.3,13.2-7,20-.3,2.9-3.2,2.6-4,0-2.7,1.8-4.9-4.2-3-6,4.7-4.8,6.6-7.1,7-14h7Z"
      style={{ fill: "#043415" }}
    />
    <path
      d="M16,0c3.8,3.3.1,4.3,0,6.5s1,1.3,1,1.5c2.7,1.7,1.7,4.4-3,8-1,2.8-4.3,3.1-5,0-5.4-1.4-.7-5.2-.9-8.5S4.5,3.4,5,0h11Z"
      style={{ fill: "#091809" }}
    />
    <path
      d="M480,0c0,2.1.5,3.2-.7,5.1s-5.5,9.8-6.1,11.6.1,6.9-.2,7.3-4.2-.6-5.5,2,.6,3.9.6,5.4-1.5.6-1.6.9c-.7,1.7-.2,3.9-2.4,4.7-3.6,5-5.6-1.9-.6-5.6-1.6-3.8-.4-7.9,3.6-12.4-1-1.6-.1-3.5,2.7-5.5l2.1-12.4,2.2-1h6Z"
      style={{ fill: "#02280d" }}
    />
    <path
      d="M474,0l-2.4,14.1c-.3,1.6-4.3,4-4.6,4.9-.4,1-1,1-2,0,.1,1.8-3.7.4-3.8-.2-.4-1.8,2-8.6,2.8-10.8.4-3.3,1.5-5.2,3-8h7Z"
      style={{ fill: "#022e0c" }}
    />
    <path
      d="M202,0c-.3,3.2,4.3,3,5.5,5s3.5,12.7,4.6,16.2,2.9,7.6,3.8,10.5,2.2,8.1,2.1,12.2c-3.4.1-4.1-2.6-3.5-5.5l-1.5-6.5c-3.5-.9-6.2-9.8-5-13-2.5-4.6-4.5-8.9-6-13-2.6.9-5.4-5.1-2-6h2Z"
      style={{ fill: "#02570b" }}
    />
    <path
      d="M640,8v16c-2,.4-9-.6-10,0-3,.5-3.7-5-1-6,1-3.8,3.7-9.7,8-10,1,0,2,0,3,0Z"
      style={{ fill: "#012f0d" }}
    />
    <path
      d="M218,0c.3,1.3,1.9,7.5,2.2,7.8.4.4,2,0,2.5.4,1.2,1,.8,5.6,1.3,7.1s2.5,0,2,1.7c-1.3,0-2.3-.5-2,1,5.3.4.2,2.4,0,3,1.5,1.2,2.1,2.4,0,3,0,2.1-.8,1.4-2,0-1.6-2.7-4.6-10.4-5.9-13.8s-2-7.5-2.1-10.2h4Z"
      style={{ fill: "#023f0f" }}
    />
    <path
      d="M254,0c2.8.5,4.7,3.4,3,6l6.5,9.7c2.9,2.5,5.2,6.3,4.5,10.3,1.3,1.6.7,2.1-1,1-.3.9-.6.9-1,0-2.2.4-3.6-1.3-4-5-3.2,1.3-6.4-3.5-4-6-.6-1.6-1.4-3.4-2-4.8s.3-2.8,0-3.2-2.4.2-3.7-.4-3.1-5.8-4.3-7.7h6Z"
      style={{ fill: "#002a0a" }}
    />
    <path
      d="M232,0c.1,1.6-.2,3.4,0,5,3.8,4.3,4.4,7.9,2,11,0,.7,0,1.3,0,2-.5.2-.9,2-1.5,2-3.6,0-2.1-5-2.7-7.3-1.2-4.2-3.9-7.7-2.8-12.7h5Z"
      style={{ fill: "#012f0f" }}
    />
    <path
      d="M414,0c2.9,6.4,1.6,9.1-6,8-1.4,2.6-2.2,1.8-2-1-3.9-2.3-.2-5.2-4-7h12Z"
      style={{ fill: "#042a12" }}
    />
    <path
      d="M170,0c1.9,3.2,4.2,6.1,7,8.5s3.9,1.5,5.1,2.9,1.7,6.3,1.9,6.6c-1.4,1.6-3.4.9-6-2-.2,2.6-1.1,2.5-2,0-2.6-4.4-5.2-9-8-14-1.9,0-1.9-1.7,0-2h2Z"
      style={{ fill: "#017c03" }}
    />
    <path
      d="M260,0c2.6,3,7.6,9.8,6,14,1.5,2,1.6,3.5-1.1,3.5l-7.9-11.5c-1-2-2.7-3.7-3-6h6Z"
      style={{ fill: "#0a5a23" }}
    />
    <path
      d="M144,0v7c1.8.6,2.2,2.7,0,3-4.5-1.1-8.5-5.3-8-10h8Z"
      style={{ fill: "#051d09" }}
    />
    <path
      d="M196,0l2.2.8c1,3.1,2.2,6.2,3.8,9.2,2.6-.5,4.2,4.5,2,6,3.8,3.9-1.1,6.8-2,2L194,0h2Z"
      style={{ fill: "#017305" }}
    />
    <path
      d="M263,0c1.1.2,1.7,1.4,1.8,3.6,3.2,2.3,4.2,4.5,3.2,6.4,1.4,1.6,2.5,5.4,0,6-2.5,1.7-2.7.7-2-2-2.2-4.2-3.8-9.6-6-14h3Z"
      style={{ fill: "#014216" }}
    />
    <path
      d="M266,0c.7,1.3,1.4,2.7,2,4l3.4,4.4c3,2.1,4.9,6.1.6,7.6-1.8,0-3.1-1.9-4-6-.1-.2,0-1-.4-1.6-1.8-2.6-4.9-3-4.6-8.4h3Z"
      style={{ fill: "#043414" }}
    />
    <path
      d="M272,0c-1.5,1.4,3.4,7.7,4,8,2,.2,1.9,1.2,0,2-2.8,1-5.5-1-8-6-1.6-.7-1.7-3.5,0-4h4Z"
      style={{ fill: "#0e1d12" }}
    />
    <path
      d="M89,0c1.7-.2,1.1,2.1,0,3,.7,2.7-2.5,1.8-3.6,3s2.2,3.5-.9,3c-3.8-3,.7-5.6.5-9h4Z"
      style={{ fill: "#0a0f0b" }}
    />
    <path
      d="M200,0c-.6,2.2.7,4.2,2,6,1.8,1,2.3,3.4,0,4l-4.3-5.3L196,0h4Z"
      style={{ fill: "#026609" }}
    />
    <path
      d="M162,0c2.9,1.3,5.5,6.9,6,10,0,2.6-.9,2.6-3,0-2-3.5-5.4-6.4-5-10h2Z"
      style={{ fill: "#013c01" }}
    />
    <path
      d="M80,0c.7,2.1-1.7,4.9-4,5-1-1.6-2.5-2.5-2-5h6Z"
      style={{ fill: "#0a0f0b" }}
    />
    <path
      d="M165,0l1.6.6c0,2,.5,3.4,1.4,4.4.9.2.9.6,0,1,1.4,1.5,1.7,2.9,1,4-.3.9-.6.9-1,0L162,0h3Z"
      style={{ fill: "#024b02" }}
    />
    <path
      d="M168,0v2c.9,1.3.9,2.3,0,3-2.9.8-2.1-2.8-3-5h3Z"
      style={{ fill: "#045c03" }}
    />
    <path
      d="M268,0v4l8,6c2.2.8,3.7,3.9,4,6,3.9,5.2,4.6,8.5,2,10-.3,2.7-1.2,2.5-2,0-2-1.7-3.6-5.1-3.8-8-1.4.2-2-.6-2.2-2-.6,2.7-1,2.5-2,0,1.3-5.1-1-5.1-2.3-7.3s-1.1-3.6-1.7-4.7-3.3.5-2-4h2Z"
      style={{ fill: "#092612" }}
    />
    <path d="M91,0c.5,1.1-1,2.7-1,3h-1V0h2Z" style={{ fill: "#1e211b" }} />
    <path
      d="M263,512c3.1.8,4.8-1.6,5-7.3.9-1.8,16.8-5,17.7-4.8,3.2.8,3.8,2.2,4.5,5.2h2.7c0-.1-1.1-11.5-1.1-11.5-.6-6.2,2.9-8.5,10.5-6.8,1.3-7.3,2.1-11.8,10.9-7.7,0,3.1,0,6.3,4.1,4.9,7-6.1,11.1-2.7,15.2,4,6.3-2.6,7.5.7,7.6,6.6,2.2,1.5,3.2,3.1,3,4.8,5.2-2.4,7.1,1.4,8.4,5.7l8.4,1c6.3,7.8,13.8,8.1,22.9,4.9l1.1,1c.4-3.9,7.6-5.6,11.6-6.1l12.1-.8c5-.5,7.4-3.6,7.1-9.2,3.7-1.6,4.7-2.2,7.2-4.9.8-2.4,6.6-7.9,8-3,2.9-.9,3.6,4,1,5-7.9,3.6-1.3,15.5-6,18.5s-10.9-2.8-9,4.5c-8.2.2-17.5,0-25.6.9s-8.4,3-13,3.1-6.5-1.8-9-2,0,1.8-1.9,2c-2.8.4-11.2.3-14-.1s-5-3.1-8.3-3.7-8,0-8.3-.2.4-3.7,0-4c-.5-.5-12.9-.3-14.6,0s-9.4,3.6-12,4c-9.5,1.6-19.3-.2-27.9,1.1s-5.1,2.4-8.2,2.8c-11.9,1.7-39-.8-51.9-1.9s-12.3-2.2-13.3-3.3,0-2.3-.3-2.6c-.6-.6-3.7.1-5.2-.2s-5.5-7-10.7-7c-5.3-4.1-6.3-7.1-3-9,.6-1.5,1.6-2,3-1.2,5.3,4.7,10.1,7.6,16.7,9.8.3,3,1.7,5.1,4.3,6.3l1.7-1.7c15.8.2,31.5.3,47.2.3l1,2.4Z"
      style={{ fill: "#191d18" }}
    />
    <path
      d="M110,448c.3-4.1,4.5-1.2,4.2,2.6-1.3,9.1-.3,12.1,2.9,8.9,5.9-3.6,17.3,7.3,14.9,12.5-2.1,1.4-1.7,1.7,1,1,3-3.7,11.3,4.3,7,7,1.3.6,1.7,2.6,1.3,5.9,4.8.9,5.9,3.6,5.3,8.2,4.4-.5,8.8-.4,7.3,5.2,4.4.1,12.7,2.1,13.2,6.8-9.6,1.1-6.5,6-7,6h-16c-.4,0,.6-6.7-.1-7.9-1.7-2.8-13.7,1.1-15.7-1.3s.4-6.4-.1-6.9-13.3.3-16,0-2.7-1.8-4.6-2c-4.3-.4-13.1,4.7-11.4-4.4s2.1-2.8,2-5-1.8-3.3-2-4.6c-2-.5-2.7-2.4-2.1-5.9-7.7-.6-1.4-7.5,2.1-10.1-2.6-2.4-5.4-15.7,0-16,1.5-3.9,11.1-2.3,14,0Z"
      style={{ fill: "#141a16" }}
    />
    <path
      d="M144,480c8.4,5.3,17.2,10,27.8,9.9,5.9,4.2,7.6,5.2,15.2,4l1.9,2.1c-1,4,1.5,5,5,6l-2,3c-7.4.1-17.5.1-25,1l-8.9-4.6c-9.6.7-4.7-4.1-6.1-5.5s-6.5.3-8,0c2.5-10.3-2.6-6.5-4.1-8.6s.1-5.8.1-7.4c0-2.9,3.4-2.7,4,0Z"
      style={{ fill: "#161c18" }}
    />
    <path
      d="M456,467c3.8,1.6.6,4.1-2.8,4l-4.2,2c-3.8,4.9-4.5,10.8-7,14.3s-3.8,3.6-4,3.7c-1.6.8-4.8,1-7,2l-1-5c.6-2.1,2.3-4.1,5.1-6,3.1-6.6,7.4-11.3,12.9-14,.1-3.3,7.3-4.5,8-1Z"
      style={{ fill: "#161c17" }}
    />
    <path
      d="M501,384c2.9-2.4,3.6,1.4,1.5,4.3,2.9,2.2,1.9,5.4-1.6,5.9-1.2,3.1.3,4.4,4.6,3.8l1.5,1.9c1.8.5,2.6,2.5,2.4,5.8,3.1.1,5.2,2.1,6.3,5.9,2.9-2.5,5.4-1.8,7.8,2.1,6.7-3.1,14.3-3,22.8.3l25-.8c2.7-1.2,5-1.7,6.8-1.3,5.2.9,15.4-3.1,14,5-.5.6-2.7-3.4-4,2-.7.7-.7,1.4,0,2,.1.1,0,2.2,1.6,2.8,4.4,1.7,20.6-.3,26.4.2.3,1.5-.4,7.7,0,8s6.5,0,8,0v16h-16v-14.5c0-2-13.6-2.1-15.1-1.1-2.3,1.6,1.2,14.6-4.4,14.6s-2.8-6.3-4.5-7.6c-2.6-2-8.6.4-7-7.5,2.2-2.5,1.8-2.8-1-1-2,.7-17.8-.2-19,1s.7,5.4-.4,7.1-4.5.4-5.4,1.1,0,1.6-.1,1.8c-.3,1.6-1,2.6-2,3-.6-.5-4.9-5.3-5-5.7-.4-2.2,1.1-6.3-1.7-7.2-5.2-1.7-18.3.9-24.8-.2s-3.9-2.6-7-3c-5.6-.8-7,1.5-11,2.1s-6.5.3-7.9-.2-4.5-8-5.2-9.8-.3-4.9-.5-5-4.8,0-6,0c-1,2.1-3,2.7-4.9,1.4-4.2-2.8.7-13.4,7.3-12.3-3.9-3.4-.4-6,3.5-7.4,2.2-3.9,5.4-7.1,9.8-9.7-4.6-4.9,3-8.7,5.3-4Z"
      style={{ fill: "#101614" }}
    />
    <path
      d="M519,359c.5,1.3-1.2,2.1-1,1.8,1.5-3.3.9,5.9-1.6,7.8.3,6.9-2.5,9.9-8.6,9.1-1.9,3.8-4.2,5.9-6.8,6.3h-5c-.6,1.9,1.6,2.5,1.9,3.7.5,3-12.1,7.1-9.9,12.2-2.6,0-4.4.4-5.9,2.6,8.8,4.8-4.4,5.1-5.1,6.1s-1.3,7-.9,7.4c1.3,0,2.7,0,4,0v4c-1.3,1.2-4.6-3-4,4-.6,1.9-1.4,1.9-2,0-4.4-6.1-8.4-12.7-11.7-19.8l1.6-1.9c-2.9-3.4.3-4.7,3.6-5.5,3.1-.1,5.9-.9,8.6-2.3,2.2-4.6,5.8-7,10.8-7.6,3-5.2,6.9-9.6,12.4-11.1-.8-5.3.7-6.6,4.7-3.8.7-3.2,2.1-5.5,5.8-6l2.2-3,3.8-8.4,2.2.4c3.1-.4,3,2.1,1,4Z"
      style={{ fill: "#0f1a13" }}
    />
    <path
      d="M496,448c7.1,8.4-4.1,8.1,1.6,15.9,14.8.3,29.6,0,44.4.1v1c-4.4.7-25.8-1.3-27.5,1s0,5.6-3.7,7.3-9.4,1.1-12,.5c-5.7-1.3-4-6.8-4.8-7.7s-3.2-.2-4.6-.9c-5-2.3-2.8-10.8-3.2-15.3-6.8-3.6,7.8-6.1,10-2Z"
      style={{ fill: "#161817" }}
    />
    <path
      d="M476,424c.4,4.5,3.5,5.4,4,11,4.2,3.5,3.9,16.1-4,16-1.3,1.9-5.4,8.1-7,9-3.3,1.9-4.6-1.4-5,4-.9,1.9-2.1,1.9-3,0-4.8-2.8-1.4-7.5,3.6-7.2,7.5-6.8,12.7-16.6,11.4-26.8-3.3,1.6-3.9-4.5-2-6,.7,0,1.3,0,2,0Z"
      style={{ fill: "#161c17" }}
    />
    <path
      d="M496,448c-2.2-1.5-8.2-1.4-9.9.2s0,1.8-.1,1.8c-.5.3-2.9-.5-3.8.1-1.3.9-.3,8.3-6.2.9.2-.3,0-1.4.5-2,1-1.1,3.3-.5,3.6-1.6s.1-10.3,0-12.4c7.2-.4,5.1,9.1,7,10.6s5-1.2,7.4-.4,1.3,2.5,1.6,2.9Z"
      style={{ fill: "#161817" }}
    />
    <path
      d="M577,432c-.5.2-1.2-.4-1-1,.9-.4,2.2-5.5,5-1.5,0,.3-3.4,2.3-4,2.5Z"
      style={{ fill: "#0f1612" }}
    />
    <path
      d="M551,442v4s-1.5,0-1.5,0c-.5-.2-.4-.9-.5-1-.3-1.9,1.5-2.3,2-3Z"
      style={{ fill: "#1e221f" }}
    />
    <path
      d="M592,184c.4.8.7,3.7,1,4-3.8,1.9-6.6,4.9-8.5,8.8l-2.5.2c-1.4,4.6-4.1,8.7-8,12.3-2.4,5.9-4.9,11.9-9.5,16.2-2,0-3,1.9-3.2,5.4-4.9.4-6.7,3.9-5.3,10.4l-2,1.6c-.4,3.9-3.7,1.6-3-2.1,0,1.2-.4.9-1-.9-5.3-3-.7-11.5,4.5-12.7,1.3-3.3,3.1-5.8,5.5-7.3l-.7-2.5c4.6-6.1,8.8-12.5,12.7-19.2l2.1-.4c-.3-2.2-.2-4.8,1.9-5.9,1.5-1.4,3.1-2,4.8-1.9,2.1-3.3,8.6-10.2,11.2-6.1Z"
      style={{ fill: "#05220e" }}
    />
    <path
      d="M606,147c-.6,1-1.4,2-2,3l-6.2,5.8c-10,4.3-13.8,11.9-17.5,21.6l-6.4,6.6c-1.1,2.4-3.6,7-6,8-.4,1.3-.9,2.4-1.7,3.4-2,2.7-6.7,8.5-9.3,8.6-2.2,2.9-3.9,3.6-5,2-2-1.7.7-5.2,3-6,.5-3.2,2.5-5.9,6-8.1,3.3-6.2,7.3-11.4,12.2-15.6,1.5-6,6.2-12.7,10.7-16.3.6-2.8,1.6-5.6,3-8.3,1.4-1.1,9.9-3.5,11-1.7,2.8-3.3,5.4-4.3,8-3Z"
      style={{ fill: "#025012" }}
    />
    <path
      d="M592,138c0,.3,0,.7,0,1-.8,1.9-2.2,3.6-4,5l-3.9,4c-6.9,4.3-14.1,10-14,19l-2.1,1.1c-5.4,7.9-10.8,15.9-16,24-.1,2.7-1.1,2.6-2,0-1.3-.7-1.3-2.3,0-5-1.5-1.7-.1-3.6,2-4-.9-.3-.9-.7,0-1,3.5-6.8,7.6-13.3,12.2-19.5-1.7-11.6,3.8-16.2,14.9-17.9,1.9-1.6,10.6-7.7,12.2-7.9s.5.5.8,1.3Z"
      style={{ fill: "#02781f" }}
    />
    <path
      d="M592,142c0,1.3,1.2,2.6,0,4l.3,2.2c-2.9,2.1-5.7,4-8.3,5.8-.9.9-1.3,2.7-1.1,5.5-3.3,4.8-6.5,9.8-9.6,15l-3.4,1.6-1.3.8c-2.6,5.5-5.5,7.5-8.7,6.2l-1.1-.8c2.5-5,5.6-11,10.1-14.2,2.1-9.8,7.3-15.8,16.1-20.9l2.9-3.1c1.6-2.5,2.9-3.1,4-2Z"
      style={{ fill: "#01681a" }}
    />
    <path
      d="M604,150c-1.5,2.7-5.2,7.9-7.5,10-3.9,3.6-5.8.5-4.5,10-6.4-2.6-8.6,6.9-13,11-.4,2.1-3.3,4.9-5,3,.5-3.3,3.6-4.5,5.2-7.3s2.3-7.8,3.3-9.7c5-9.6,12.9-11.7,21.5-17.1Z"
      style={{ fill: "#01480f" }}
    />
    <path
      d="M588,112l2,1.1v11.3s2,1.6,2,1.6c-.1.6,0,1.4,0,2l-1.1,3.5c-2.2,9.6-10.3,8.2-10.7-1.6-4.7.7-4.1-3.5-2.2-6-2.4-2.7,2.5-11.4,6-12,1-2.7,3.1-2.8,4,0Z"
      style={{ fill: "#02832b" }}
    />
    <path
      d="M578,124c.3.5-.3,3.7,0,4s3.6-.4,4,0-.2,7.2,2.7,8c7,2,2.6-5.3,7.3-8-.2,1.3,0,2.7,0,4-.7,2.2-1.3,4.2-1.8,6.1-13.5,10-30-1.7-14.2-14.1.6-2.6,1.2-2.6,2,0Z"
      style={{ fill: "#018126" }}
    />
    <path
      d="M607,96c1-3,5.9-2.9,7,0-5.5,6.6-11.3,12.8-16,20-1.1-.8-1-3-.8-4.4,3-3.8,1.2-7.1-2.2-9.6-3.1-1.2-1.3-2.7,1-4-.1-3.4,9.9-5.4,11-2Z"
      style={{ fill: "#0d6f2c" }}
    />
    <path
      d="M595,102c5.9-.5,6.1,4.5,5,9.3s-1.3,0-2,4.7c-.5,3.5-.9,1.3-2,2-1.8-.4-2.5-2-2.2-5-2.7,1.1-4.6.8-5.8-1-1.3,0-2.7,0-4,0-.3.5-.6.4-1,0-3.8-3.2.6-6,4.1-6.3,2.6-4.5,5.2-5.8,7.9-3.7Z"
      style={{ fill: "#08782d" }}
    />
    <path
      d="M593,188c.3.3,3.8-.9,1,3-1,0-1.7-.3-2,1,5.3,3-3,10.3-6,12l-.7,1.3c0,4.4-1.1,5.3-3.3,2.7-4.1-1.5-1.5-7.8,0-11,2.9-3.8,4.4-10.6,11-9Z"
      style={{ fill: "#081e0e" }}
    />
    <path
      d="M584,184c.5,3.4-4.8,7.2-8,8-3.5,6.9-8.1,13.2-13.8,19l-2.2-1c-2,0-1.6-1.1,0-2-4.6-3.2,4.7-10.8,8-13,1.6-4.4,6.3-11.8,11-11,1.3-.3,3.5.2,5,0Z"
      style={{ fill: "#03350f" }}
    />
    <path
      d="M588,112c2.7-.1,5.2-1.4,8-1v7c-.9.6-1.2.5-1.8,1.7s-2.1,6-2.2,6.3l-4,2v-16Z"
      style={{ fill: "#07822f" }}
    />
    <path
      d="M602,144c2.1,1.3.7,3.3-4,6-1.6.6-3,1.5-4.6,2s-6.8-.4-7.4,2.8,1.4,5.8-2,5.3c-1.5-1.1-1.7-4.9,0-6,.8-.9,1.1-2.6,2.8-3.9s4.9-3.9,5.2-4.1c3.4-2,6.2-1.9,10-2Z"
      style={{ fill: "#015715" }}
    />
    <path
      d="M624,95c1.7-1.8,3.1-1.7,4.1-2.7,2.8-2.8,0-5.6,6.9-3.3,1.4,1.4-8.3,6.9-3,7-3.7,3-5.6,4.8-11,4-.7-2.5,2.1-2.3,2.8-3.2s.1-1.7.2-1.8Z"
      style={{ fill: "#0a642a" }}
    />
    <path
      d="M608,89c1.7-.3,3.7-.3,4.8-.6s1.5-4,3.2-2.3c2.6,2.5-.7,7.2-1,10-.3,0-.7,0-1,0-2.3,0-4.7-.2-7,0-3-1-1.9-6.2,1-7Z"
      style={{ fill: "#0a692d" }}
    />
    <path
      d="M584,184c2.1-1.4,3.8-1.5,5.7-2.3s1.1-3,4.3-1.7c.3,1.6-1.2.4-2,1.8s.1,2,0,2.2-2.4-.3-3.7.3-6.1,7.2-6.5,7.5c-1.4.8-5.4-.2-5.8.2,3-3.3,3.5-5.1,8-8Z"
      style={{ fill: "#042d10" }}
    />
    <path
      d="M579,184c-2.3.6-8.2,9.6-11,11-2.2-.2-1.4-1.6,0-3,.4-3.2,1.4-5.6,4.3-7.7s1.5-.2,1.7-.3c.7-.5,4.1-2.1,5-3v3Z"
      style={{ fill: "#034012" }}
    />
    <path
      d="M608,144c1.8.4,1.7,1.2,0,2-.7.4-1.3.7-2,1-2.6,1.2-5.3,2-8,3-.4-2.8,4.4-3.2,4-6,2,0,4,0,6,0Z"
      style={{ fill: "#025217" }}
    />
    <path
      d="M608,146c0-.7,0-1.4,0-2,.3-2,6.9-10.6,6-6.5-.1.5-2.7,6.8-2.9,7.1-1.2,1.3-2.2,1-3.1,1.4Z"
      style={{ fill: "#024b15" }}
    />
    <path
      d="M574,122c1.6-3.6,5.3-.2,2,2,.8,6.1-1.9,3-3.1,4.6s-2.1,6.1-2,6.8,4.8,4,5.1,4.1c1.7.8,4.7.8,6.5.6s7.1-1.9,7.4-2.1c.6-.6-1-5.3,2.1-5.9-.1,2,0,4,0,6-5.4.3-8.7,6.7-12.1,8.4s-5.5,1-7.8,2.2c-9.5,4.9-4.5,7.4-6.1,14.7-.1.5-5.5,6.8-6.6,8.5-2.2,3.5-2.6,9.4-7.4,10.1,1-8,3.4-14.9,7.4-20.7-2.3-5.3-5.4-7.9-9.3-7.7l-4-2.9c-4,4.8-6.7,5.5-8.1,2.2-1.6,1.1-3,1.2-3-1-.6-2.7,1.9-4.7,7.4-5.9,3.5-4.2,4.5-5.2,10.5-4.4,2.6-4.2,6.9-6.8,13.1-7.7-.5-4.5,4-10.7,8.1-11.9Z"
      style={{ fill: "#017d21" }}
    />
    <path
      d="M592,142l-4,2c-3.1,6.8-11.6,10.1-14.7,16.3s-.9,6.9-1.5,7.5-2.3,0-2.8.1h-1c0-2.4,1.5-9.2,2.5-11s3.2-1.9,4.5-3.5.7-3,1.1-3.4c1.6-1.2,9.5-3,11.8-6.2,1-2.2,1.2-3.8,4-5,0,1,0,2,0,3Z"
      style={{ fill: "#03711e" }}
    />
    <path
      d="M461,464c1,0,2,0,3,0-.2,2.6.1,5.4,0,8l-15,1c.9-1.2,1.6-2.7,3.1-3.5,2.7-1.4,3.7,1.1,3.9-2.5-6.2-2.2,2.9-8.5,5-3Z"
      style={{ fill: "#151b16" }}
    />
    <path
      d="M65,448c12.7-4.6,19.3-3.5,16.9,9.8-.7,3.6,2,5.2,8.2,4.8v2.4c-1.3,2.1-.4,1.7.9,0-.6-3.4,4.4-4.6,5-1-.5,4.6-2.7,4.5-5,8,7.3-.8,4.2,3.9,5,8h-18c-.9-.9,3-13.3,1.9-14.9s-4.6-1.4-4.9-1.1c-.5.4,1.1,4.2-2,3,.8-4.7-1.9-2.6-5-3s-6.2-1-6.9-3.6,2.2-3.3,2.7-5.1,0-5,.2-7.3c.2-.9.5-.9,1,0Z"
      style={{ fill: "#131815" }}
    />
    <path
      d="M77,472c-2-.5-2.2-2.1-2-4,2,.5,2.2,2.1,2,4Z"
      style={{ fill: "#131815" }}
    />
    <path
      d="M560,314c.2.1,1.6,2.7,3.1,3.5s2.3.3,2.9.5c3.9,1.4,4,.5,6.3,1s.7,1.3,1.1,1.6c.8.5,2.2.3,2.4.5.4.4.4,3.5,0,3.8s-7.3-.2.1,3.1c2.5,8.9-6.1,6.8-7.6,8.9s-.7,6.7-.4,7,4.4-.1,6,1.5c2.3,2.4-1.1,5.9,4.4,6.6,8.9,1.1,4.3-6.8,5.5-8.1s9.3,0,11,.5c2.9,3.3-2.5,0-3,.5,2.8,3.5-1.5,5.8,0,9-1.5-.1-8-.4-8,1.5s2.9,2.1,3.6,2.9.2,1.5.3,1.6c-2.9-.7-1.5,2.3-3,3.5s-2.1.2-2.7.8c-1.1,1.1-.7,4.6-5.3,4.7-3.2.2-4.4-4-3.4-6.7l-1.4-1c-5.7,11.2-21.6,3.3-19.5-8.9-.7,7.5-3.1,10.4-7.1,8.7-5.5,7.5-12.7-4-8.8-10.1,5.8-2.3,9-7.3.2-7.1l-1-2c-.5-.8-2.4-7.2-1.8-8s1.4-1.5,2.6-1.3c1.4-2.9,5.9-6.7,9-5.1,1.6-2.8,3.7-4.5,6.3-5.1-.3-5.4,1.3-8.2,4.9-8.5-.6-2.2,2.4-1.6,3,0Z"
      style={{ fill: "#0b110f" }}
    />
    <path
      d="M619,238c3.2-4.1,4.5.4,4.9,3.6,1.4,10.7-3.4,4.8-4.9,6.4s.4,5.7-.9,7.4-3,.1-4,.7c-1.9,1.2.9,6.9-4.2,7.9,0-2,.4-4.4-.2-6.3s-1.8-1.6-1.8-1.7c-2.7-9.5,1.6-6.9,5-10.5s.8-4.2,2.4-5.6,3,.5,3.4,0,0-1.7.1-1.9Z"
      style={{ fill: "#070f0a" }}
    />
    <path
      d="M622,224c3.6-.7,2.8-5.2,8-4-.9,4.2-4.1,2.1-4.1,7.5,0,5.8,5.2,6.8,4.1,12.5-6.7,1.9-4.4-7.5-10-5,.2-1.5-2.3-9.6-1-11s2.2.1,3,0Z"
      style={{ fill: "#070f0a" }}
    />
    <path
      d="M624,210c-6,1.2-1,6.5-5,6-.1-3.3-1.4-1.6-2.5-2.9-3-3.7,5.8-2.8,7.5-3.1Z"
      style={{ fill: "#070f0a" }}
    />
    <path d="M626,216h1c-2.3,3-3.3,3.1-3-1l2,1Z" style={{ fill: "#070f0a" }} />
    <path
      d="M25,56c.3.4,1.6.6,2,1,.9,2.6,1.7,5.2,2.2,7.9s-.5.6-1.2.8c2.6,4.1,3,7.5,1,10.3l1,.9c5.4,4.1,5.8,6.3,1.1,6.6l.9,1.5c3.3,1,3.1,13.4,0,15,2.2-5.1,5.5,2.5,4.9,6.1,2.7,3.6,4.8,6.9,4.3,11.7,1.8,2.3,1.9,9.3-1.2,10.2,5.1,2.7,4.8,5.6,2.4,10.9,4.4,2.8,5.6,7.8,3.6,14.9,3.3,1.5,6.2,7.7,2,9.1,2.7,2,2.8,4.2,1.4,7.1,2.1,3.8,2.1,6.9-1.8,9.6,2,.4,2.8,1.3,3.7,3.1s1.3,10.3,0,11.9c4,3.6,4.9,6.6-.9,6.7l-2.2.6c-.5,3.8-8,4.3-10,1-.3-.6.3-2.7,0-3-.6-.5-5.6.4-6,0-1.3-1.3,3.8-10.7-9-8l1-4h-5c-.6-2.2,1.4-2.3,3-3s1.6.7,2-.7c.8-2.6-.3-8,0-8.3,1.1-1.1,3.8,3.9,5.5,0s-.9-7.6,2.5-9v5c3.1.8,6.2-1.6,7-4.5s-4.1-.8-5-1.5c-2.3-1.8,2.6-1.7,2-3l-4-2c4.4-1.3,6-1,10,1,1-3.3-2.7-1.2-3.1-2.5-.7-2.7.8-6,.8-8.2s-8.5-12.5-7.8-4.3c-.9.8-3.7-1.7-4-2.5-1-2.4,4.9-1,4-4.5h-8c0-6.1,9.6-.6,10-1l-1-6-4,3c4.6-3.5,1.8-5.8,2-8s.9-2.8,1-5c-5.5-3.6-6.5-6.4-2.9-8.7.1-2.2-.8-3.8-2.9-4.6-.3-2.3-.7-4.6-1.2-6.7,2.3-9.1-7.1-11.5-4.5-20l3.5,2c0-1-.1-2,0-3-1.8-2.2-1.2-5.2-.4-7.7-2.9-1.8-3-3.3-.8-5.6-3.7-4-3-9-2.1-13.9-3-2.7-5.3-10.1.3-7.8l2.1.6,1.9,8.4Z"
      style={{ fill: "#09190d" }}
    />
    <path
      d="M14,16c4-5.5,4.9,2.8,2.2,6.7,2.7,4,3.4,9,2,14.9,3.1,1.5,8,9.2,2.8,9.4-4.2,1.7,1.6,5.9,1.8,7.7s-2.4,3-1.8,6.7,7.5,6.9,1,10.6c6.6,0,2.5,4,2,6.6s0,2.4,0,3.4c-4-1.7-1.5-6.9-2.9-9.3s-5-2.4-5.2-3.3c-.5-3.5,9.4-11.4,0-13.4-.9,1.1,2,8.1-2,7-.3-1.6.1-8.4-1.5-9s-.3,1.8-1,2c-4.1,1.3-1.2-2.5-3.5-3l-2,3c1.2-3.8-.8-8.6,5-4-2.7-6.2,1.8-3.1,2-4.5.3-4.8-1.4-5.2-5-7-.4-3.4,4.1.5,5-.5,1.8-1.9-2-3.3-2.3-4.2-2.4-6.4-3.3-13.1-1.7-19.8,1.3.3,3.5-.1,5,0Z"
      style={{ fill: "#09160c" }}
    />
    <path
      d="M25,103c.5,0,1.9,1.3,3,1,.6-.7-1.2-9,3-8-.9,2.1,1,7.5.8,8.5-.3,1.5-5.9,1-3.7,4.4s10.1-.8,7,2-4,.5-5,1c.5,1.5,4.9,2.7,3,5s-4.2-2.6-5,1c3.5.7,4.1,2.2,4,5-1.5.5-2.8,3.2-4.4,2.9s-2.2-2.8-3.6-2.9v5c-7,1.9-3.8-5.9-5.1-7.9s-4.7.4-4.9-.6c-.6-2.7,5.1-3.4,5.5.5l.5-2c.3-.9.6-.9,1,0l2,2c-.4-2.1.6-5.7,0-7.4s-6.3-.5-3.9-7,4.6-2.6,5.9-2.6Z"
      style={{ fill: "#08170b" }}
    />
    <path d="M21,118h-1c-2.9-4.9,3.9-4.9,1,0Z" style={{ fill: "#142218" }} />
    <rect x="4" y="153" width="3" height="2" style={{ fill: "#18251b" }} />
    <path
      d="M16,152h-2c-.3-1.5.4-2.1,1.5-3l.5,3Z"
      style={{ fill: "#101e14" }}
    />
    <path
      d="M128,30c-.5,2.9.7,1.8,3,2,.9-.4,5.5-.9,5.7-.8,2,1.1,2,21.1-.3,23.3,1.3.7,1.7,2.2,1.4,3.6,3.3-.9,4.6.9,3.6,4-3.6,6-3.8,10.6-.8,13.9.6,2.8-1.1,16.8-4.6,17,3.4,3.7,3.5,5.8.2,10.3,3.7,5.7,2.8,12.4-5.5,8.7l-1.6,2.8c5.3,2.2,2.2,9.1-2.1,6.2.7,3.2-4.1,4-6.1,2.2s-3.2-12.8,0-13.2c.2-4.3,1.9-10.6,3-15h-3c0-2.4,4.3-3.3,3-5s-1.4.7-2,1c-1.5,1-1.5-.6-1-2,1.7-4.3.7-4.1,1-7s.6-4.1,1-6c-.2-1.8.8-2.3,2.9-1.5-.8-3-.4-4.9,1.1-5.7-2.2-.3-2.2-.8,0-1.4-2.6-1.3-2-4.1,2-8.5,0-4.5-.5-2.8-5-3l4-1c.9-3.3-2.4-1-3-2.6s-1.1-6-1-6.8l4-.5c-1.4-2.8.3-6.2,0-8.4s-2-2.8-2-4.1,2-2.3,2-2.5Z"
      style={{ fill: "#0a130c" }}
    />
    <path
      d="M75,24c1.2.8,2.2.8,3,0,0,.2,1.4,1.2,1.8,2.7s-.2,4.7.3,5.3,2-.2,2.6.3,3.8,7.3,3.3,7.8l-3,.5c.8,2,4.7,1.2,5,1.5,2.2,2-1.9,1.9-2,2-1.5,2.1,1.4,1.9,1.7,2.2,1.7,2-1.8,4.9-1.6,6.2.4,2.6,7.3,4.3.9,9.6,5.3,2.7,7.6-8,10.9.6s-3.7,1.8-4.9,2.4c-1.1,1.6,5.7,7.2-4,7,.4,2.8,6.6-.5,7,0v3s-8,1-8,1c1.5.3,7.4-.4,8,0,2.2,1.8-3.5,1.8-2,4,1,0,2,0,3,0,1,3.4,1.6,7.1,1.8,11.2,3.4,2.2,4.8,4.6,4.2,7,1.5,1,1.8,2.9,1,5.7,3.7,1.7-.6,9.6-2.9,4.1-4.8.7-7.2,1-7.1-4.8-3.2-.9-5.7-3.6-3.3-6.7l-.8-1.2c-3.6,1.1-4.6-.8-3.8-4.2-6-2.5-4.6-10.1.4-6.9,1.6,1-3.1,1.8.6-1.1l-2-2c-1.4,1.4-1.9.8-1-1,.2-1.9-.5-3-2.1-3.3-2-3.9-2-9.5-1.9-13.7-3.1,0-4.1-.7-3-2-1.6-.9.3-4.2,2-4-.5-1.9.6-2.3,2-1,.1-.1.9.1,1-.5-3.4-4-.4-4.3-.3-6.8.2-3.8-1.3-7.9-5.8-6.7,2.5-7.4-.2-3.9-1.8-5.1s0-1.5-.2-1.8c-1.9.4-2.1-.5-1-2,.9-.8,3.2-.9,3-1.5-1.2-.9-2.9-3.9-4.5-3.5s-.6,4.2-2.5,4c-.1-1.1-.3-6.4,1-6s0,1.5,1.5,1,2.5-2.3,3.5-3Z"
      style={{ fill: "#0d170c" }}
    />
    <path
      d="M97,80c4.1.2,1.1,3.6,2,7.3s1.9.6,2,1.3c.4,2.5-2.3,5.3,4,5.3l-2-7c3.9-.8.3,7,5,4s-2.9-3.8,1-3c2.1,3.8-1.5,3.9-2.5,6s.4,3-.1,4.7-2.5,3.1-2.4,5.2c5.7,2.4,6.5-5.9,10.3-4.9,5.7,1.5,1.6,10.1,6.7,10.9-.2,2.8,1,8.6,1.1,11.9,1.8.5,3.3-.3,4.9-.9l2.2,1.3c1.7,8.2,1.4,14.7-3.2,21.7,1.7.5,1.7,2.4.5,3.7s-14.9-1.7-15.4-2.4c-1.7-2-1.3-4,.8-5.4-.7-2.8-2.9-7.6-5.8-5.4l-2.1-1.6c-2.7-.9-2.7-2.2,0-3,1.5-3,3.3-5.7,5.3-8.1-3.6,1.1-5.4.4-5.3-1.9-2.8-1.3-3.8-3-3-5-.9-1.7-.9-3,0-4-3.5.7-3.7-3.4,0-3,.7.2,2.1-.1,3,0l-3-2.5c0-1.2,3.2.2,3-1-.5-2.7-.9-5.5-4-4.5-.2-2.4,2.7-2.9,1.1-5.3s-3.8-1-4.1-2.4c-.8-3.1.6-8.8,0-12.3Z"
      style={{ fill: "#0d130d" }}
    />
    <path
      d="M104,40c-.3.3-7,1-7.4,1-.6,0-.4-1.7-1.3-1.9-1.3-.4-8.3-.2-7.3-2.5,2.4-5.2,7-5.4,12.3-4.3s5.1,6.5,3.7,7.8Z"
      style={{ fill: "#0a0f0b" }}
    />
    <path
      d="M111,35c-.5.6-6.6,1.5-6-1.5,1.6-3.4,7.6-.6,6,1.5Z"
      style={{ fill: "#0a0f0b" }}
    />
    <path
      d="M78,23c0,.3,0,.7,0,1,.8,4.7-2.5,3.8-3,0,1-.7,1.5-1.3,3-1Z"
      style={{ fill: "#0f1c0d" }}
    />
    <path
      d="M114,61c0,.1.9,1.9,1,2-4.2,2.7-4.5-2.4-1-2Z"
      style={{ fill: "#161c19" }}
    />
    <path
      d="M78,22c-.5-3.9.6-2.1,1-3,1.1,1.4.7,2.5-1,3Z"
      style={{ fill: "#1c231a" }}
    />
    <path
      d="M111,94v2s-3,0-3,0c.3-1.4,1.6-2.3,3-2Z"
      style={{ fill: "#151b17" }}
    />
    <path
      d="M122,91c-.6.2-1.8,1.3-2,1-.6-1.7.7-2.2,1-3l1,2Z"
      style={{ fill: "#151c18" }}
    />
    <path
      d="M48,202c.7-2.3,2-1.8,3.8-.5,1.8,6.3,4.8,11.5,9.1,15.6,7.3,1.3,10,3.6,8.1,7-.2,1.4-4.2,0-4,0,1.9,0,6,.9,5.9,2.9,7.8-1.6,7.3,5.9.1,7,1,2.9.6,5-1.1,6.2-.6,6.7-3.7,6.2-7.9-.2l-1.1.2c.3.8.1,1.6-.5,2.1l-15.3-.3c-5.9-.4-6,1.2-6,6.5l-2.3.7c.2.9.2,1.9,0,3,2.9.6,2.7,2,0,3,.7,1.2.2,1.7-1,1h-4c.6-3.6,0-7.7,0-11s0-1.3,0-2c-1.1-.6-1.1-1.6,0-3l-.6-.8c-4,1.5-7.5-1.2-11.4.8-2.9,3.8-7.9-4.1-4-6-2.5-.5-2.7-3.5,0-4-2.5-2.1-2.8-4.1-1-6-.4-1.3-.7-7.7-1-8-1.8-2.2-2.3,3.5-4.5,3s-1.9-2.3-1.5-3.5c.9-2.8,4.9-.6,6.5-.7,5.6-.2,6.5-3.7,7,4.2,2.6,2.9,2.3-5.8,4.5-3s-3.1,8.9,4,9c-.6,3.5-.2,3.5,1,0-1.5-11-.7-8.8,7.5-16,3.4-1.5-.8,8.4,3.5,7,1.8-6.6-2.1-8.6-4-13,3.8.7,6.8-.4,10-1Z"
      style={{ fill: "#071a0f" }}
    />
    <path
      d="M15,224c.9,3.3,1.2.3,1,6-2.3.5-2.3,1.8,0,4,.6,5.8,3.8,5.6,4,6,.6,1.3-.4,6.1,0,8-11.9,1.2-6.1-1.6-8.4-5.1s-4.2-.6-4.5-1c-.5-.5.6-13.3,1.1-13.8s2.6,0,3.4-1.1c1.6-2-1.7-5.4,3.4-3.1Z"
      style={{ fill: "#061b0d" }}
    />
    <path
      d="M31,225c.1,1.1,1.1,3.3,1,4.4s-2.9.2-1,2.6c-7.7,1.3-5.2-3.7-1-7h1Z"
      style={{ fill: "#0c2014" }}
    />
    <path
      d="M26.5,193c2.9-.7,3.4,5.4,1,6s-3.4-5.4-1-6Z"
      style={{ fill: "#09190d" }}
    />
    <path
      d="M32,243l-8-1.5,8-1.5,1,.5c.3,1.3-1,2.1-1,2.5Z"
      style={{ fill: "#061b0d" }}
    />
    <path
      d="M527,385c0-4.4,4.5-3.3,7-.4l8.3-2.5c4.6-7.4,12.2,0,4.1,4.7-1.9,3.7.4,4.1,7.1,1.1,5-.4,7.4,1.4,9,6,4.4-3.7,10.5-6.3,12.5,1,1.2,7.2,8.8,5.2,15,5,0,2.7-1.2,2.8-3.4,3.1s-6.1-1.1-6.5.9c4.7-.3,7.2-.6,10.7,2.7s2.6.3.8,2.3-7.9.6-10.9,1.2-1.9,1.4-2.7,1.8c-1.7,1-3.4,2.8-4.5,3-4,.8-23.1,1.2-28.1,1s-9.8-3.3-14-3.2-5.4,2.9-7.8,2.9-3.3-3.6-4.2-3.8-2.6,2.2-3.9,1.9c-2.2-.4-3.2-5.4-3.8-5.8-.9-.6-2.8.2-3.6-.3s-1.3-6.2-1.2-7.9c.5-1.7,1.4-2.3,3-2-.5-4.7-.1-9,1-13,4.4,0,8.9.6,13.3,2.1,0-1.7.9-2.2,2.6-2.1Z"
      style={{ fill: "#0f1513" }}
    />
    <path
      d="M588,419c-.1.6,0,1.4,0,2-.5-.4-4.1,3.8-3-3l3,1Z"
      style={{ fill: "#191f1b" }}
    />
    <path
      d="M536,342c1.5-.2,7.7.4,8.1,1.6,2,7.2-5.9,8.4-6.1,9-1,3.5.5,7.4,3.6,9.4,3,.6,2.7-5.2,2.5-7,1.7-.3.8,1.9,1.6,2.9,4.8,7.2,4.1-4.5,4.9-5.9s8.1-6.5,7.5-1c-5.6-1.7-2.9,9.3,0,11.6s8.1,2.1,10.5,1.3,1-4.6,3.1-5.9c3.7,2.8,2.1,3.5,7.5,2-7.1,4.6-2.3,5.7-2,9,.6,7.2-3.2,19.2-2,26-7.5-6.5-8.2.9-15,2,.4-4.7-.5-7.1-5.6-7.1s-2.3,2.1-3,2.1-2-1.1-3.4-.5-1.8,3.1-4,2.6c.2-3-1-6.8,1.5-9.1s5.8-2.9,1-3.9-1.9,2.2-2.7,2.7c-1.5,1-7.1,1.1-9.3,3.3-2.2,0-4.6-5.5-7.5-2-3.4,1.8-4.1-2.9-1-4.9-2.1-3-2-6.3,0-10.1-.4-2.2,0-4.4.9-6.8-4.7,4.2-7.9.8-4.8-4.5l6.8-6.8c-.3-1.7.6-2.4,2.7-2,.6-4.4,2-7.1,4.3-8Z"
      style={{ fill: "#0d1210" }}
    />
    <path
      d="M64,416c1.4-2.1,2.6-1.3,4.3-.1,1.3-1.2,2.4-1.5,3.7.1l2.1.8-.3,13.2c8.1-1.4,7.4,14.8-.6,14h-5.3c.1,2.7-.8,4.1-2.9,4.1-.3,0-.7,0-1,0-.7,0-1.3,0-2,0-1.1,1-2.7,1.6-3,0-3.7,0-7.3,0-11,0-.9-3.4.9-7.3,1-10l6,2c1-2.7-2.6-5.2-2.8-6.7s3.4-9.2,3.8-10.9c1.4-5.4-1.4-7.8,8.1-6.4Z"
      style={{ fill: "#111814" }}
    />
    <path
      d="M62,448c-.4,1.4-1.5,2-3,2,0-.7,0-1.3,0-2,.8,0,2.1,0,3,0Z"
      style={{ fill: "#131815" }}
    />
    <path
      d="M123,76c-.4-.6-2.1-5.8-2-6.5.5-2.7,2.6.3,4.7-.3,5.9-1.7-2.3-2.2-2.5-2.4-1.1-.9-4.2-9.8.8-8.8l1.5,4,3.5-3c0,2.9-1.6,5.6-1.7,7.3-.2,3.7,2.7,13.6-2.3,13.7,1.2-4.7-1.8-3.8-2-4Z"
      style={{ fill: "#0c110c" }}
    />
    <rect x="99" y="8" width="2" height="3" style={{ fill: "#131813" }} />
    <path
      d="M72,384c3-3.2,5-.3,6,8.8,6-1.7,9.3-1,10,2.2.6.9,1.2,1.8,1.3,2.9,0,2.1-2.4,18.6-3.1,19.3-1.6,1.5-5,.9-6.1-1.2-1.2,2.9-6.7,2.8-8,0-1,0-2.5,1.8-3.7,1.8s-2.5-1.6-4.3-1.8c-.4-1.5,1.4-3.8-1.5-4s-9.5,1-12.5-1.5-1.5-9.9-2.1-10.4-2.8.3-4-.5-3-5.2-3-5.5c-1.8-2,.5-5.6,3-6,1.4-3.5,4.8-.7,6.3,1.6,3.3.5,7.9,1,10.8-1,1.2-7.8,4.4-8.2,10.9-4.6Z"
      style={{ fill: "#0e1a12" }}
    />
    <path
      d="M24,352c3.8-2.5,11.8-3,15.8-1.3s2.8,12.7.2,13.3l.5,3.4c3.7,2.4,5.5,5.3,1.2,8.4-.6,3,.6,5.8,3.4,8.2,1.1,2,1.2,3.3-1.2,3.9.5,1.9-3,5.7-3,6h-2.4c-.2-2.8-.5-5.3-3.6-6-.5-.3-2.5.4-2.9,0-1.5-1.7,2.7-3.7-3.1-4-1.9-.4-4.5-7.9-4.7-10.2-.8-6.2.2-8.5,4.7-12.8l-5.6-4c-2-1.5-3.2-2.9-1.4-5-.2-1.7.5-1.7,2,0Z"
      style={{ fill: "#0c1910" }}
    />
    <path d="M24,416v-7.5c5.5-2.6,5.9,8.9,0,7.5Z" style={{ fill: "#0e1510" }} />
    <path
      d="M35,388l3-3c-.6,3.4,1.2,5.9,2,9,.9,2.1-2.3,2.7-3,2s2.2-5.8-2-8Z"
      style={{ fill: "#0e1a12" }}
    />
    <path
      d="M588,217c1.5-3.8,6-.7,6.1,2.8,3.5,0,5.7,1.1,6.6,3.5,3.4.4,10.7,5.8,7.3,8.7-.6.1-2.3,1.2-2,2.5s2.9-.3,2,2.5l-4,.5,4,2.5c-11.3,1.9-6.8-5.6-8-7s-6.7-.9-7.9-1c-2.7,9.6,7.6,4,6,12.5-.7,3.7-5.3,4.8-5.8,5.7-.8,1.4.2,3.6-.2,5.1s-2.1.9-2.8,2.4c-2.8,5.9-1.5,10.2-2.2,15.8-.4,3.7-5.2,14.9-7.5,17.5-2.8,3.1-6.9,3.3-8.4,5.6s-2.2,8.5-4,11-5.6,4.4-7,4.4c0,.7,0,1.3,0,2-.3-.1-2.9,0-3,0-4.9,4.5-4.8-4.9,0-3,1.5-2.3,2.7-1.5,3,1-3.6-1.6-3-10.6,1.6-9.8,1.2-9.2,6.8-13.2,14.4-17.2,5.8-8.4,4-13.3-.8-21-3-2.6-2.2-11.4,3.6-10,.7-2.6.4-6-2.5-3.8-5.6-1.1-5.2-9.6-1.9-13.1-6.7-1.9-.6-9,2-11.8-1-3.7,1.8-5.4,5.2-5.3.8-3.4,3.9-6.8,6.4-3Z"
      style={{ fill: "#07160c" }}
    />
    <path
      d="M592,192c4.4-1.1,3.5,3.8,5.1,6.4s3.8,3,4.9,4.6c2.1,3.1,11.3,18.4,11.7,20.6s-.4,8.1-.7,8.4-3.8-.2-5,0c.2-1.7-5.3-6.2-6-6.5-1.6-.7-4.4,1-5,.5-2-1.8,4.4-2.3,1-3.5s-5.8-.3-6-.5c-.5-.6,2-5-4-5-2.5-.5-3.5-2.9-2.9-7.3-2.1-1.2-.4-4.2.9-5.7,1.2-5.5,4-5,5.5-8.1s.2-2.9.5-3.9Z"
      style={{ fill: "#09170e" }}
    />
    <path
      d="M632,344c-1.2,4.2-11.1,1.1-8-2s6.2,2.5,8,2Z"
      style={{ fill: "#0b120e" }}
    />
    <path
      d="M598,350c1,.5-2.7,2.4-4,2,.5-1.9,2.2-2.3,4-2Z"
      style={{ fill: "#0b120e" }}
    />
    <path
      d="M37,255c6-4.7,12.2-.8,16.7,4.9,1-12,10.3-1.6,6.3,4.1.4,2.2.5,4.2.4,6.1-6,1.5-8.6,4.4-7.8,8.9-5.3,7.2-.8,10.5,0,.3,6.6-3.7,14.6.3,6.4,6.9,4.3-1.1,5,3.9,1,5,4.8,3.6-.1,8.4-4.5,6.2-4.9-8.1-6-2.7-7.3,4.4l-2.3.2c-1,2.8-2.8,4.1-5.7,3.7,1,3.2.6,5.3-1.3,6.3-.1,1.3-1.1,1.9-3,2-5.8,2-7.2-3.1-3.7-7-3.4-1.9-3.3-2.6-2.3-6-1.6,0-2.3.4-2,1,2.6.6,1.8,3.1,0,4,.3-2.4,1.7-2.9,2-.5,2.4-1,3.3,1.6,2.1,3.5-1.8,2.9-8.8-.5-4.1-3.1.3,3.8-5.2,4-5.9,0-7.3-1.5-.8-7.2,2.9-8,.3,0,4.1-1.3,4-1.5-1.6-3.7-1.4-2.3-5-1.5v-6c.3-.4,2.9.5,3.4.9,1.5,1.2-.6,3.8,2.6,3.1.6-1.7-1.7-2.6-1.7-3.5s2.3-1.8,1.3-5.6-2.9-2-3.6-3.9c4.2-1,8.1-3.9,11.5-7s5.1-3.1,2-3-9.6,6.3-7.5-2.3,1.8-.9,2-1.4c.5-1.4,2.7-7.3,2.8-8.1.3-1.4-.8-2.1-.9-2.2,0-.8.2-1.1,1-1Z"
      style={{ fill: "#081e0f" }}
    />
    <path
      d="M13,288c.7.2,2.8-.3,3,0,.7.8-1.3,10.7,1.5,11,2.7-3,6.2-.9,7.5-1-1.6.8-.2,3.2-1.7,4.1s-4.8,0-2.3,1.9,4-1.5,3,3c.4.4,3.1-1,4-1,.1,3.1.5,1.9,4,2-.4-1.9-2.3-2-4-2,0-1.3,0-2.7,0-4-.1-.7.5-1.1,2-1,.7.3,2.4,1.4,2,2l-2,2,3-1c1.2,6.3-3.7,8.5,3,10,2.2.5,2.7,1.4,1.4,2.8-4.6-1.1-6.4-.4-5.4,2.2,3.3,1.5,7.8,7.5,1.8,9.5.8,3.3.2,5.5-1.8,6.5.5,1.9-1.2,3.5-5,4.9,4.1,3.7.8,5.8-2.7,8,.5,1.8.4,3.2-.4,4.2-.7,0-1.3,0-2,0-2.2,3.4-7.8,2.7-8.1-1.7.9-4.9,0-7.4-1.9-11.3.2-.7-.3-2.7,0-3,.7-.6,4.2,1.1,4-1l-6-2,9,1c-1.4-3.1-5.2-3.1-8-3-.8-3.6,3.9-1.5,6-2,.9-.9-4.3-10.8,2-9-7.3-4.7.4-6.7,0-8.5-1.7-2-4.2-1.3-6.6-2.4-5.3-2.6-7-7,.6-7.1.8-2-3-3.8-3-4.5s5.3-5.3,3-9.5Z"
      style={{ fill: "#091b0e" }}
    />
    <path
      d="M576,6c.9.5,2.9.1,3,2-4.6-.9-2.7,2.4-3,6-1.5,3.6-6.1,5-13.6,4.1-.3,2.4-1.2,4.1-2.8,5-9.1-1.3-18.1,3.2-23.3,10.6-3.8.6-7,2.5-9.6,5.5-3.5-.3-7.1-.4-10.8-.3-1.2,2.1-2.5,3.5-3.9,4.1-1,1.4-2.1,1.7-3.5,1.7,3.5,4.8-10.6,9.1-12.6,8.6-1.4-3.6-3.3-6.2-5.6-7.9-7.1,2.5-6.2-11.2-2.3-13.4,3.3,0,23.2.9,24,0,1.5-1.5-3.3-10.7,4-9.5-4.7.3-3.8-2.3-4-2.5,2.3.2,2.1.1,2-2,.1-1.5,2.6-1.5,3,0,.2,1.9-.7,2.3,2,2-1.1-3.3,1.1-3.5,1.6-4.4s.3-2.5.4-2.6c1.7-1.7,7.6,3.8,1,3-.3.3,1.1,1.8,1.5,2,2.9,1,2.8-4,4.5-2s-.9,8.3.5,10c1.3-3.6,8.4-12,7.5-3,2.5,2.7,13.2-13.4,14.7-13.8,3.5-1.1,12.3-.4,16.8-1.1s.2-2.4.6-3.1c2.7,2.3,4.3-.6,5.5-.7s1.7,1.3,2.5,1.7Z"
      style={{ fill: "#06170e" }}
    />
    <path
      d="M514,18c0-.1-1-.3-1-.5,0-1,2.4-1.9,3.5-1.5.9,0,.4,1.3.5,2h-3Z"
      style={{ fill: "#0e1f16" }}
    />
    <path
      d="M276,8c.5.3,2.7-.5,3.6.9s0,3.2.5,4.3c1.3,3.4,5.8,2.5,8.7,3.6s11.2,9,12,9.2c2.7.6,6.7-.2,9.6,1.2s1.5,2.2,2,2.5c1.1.5,7,2.5,7.6,2.2s1.8-4.5,3.8-4.8,2.3,1.8,3.7,1.7c2.1-.1,7.3-4.8,10.7-5.8s4.9-.8,7-1c-3.5,4.3-.2,5.3-2,9.5-2.6,1.6-6.1-.5-7.6-.2s-1.5,2.6-2.4,3.3c-1.6,1-4.2-2-5,.4,5.4,4.6-.4,6.7-5.4,6-4.6,3.6-10.7,5.6-13.6-.9-5.5.3-8.7-2.9-10.1-8-8.1,1-10.3-2.7-12.5-9.8-3-.9-6.4-3-6.4-6.4-2.4-2.4-3.6-1.4-4-6s0-1.3,0-2Z"
      style={{ fill: "#081c11" }}
    />
    <path
      d="M336,11c.2,3.7-2.1,1.9-4,0,1.5-1.4,1.9-.6,4,0Z"
      style={{ fill: "#0b1a10" }}
    />
    <path
      d="M12,339c6.4,2.1,3.4,7.4,4,13,2,0,4,0,6,0,1.1,3.5,7,6.1,10,8-2.9,5.4-7.6,5.6-5.8,13.3s2.3,4,2.8,10.7c-3.3-.1-6.8.3-10,0s-2.9.5-3-.6c.4-2-.5-5.2,0-6.9s3.8,1.3,2.9-2.9-4.6-7.5-7.3-10.7c-4.4-5.4.8-5,1.4-6.5s-4.6-2.3-3-6.5c-2.6-1.1-1.7,2-2.5,2s-2.5-1-2.5-1.5,2.3-4.2,3-5.5c1.3-2.4,3.5-4.7,4-6Z"
      style={{ fill: "#0a170e" }}
    />
    <rect x="301" y="1" width="3" height="2" style={{ fill: "#151d16" }} />
    <path
      d="M488,32l-1,12c5.8-2.5,8.8,2.6,9,8,3.4-3,4.5-1.8,7.4-2.1s.6-1.3,1.5-1.9,2,0,2.2,0c.4-.3-3.3-7.1,5-5,2.5.2,2.4,2.6,1.6,4.5l1.4.5c5.8-.7,2.6,5.6-2.7,5.7l-8,5.1c-2,6.4-4.1,8-11,7.2-2.5,4.9-7.1,5.8-7.3-.5-2,2.5-4.1,4.7-6.1,6.5-.3,3.3-6.7,2.7-8,0-6.5.8-3.8-7.4-2.1-10.8l1.1-2.2c1-2.4,8.5-5.1,9-6.7,1.9-6.6-3-2.7-6-5.8,3.7-5.2,7.6-7.1,6-14.5,2,1,5.3-1,6-1s1.5,1,2,1Z"
      style={{ fill: "#051b0e" }}
    />
    <path
      d="M57,26l7,2,1-4c-.1,1.7.5,4.7-.4,6.1s-4.6-.2-4.6,1.9c1.6-.8,9.2,1.9,7,4s-2.8-2.2-4-1.5-2.2,4-3,5.5c1.2,0,7.7,2.7,8,3,1.8,1.9-4.1,2.2-2,4s5.6-.4,6,0-.4,6.6,0,7c.7.7,8.1-1.6,9,2-.3.2-1.7.9-2,1-1.3.3-3.8,2.5-2,4,.2,0,.8,1.1,1.4,1.1s.5-2,1.1-2,3.4.8,3.6.9c2.5,2.2-2.6,1.9-3,2,0,1.8-1.3,2.9-4,3.2l-1.4,3.8c4.1-.4,5.6,2.4,4.3,8.4l2.1,1.6c.5,1,1.2.6,2,0,.3-.9.7-.9,1,0,0,0,.5.6,1,1,1.5-.5,2.2.6,2,2,.1,1.4,1.1,3.9,1,4-1.9,1.8-2-1.7-2.1-1.8-.3-.2-2.9,1.2-3.8.8l5,2c.4,1.4-.7,2.1-1,3,.6,4.3,3.5,8.8,6.6,12.4l-.3,1.3c3.8,1.7,3.7,4.7-.2,9l2,4.1c4.7,1,4.3,6.5,0,6.2-1.5.9-4.8.2-5-2-8,6.8-9.4,1.9-4.1-14.8-6,0-7.1-2.7-6.7-8.4-5.7-.3-8.1-9.2-5.2-12.9l-1.1-.4c.4-5.6-.9-7.6-3.9-6-4.2-4.5-6.8-12.3-2-17.3-3.1-1.4-4.4-3.1-3.8-6.6-3.3-1.6-5.7-4.4-4.7-8.3-3.1-1-6.9-4.9-3.7-7.5l2-2c1-3.9-2.8-3.4-3-4.5-.2-1.6,2.8-.4,2.9-.6.2-.4,1-5.8,1.1-6.9Z"
      style={{ fill: "#13200f" }}
    />
    <path
      d="M74,35c-1.8.4-2,2.4-2,4-3.6-1.2-1.1-4.1,1-6,.3.7.7,1.3,1,2Z"
      style={{ fill: "#192619" }}
    />
    <path
      d="M594,82c.6,1.7,1.2,3.4,0,5-1.4.6-2.3,2.2-3.7,2.8-3.9,1.6-10.7,1.4-11.3,6.2-.2,1.4-1.5,2.4-3.8,3-2,2.2-15.7,13.9-16.8,14s-1.7-.4-2.4-.9c-.6,1.5-1.5,3.2-3,2-2.6,1.1-3-.2-1-2-.5,0-.9,0-1.3-.2-4.8-2.2,13.5-15.2,16.3-15.8.7-1.3,5.4-5.4,6.8-6.3,5-3,8.1-3.9,13.9-4.6,2.8-3.3,4.8-4.3,6.2-3.1Z"
      style={{ fill: "#025b22" }}
    />
    <path
      d="M579,96c1.2-.2,2.7,0,4,0,3.4,2.4-2.9,5.3-5.7,6l-4.6,5.3c-5.1,3-8.9,6.4-12.7,10.7-2.3,1.9-5.7,4.3-8,2l-1.5-2c2.2-1.8,4-3.8,5.5-6,4-2.2,8.4-6.6,13.3-10s3.6-5.2,9.7-6Z"
      style={{ fill: "#026423" }}
    />
    <path
      d="M588,96c2.5,1.8.8,3.8-5,5.9l-2,2.1c.7,1.7-.5,2.7-3.4,2.9-1.1,2.9-3,4.6-5.6,5.1,1.3,2.5-1.6,4.4-4,4-3.5,2.8-6.2,3.5-8,2,1.4-7.9,5.7-7.6,10.8-11.7s4.9-5.7,5.5-6.1c1.7-1.1,5.6-.3,6.7-4.3,1.7,0,3.3,0,5,0Z"
      style={{ fill: "#026926" }}
    />
    <path
      d="M608,89l-1,7c-5.1.4-6.3.8-11,2-2.7,3-11.5,7.9-15,6-.1-3.8,3.7-3.4,5.6-5s1-2.5,1.4-3c3.5-4.8,5.8-1.2,10.3-3.2s2.7-2.4,9.7-3.8Z"
      style={{ fill: "#066929" }}
    />
    <path
      d="M280,16c3.1,3.2,7.6,4.8,7.9,5.1s.6,2.3,2.1,4.6.9,3.1,2,3.8c3.2,2,10.2-2.5,9,5.5l3-3c-1,9.5,1.6,4.3,6,6.5s1.5,3.2,2.7,3.5c5.8,1.5,6.7-2.4,9.8-3s5.2.3,5.5,0-.3-3.1,0-4c3,1.7,6.2,1.4,9,2-3,3.6-8.8,5.2-9,11-.9,1.7-2.9,2.4-6,2-1.1,5.2-8.5,2.4-10.9-.2-9.2,1.8-14.5-.7-15.9-7.6-4.3,2-5.7-.6-7.2-4.2-1.4-1.9-2.8-3.9-4-6-2.4,1.1-3.8-5.1-2-6-.2-.9.3-2.2,0-3.3s-3.7-2.7-2-6.7Z"
      style={{ fill: "#081f14" }}
    />
    <path
      d="M345,32c.5,2.5.9,4.5,0,7-.9.7-5-1.8-6-2,2.4-2,2.9-3.8,6-5Z"
      style={{ fill: "#081f14" }}
    />
    <path
      d="M288,38c2.5-.6,2.7,3,3.7,3.1s2.8-1.4,4.3-.6,1.6,4.1,2.5,5.5c2.4,3.7,10.4,1.1,13.8,2s.6,3.5,2.6,2,0-4.1,2-2-.6,2.7.5,3c4,1.1,1.6-2.8,3-3.1s5.3,0,7.5,0,3.2,0,5,0c3.2-3.7,9.7,1.4,6,5,3.6,6.4-9.9,9.3-5,2-2.1-1-2.7-.7-2,1-1.3,5.4-10.6,7.2-14,3.9-2.7.7-5-.2-4.7-3.2-1.9,1.9-4.4,1.1-6.5-.3.2,5.8-4.8,6.2-7.5,1.5-5.4.6-7.1-2.9-6.2-7.8-3.6-2-10.1-9.4-5.1-12.2Z"
      style={{ fill: "#072414" }}
    />
    <path
      d="M368,28c-.1,1.6.2,3.4,0,5,1.7,3.5-2.5,5.3-5.7,6.2-1.7,5.2-5.2,6.5-10.3,6.8,0,1.1-.6,1.8-2,2-.2.9-.6.8-1,0-.8,1.3-3.5,2.5-5,2,.3,2.7-.1,2.9-2,1,.2,3-.8,3.7-3,2l-2-5-1.5,4-.5-4c-.7,0-1.3,0-2,0,.2-3.4,4.4-4,7-5.6s.9-2.3,1.6-2.5c3.4-.7,3.7,2.6,3.5,5.1l2-5c3.2-.9.9,2.7,3.5,2s.6-3,3.5-2c-.2-1.9-4.5-1.9-4-4.4s14.7-12,18-7.6Z"
      style={{ fill: "#0a2417" }}
    />
    <path
      d="M400,5c0,1,0,2,0,3l-3-1c.5-.3,1.1-2.5,3-2Z"
      style={{ fill: "#122c1e" }}
    />
    <path
      d="M234,72c2-2.8,3.6-1,4.6,5.5l1.4,2.5c1.9.6,1.9,1.4,0,2,1.6,1,1.9,1.7,0,2,3.5,4.4-1.5,3.9-5,2.2l-.8.9c3.4,2.1,1.3,7.6-2.2,4.9l-1.8,1.7c-9.8-1.7-21.7,2.7-23.6,13-5.8,5.9-9.9,13.1-12.3,21.8l-2.3-.5c-2.3-.7-1.4-2.4,0-4-2.8-2.6-2.4-6.4,1.2-11.2.1-8.1,2.4-11.7,6.8-10.8.6-3.5,1.9-6.6,5.6-7.6,1.5-6.4,10-10.4,16.2-8.2.7-2.5,2.3-3.6,4.7-4.2-.7-2.5-.2-4.2,1.6-4.9,3.1-.8,1.6,2.4,2.1,2.9s3.8.3,3.9.2c.3-.3,0-6.5,0-8Z"
      style={{ fill: "#014813" }}
    />
    <path
      d="M216,64c3.2.5,1.9,2.2,4.2,3.3s3.4.3,4.5,1c2.8,1.7,2.9,6.1,3.3,8.7,1.5,10.2-1.1,5.2-3.8,8.3s0,2.3-.3,2.6c-1,1-5.6-.6-7.3-.5s-7.9,4-8.4,4.9.2,2.9-.4,3.6-2.2.2-3.3,1.2-3.3,3.7-4.5,5c-3.4-1-3.1-8.8,0-10,.7-3.7,2.7-6,6.7-6.1,3.4-5.7,7.6-8.4,12.7-8.1.5-.4-3.1-11-4.6-11.7l.3-2.1c.2-.9.6-.9,1,0Z"
      style={{ fill: "#024510" }}
    />
    <path
      d="M230,28c.6.8.9,2.1.9,3.7,2.8,3.1,6.9,12,3.1,15.3,1.7,5.3,1.8,11.9,0,17-1.5-.4-3.8,1.4-4-1.5-.4-5.4,1-14.2,0-18.8s-6.8-17.7-8-19.7c.6,0,1.4.1,2,0,0-2,1.4-1.8,3-1,1.2,2,.4,3.2,3,5Z"
      style={{ fill: "#024311" }}
    />
    <path
      d="M194,47c3.1,2.2,6,4.9,8.8,8.1,5,.2,8.8,1.5,11.2,3.9.5,1,1.6,2,2,3s-.1,1.4,0,2c-.3,0-.7,0-1,0-2.2,2.9-8.9,3-11,0-1,2.8-3.1,2.8-4,0-2.4-1-4.8-5.7-3-8-3.5-3.8-4.5-6.8-3-9Z"
      style={{ fill: "#014504" }}
    />
    <path
      d="M234,47c2.8-.7,3.6,1,4,3.5-.3,9.4,1,18.8,4,28l-2,1.5c1-2.8,7.3-3.1,8,0,.9.3.9.6,0,1,2.9,0,2.8,3.4,0,4-1,3.4-6.7-.9-8-3,0-.7,0-1.3,0-2-4.6-.5-2.1-8.3-6-8,0-2.7,0-5.3,0-8,0-5.6.2-11.4,0-17Z"
      style={{ fill: "#014116" }}
    />
    <path
      d="M615,43c0,4.7-6.8,6.8-11,8-.8.3-3.7-.3-4,0s.6,4.1-.1,4.9-5.7,1.4-7.1,2.2-6.3,5.1-8.5,5.8-8-.1-8.3.2.5,3-.1,3.9-2.9.7-4.1,1.5c-4,2.6-2.1,1.3-6.2,2.8s-6.8,3.6-8.6,7.9c-.3,3-3.2,2.9-4,0l-.7,1.2c-4.2-1.9-6.6-.6-7.2,3.8-9.4,5.4-17.9,11.9-25.6,19.6l-2.5-.6c-3.2-1.5,1-6.4,3-8,.3-3.4,2.9-5.7,6-7.2-.1-3.4,2.3-6,7.1-7.6.3-2.9,11.4-9.8,10.3-6.1s-.4.9-1,1c-.9.2.2-.8,1.6-1.1,8.5-5.2,17.6-9.4,27-12.6,1.2-3.2,4-5,8.6-5.3,3.1-3.2,6.5-4.7,10.1-4.5,3.5-3.1,7.8-5.2,12.9-6.4,1.7-3.1,9.1-7.3,11.4-4.1.2,0,.7.7,1,1Z"
      style={{ fill: "#023717" }}
    />
    <path
      d="M576,74c.9.3.9.6,0,1,5.5,4.9-3.9,7.4-8.7,6.9-3.2,5.8-7.1,8.4-11.8,8-4.3,4.7-9.1,9.1-14.4,13.1h-3.1s-1.8,3.9-1.8,3.9c-7.1,6.1-14.8,11.4-22.4,16.4.4,2.1.3,3.5-1.8,4.7.5,2.4-3.1,4.7-5,3-1.1,3.8-5.1,4.3-7,1-1.6-.7-.7-4.6,1-6,1.3-3.9,5.1-6.2,9.1-7.6l.7-3.3c5.7-4.7,11.2-9.6,16.6-14.5h3.9c1.5-4.7,4.2-6.9,8.1-6.5,2.3-6.4,7.7-11,13.6-14.1,1.1.2,2.9,0,4,0,4.7-.4,2.8-2.3,4.6-2.9s3.9.2,5.7-.2.6-2,2.6-2.6,4.6,0,6.1-.4Z"
      style={{ fill: "#024219" }}
    />
    <path
      d="M617,58c-1.2,2.5-4.1,2.9-6.3,4-3.6,1.9-8.1,3.8-12.7,6-2.7,4-13.2,10.1-18,10.1s-4.2-.3-4-3.1v-1c4.8-1.3,16.7-7.1,16-13,1.2,2.2,7,2,8,1,1.3-.9,2,.9,1.6,1-1,.2,0-2.5,1.4-2,4.3,2.5,2.6-.6,3.9-1.5s8.4-1.2,10.1-1.5Z"
      style={{ fill: "#03451b" }}
    />
    <path
      d="M638,37c-.9.6-1.9,1-2.9,1.6-5.7,3.8-13.6,5.8-20.4,9.4-1.2.6-1.3,2.5-2.4,2.9-1.7.5-5.9-1.3-8.4,0,2.2-.9,7.6-6.2,11-8s7.3-2.4,10.7-3.8,2.7-2.8,4-3.1c2-.5,5.8,1.7,8.3.8Z"
      style={{ fill: "#013916" }}
    />
    <path
      d="M602,60c.3.3.6.8,1,1,.6,2.3-1.1,1.9-2.5,3l-.5-2c.9-.9-1.6-.5,2-2Z"
      style={{ fill: "#0b4b24" }}
    />
    <path
      d="M468,49c1.2-.6,2.4-.4,2,1-.2.6-1.3,1.8-1,2h3c1.3,3.1-10.8,0-7,3s1.7-.5,3.1.7,2.8,3.1,2.9,3.3c3,5-.8,5-1,5.7-.5,2.2,0,5.9,2,7.3l.4,2.1c-5.6,3-6.8,6.8-8.3,12.6-1.4,4.5-3.4,6.2-6.1,5.3.3,1.1-4.6,5.7-5.8,6-2.3.6-3.5.7-4.2-2-4.2-2.3.1-8.9,4.1-10-.5-4.3,1.5-9.8,3.9-13-.3-1.7.4-3.6,2.1-5.7.1-4.4,2.9-20.8,7.8-21.1s2,1.1,2.1,2.8Z"
      style={{ fill: "#052011" }}
    />
    <path
      d="M469,44c-.3,1.2-1.7,2.7,0,4,.9,1.1.3,1.4-1,1-.5,0-1.5-1.1-2.4-.9s-3.9,8.8-4.4,10.6c-1.2,4.9.8,10.6-5.2,14.3-3.7,4.3-6.4-3.1-3.9-6.9,1.5-2.7,2.8-5.4,3.9-8.1-3.4-3.3-.7-10,3.1-12.3-.2-3.2,2-7.3,4.9-4.7l5,3Z"
      style={{ fill: "#052510" }}
    />
    <path
      d="M469,48c.2.2,1.4-.2,2,0,.3.3-.8,1.3-1,2l-2-1c0,0,.6-.8,1-1Z"
      style={{ fill: "#122f20" }}
    />
    <path
      d="M29,16c.5.3,2.5-.4,2.9,0s0,4.6,0,5.9c5.3.2,10.7-2,16,2,.3,8.2,2.8,5.7,4,11-.3,1.2-1.3,1.9-3,2,1.6,1.4,3.2,3.8,1.1,4.9,2.1,3.7,2,6-.1,7.1-1.3,1.1-2.8.9-4.4-.5.7,2.6-.7,5.9-3.6,6.5,3.1,3.2,7.6,16.4,5.5,19.9s-4.4,2.3-5.5.1c-4.2,1.2-1.5-2.6-1.5-2.3-.2,5.8-4.8,4.3-4.5-.7-.8,1.9-1.3,2.1-2,0,1.1-2.5,1.5-8-2-8-.2-1.8,3.6-1,3-4-7.4-.3-.5-2.5,0-3.5-4.5-1.6-.2-1.1,0-2.1.3-2.1-1.1-7.5-1.1-9.9s2.7-5.1-.9-6l2-3.5c-5.2.5-4.2-3.7,1-3.5-5.9-1.9-3.8-4.1-4-9.5-2.4-.1-5.4-1.1-8-1-.8-3.7,4.2-.9,5.5-3l-3-1,2.5-1Z"
      style={{ fill: "#122612" }}
    />
    <path
      d="M34,72c.5.2,1.6-.2,2,0,1.3,1.7,1.6,3.7.9,6l2.1,3c1,5.2,1.7,11.7,1.9,17s-.4,6.8-1.2,8.1c3.8,1.2,5.2,4.6,4.1,10.2,5.8,2.1,8,8,4.3,12.7-1,3.7-6.9,1.8-8-1-.1-4.7.7-5.6.3-10.3s1.2-2.9.7-3.7-2,1.1-2.5,0,0-3.3-.5-4.3c-1.2-2.7-2.7-5.8-4-9s-1.9-.7-1.9-.8v-15c0-.1-1.8.2-2-.5-1-3.1,1.9-2.3,2-2.5,1.7-2.5-2.2-1.5-3.1-2.6-1.6-2.1,0-3.2,0-3.4,1-1.9,1.5-4.3,1.6-7.3l2.4.3c.4.9-.8,2.3,1,3Z"
      style={{ fill: "#0a2010" }}
    />
    <path
      d="M17,8c5.4.5,1.5,5.2,1.3,6.7-.7,3.8,4.2,6.3,4.5,7.9s-1.9,5-1.6,6.8,5.2,9,5.7,12.2c1.1,6.3-1.7,6.3-1.9,7.1-.5,2.1.3,5,0,7.3-1-1.3-4-7.5-4-9s.7-2,1-3c-7.3,3.1,3-6.2-6-3l.8-14.4c-5.1-4.5-1.3-5.8.2-10.6-1,0-2,0-3,0-1.9-4.9,3.8-4,4-5.5s-.8-1.4-1-2.5Z"
      style={{ fill: "#0b1b0e" }}
    />
    <path
      d="M27,57c2.7,3.1,1.7,5.6,3.2,8.3s1.7.6,2.8,3.7c-3,2.2.5,7.1-4,7,.3-5.6-2.7-7.2-3-10.6s2-1.2,2.1-2c.2-1.7-1.6-4.3-1.1-6.4Z"
      style={{ fill: "#0b1b0e" }}
    />
    <path
      d="M438,27c3.8-6.3,9.2,1.4,3.7,4.8-.4,4-.3,8.1.4,12.3l-2.1.9,1.4,2.3c-2,3.8-4.5,7.2-7.5,10.2.9,7.3-.3,9.4-7.9,8.6.1,3.8-.6,7.7-2.1,11.6,3.2,2.9,3,13-2.4,10.6-2.1,6.4-3,13.3-2.5,20.5-1.1,6-2.9,11.7-5.7,17.1l1.2,2.1c-2.3,3.3-4.4,6.6-6.4,10.1,4.1.9.7,11.3-2,6-.3,2.7-1.2,2.5-2,0l-1.3-.5,3.7-20.6c5.6-10.6,5.5-20.8,3.8-32.6l1.8-2.3c-.1-2.1.3-3.9,1.3-5.2l.5-17.9,2.3-.9v-.8c-7.6,0-5.2-2.3-.6-5.7l.6-3.5c-4.4-.4-2.3-6.4-.1-9.1,3.9-2.9,6.4-6.5,7.6-10.8,3.2-1.5,6.5-2.9,9.9-4-.3-3.1.6-4.4,2.7-4.1.4.3,1.6,1,2,1Z"
      style={{ fill: "#023f17" }}
    />
    <path
      d="M436,26c-1,1.4-.3,4.8-1.3,5.8s-1.6.2-2.3.7-.5,1.2-.9,1.3c-1.2.4-4.1-.6-6.1,1.2-1.7,1.4-.9,3.6-1.7,4.8-3.1,4.6-10,6.7-7.7,14.3-2,3.3-7.8,2.9-10.1-.1-4.4,1.3-8.5,3.3-12.4,6l-2.6-.9c-2.7-1.1-1.8-4.4,0-6-1.8-3.8.6-6.1,7.2-6.8,1.6-2.1,4-3.4,7.3-4,.8-3.5,3.1-4.6,6.8-3.5l4.2-3.3c.7-4.2,1.4-6.3,6.2-5.3,1.3-3.4,3.5-9,7.4-6.1,5.4-1.5,3.8.4,6,2Z"
      style={{ fill: "#033d1a" }}
    />
    <path
      d="M425,20c1,.2,6.2,2.6,5,4-4.6,1.3-5,6.8-6.1,7.9s-4.3-.4-4.8.2.1,2.9-1,4.5-4.7,2.7-5.6,5.4c-6.5-4.1-3.2-.9-5.5,1.5-1.1,1.2-4.5,1.6-6.3,2.5s-.7,1.5-1.5,1.9c-3.3,1.6-6.7-.2-8.2,5.1-2.4,1.5-4.1-.5-3-3-2.7.3-2.9-.1-1-2,2.1-6.6,5.7-9.9,10.7-10,3.4-3.5,7.9-6,13.5-7.6.8-3.1,2.1-5.3,3.8-6.5h1c1.3-2.6,3-3.9,5-4,.2-.1,3.4-.1,4,0Z"
      style={{ fill: "#03381a" }}
    />
    <path
      d="M448,16c-.2,10.5-.3,12.1-2.8,21.7-.8,3,.9,8.4.8,10.3-1.8,2.9-5.9-.2-6-3,.8-4.8-2-8.9,0-14.2s2.8-.5,1.9-3.3-3.1-.4-4-.5c2.5-5.3,1.8-12.3,10-11Z"
      style={{ fill: "#013a12" }}
    />
    <path
      d="M408,8c.5,4.2,1,7.7,2,12l-.4,2.1c-2.6.6-2.7,1-2.7,3.7-6.1,1.4-7.6,1.9-9.3,7.9-3.1.9-6.4,1.3-9.6,1.3,0,.9-3.2,8-3.6,8-.9,0-1.7-.2-2.3-.9-.8,1.1-2.1,1.2-3.7,0-4.6,1.2-8.1.8-10.3-1.1-4.8-.6-.6-6.4,2-7,1.8-.7,7.2-1.5,8.8-4.2s-.4-2.9.8-3.9c1.7-1.4,5.5.5,7.8.1s6.2-1.9,6.5-2.1c.5-.5-.3-3.5,1-4.5s4.3,0,5-.6-.2-2,.3-2.6,2,0,2.9-.9,2.9-6.5,2.9-8.4c.2.1,1.9,1,2,1Z"
      style={{ fill: "#062c18" }}
    />
    <path
      d="M370,34c-.5.2-3.1,5.7-2,7,1.7,2.9-.4,4.8-6.3,5.9-.1,5.5-3.5,8.1-8.7,7.1.4,3.3-6.7,5.3-8,2-3.9.1-6.6-1.7-3-5,.5-.4,1.4-.6,2-1,2-.4,3.3-1,5-2,.3,0,.8,0,1,0l2-2c.1-.1-.3-1.3.2-1.7.9-.8,4.2-.2,6.3-2.2s1-3.1,2.1-4.1c2.4-2.1,6.9-1.1,7.3-4.9l2,1Z"
      style={{ fill: "#0b2919" }}
    />
    <path
      d="M236,18c.8.3.7,2.9,3,2l2-4c-1.7,6.8.4,4.2,1,5,.5,2.7.8,2.7,1,0-.1-1-.3-5,.5-5l.5,6c3.5-1.9,5,.2,1,1.5,2.5,2.8,3.6,5.7,3,9.5,1.7-1,3.1-.9,5-1,.7-3.2,5.8-3,7,0,3.1,4.5,4.2,8.8,3.4,12.8l.6,1.2c4.7,3.4-.4,11.2-4.5,3.9-2.2.4-3.8,0-5.4-1.7-5.1,2.6-10.8-2.5-4.9-5.6.2-.3-2.8-2.4-3-2.5-1.8,1.9-3.3,2.8-4.1-.2-1.1,2.7-4.5-1-2-2,1.4-5.2-1.3-5.3-2.6-7.9s-1.6-6.6-1.7-6.9-3,0-1.7-2.2,3.1.3,2-3Z"
      style={{ fill: "#032b14" }}
    />
    <path
      d="M253,32l1-9h-6c-.8-3.4,3-1.2,3.8-3.6s-7.5-17.8,6.2-3.4c1.5,3.6,2.1,3.6,4,6,1.4,2.3,1.4,3.6,0,4l-1.5,1.7c1.4,2,1.2,3.5-.5,4.3-1.9-.3-5-.1-7,0Z"
      style={{ fill: "#062a15" }}
    />
    <path
      d="M232,5c2.9-.7,1.4,1.9,2.7,3s5.2,2.9,5.4,3.5c1.5,4.6-4.7.5-6,4.5,0-5.4-1.5-6.8-2-11Z"
      style={{ fill: "#032b14" }}
    />
    <path
      d="M243,21c0,.6,1.4,1.8,1,3-5.3,1-1.7-2.6-2-3h1Z"
      style={{ fill: "#123923" }}
    />
    <path
      d="M448,48c2.9,1.9,1.6,9.9-1.5,11.2,0,3.5-.8,5.7-2.7,6.5,0,3.6-1.4,6.6-4.7,8.5l-1.1,5.8c2.7,4.4,3.4,9.2-2,9.8-.5,6.9-1.2,13.8-1.9,20.8l-2,1.4c-.4,1.9-1,1.6-2,0-.2,2.3-1.1,6.1-4,5-.5,1.7-1.4,3.8-2.5,6.4.6,5.6-2.8,9.6-6.5,4.6-4.5.2,2.9-14.5,3.9-15.8l2.1-.7c-3.3-1-3.1-13.4,0-15-3.3-1.3.3-6.3,2.1-8.3,1.3-3.1,1.5-6.2.7-9.3,6.1-7.4,11-16,14.7-25.6,1.3-3.5,3.1-5.3,5.4-5.2.6.1,1.4,0,2,0Z"
      style={{ fill: "#023514" }}
    />
    <path
      d="M464,8c-.3,3,1.6,9.4-2,9l3,2c2.8,1.1-.6,5.3-2.5,7.1-.7,3.5-1.4,7.2-4.4,9.6,6.3,2.4,4.2,6.4-2,6.7-.4.3-2.2,8.7-1.6,9.7-3,5.4-4.5,9.9-6.5,15.7s-1.2,1.1-2.1,1.3l-5.6,10.8-2.4.2c0-2.1-1.3-5.8,0-7.4s2.4-.3,2.9-.8c1.6-1.6-.8-9.5,4.2-7.8-1.1-1.4-.8-5.9.2-6.8s2.5,0,2.8-.2-.8-1.6-.8-2.5c0-2,.7-4.2.8-6.5s-.4-5.6.2-8.3,5-6.4,5.6-8.4c1-3.5.5-9.4,2.2-13.5l8-9.8Z"
      style={{ fill: "#023211" }}
    />
    <path
      d="M589,30c2.8,1.2-.3,6-3,5-2.2-.3-3.8,1.1-5.1,4-4.9.7-9.4,2.7-13.5,6-4.6.3-7.5,1.8-8.7,4.3-4.7,1.4-9.2,3.8-13.4,7.1-1.3-.2-2.6-.7-3.9-1.4l-9.8,10.2h-5.1c-1.7,3.1-4.3,4.9-7.7,6.2-1.4,6.2-3.7,8.4-6.9,6.6l-.9,2.2c-8,4.2-15.7,8.8-23.2,13.8-.8,2.2-2.1,3.2-3.8,3,2.2,2.3-1.1,5.5-3,4-5,1.1-4.1-4.6,1-5-3.3-.3-4.6-1.4-3.8-3.2,2.8-4.2,5.7-8.1,8.8-11.8l.4-2.1c9.4-2.7,14.2-7.3,19-15.6,1.8-1.1,3.9-1.5,6.3-1.3,2.7-6.2,6.9-9.3,12.7-9.3,5.2-3.4,10.6-5.6,16.2-6.6.2-3.9,5.2-5.7,8.4-5,.8-2,2.8-3.1,6.1-3.1,3.1-1.7,5.8-4.3,8-7.7h10.3c1.2-3.1,6.8-5.3,8.6-2.2.3,0,.9,0,1,0,.4.2.8,2,1.9,2.5s2.6-.6,3.1-.5Z"
      style={{ fill: "#032112" }}
    />
    <path
      d="M637,8c-3.2,2.8-4.2,9-8,10-.3,1.7-1.6,2.4-3.8,2-1.1,3.3-4.2,5.7-9.3,7.1-2.1,5.2-5.5,7.5-10.3,6.9-3.6,4.4-7.6,7.1-11.9,8.2-2.2-1.3-4.4-1.7-6.7-1.2-2.4,2.2-3.4,2.5-3-1-.9-1.7-.2-3.3,2-5l3-5c6.2,1.5,4.2,3.3,10.5-2s3.6-3.1,4.5-4c4.4-2.1,5.9-4,7-8,.3-.2,3.5-3.8,5-5,0,1.3-2,5.5.6,5.9s6.2-1.2,6.5-1.4c1.4-1,.7-4,1.5-5.5,1.7-3.4,9.2-2.1,12.5-2Z"
      style={{ fill: "#02290f" }}
    />
    <path
      d="M611,16l2,5-9,3c1-.9,3.6-3,3.8-3.7.4-1.3-.2-3.7.3-4.3s2.4.2,2.9,0Z"
      style={{ fill: "#01260c" }}
    />
    <path
      d="M576,14c1.5,1.1-1.8,1.7,5,2,.4.4.8.8,1,1.3,2.4,5.2-16.7,8.2-20.1,8.4,1.2,5.7-8,12.5-12.9,14.3-.9,1.7-2.6,1.8-5.1.4-4.4,2.4-9.8,3.5-13.5-.3-1.4,3.4-3.3,5.4-5.5,5.9,1.4,5.8-5.6,7.2-6.9,1.6-.7,1-1.8.8-2.9.4-2.6,2.3-3,4.4-6,.5,4.5-.2,2.7-2.1,3-5.5,1.1-10.9,8.9-3.8,15.4-6.1s1.2-3.1,1.8-3.7,4.4-.4,6-1.2,2.3-3.8,4.2-5,10-5.5,12.1-5.9,8.1.2,8.4,0c.4-.4-.8-3,.6-4.1,3.6-2.9,7.2-.2,10.8-.9s3.7-2.7,4.5-2Z"
      style={{ fill: "#041a0f" }}
    />
    <path
      d="M584,16l-1,12c-.4,0-5.1,1.1-5.4,1.2-1.5.5-1.3,2.6-2.3,2.9-2.1.5-10.7-.2-11.1,0s-1.7,3.9-3.3,5.3c-4.3,3.9-7.9,2.3-10.9,3.6-1.4,1.1-1.9.5-1-1,2.7-2.9,5.4-5.5,8-8.5s2.6-1.3,2.8-1.7c.9-1.5-.4-4.4.3-5.7s19.6-.8,20.9-8.1c1,0,2,0,3,0Z"
      style={{ fill: "#031d0f" }}
    />
    <path
      d="M218,44c-.1,5.1-1.8,3.8-2,4.7-.4,1.5.4,4.5-.1,5.3s-1.6-.1-1.9,0c-2.6.3-4.2-1.9-2-4-3.9-3-4.6-5.4-2-7-.5-.3-.5-.6,0-1,1.8-2.4,2.2-5.3,1-8.5l2-1.5c1.5,4,2.9,8.5,5,12Z"
      style={{ fill: "#026010" }}
    />
    <path
      d="M192,42l.7-1.3c2.6,2.2,5.1,4.7,7.3,7.3,3.1,3.1,6.7,3.7,11.5,4.5,1.2,1,1.3,2,1.5,3.5,1,1.6.7,2.4,1,3-3.9-1.9-7.4-.2-11.6-1.9-2.7-1.1-6.7-7.5-8.4-10.1-1.4-.3-3.1-3.8-2-5Z"
      style={{ fill: "#034e02" }}
    />
    <path
      d="M192,38c3.6-.3,12.1,6.5,8,10,2.3-3.9,10.4-2.9,12,2,.4,1.3,1.5,2.7,2,4-1,.5-1.2.9-1,2-2.3-3.6-8.5-1-12.3-4.9s-.4-2.3-.7-3.1c-5.8-.3-3.1-.5-6-5.3s-2-.6-2-.7c-1.4-2.5-1.4-3.8,0-4Z"
      style={{ fill: "#025403" }}
    />
    <path
      d="M467,19c-.3.9.3,2.8-.2,4.3s-2.7,2.4-2.9,4.3c-.2,3.3,3.2,2.2,0,6.7s-1.9.7-2,.8c-1.5,2.4,1.8,1.8,2,2s0,3.8,0,4c-.1.3-2,.8-2.5,2-.7,1.6-.2,4.5-.6,4.9s-2.3-.2-2.7.2.1,2.2-.2,3.2-4.4.5,0,4.3c-2.2.4-1.6,2-2,2.5-1.9,3.8-3.8,8.6-5.8,14.3.5,4.2-.6,13.4-5.2,7.7-3.7-2.8-1.4-7.9,1-11,.7-3.2,1.3-6.9,2.6-9.9s3.8-4.4,4-7-1.5-2.2-1.6-3.5,1.6-.4,2-1.3c2-4.4-1-7.7,7.1-7.3.9-3.3-3.2-1.4-4-3.5s1.5-2.4,1.7-2.7c.8-1.8,1-1.6,1.6-3,1.2-2.4.7-7,3.7-6.8,1.1-.9-4.3-1.3-2-3s3,.5,3.9-.2,0-1.6.1-1.8,1.2-.4,1.5-1l.5,1Z"
      style={{ fill: "#022c11" }}
    />
    <path
      d="M144,7c2.6-.1,2.6,1.5,3.7,2.9,4.5,5.8,3.2,10.1,6.7,15.4s2.7,6.3,5.7,6.8,2.7,0,4,0c1.8,3.8,3.2,7.7,4.4,12,3.1,1.6,5.6,8,1.6,9,.2,5.9-1.1,8.2-4,7-1.9,4.5-4.7-.4-4.5-4-3.6.1-4-2.9-3.5-5.8-4.3-.9-7.3-3.5-7.9-8,.9-8.2-1.4-13.1-6.1-19.2,0-4.3,0-8.7,0-13v-3Z"
      style={{ fill: "#042108" }}
    />
    <path
      d="M179,36c4.9,0,7.6,5.3,7.1,10.3,1.7,1.7,2.5,4.4,2.2,7.8l2.5,2.5c3.8-1.8,7.2,1.8,3.2,3.4,1.5,3.1,2.2,6.9-2,5-.8,3-7.7,2-8-1-3.9-3.2-6.6-8.8-8-13.8-3.7-3.3-4.4-6-2-8.2-.7-3.9-.3-.8-2-4,4.1,1.3,3.6-2.7,7-2Z"
      style={{ fill: "#023207" }}
    />
    <path
      d="M164,32c3.6.2,1.2,1.1,2.3,4.2,1.6,4.4,4.1,3.5,7.7,5.8.4,2.3,1.7,7.4,4,5-.6,3.3.8,5.5,2,8.3s3.7,4,4,8.7c2.7.9,2.7,2.1,0,3-2.7-.1-5.4-1.1-8-3-2,0-2.7-2-1.9-5.6-3.8-1.2-5.1-3-4.1-5.4-.2-.3.4-1.6,0-2.3-.6-1.1-1.8-.3-2-1.2-.6-2.7,2.1-.4,2-2.5-5.2-1.2-3.2-4.4-4-7.5s-2.9-4.4-2-7.6Z"
      style={{ fill: "#022809" }}
    />
    <path
      d="M606,74c.9,0,1.8,1,2,1-.2,6.7-4.5,3.9-7.3,4.3s-4.7,2.1-6.7,2.7c-3.5,1.1-1.1,1.7-3.7,3.8-3.9,3.1-8,1.4-11.6,2.4s-3.9,7-11.7,7.8c-1.7,2.4-5.9,3.2-7,0-1.4-1-.7-2.7,2-5,1.4-1.4,2.5-1.7,3.4-.7,6.7-4,13.8-7.5,21.1-10.3l2.5,1c-.7-4.1,14.6-9.5,15.7-8.9s1.1,1.1,1.3,1.9Z"
      style={{ fill: "#025521" }}
    />
    <path
      d="M629,18l1,6c0,0,.2,1.4-.3,1.7-1.6.8-4.4.9-5.2,2.3s-.2,3.6-.5,3.9-2.5-.4-3.7.3-.6,1.6-1.4,2.1c-2.2,1.7-1.3-.3-2.9,2.6-1.3,2.2-3.3,3.1-6.1,2.6-3.5,4-14.3,9.9-18.9,6.4-5.2-.9-4.2-4-1.6-1.4,4.7,4.7-5.5,6.6-5.4.4-2.7-1.8,0-4.5,3-4,1.7-1.6,2.2-3.7,4.5-3s.2,3.4,4.8,1.8,5.5-6.2,8.5-7.6,5.9,0,6.2-.2c.5-.4,1.3-5.2,5.1-7.4s4.9-1.3,6-2,.9-3.3,2-4,3.7-.1,5-.5Z"
      style={{ fill: "#022e12" }}
    />
    <path
      d="M616,37c-.9,1.6-1.7,3.2-2,5-1.1.2-6.8,2.2-7.8,2.8-1.6.9-2,2.7-2.5,2.9-1.5.8-11.1,4.1-11.5,4.5s0,2.5-.2,2.8c-.5.6-3.8-1.2-5.3-.9s-3.8,3.7-6.3,4.8-5.6.9-6.5,1.6-.6,2.8-1.1,3.3-12.7,3.6-15.5,5-7.8,5.1-8.1,5.4c-1.5,1.8,2,4.5-5.2.8-.5-.9-.7-1.8-.3-2.8,13.6-8.9,27.9-16.6,42.9-23.2,3,.7,4.5-.3,4.4-3,4.1-.4,13.2-3.6,16.3-6s.5-2.8.7-3,6.1,1.6,8,0Z"
      style={{ fill: "#013316" }}
    />
    <path
      d="M136,27c1.5,4.9,2.9,5.7,8,5,1.8,1.1,2.6,3.6,2.3,7.4,4.1,4.6,4,9.9-.4,15.8l2.5,8-1.4,1.8c1,3.5.6,7.3-1.3,11.4,3.3,1.3,3.6,4.7-.2,5.7.3,1.7.3,3.4-.3,5,2.5,2.2,2.3,8.3-1.2,8.9-.7,4.6-5.6,4.9-5.4-.2-1.3-.5-2.5-1.4-2.6-2.8.4-.7,2.8-14.6,2.8-15.7.1-1.9-2.7-2.6-2.8-4.8-.1-3.1,3.7-10,4-13.5l-5,4c-.8-2.1,1.1-5.1,1-6.4s-4,.1,0-5.1v-19.4c-1.7,0-3.4.1-5,0,.6-2.1,5.8.4,5-2.5s-5.9,1-5-2l5-.5Z"
      style={{ fill: "#0a170b" }}
    />
    <path
      d="M144,23c3.7-.6,7.1,7,7.7,9.8,1.2,5.2-1.9,11.6,4.3,14.7s3.7.4,3.9.6c.6.6-.6,5.1.2,5.9s2.2-.3,3,.7c1.3,1.8-.5,6.1,2.9,5.4l1.8,1.4c-1,3.6-1,6.8.2,9.6,1.9.6,1.9,1.4,0,2-.4.9-3,2.8-3.6,2.8-1.1,0-5.8-4.6-6.5-5.9,1.8-7.3-5.6-5.7-4.5,1.3l-2.5.8c-2.6,1.6-3.9-.7-4-7-.6-1.4-3.1-8.5-3.1-9.5,0-2.9,4.2-8.4,4.2-8.9-.3-2.2-3.2-4.5-3.8-7.3s-.1-4.9-.2-7.3,0-6,0-9Z"
      style={{ fill: "#081c0b" }}
    />
    <path
      d="M597,72c0,3.1-6.6,7.7-9,5l-1.4,2.8c-7,2.7-13.6,6-20,9.8-.8,1.1-2.3,1.6-4.6,1.4-2.1,3.5-5.6,5.7-10.5,6.6-.2,4.4-2.9,6.2-6.7,7.7-1.1,2.8-3.4,5-6.8,6.7-.1.9-.4.9-1,0-3.8-1.6-.8-6.4,1-9,4.5-4,8.4-6.8,13.1-10.4s2.9-4.2,3.1-4.3c1.5-.8,4.1.4,5.6-.4s6-7.3,6.4-7.6c2.3-1.3,10.5,1.6,9.8-5.2,2.2,1.2,4.9,1.4,7.3.8s11.3-6.2,14.7-7.8c-.5,1-.5,3.4-1,4Z"
      style={{ fill: "#02491d" }}
    />
    <path
      d="M620,66c-1.9,4.5-1.3,2.1-6,2.4l-8,5.6c-2,0-8.4,1.6-10,2.5-2.7,1.6-2.6,2.3-7,4.5-4.7,1-4.6-1.6-1-4,4.8-2.1,3.8-.7,7.4-4.7s1.6-.2,1.6-.3c4.4,1.4,4.6-1.5,6.2-2.3,4.3-2.1,11.7-2.8,16.8-3.7Z"
      style={{ fill: "#034f20" }}
    />
    <path
      d="M52,38c0,0-1.2,1.9-1,2,.8.2,2.6-.3,3,0s.9,3.3,1.9,4.3,3,.9,3.6,1.7-.5,2.7-.1,4.1c.8,3.2,4.4,3.8,5.1,4.9s-.9,2.6,0,4,3.1,1.3,3.5,2.6c.7,2.2-2.6,4.1-2.8,6s1.8,9.3,2.8,9.9,3.7.3,3.9.5.5,5.7,1,8c0,2.6-.7,3.3-2,2-1.1-.6-1.4-2-1-4.2l-1,.2c-.6,1.9-1.4,1.9-2,0-.8-1.5-1.4-2.9-2-4-2.2-.7-3.7-2.6-4.3-5.8-2.2-.6-4.2-1.7-3.7-4.2l-1.9-1.6c0-4.3-1.9-9.9-3.4-14.3-2-1.7-2.6-3.4-1.7-5.1.5-3.1-4.7-9.3,0-9v-1c-3.7.1-1.5-.8-1-2,.8-1.7,3.1-.9,3,1Z"
      style={{ fill: "#152411" }}
    />
    <path
      d="M52,35c.2.9-.1,2.1,0,3,0-.1-2.4-.3-3-1,.6-1.3-.9-2.6,3-2Z"
      style={{ fill: "#1d2f1b" }}
    />
    <path
      d="M230,28c.2.2,1.6-.4,1.9,0,.5.7-.4,2.8.3,3.7s1.6,0,1.8.2c4.8,5.4,7.5,11.1,8,17.2,4.8,7.9,6.9,15.6,6,24.8,3.2,0,2.9,5,0,6-2.6-.2-5.5.3-8,0,0-8.3-3-10.1-4-16.5s1.9-12.9-2-16.5c-.1-2.5-1.4-9.4-2-12.3s-4.4-2.6-2-6.7Z"
      style={{ fill: "#033b15" }}
    />
    <path
      d="M240,38c.2.2-.4,2.5,2,2,4.7,4.7,8,10.1,10,16-1.4,2.2-1.3,2.4,0,4,2.8.9,2.4,7.6,1,10,3.3,2.6-2.7,7.7-5,4,0-.2-1.6-.4-2-2.5-1.1-5.7,2.8-13.5-6-14.5-.3-2,2.4-.5,3-1s-2.7-5.3-3-6.5c-.4-2.5.5-5.7,0-7.7s-7.3-5.8-6-9.7c1.8,1.8,4.3,4.2,6,6Z"
      style={{ fill: "#033517" }}
    />
    <path
      d="M227,23c-.9.6-1.9.8-3,1,.2-.9-.3-2.4,0-3,1.1.4,2.3.9,3,2Z"
      style={{ fill: "#124d21" }}
    />
    <path
      d="M184,18c0,0,0,1.3.7,1.9,1.6,1.3,6.7,6.8,7.8,7,2.1.5,1.9-2.7,3-3,1.7-.5,4.5,3.8,8.5,2,3,1.5,9,7.9,4,10-4.1,5-12.5.6-16-4l-2.4-.3c-1.2-1-12.3-13.4-12.4-14.4s.3-.9.8-1.4c1.3.4,5.2,1.5,6,2Z"
      style={{ fill: "#027503" }}
    />
    <path
      d="M208,19c2.5,3.9,3.4,8.7,5,13-.3,4.4,3.2,9-3,10-2.5-.6-4.1-4.2-2-6-.3-.7.3-2.2-.3-3.7s-2,0-3.7-6.3-3.2-3.5-2-8l2,1v-3c1.6-1.3,3-.3,4,3Z"
      style={{ fill: "#02750e" }}
    />
    <path
      d="M184,36c2-.6,6.1,4.8,7.5,7.1.9,4.8,2.7,9.1,5.5,12.9,1.3,3,3.5,4.9,3,8-2.7,5.2-6.3,1.1-6-4-.3-.5.8-2.8-1.5-2s0,3.1-2.5,2c.1-2.4-2.9-3.6-3.5-5-1-2.3,0-4.3-.5-6.3s-1.8-.6-2-1.4c-.4-1.6.2-3.6-.3-5.6s-4.3-4.5-4.7-5.7.2-2.8,0-4c1.5.9,3.2,2.2,5,4Z"
      style={{ fill: "#013903" }}
    />
    <path
      d="M168,10h1c2.9,3.3,7.5,9.7,7,14,2.8,3.4,5.6,6.9,8.4,10.3l-.4,1.7c-2.8.3-3.5-3.3-5-4s-2.5.4-2.9,0,0-2.7-.6-3.9-2.6-1.5-3.2-2.3c-2.6-3.6-4.8-11.3-7.3-15.7h3Z"
      style={{ fill: "#013c03" }}
    />
    <path
      d="M410,20c1.3-.2,2.8-.1,4.1.5.5.4.9,2.9.9,3.5,0,2.3-.7,6.4-2.5,8s-11.8,4.6-12.3,5.3,0,2.3-.3,2.6c-.5.5-3.9-.2-5.9.6-4.2,1.8-2.2,8.5-7,7.5-2.1,1-3.1,0-3-3-2.4,1-3-.9-2-3,2.7-1,1.5-3.1,2.3-4.7s1.5,0,1.7-.8c.5-2.1-1.4-1.4-1.7-1.7-2.9-4.3,2.3-1.8,4.1-1.8s8.3-.6,8.6-1-.2-2.3.2-3.2c1.4-3.1,5.6-4.6,8.8-3.7-2.5-2.8,1.2-4.6,4-5Z"
      style={{ fill: "#043219" }}
    />
    <path
      d="M418,14l3,6c-.5.3-1.5,5.3-5,4,.4-2.8-.6-6.9,0-9.4s1.9-.5,2-.6Z"
      style={{ fill: "#043219" }}
    />
    <path
      d="M202,6c0,0,1.4-.3,2,.7,2.2,3.3,3.5,8.4,4,12.3-.5-.7-3.5-2.3-4-3s-1.8-5.3-2-6c-.2-1.1.2-2.8,0-4Z"
      style={{ fill: "#03650a" }}
    />
    <path
      d="M262,26c1.2.5,2.6.9,4,1h1c.5,0,.9.1,1.1.4.5.5,2.3,11.2.9,11.6,4.4,4.3,7.9,9.6,10.5,16,2.9,3.4,4.1,6.4,3.5,9,1.6,2.2,1.6,3.2,0,3,1.9,3.6,1.9,5.6,0,6-1.4,3.5-7.3-.2-8-3-1-1-1.3-2.5-.9-4.3-2.2-1.9-3.8-4.2-4.9-6.9l1.2-1.2c-1.3-3.2-1.6-5.2-1.4-8.5-.9-.3-.8-.6,0-1-1.4-.6-1.7-1.6-1-3-1.7.7-1.9.6-1-1-1.7,0-2.3-1-2-3h-1.5c-.3-3.6-1.5-6.6-3.5-9-.9-3.7-4-8.6,2-6Z"
      style={{ fill: "#08331c" }}
    />
    <path
      d="M257,6c3.3,2,4.4,5.7,7,8.5s2.3,2.6,2-.5c.3.6,1.6,1.3,2,2,5.3-.4,7.3,12.2,1,11-1.3,1.1-1.6.6-1-1-3.8-6.1-7.5-13.1-11-20Z"
      style={{ fill: "#09401e" }}
    />
    <path
      d="M269,27c2.9-.4,5.2,2.8,3,5,2.8.6,5,3.1,4,6,2.3,2.2,2.5,3.2.5,2.9-1.1,5-5.4.9-7.5-1.9-3.6-7.1-1.5-6.1-2-12s.8-.6,1-1c.2.3.8.6,1,1Z"
      style={{ fill: "#032710" }}
    />
    <path
      d="M266,27l-1.5,3c-1.5-1.2-2.2-2-2.5-4s.1-2.7,0-4c1.3,1.7,2.6,3.4,4,5Z"
      style={{ fill: "#032710" }}
    />
    <path
      d="M178,16c3.3,6.3,10.6,9.7,14,16,2.3,1.3,3,2.6,2,4-.2,2.4-1.2,2.3-2,0-2.8.5-5.4-1.6-7.8-6.5l-7.5-7.2c-2.8-3.1-3.1-5.2-.8-6.3.6.1,1.4-.2,2,0Z"
      style={{ fill: "#026304" }}
    />
    <path
      d="M168,2l7.5,9c1,1.5.3,3.5.5,5,.2,1.5-.4,2.4-1.8,1.9l-6.2-11.9c0-.3,0-.7,0-1,0-1,0-2,0-3Z"
      style={{ fill: "#026803" }}
    />
    <path
      d="M272,16c.4.2,1.4,0,2,0,2.5,2.6,4,6.5,5,10,8.2,6.2-.8,12.1-7,6-1.2-2-1.8-3.1-3-5,4-4.6,1.1-7-1-11v-6c.3.4,1.5.6,2,1.4.9,1.4.7,4,2,4.6Z"
      style={{ fill: "#053618" }}
    />
    <path
      d="M168,6c2.4,1,5.4,9.2,6.2,9.8s1.3,0,1.8.2c.2,1.1-.3,2.2.3,3.7s8,7.1,9.3,8.7c2.8,3.3,1.2,5.1,6.4,7.6,2.2.2,2.2,1.5,0,2,0,1-.2,3.6,0,4h-1.4s-14.6-18-14.6-18c-1.9-3.6-5.8-10.5-7-14s-.8-2.8-1-4Z"
      style={{ fill: "#024f04" }}
    />
    <path
      d="M271,432c0-3.3,15.7-2.7,17-1.8s2.3,3.8,0,3.8l1.4,4.6c5.4,5.2,5.9,9,1.7,11.4,3.6,2.1,7.6,3.8,11.7,5l.8,2.3c2.1.2,2.9,1.8,2.4,4.8,5.6-1.5,8.4,1,8.6,7.5,4.1,0,6.5,1.1,7.3,3.5,11.3-1,17.4,2.8,18.1,11.5,2.4,3.1,2.9,5.8,3.2,9.4,6.9,1.2,10.5,3.9,10.7,7.9,7,.5,13.7,2.9,20.1,7,3.8-.8,15-2.4,9.9,3.1-7.2,3-8.8,4.1-17.4,1.9s-5.5-5.9-8.3-6.7-7,.4-8.2-.3-2.4-10.9-5.9-5.1.8,2.9-3.3,2.2c-.3-2.3.5-5.2,0-7.3s-1.7-.6-1.9-1.4c-.6-2.3.8-5.1-2-6.9-8,5.5-7.3-4.7-11.5-5.5s-5.2,2.5-8,3c-4.6.8-7.5-1.6-5.5-6-10.5-.8-5.5.5-7.2,6.3s-7.5,1.4-9.2,1.8c-3.6.8-.9,15.6.4,18.9-1.5-.3-7.5.4-8,0-.8-.8,1.8-6.3-5.5-4.9s-2.6,1.6-4.1,1.9c-3,.6-8-1.4-9.2,2.8s.5,5.5-2.2,6.8-3.6-1.4-4-1.5c-.3-2.7-.6-5.5-.9-8.3l1.9-1.7-1.3-.4c.6-6.7,0-13.2-1.8-19.5-4.2.4-6.3-1.1-6.2-4.4l-.4-11.4c-4.4-1.2-4.4-10.8-4-14.9l1.8-1.5c.7-1.2,1.4-2.1,2.1-2.8v-12.7c.7-7,11-5.1,16.9-2.5Z"
      style={{ fill: "#1b2019" }}
    />
    <path
      d="M288,434l1.4-.5c5.1,4.6,10,9.4,14.6,14.5,2.9.4,2.7,2.1,0,3,1.5.4,2.3,1.8,2.5,4,11.8-.5,17,3.4,15.8,11.8,7.5-.8,13.5.9,18.1,5.1,2.9-.4,5.8-.4,8.6,0,3.5-5.8,6.1,1.5,4.9,6,3,4.9,4.9,10.7,5.5,17.2l5.2,1.7c5.8,5.7,13.9,10.4,22.4,7.2,8.7-5.1,17.6-9.8,26.6-14.3.7-3.7,6.1-5.6,7.4-1.7,2.5.2,2.7,1.1,1,3-.2,7.2-4.3,4.2-5.6,5.9s.2,6-.6,6.9-6.1,2.8-6.6,2.9c-6.1,1.5-14.9-.2-20.1,2.9s-2.5,1.2-5.2,2.3c.6-2.9-1.6-2.1-3.5-2.1s-5.6,1.2-6.8.9c-1.9-.5-9.7-5.3-13.7-6.4s-6.6,0-7.6-1.4,0-3.4-.6-3.9-8.2-2.2-9.9-3.6-.6-4-1.2-5.8-1.9-2.6-2.6-4.5c-1-2.8.2-5.7-3.6-8.3s-10.7-1.3-14-1.9-.2-2.6-.6-3c-.8-.7-6.5,1.4-7.7-2.8s0-5.1-.2-5.3c-.4-.4-7.6.4-8,0s.9-4.7-1-6.5-14.8-3.3-15-9.5c9.6.7.3-6.3-.6-9s-1.2-4.1.6-5.1Z"
      style={{ fill: "#1a2319" }}
    />
    <path
      d="M128,453c1.7-3.8,5,0,5.6,3.1,1.1,0,2.2.4,3.4,1.4,1.4,4.5,5.9,14.2,9.2,17.3s9.9,6.6,14.9,5.2c4.3-2.4,12.1.6,15.7,4.4,3.5,1,7.3.6,11.6-1.3,3.2,2.6,5.9,6.3,8.1,10.9,4.8,1.8,12.7,5.7,14.7,10,1.8,1.8,3.8,4.9,2,7-1.1-.2-2.9.4-4-.4s-.6-3.7-1.9-5.1-4.9-1-6.8-1.8c-2.9-1.3-7-7.3-11.3-7.7s-9.5.8-12.3,0-1.3-2.4-3.7-3.5c-4.2-1.9-8.6-.8-12.3-1.7s-15.9-7.3-16.5-8,0-2.5-.2-2.7c.5-.9-.3-1.6-2.4-2.1l-6.4-9.1c-5.9-3.6-14.6-9.5-7.2-15.8Z"
      style={{ fill: "#162017" }}
    />
    <path
      d="M232,496c.3-.9.6-.9,1,0,5.2.1,12,3.8,16.5,7.4,2.8-3.7,12.5-5.7,14.5-1.4-.2,3.9,2.2,7-1,10-8.4-3-37.2,1.9-50-1-1.9-2-2.4-4.3-2-7,1.2-1.2,1.6-.6,2,1l2.2-1c4.4,2.9,12.8-.7,12-6.6l1.8-1.4c.4-2.8,2.2-2.8,3,0Z"
      style={{ fill: "#182318" }}
    />
    <path
      d="M448,464c3,.2,2.7,2.9,0,4-.1,0,.1,1.4-.3,1.6-9,6-8,14.3-17.7,18.4s-2.2-.3-3.3,0-2.7,2.9-4.7,3c0-.2-1.5-1.9-1-3l2.7-5.1c6.2-1,10.6-2.6,10.3-9.8l2-1.1c-1-5.3,8.5-14.4,12-8Z"
      style={{ fill: "#162217" }}
    />
    <path
      d="M88,395c.8-4.8,8.2-3.6,9.5,1,2.5-2.2,4.7-2.9,6.5-2,2.3-.4,2.7.3,1,2,1.2.3,2.1,1,1.4,2,8.6-1.2,8.8,6.6,6.8,12.6,2.9.8,5.7,2.9,2.8,5.4-1,4.2-5,2.3-6.1-1.3-2.2,1.7.5,3.1,2.7,3.8,3.3,3.3,6.9,18.4-1.2,15.1-.6,2-.9,4.1-.5,6.1,4.7,1.1,4.4,5.8-.5,6.3l-.5,2h-14c-2-5.3-2-10.7,0-16-1.3,3.2-12.9,3.1-14.5,0-.4.8-1.4,1.5-2,1-1.9-1.7-3.1-15.9.5-17,1.7,0,3.3,0,5,0,.5-7.1.9-14.2,3-21Z"
      style={{ fill: "#101c15" }}
    />
    <path
      d="M80,416c.4,2.6-1.1,16,1.5,16h14.5c0,5.3,0,10.7,0,16s-1,2-1.1,3.5c0,3.9,1.6,7.8,1.1,12.5-1.7-1-4.6,1-5,1-.2.9-.5.9-1,0-.1,0-.8-.8-1.6-.9-2.6-.3-6.6.7-7.9-2.1s.1-13.4-.5-14c-5,0-10,0-15,0,.8-1.1.8-5.7,1-6,.5-.5,10.4.9,11-1.7s.3-8.1,0-8.3-4.6.4-5,0c-.6-.6.4-13.6,0-16,2.5-.2,5.4,0,8,0Z"
      style={{ fill: "#111a15" }}
    />
    <path
      d="M110,448c-1.3-.7-3.3-2.7-2-4s6.4.6,4-2-2.3,0-2.9-1.6c-1.3-3.8.9-8.7,1.9-12.4,2.6,1.6-1.2,5.1,4,4,.5-3.3-.6-9.1-3-11.5s-3-1-3.7-1.7c-1.5-1.5,0-4.2-4.2-4.3,1.3-1.8,5.6-2,7.5-1.6s-.5,4.8,4.5,3.1l2.2,1.2c-.8,6.9.6,12,4.3,15.4-.3,2.9.2,5.4,1.6,7.4,2.1.7,3.2,3,3.4,6.9,1.5,2.5,1.7,4.5.6,6.1-.9,2.9-1,7.9.9,10.4s2.8.4,4,1.2c6,3.7,5.6,6.1,9.1,11,1,1.4,7.4,4.1,2,4.5-.2-.2-3.1.2-4,0,0-5.4-4.4-5.3-7-7-1.5,1.3-2.1.7-1-1-1-1.1-3.6-7-4.3-7.7s-1.7-.1-2.3-.6-.9-2.1-2-2.5c-4.6-1.7-7.5,1.6-11.4,2.8-.7-.8.6-12.8,0-15.4s-1.7-.4-2-.6Z"
      style={{ fill: "#121e17" }}
    />
    <path
      d="M132,472c.2.3.8.9,1,1-.5,1.4-1.7,2.9-3,1.5-2.3-2.5-.7-2.8,2-2.5Z"
      style={{ fill: "#1a201e" }}
    />
    <path
      d="M476,430c1.5,3.2,2.7,3.8,2.1,8.6s-1.7,1.3-2,2.2c-.5,1.8-.2,5.2-1,6.6s-2.4.3-2.8.8-2.1,7.7-5.6,9.9-6.7-1.8-5.6,5.9c-1.1,0-4.8-.2-5,0s0,2.2,0,3c-1.9,0-6.7,0-8,1v-4c.2-2.4,3-2.9,5-3,1.1-4.6,8.7-8.8,13.4-10.6,3-1.6,3.8-4.6,4.2-7.8l1.3-.7c1-3,1.6-6.7,2-11.1l2-.9Z"
      style={{ fill: "#182119" }}
    />
    <path
      d="M529,352c-.2,5-5.5,4.8-6,10.5s2.8-1.9,2.1-2.5c3.2-.7.9,3.2,4,1v6s-2-1-2-1c2.6,4.7-.4,7.1-.8,9.7s1.8,2.7,1.9,4.8-2.1,1.3-2.2,2.1c-.2,1.2,1.1,2.1,1.1,2.4,0,.7-1.1,2.3-.6,3,1.1,1.4,3.9.6,4.4,1.1s2.1,7.5,2.1,8.9c-3,.7-1.9-3-3-5.3-2-4.1-13.5-4.7-18-6.7v14c-1.7,0-3.3,0-5,0-4.6.2-10.2.5-7.9-6.4s2.7-1.4,2.9-1.6c1.6-2-1.9-2.2-2.5-2.9-1.9-2.4,2-2.5,2.5-3.2s-1-1.7-1-1.9c0-.5,5.1-7.6,5.6-7.9,3-2,5.9.3,6.4,0s1-6.5,1.5-8,1.7-3,2.3-4.7-.1-3.5.3-4.2,1.6,0,1.9,0c-.2-3.4,7.3-9.7,10-7Z"
      style={{ fill: "#0e1612" }}
    />
    <path
      d="M492,408v4s-1.5,0-1.5,0c-1.6-1.1-1.7-2.1-1.5-4h3Z"
      style={{ fill: "#0f1a13" }}
    />
    <rect x="483" y="410" width="2" height="3" style={{ fill: "#0f1a13" }} />
    <path
      d="M581,425c-.8,0-3,2.4-4-1,1.9,0,3.2-.7,4,1Z"
      style={{ fill: "#191f1b" }}
    />
    <path
      d="M581,425c1.4,0,2.7.4,3,2-4.3.6-2.4-.7-3-2Z"
      style={{ fill: "#0f1612" }}
    />
    <path
      d="M557,311c-2.5,1.1-3.2,3.5,0,3-.2.2-2.6,4.4-2.7,4.7-.3,1.1,1,2.9-.3,4.8s-3.7,1.4-4,1.6c-2.2,1.9,2.6,1.7,2,3-4.2-1.7-5.2,1.9-5.5,2-3.3.9-1.6-1.7-3.5-2,.6,3.9-.7,3.2-4,3,1.5,4.3.7,6.3-3,3-.7,2.3,2,3.8,2,5.5s-1.4-.2-2,2.5-2.1,9.1-3.2,9.9-3.1-.2-3.8.1c-3.7,1.5-6.8,5.1-10,7l-1-4c-.3-.4-.5-.8-.6-1.3-.1-.7,6.3-7,7.6-7.7-.5-3.5-.1-6.6,1.2-9.4,4.5-3.8,8.9-8.1,10.3-14l5.5-.6c-.3-1.6,1.7-2.3,6-2-1.1-3,2.2-10.7,6-11,1.4-3,4.3-.8,3,2Z"
      style={{ fill: "#0b1810" }}
    />
    <path
      d="M512,360c2,0,1,1.6,0,3l-1.4,3c-4.3,1-5.4,1.9-6.6,6-.6.8-1.3,4.9-3,3s1-3.3-1-3c1,6.6-4.9,7.4-7.5,10s-3.3,6-4.8,6.7-4.2,0-7,2.3-1.9,3.8-3.5,5c-2.9,2.2-9.5,2.7-13.3,3-.1,1.6,3,1.6,3,2.5,0,1.9-8.4,5.6,1,3.5.5.4-2.1,1.8-1.9,3.3s3.8,7.3,4.5,8.6c1.8,3.5,2.3,1.4,3.4,7.1-.6,1.9-1.5,1.9-2,0-1-.9-1.7-2.2-2-4-.7.2-1.3.2-1.6,0-4.8-5.4-13.7-16.7-6.6-22.9,11.9-6.5,22.5-14.3,31.9-23.4,1,0,1.7.5,2.3,1.4.8-1.1,1.2-2.1,0-3-.9-.9-.2-1.5,2-2-3.2-2,1.3-5.1,4.4-5.9,2.2-3.8,4.4-5.1,6.6-4.1.8-2.9,2-2.7,3,0Z"
      style={{ fill: "#112115" }}
    />
    <path
      d="M525,346c-.3,5.1-4.1,5.9-7,9-2.8,3-3.3,6.7-6,8-.1,1.1.2,4.8,0,5-.4.4-5.1-.6-5.9.1s0,1.8-.4,2.5-1.4,1-1.7,1.4c-2.5-3.4,5.8-8,8-9,.1-1-.2-2.1,0-3-1.9-1-.3-5.3.4-7.4,1.2-3.2,3.8-8.1,7.6-6.6,2-2.1,3.9-3.1,5,0Z"
      style={{ fill: "#0f1c13" }}
    />
    <path
      d="M472,424c.5-.1,1.4,0,2,0,.2.9-.3,2.1,0,3.3s1.2,1.1,2,2.7c.4,2.2-1.6,3.6-2,5.5-.6,3,1.5,7.3-2,6.5-1.6-1.2-1.4-6.3,0-8-2-2.9-2.4-7.4,0-10-1,6.1-5.9,2.1-2-2-1.6-.9-1.9-1.6,0-2,.8,1.4,1.6.3,2,4Z"
      style={{ fill: "#142818" }}
    />
    <path
      d="M582,208c-.9,1.6-2.7,3-5.2,4.2-.9,5-3.1,8.6-6.6,10.9l-.5,6c-1.4,3.4-3.2,6.6-5.3,9.5-5.7.7.8,5,3.3,6.4,2.9,4.9,4.9,10.5,5.9,16.6,5.2,4,7.5,16.4,2.5,21.3-2.8,3.5-8.7,10.9-12,5l-.4-1.3c6-5.9,6.6-6.7,6.1-15.2.7-1.7.1-4.2-1.8-7.5-1.7-2.8-3.4-5.4-5-8-3-.9-5.3-3.7-6.7-8.3-2.2-.9-4-2.6-2.3-4.7.1-3.4-.9-9.9,1.9-12.4s3.7-.3,4.1-.6-.4-5.6,0-6,2.4.3,3.7-.3c4-1.7,6.9-12.2,8.7-15.4s7.6-8.6,9.6-11.3v11Z"
      style={{ fill: "#071e0e" }}
    />
    <path
      d="M576,192c-.4.4.4,7.6,0,8s-2.3-.3-3.2,0c-2.8,1.3-4.2,7.9-5.7,10.5s-5.1,6.6-7.1,9.4l-2.6,2c-1.7,4.2-10.3,14.4-13.4,9-.7-2.2,3.4-6.2,6-7l-.7-1.2c3.2-4,6.1-8,8.6-12.1l2.1-.7c6.4-3.1,9.6-12.1,14-17.3s1.9-.6,2-.7Z"
      style={{ fill: "#022c0e" }}
    />
    <path
      d="M560,220c-1.9,2.7-4.2,7.2-6.3,9.7s-2.6,1-3.2,2.2c-1,2.1-.4,5.6-.5,8,1.8,1.3,2.5,5.4-1,4-.9,2.6-5,1.7-5-1-6.7-2.5-3.7-8.6,0-12,6.6.4,5.5-3.5,6.9-4.6s2.3.8,2.9.5c1.6-.9,1.4-7.7,6.2-6.9Z"
      style={{ fill: "#04280c" }}
    />
    <path
      d="M550,240c3-.8,1.5,1.9,2.2,2.7s1.6.1,1.8.3c1.1,1.1,1.8,2.7,3.7,3.3l5.3,9.7c-.1,2.4-1.3,2.1-3,1-6.2.7-13-6-11-13,1.5-.7,1-2.7,1-4Z"
      style={{ fill: "#062310" }}
    />
    <path
      d="M557,204c1.2,1.8.2,4.5-3,8,2.5,1.5.7,4.7-2,4-7.1,7-14.5,14.8-19.9,23.1l-2.1.9c-1.4,3.1-1.3,5.9-.6,9.2l-1.4.8c-2.2.7-3.9-4.8-2-6-3.8-1.2-1.6-7-.2-9.8s6.6-7.8,10.2-10.2c1.6-4.9,4.9-8.9,10-12.1,1.9-2.9,3.9-4.9,6-5.9l5-2Z"
      style={{ fill: "#02470b" }}
    />
    <path
      d="M584,154c-.3,1.8.4,4.3,0,6-1.2,5.4-5.4,8.6-7.7,12.3s-.4,3.5-1.4,5.1-5.4,2.9-6.6,4.9c-2.5,4.2-6.2,10.9-10.3,15s-1.5-.8-3,2.7c-.3.7-.6.6-1,0-4.3-1.4,2.1-8.3,4.7-10.2,3.3-5,7.2-9.7,11.3-13.8,2.9-4.3,5.5-9.1,8.4-13.6s3.2-2.4,3.3-2.6c.7-1.9-.2-4.7.5-5.6s1.7,0,1.8-.2Z"
      style={{ fill: "#015b15" }}
    />
    <path
      d="M554,200h1c-1.1,2.5-1.9,3.8-3,6-4.8,9.3-4.9,6.9-11.4,13s-1.2,4.4-4.6,5c-.3,2.2-2.4,2.4-3,0-2.5-1.9-.8-4.6,5-8v-1.3c2.7-3.1,5.4-5.9,8.3-8.3,1.3-3.1,5.1-10.7,7.7-6.4Z"
      style={{ fill: "#02570d" }}
    />
    <path
      d="M568,192c-.1,1,.1,2,0,3-.6,6-7,5.2-8,13-.8,2.5-3.4,5.6-6,4-.4-2.4,1.7-5.8,3-8,2.4-4,6.8-9.9,11-12Z"
      style={{ fill: "#01410f" }}
    />
    <path
      d="M538,153c5.5.9,3.5-1.9,6-3.5s4.7,1.7,6.7,2.3,3.9-.3,5.1.4,3.5,5.8,4.2,7.1,2,.7,2,1.3c0,2.9-3.9,4.9-4.5,6.4-1.9,4.9-3.6,9.9-5.6,15s.1.7,0,1c-1.6,2.4-2.6,2.4-3,0l-1.3-1.8c4.6-6.5,9.8-20.8-2.8-19.8l-.8-1.4-1.6,1.9c-2.9.4-10.7,0-12.4-1.9-3.9-3.8,4.3-7.1,8-7Z"
      style={{ fill: "#028324" }}
    />
    <path
      d="M557,189c.3.4.6.8.8,1.2s-6.6,8.6-7.8,9.9c-2.5,2.3-3.9,4.7-4.2,7.4-3.5,2.6-6.7,5.4-9.8,8.4.5,2-2.3,4.2-4,3-1.5.4-2.6.7-2-1-1.1-1.5,2.2-4.8,4-6,1.1-1,1.8-2.4,2-4-.3-.5-.5-1-.3-1.5.3-.9,11.1-12.3,12.9-14.2s.8-.4,1.5-.4c.6-.1,1.5.2,2,0,1-2.7,2.8-6.7,5-3Z"
      style={{ fill: "#026d14" }}
    />
    <path
      d="M552,183c-.7,1.6-1.1,2.5-2,4l-1,4.2c-6.9,8.4-13.9,16.7-21,24.8-1.1,2.8-6.9,3-8,0-.9-.3-.9-.6,0-1-.7-2,1-2.8,5.1-2.1l2.9-1.9c1.7-6.7,5.2-13.9,11.9-16.1,1.6-4,3.8-7.8,6.6-11.6l2.5-.3h3Z"
      style={{ fill: "#01831e" }}
    />
    <path
      d="M550,192c-.6,3.4-3.1,4.1-5,6.5-3.3,4.1-4.5,5.8-9,9.5-.1,1.8-.8,3.2-2,4l-2.1,1.7c-1.5,3.3-2.8,4-3.9,2.3l7.9-13.3c6.3-2.7,11-10.5,14.1-15.7-.2,1.6.3,3.5,0,5Z"
      style={{ fill: "#01791a" }}
    />
    <path
      d="M568,168h1c-1.6.7-2.9,7.5-3.2,7.8s-2.2.1-3.2,1.2-1.8,4.5-2.6,6c2,2.4.2,6.3-3,6-2.3-.2-3.4,2.5-5,3-.9-6.1,4.3-8.4,7-12.5s3.3-10.2,9-11.5Z"
      style={{ fill: "#03701c" }}
    />
    <path
      d="M570,176c-3.2,4.7-6.3,11-10,15.3s-2.5.7-3.5,1.8-2.5,5.2-2.6,7c-3.5-.5-5.3,6.9-6.3,7.7-2.8,2.5-6.6,4.8-9.7,8.2-.6,1.5-1.9,1.9-2,0,1.2-1.2,3.7-6.1,4.9-7.3s2.6-.5,2.9-.8c.6-.7-.2-2.6.4-3.6,1.5-2.6,9.4-9.4,12.8-15.2s2-4,3-6c1.8-1.5,4.2-2,6.1-3.4s1.4-4.2,3.9-3.6Z"
      style={{ fill: "#026414" }}
    />
    <path
      d="M583,112c.3,0,.7,0,1,0l-6,12c-.1-.2-1.5.1-2,0-.4-3.1-1-1.4-2-2-2.1,2.4-4.1.5-2-2-5.1-2,.4-6.4,4.3-6.6l2.7-1.4c1.5-2.5,2.5-2.5,4,0Z"
      style={{ fill: "#027a28" }}
    />
    <path
      d="M596,98l-1,4c-4.6.4-5.1,4.5-7,5.5-2.7,1.4-4.2-1-5,4.5-1.3,0-2.7,0-4,0-1.5,2-6.3,3.3-7,0,.7-.8,3.9-6.4,4.2-6.7.8-.8,3.5-.7,4.8-1.3,6.7-3.4,7.6-4.2,15-6Z"
      style={{ fill: "#03702a" }}
    />
    <path
      d="M586,204c-1.3,6.2.6,3,1,4.7s-.4,5.8,1,8.3c-6,0-3.4,4.4-4,5s-5.6-.7-6,1.5c-.6,3,4.9,1.5,4,4-1.4,3.7-4.9-1.5-6-.5.6,2.6-2.6,4.9-3,6.5-.7,2.7,2.7.6,3,2,1.2,4.4-2,4.3-2.2,5.9s.8,8.5,3.1,6.5-3.1-8.4.5-7,4.5,14.1,3.5,15-4.6-.4-5,0-.2,7.8,0,8.4c.3,1.1,2.8.5,3.7,1.6s-.5,2.5-.3,3.4,2.1,2,2.5,3.3c1.1,3.4-1.7,11.5-5.8,12.3-1.9-.6-1.9-1.4,0-2,.6-6.7,1.2-11.8-.9-18.4s-2.5,0-3.1-1.2c-1.5-3.4-2.5-11.3-4.5-15.3s-7.1-4-7.2-7.3c-.1-5,3.4-3.4,3.8-3.7s-1.4-3.6-1-4,2,.1,3-.5c2.5-1.8,1.5-7.3,2.2-9.8s3.1-2.1,3.9-3.4c3.1-5,.4-10.5,9.9-11.3s-.4-4.5,4-4Z"
      style={{ fill: "#07190d" }}
    />
    <path
      d="M560,208c0,.6,0,1.3,0,2-.8,6.4-3.7,5.7-7.2,10s-1.6,2.5-2.8,4c-2,1.6-3,1.6-3,0-1.1-2.4.5-5,5-8,.1-.2,1.8-3.7,2-4,1.2-1.3,4.1-3.7,6-4Z"
      style={{ fill: "#01370b" }}
    />
    <path
      d="M90,465c.3,0,.7,0,1,0,1.7,1.6-.5,4-2.5,4s-1.2-5.6,1.5-4Z"
      style={{ fill: "#141a16" }}
    />
    <path
      d="M601,261c-2-2.2,4.8-3.5,6-3,2.4,5.1-4.4,4.7-6,3Z"
      style={{ fill: "#070f0a" }}
    />
    <path
      d="M48,163c3.8-3.4,9.4,3.3,5,6.9,1.3-.8,2.1-.2,3,1.1,3.6-2.2,5.9,2.2,2.7,5.3,4,3.3,2.3,6-1.7,7.7,4.1,2.1,3.8,5.1-1,9,.4,2.4.7,2.4,1,0,1.2-4.7,3.2-3.4,5.9,4l1.1,1.9c5.7-.5,21.4,15.1,9.8,15.2,1,4.1.7,14.3-4.8,10-.8-4.2-5.1-3.3-8.6-5-5.5-2.6-8.3-11.8-10.4-17-.6,0-1.4-.1-2,0l1-3.5,4,1.5c-1-2.4-4.5-3.5-4.1-6.5s2.5.2,2-1.5h-3c2.4-2.3,2.3-6.4,1-9.3s-3.6-.8-4-2.2,3.4-3.3,3.8-5.1-.7-10.1-.8-12.4Z"
      style={{ fill: "#091d0d" }}
    />
    <path
      d="M48,129c2.3.2,2.7,1.8.9,4.7,1.4,2.5,3.2,7.9,0,9.3.8.3,1.7.8,1.7,1.5-.4,7,.5,12,6.2,17.1,2.3,2.8,3.7,9.5-.8,9.4-.5,0-3.9,1.8-5,1s-1-2.4-1-2.5c0-.5,7.6-2.8-2-6.5,0-1.6.5-4.6-.2-5.8s-3.1-.8-3.7-2.9,3.2-9.9-1.2-12.8-5.2.6-.9-5.5c-2.5,1-4.3-1.5-3-3s4,3,4-.5-2.6.3-3.1-1.1,0-2.4,0-3.4c3.2-.7,5.1,2.6,8,1Z"
      style={{ fill: "#091e0f" }}
    />
    <path
      d="M29,110c2.1,2.4-2.9,1.9-3,2l1,4c-.8-.1-2.8.2-3,0s.3-6.7.5-7c.7-1.2,4.4.9,4.5,1Z"
      style={{ fill: "#07140a" }}
    />
    <path
      d="M136,93c3.4.6,5.7,1.3,4,5l4-2c2.7,5,2.6,27.4.9,27.3-1-.8-1.9-.8-2.9-.3-1,1.5-6,8.6-4.1,11.2,7.6,2.4,1.7,9.6-2.3,12.2v8.6c.1,0-1.6,1.9-1.6,1.9-1.2,2.8-6-.3-5-3l-2-.9c.2-3.1,0-6.2-1-9.1-2.9-2.3,1.7-5.9,2-9.5s-.6-9.4-1-13.5c3.2-1.2,4.3-4.4,1.7-3.8-7.5,1.8,0-3.9.1-5.9s-2.9-7.1,2.2-7.3c.3,1.3-.4,5.5,0,6,1.7,2.1,2.2-4.1,4-2s-1.1,4.2,1,4v-7s-3,.5-3,.5c.6-2.9,3.4-5.2,3.9-8.1s-2.3-.8-2.9-1.4c-2.1-2.4,1.9-2.8,2-3Z"
      style={{ fill: "#0b170e" }}
    />
    <path
      d="M86,91c0,4.7,3.6,1.3,4.8,2.1s1,4.2,5.2,2.9c.5,3.6-1.3,1.1-2.5,1.4-4.8,1.4-.8,4.8,3.5,3.6-2.3,9.1-.6,4.6,2.4,5.1s1.5,1.8,1.6,1.9c-1.5,1.2-.1,2.1,0,3-.6,1.2-.6,2.5,0,4,2,2.3,3.4,1.5,3,5,2.3,1.4,2.2,8.7,0,10,.2.9-.2,2.1,0,3,3.2-1,4.6.7,4.2,5.2-.5,4.1.1,7.3,1.2,11.1,4.2,1.2,4.8,7.1.6,7.7.8,4.8-2.1,5.9-6,3-3.3,0-3-1.8-1.3-4.2l-.5-5.8c-3.6-.8-6.2-6.6-3.9-9.7-2.1-3.1-3.1-6.7-2.9-11-1.9-2.1-2.4-3.9-1.4-5.4l2-4-6,1c-.4-1.7,1.8-.5,2.1-1.6.6-2.8-2.1-4.4-2.1-5.9s4.7-4.9,3-6.5-3.7-.1-3.9,0c-.6.6.9,3.2-1.1,2.9-1.7-2.8,3-6.1,3-6.5s-4.3-2.8-5.5-7.5-.2-2.8.5-5Z"
      style={{ fill: "#101810" }}
    />
    <path
      d="M80,63c3.4,2.1,1.7,7.6,2.9,11.6s4.3,2.6,1.1,5.4c-.2,0-1,0-1,0-.7-.7-1.3-.7-2,0-7.1,2.2-2.6-6.7-4-8s-3.9.2-5,0c0-3.3,2.9-5.4,2-9,2.8,1.3,3.7.5,6,0Z"
      style={{ fill: "#101c0d" }}
    />
    <path
      d="M79,57l-2,4c-1.1,0-4.1,3-1.2-2.7s3-1.2,3.2-1.3Z"
      style={{ fill: "#1a261a" }}
    />
    <path
      d="M87,83c-1.1-.2-1.5-1.5-2-2,4.5-2.2,2,1.6,2,2Z"
      style={{ fill: "#273225" }}
    />
    <path
      d="M104,133c2.3.9,1.6-2.9,3.5-2s3.2,4.5,7.5,3l-3,2h6s-5,1-5,1c-1.8,2.8,5.5,1.5,3,4s-4.7-.3-4,3c2,0,4.1,0,6.1.4,2.9.7,7.8,4.7,7.9-.4.2.1,1.3-.3,1.8.2,1.4,1.4,1.1,7.4,1.2,9.8,1.4.6,1.7,1.9,1,3.8.7,6.7.8,11.4,0,18l-2,1.2c.1,6.7-8.6,5.2-9.9,0-4,1.4-6.6-.5-3.7-4-4.3-.3-5.1-7.9-4-11.8-1.8-.5-1.9-2-.3-4.3,0-.2,1.1-4.7.9-4.9s-2.3-.5-3-1.5c-1.1-1.6-.6-4-1.1-4.5s-2.5.1-2.8-.1c-1.8-1.3,1.9-1.9,2.1-3.4s.2-6.3-.1-7.8-1.9-1.4-2-1.7Z"
      style={{ fill: "#10140f" }}
    />
    <path
      d="M104,120c1.2,1.5,8.7-2.1,8,1.5s-2.2,1.9-3,3.2c-1.5,2.3-.9,6.3-5,5.3-1.2-5.4-.5-5.4,0-10Z"
      style={{ fill: "#10140f" }}
    />
    <path
      d="M101,115c-1.7-1.9-3.1-2.7,0-4,.2,1.3,1,2.3,0,4Z"
      style={{ fill: "#0d170c" }}
    />
    <path
      d="M64,180c.7-.4,1.7-.9,2.1-.5,2.7,2.6,7.3,9,7.4,12.7,2.7.9,4.2,6.6,3.3,9.2,5.2,2.4,5.7,3.9,4.6,9.3l2.6,3.1c5.9,3,7.3,5.7,4,8.1l1,8.5c7.7,3.9,9.7,7.1,6,9.7-1.2,3.7-5.3.3-3.8.6,3.6.8,11,9.3,2,9.9,5.6-1.1,8.5.4,8.7,4.5,3.3,0,4.1,4.5,1.1,3-.2,1.5-.9,2.5-2,3,2.4,2.2-2.1,2.4-1.4,1.8,5.7-5.3,9.5,0,5.4,6.4l6.8,9.1c.8,1.4.2,2.8-1.8,4.1.9,2.2.6,3.8-1.1,4.6-.9,1.5-8.7,4.1-9,1-2.4,3.6-4.4,2.1-6.2-1l.5-13.4c-4.9-2.8-5.6-8-2.4-15.5-2.4.2-4.4.2-5.9-2-3.2-1-3-3.7.6-8.4-.7-1.8-1.2-3.7-1.4-5.6h-13.8s-1.4-2-1.4-2c-.3-1.1-1.3-7.7-1-8s5.2-.4,6-2.5c-3.3-3.9-2.4,1.7-6,.5,0-1.2.2-3.1-.4-4.1-1.5-2.1-5.9,1-4.6-4,1.9,1.2,2.5,2.1,5,2,5.8-.3,1.7-10.7,3-12s6.3,1.1,5-1.5l-11.5-10.5-2,3c-1.7-1-1-3-1.6-4.2s-1.5-.7-1.8-1.6c-.6-1.5.3-7.3-3.1-4.2-.3.9-.6.9-1,0,0-.6-1.5-2.1-.9-2.9,1.1-1.6,4.5.4,1.9-6.1.4-1.8,1.9-1.9,4.5-.5.2-2.1,1-3.2,2.5-3.5Z"
      style={{ fill: "#07200f" }}
    />
    <path
      d="M61,240c2.2.5,4.5,2.6,5.2,4.6s.7,12-2.2,12.4c4.3,1.6,2.6,11.4-3,7-.2.9-.6.8-1,0l-2.5-8c-3.1-.5-.3,7-3,7s-8.3-8-9.5-8.5c-1.8-.7-5.6,1.3-8,.5-.2-.8.2-2.7,0-3,.6-1,.4-2,0-3,1.3-2.9-1.2-7.4,3.7-8.8s19.4.5,20.3-.2Z"
      style={{ fill: "#061d0e" }}
    />
    <path
      d="M61,240c.2-.2-.4-3.7,1.9-1.9s-.3,3.2,4,1.9c0,1.3-2.5,3,.5,3s-.6-4.1,2.5-3h18c-2.4,3.3,1,5.9.9,7.3s-4.4,5.2-2.9,8.7c-3.3,2-7.5,2.6-12.8,1.9-3.7.9-6.8.6-9.2-.9.1-2.9.8-10.3.1-12.5s-3-2-3.1-4.5Z"
      style={{ fill: "#091d0d" }}
    />
    <path
      d="M37,249c4.3,1.9,9-2.6,10,3h-10c-.1-.2-2,.4-2-.5,0-2,1.8-2.1,2-2.5Z"
      style={{ fill: "#081e0f" }}
    />
    <path
      d="M16,230c0,1.3-.1,2.8,0,4-6.5,1.2-3.1-9.2,0-4Z"
      style={{ fill: "#06190c" }}
    />
    <path d="M32,221l.5-4c2.2,1.5,1.9,3.1-.5,4Z" style={{ fill: "#06190c" }} />
    <path
      d="M64,368c-7.4-3.6.8-16.7,6.3-9.3,3.7,0,7.3.5,10.7,1.3.7-2.9,4.2-2.8,5,0,1.7,0,2.7,1.3,3,3.6,7.8,0,10.8,5.4,8.9,16.4-.7,5.5,5.1,7.8,9.2,7.8,1.6,1,1.1,5.1-1.1,5.1.5,1.9-.7,2.5-2,1-1.9,1-5.5,5.3-7.8,3.9s-.7-3.1-2.9-3.7-3.8.9-5.3.9-2-1.1-3.5-1.1c-4.4,0-7.9,3.2-9.3-2.7s.1-4-.4-5.7-2.6-1.3-2.9-1.6c-6.9-.8-12.9-11.2-8-16Z"
      style={{ fill: "#0c1d14" }}
    />
    <path
      d="M40,364c2-1.5,2.9.6,3.6,2.4l19.1-.5,1.3,2.1c.8,10.5.8,8.8,8,16-1.6.3-7.5-.4-8,0s.2,4.1-1.6,5.9-9.5,2.4-12.6,1.6-1.7-1.8-2.6-2.6-3.1-.8-3.3-1,.2-1.9-.5-2.9c-2.7-3.6-4.9-4.5-3.5-10.4s2.9-.2,1.9-4-3.7-3.5-5-2.7l3-4Z"
      style={{ fill: "#0b1b12" }}
    />
    <path
      d="M79,410c.4.4,1,5.3-2.5,4-3.9-1.4.8-5.6,2.5-4Z"
      style={{ fill: "#101c15" }}
    />
    <path
      d="M56,336c2.3-.2,3,2.3,2,4l2.1.7c-1.4,9.4-.9,10.1,8.7,10.5l1.3,1.8c2.8,2.4,4.3,3.1,8.3,3.1,2.4.5,3.9,1.4,2.7,3.9-2.2.1-11.3,1-12,.5s-.7-3.9-2.8-2.4,0,1.2-.4,1.5c-1,.7-2.5.2-2.7.3-.3.3.9,6.2,1,8-6.1-.6-15.6,1.5-21.3,0s-2.6-3.8-2.7-4c-1-3.3-.9-8.5-1-12-5,0-10,.1-15,0-4.9-6.4,2.6-6.9,3-8.5.7-2.4-3.6-1.5-3-4s7.5-4.5,8-4.5c6.3-1.8,18.2-2.8,24,1Z"
      style={{ fill: "#0a1c11" }}
    />
    <path
      d="M576,283c0,.7,0,1.3,0,2-.4,4.8-.9,1.8-3.5,4-.9.7-6,5-6.2,5.3-.8,1.3-2.2,8.4-3.6,9.5s-2.3,0-2.6.3c-.5.5-.2,6.3-.2,7.9-1,0-2.1-.5-3-1s-1.7-1.9-3-2c-1.6-2.7-1-4.1,2-5-2.9-2.1-.2-8,2.2-10-1.1-5,1.2-10.9,5.8-6,1.6,0,4.1.4,5.3,0s2.9-6.3,6.7-5Z"
      style={{ fill: "#061a0f" }}
    />
    <path
      d="M60,264c.3,0,.7,0,1,0,3.1-.4,5.1,1.3,5.7,5.1,11-6,19.7-1.8,11.6,9.8,1.9,1,3.1,2.6,3.7,5,2.4-3.9,9.7.6,7,4.1-7.1,2.1-8.7,4.7-4.8,7.8-.4.4-3.6,4.2-2.3,5.1,5.5.3,10.7,1.8,7.4,8.2,7.3-6.6,12-1.2,6.8,6.9,4.3,2.5.9,6.7-3.9,5.9.2,5-.5,8-6.2,8.3.8,3.8,0,6.4-2.3,7.8,1.1.3,1.6,1,1.8,2.1s-7.8,10.5-10.8,9.3c-.1,3-1.8,5.6-4.6,3.6-1.9.9-11-1.1-11.6-1.9-1.8-2.9,0-9.8-.4-11.1-.2-4.5,1.5-6.6,5.1-6.2-.8-4-.8-7.8,0-11.5-3.5-.2-6.5-1-9.1-2.3-1,3.7-8.3,2.4-8.1-1.6.1-5.6.2-11.1.1-16.4l2-4-4-.5c3.9-1.6,4.5-1.5,4-6.5.6-.7,7.3,1.7,7.8,2.3,1,1.2-.6,4.9,5.2,1.2l-5-3.5,6-3c-1.6-.8-6.7,2.7-6-.5s5-4.1,5-7.5h-7c1.4,3.7-2.6,4.7,1,8h-7c.9-1.9-.3-5,0-6.3s2-.6,2.6-2.6.1-6.5.6-7,6.6-2.6,7.8-3.2c1.6-2-5.1-3.3-3-5s3.3-.1,4,0Z"
      style={{ fill: "#082010" }}
    />
    <path
      d="M39,312l-1-9,5,1v-5s2,.5,2,.5c-2.3,3.8,0,2,1,2.5,3.7,2,1.4,14.1,2,18,2-.1,4.1.2,6,0,4.4-1.4,5,6.6,1,7-2.1,1.4-5,.7-8.8-2h-4.5c-2.6-4.1-5.6-6.7-9.7-6-.4-.7-3.9-.4-3-2.5,1.3-3.1,6.1.2,7-.5s-.2-1.6,0-2c1.7-.6,6.1-1.8,5.4-1.4-1.4.8-2.3,1.3-2.4-.6Z"
      style={{ fill: "#0b1f11" }}
    />
    <path
      d="M39,312c.1,0,2.1.6,3,0-1.7,3.3-3.3,2.6-6,2,1.2-1.9.9-1.5,3-2Z"
      style={{ fill: "#122518" }}
    />
    <path
      d="M30,301c0,1-1.3,1.1-2,1s-1.7-1.4-3-1v-1c1.9-.3,3.3.2,5,1Z"
      style={{ fill: "#061b0d" }}
    />
    <path
      d="M32,319c-1.1-3.5,5.4-1.3,6.2-.9,1.4.5.5,3,5.8.9l-3,4c6.5-1.6,8.6,2.2,14,4,3.1,1.2,3.8,7.2,1,9-7.2-.7-14.3-.1-21.5,0s-2.3-1.1-2.5-1.1c.3-2.2-.5-5.3,0-7.3s2.8-1.3,3-2.2c.4-1.4-1.8-2.4-2.5-3.6s-.3-2.6-.5-2.9Z"
      style={{ fill: "#081e0f" }}
    />
    <path
      d="M549,40c.4.1.8.6,1,1-.5.2-1.6,1.5-2.4,2.1s-3.2.8-3.4,1.1c-.7.8.2,3.4-.1,3.9s-3.7-.2-5.3.2c-3.4,1-9.2,4.9-12.7,6.3s-4.2,0-6.1.9-5.4,7.9-6.2,8.3c-1.3.7-3.6-.1-5.6.5s-5.6,8.7-7.9,10.6-11.8,6.1-13.4,6.1c-5.8,6-13.4-4.1-7-9,.4-8.5,2.5-3.6,4-6.5s-2.5-.8-2-2.5c4.2,0,7,.2,6,5,2.8.6,2.7-3.2,4.7-3.8s4.8.4,6.7-.3,1.9-4.7,3.6-6.4,7-4.6,8.5-5.5,7.1-.2,3.6-3.9c1.4-1.2,1.7-3.8,5-3,0,3.4-.9,4.6,3,4,1.1-2.2-3.4-7.6-2-9,2.8-2.8,3.2,5.1,6,4-1-2.6,1.9-6.6,4.5-6s1.2,3.7,3.5,2-2.5-2.6-2-3c1.3.5,3.2-1,3.5-1,3.2.1-2.2,4.5,1,5s4-2.5,6.4-2.5,3.8,1.2,5.1,1.5Z"
      style={{ fill: "#041d10" }}
    />
    <path
      d="M480,72c0,1.4-.3,7.7,0,8s6.6,1,7,1c1.7,4.6-7.8,11-7,13.4s2,.6,2,1.6c-2.9,3.4-21.8,3.4-24.1,1.1-2.1-2.1-2.9-3.9.1-5.1,5.2-4.1,4.9-10.5,7.1-14.4s6.4-5.5,6.9-5.6c2-.5,5.7.4,8,0Z"
      style={{ fill: "#061e14" }}
    />
    <path
      d="M483,60c-.3,1.4-1.6,2.3-3,2v-2s3,0,3,0Z"
      style={{ fill: "#12271b" }}
    />
    <path
      d="M69,84c.7,1.7,1.4,3,2,4,.6-.2,1.4-1.5,2-2,1,4.6,1.9,15.9,7,8,.2,1.4.1,9.7.5,10.1,1.3,1.5,4.1.5,5.6,1.4,3.2,2-3.7,16.9-3,17.5,2,1.7,3.4-2,3.6-2,.9,0,1.8,1,2.4,1l1.3.4c0,4.1,1.1,8.1,3.7,12.1,1.6,10.1,4.1,20,7.7,29.7,1,3.2-.4,5.6-3.7,5.8-.2.9-.6.9-1,0-2.9,3.1-5.7-.2-6.7-3.5l-1.4-9.1c-3.2-6.9-6.9-15.2-3.9-23.1l-4.7-3.8c-6.4-.7-8.2-2.8-5.2-6.4-2-3-1.8-5.3.6-6.9-7.9-2.9-12.6-8.8-8.9-17.3l-1-1c.9-3.3.4-5.2-1.7-5.9-3,0-2.8-4.5,0-5,.7-1.8,1.7-3.2,3-4,.7,0,1.3,0,2,0Z"
      style={{ fill: "#162413" }}
    />
    <path
      d="M89,122c3.3.5,2.4-1,5,2,5.4,6.2,2.7,5.6,4.2,12.3s2.5,3.5,2.5,4.2-1.6,1.5-1.5,2.8,1.9,4.3,2.1,4.5c.7.6,2.6-.3,2.8.8s.7,7.4.6,7.8c-.3,1.9-2.5,1.5-.7,3.7,1.9.2,3.2.5,3.8,1,0,8.7,1.9,17.2,6,25.4l-1.8,1.5c2.9,1.5,2.8,6.5,0,8-2.5,4.2-3.7-1.4-3.8-4.6-.8-1-1.2-2.2-1.2-3.4-1.9,4.9-6.3.6-4.4-3.9-2.5-1.1-3.2-3.6-3.2-6.3-2.8-1.9-3.6-3.8-.3-4.8-1.9-.2-2.1-1.6-1-3-.6-2.3,2.1-1.4,2-3.4-.3-4.6-3.2-10.2-4.4-14.9s-1.6-5.6-2.4-8.5c-1.1-4.3.2-11.9-5.3-11.2,3.4-6.8,1.5-3.6-.5-6s1.9-2.6,1.5-4Z"
      style={{ fill: "#141f13" }}
    />
    <path
      d="M81,80c-.6-3.1,2.6-3,2,0-.5.6,1,2.8-3,2l1-2Z"
      style={{ fill: "#0d170c" }}
    />
    <path
      d="M552,112l1,2c-.2,2.9-2.7,4.7-5.5,5.4-15.2,13.8-31.8,25.4-49.9,34.9-4.5,7.2-7.3,13.2-7.6,21.7,2.6,1.8.7,5.2-2,6-.3,2.7-1.2,2.5-2,0-2.4-1.7-2.1-3.1-1-5.6-1.8-2.6-1.6-6.4,1-8.4-.6-.2-1.1-.5-1.2-.9-.8-2.6,5.1-16,8.7-16.7,1.6-5.6,7.6-7.9,12.5-9.3l.5-1.7c10.1-5,19.5-11.5,28.2-19.5,3.2-2.8,6.3-4.1,9.3-3.8.6-3.9,5.8-9.2,8-4Z"
      style={{ fill: "#02591e" }}
    />
    <path
      d="M556,112c.7,3.8-3.8,4.6-4,8-.7,2.6-1.3,2.6-2,0-.9.9-1.9,1.6-3,2,.6.5,1.4,1.3,1.2,1.9-1.4,3.6-16.8,16-20.8,18.2s-4.5,2.4-6.3,2.9-.6-.4-1.1-1c-.1.8-.4,1.6-1.2,2.1-5.3,3.5-13.4,8.2-19.4,9.7-.8,4.3-2.5,8-5.1,11.1.3,4.1-.5,6.8-2.3,8.2.1,1.7-.6,2.1-2,1-4.9-2.8,0-4.3,0-4.7.4-2-.3-4.2.3-6.6,1.7-7.3,10.7-14.6,16.6-18.4s9.7-4.3,12.8-7.7c3.7-4,3.3-2.2,7.9-5.1,8-4.9,17-13.8,25.4-19.6s1.2-1,3-2Z"
      style={{ fill: "#01601e" }}
    />
    <path
      d="M567,96c-.4,1.7-1,2.3-2.3,3.3-2.5,2.1-9.9,6.7-11.3,8.6s-1.3,3.9-1.4,4.1c-.4.3-2.7-.5-3.8.2s-.9,1.9-4.2,3.8c-2.8-.6-2.5-2.9,0-4-.8-.5-1.7-1.4-1.5-2.2s13-12.4,15-13.9h2.4c2.3,0,4.7.3,7,0Z"
      style={{ fill: "#01561f" }}
    />
    <path
      d="M560,118c.4.4.8.8,1,1.2.6,1.4-20.2,17.3-22.5,19-3.1,3.5-5.8,5.1-10.5,5.8,0,2.8-6.1,4.5-8,3-2.9,0-2.5-2.1,0-3,4-.9,17.3-11.9,21.3-15.7s2.2,0,2.5-.4,1.7-3.7,3.2-5.9c1.1-.7,2.1-1.3,3-2,.7,0,1.4.1,2,0,2.6-.5,5.4-1.3,8-2Z"
      style={{ fill: "#01671f" }}
    />
    <path
      d="M568,116c.7,1,1.3,2,.5,2.7-6.1,4.1-11.1,9.1-15,15-1.9,2.5-3.8,3.9-5.5,4.3.3,2.8-.4,3.1-2,1-3.8,1.2-7.2,2.7-10,6.2-2.7,1-6.8,2-8-1.2,6.4,3-3,8.5-7,6-1.9,2.2-3.4.4-2-2-1.3-1.4-.7-1.9,1-1,3.1-1.7,6.4-2.2,8-3,5.8-2.9,18.4-15,24.3-19.7s7.3-3.8,7.7-6.3c2.8-.8,5.2-2.1,8-2Z"
      style={{ fill: "#016d20" }}
    />
    <path
      d="M572,112h7c0,3.8-5.5,3.4-6.8,5.3s0,2.5-.2,2.7c.9,4-6.6,10-10.8,10.2-1.9,3.1-4.2,5.9-7.5,7.7-.4,6-10.5,4.6-5.7,0,2.8-2.8,6.2-9.5,9-12.5s8.7-5.8,11-9.5,3.1-3,4-4Z"
      style={{ fill: "#017323" }}
    />
    <path
      d="M284,32h4c0,2,0,4,0,6s-.4,3.3.3,4.7,1.3.4,1.7,1c.7,1,.7,2.5,1.5,3.3s3.3.6,3.6,1.6c.4,1.6-.8,4.8.4,6.5s4,.4,5.4,1.2,1.2,4.6,4.2,2.3l-1-4.5c2.9-.9,5.1,2.3,7.5,2s.2-3,3-2,.2,3.7.5,4c1.2,1,6.3-2.3,5,3,2.3-2.6,5-1.4,7.4-2.2s0-1.8,4.6-2.8c-.6-2,.4-2.4,2-1,.4.6.1,3,2,3,.3-.9,2.7-4.7,3-5,.7-.7,2.1-1.3,3-2-.8,4.1,1.1,2.3,2.7,3.3s.3,1.6.3,1.7c4.3,2.4-3.5,11.8-7,10-1.2.7-2.2.3-3.1-1.1-5,1.9-11.9,3-16.4-.4-3.7,2.4-9.2,2.3-12.9.1-4.2,1.7-9.4,2.1-13.6,2.3l-1.2-.8c0-5.4-.7-9.2-6.3-11.8-.2-5.2-2.2-7.4-6.2-6.5-4.7-1.5-3.8-10.6,1.7-7,2-2.2,2.8-4.6,2.3-7l1.7-2Z"
      style={{ fill: "#072817" }}
    />
    <path
      d="M282,26c.6,3.2,1.7,4.8,2,6,.5,2.2.3,5.6,1,8-1.7,0-3.3.7-5,1-3.4,0-4.7-1-4-3-2.9-2.6-2.4-3.3-4-6l8,.5c1.9-1.1-.3-5.1-1-6.5.3-.6.6-.6,1,0h2Z"
      style={{ fill: "#062d17" }}
    />
    <path
      d="M334,55c-.7.2-1.4.9-2,1-2.3-3.5-.5-4.4,2-1Z"
      style={{ fill: "#133220" }}
    />
    <path
      d="M349,48c.2,2.9-3.2,4.3-5,2,1.4-.8.7-2.8,5-2Z"
      style={{ fill: "#072817" }}
    />
    <path
      d="M352,46v4c-1.2.2-1.6-1.8-2-2-.7-2.6,1.7-1.7,2-2Z"
      style={{ fill: "#153324" }}
    />
    <path
      d="M200,92c0,3.2-.5,6.6,0,10-1.1,1.2-3,2.4-3.1,2.6-1.3,1.8-1,7.4-2.1,9.7-2.4,4.8-2.5,1.3-2.8,9.7-1.7,3.6-2.5,7.6-2.1,11.9l-1.3,1.8c-.5,10-.9,20-1.2,30l-2.4,1.4c1.4,0,1.3.9,1,2,2,.5,3.9,8.7,0,10,3,4.8-1.3,7.1-2,1-1.7,0-3.9-5.3-4.2-6.7-1.6-6.9-1-29.7,0-37.2s.7-3.2,2.4-3.8l-.2-12.7,2-1.6,1.9-2.4c1.2-6.8,2.6-13.6,4.1-20.3l2.1-1.3c0-1.2.3-2.2,1.4-2,0-3.7,1.8-8.5,5.6-6,3-1.1,3.3,2.6,1,4Z"
      style={{ fill: "#023d12" }}
    />
    <path
      d="M192,128c2.7,2.5,2.7,5.5,0,8l-1.5,3.1c.1,13.9.3,27.7.5,41.6l1,2.3c1.5,3.6,3.3,14.3,0,17-3.9-.2-6-11.9-6-16,1.1-.5,1.1-1.5,0-3,.1-2.3.5-8.2,0-10,.5-.7.1-1.3-1-2,2.4-8.8,1.1-21.9,3-32.5s-1.5-9.8,4-12.5c0,1.3,0,2.7,0,4Z"
      style={{ fill: "#014311" }}
    />
    <path
      d="M248,85c2.5-.4,7.9.7,9.5,3,1.3-3.2,12.9-3.1,14.5,0,2.2,0,4.2,1,6,3,3.3,4.2-2.7,4.3-6,2-.4,2.2-2.7,2.6-6.7,1.2-7.5,3.9-12.5,4.3-17.3-2.2-3.4-.6-8.2-4.4-8-8v-2l8,3Z"
      style={{ fill: "#01441f" }}
    />
    <path
      d="M232,92c3.4-.4,3.4,3.2,0,3,1.6,2.2-13.3,6.9-14.1,6.5s-1.4-.8-1.9-1.5c-4.6,1.9-7.2,5.3-7.8,10.1-3.4,2.1-6.3,8.2-7.9,12.4l-3.6,14.7c-.7,4.4-2.3,6.3-4.7,5.8-2.2-2.1-2.2-4.9,0-7,0-2.7,0-5.3,0-8,3.3-3,3.9-9,5.7-12.8s8-9.9,9.8-13.3.7-4.5,5.6-7.4c6.3-3.7,11.9-3.5,18.9-2.6Z"
      style={{ fill: "#024e16" }}
    />
    <path
      d="M232,92c.3-.7,3.2-1.1,1.9-2.9s-4.5-.8-.4-4.2,6.6,4.2,6.6-.9c2.6,2.1,5,4.4,7.7,6.4s.3,1.6.3,1.6c2.8.9,1.9,4-1,4l-1.1,1.9c-2.9-1.6-5.9-2.9-8.9-3.9.8,3.9-4.2,4.9-5,1,1.1-.9,0-2.9,0-3Z"
      style={{ fill: "#024d1b" }}
    />
    <path
      d="M215,64c5.6,2.9,4,8.7,5.5,12s4.3,1,3.5,4c-2.9.4-6.6-.5-9.3,0s-5.7,6.7-7,7.6-2.6.3-3.6.9c-1.9,1-1.3,4-4,3.5,0-1.5-1-3.6-1-4-3.7-1.7.3-9.8,4.7-10.1-.9-3-.5-5,1.3-5.9-1.1-2.1-3.1-6-1-8,3.6,0,7.5-.3,11,0Z"
      style={{ fill: "#01400c" }}
    />
    <path
      d="M194,60c.7,1.1,2,3.7,2.2,3.8.6.6,2.8,0,3.8.2s2.7,0,4,0c.6,1.7,1.9,3.8,2,5.7s-1,2.2-1,2.3c-.8,2-3.3,2.6-7.6,2-1.3,2.8-4.6,5-5.4,1-3-1.2-3.5-9.5,0-10,.1-.4,3.5-2.1,2-5Z"
      style={{ fill: "#013908" }}
    />
    <path
      d="M176,24l8.9,8.6c1.8,3.7,4.6,5.7,7.1,9.4s1,3.4,2,5c-1,3.4,1.9,4.6,2.8,6.7s0,1.8.2,2.3c-.7-1.8-3.9-2.7-4.5-4-1.1-2.3.1-5.4-.7-6.8s-6.1-6.8-7.8-9.2c-2.8-3.8-5.6-7.6-8-12Z"
      style={{ fill: "#014401" }}
    />
    <path
      d="M584,45l6,3-4-4c1.1-.1,4.3-1.6,5-1s-.2,2.2,0,3c1.7,6.6-.2,3.5-6,5.5-8.8,3.1-20.7,11.2-29.1,15.9s-10,4.2-11.9,7.6.2,1.8,0,2c-.5.4-2.6-1.3-4-.6s-4.1,5.2-5.6,6.4-6,3-6.2,3.3c-.7,1.2.4,3.9-.3,4.7s-2.4.3-3.4,1.1c-2.1,1.6-1.5,3.6-4.5,4.1-.2,1.7-1.7,2.4-4.5,2.1-.9,4.1-3.9,6.6-8.8,7.6-1.5,5-4.9,10.3-10.6,8.3-.8-.2-.8-.5,0-1-.9-.2-.9-.5,0-1-2.7-2.6-2.6-5.3,0-8,.4-1.5,2-2.4,4.6-2.6,2-4.5,4.7-6.7,9.4-7.8l.8-3.5c4.6-3.2,9-6.5,13.2-10,2.9-4.5,6.4-8.8,12.7-10,4.6-3.4,9.2-6.7,13.8-9.9,5.3-1.3,9.7-3.9,13.2-7.8,6.3-2.5,12.4-5.2,18.3-8.3l2.1.9Z"
      style={{ fill: "#022f15" }}
    />
    <path
      d="M553,80c.9,4.1-2.8,2.6-5.1,5.5s-2.2,2.3-4,4.2-2.9,5.9-3.1,6.2c-.7.6-3.3-.3-4.9.6s-1.9,5.1-4,6.1-3.1-.6-3.8-.3-1.9,2.8-3,3.7c-2.4,2-11.5,8.2-12.7,10.1s-.2,3-.7,3.7-4.2,1.7-5.8,2.8c-2.9,2.1,0,2.1-5,3.4-.1,2.5-1.4,3.1-3,1-1.2,2.8-3.8,2.7-5,0-.8-2,.4-3.9,3.5-5.8,2.2-4.9,6.7-8.5,13.5-10.8-.6-3.9,3.4-9.3,6.9-6.4,5.4-4.2,7.2-6.7,11.3-10.7s12.7-6.5,14.8-8.8,0-5.5,5.7-6.3,2.5,1.5,4.3,1.8Z"
      style={{ fill: "#023b17" }}
    />
    <path
      d="M520,96c.9,3.6-2.3,4.9-3,8-.8.6-3.9,1.9-4.4,3-.9,1.8-.2,4.2-.7,4.8s-9.4,4.7-11.3,6.2c-3.6,2.8-4.2,6.3-7.5,9,0,2.9-3.8,3.5-5,1,4.9.4,0,7.9-2,3-4.7-1.1-.3-7.1,2-3-6.4-3.3,2.1-7,6.1-8.4-.5-2.8.1-4.6,1.9-5.6,1.8-1.6,5.1-2.1,6.6-3.9s1-3.4,1.8-4.8c1.8-3.1,6.3-2.6,7.4-3.6s2.1-5.3,2.4-5.5c.8-.7,4.3,0,5.9-.2Z"
      style={{ fill: "#013516" }}
    />
    <path
      d="M501,126c1.6,2-1,3.7-1,6,7.1,2-.7,9.4-1,3-1.5.1-2.5.8-3,2l.7,2.2-8.3,7.6c-1.3,3.5-3.5,5.9-6.5,7.3.3,4.6-.7,7.6-3,9-.8,2.3-2.9,3.6-5,2-3.1-.5-1.4-9.1-.5-11.8,1.9-5.6,2.8-8.3,8.6-11.6,3.4-6.4,8.7-11.3,15.9-14.6.7-.1,2-.7,3-1Z"
      style={{ fill: "#02411d" }}
    />
    <path
      d="M507,131c4.1,2.3,0,5.8-3,7,.5,3.2-2.6,6.1-9.3,8.6-.6,3.6-2.3,5.6-5.1,6l-4.6,12.4-2,1c-1.2.3-2.2-.4-3-2-1.3.8-1.8.3-1-1l1.2-10.8c5.2-.4,4.8-4.6,6.8-6.7s6.5-5.6,9.1-8.4c.2-1.5,1.6-1.7,3-2,.4,0,1.7,1.4,2,1,.6-1.4-1-3.3-1-4,2.3,0,5,.4,7-1Z"
      style={{ fill: "#024a1e" }}
    />
    <path
      d="M537,112c.3,0,.7,0,1,0,.7-2.4,2.4-2.5,3,0,1.9,1,1.7,2.5,0,3.7-10.3,6.5-20.1,13.5-29.5,21.2-2.2,1.7-4,2.1-5.5,1.1-.5,2-1.4,1.9-2,0,.2-.3,2.2-3.1,2.3-3.2.6-1.1.6-2.5.7-3.7,2.4-1.7.8-1.5,5-3l1.1-2.6c7-6,14.4-11,22.1-15.1l1.8,1.7Z"
      style={{ fill: "#024d19" }}
    />
    <path
      d="M537,112c-9.2,1.1-15.7,12.7-25,16,.2-1.7-.6-4.5.2-5.8s7.9-5.4,9.5-6.5c5.3-3.9,11-7.9,16.3-12.7l-1,9Z"
      style={{ fill: "#024818" }}
    />
    <path
      d="M456,58c2.4,5.2-3.1,5.8-3.1,10.5s2.7,3.9,3.1,4.5c1.6,2.4-1.4,7.4-2,9.5s.3,3.6,0,4.8-5.4-1-6.1,8.6c2.9,2,3.2,10.1,0,12,4.2,1.9-2.4,12.2-6,12-1.9,3.2-4.8,1.9-7,0-3.2,3.6-5.1.3-2-3-.7-2.2.9-3.6,2.6-4.8-.9-2.8-1.1-5.8,2.5-6.2,0-7.7,2.2-17.9,4.8-25.2l2.1-.8c5.1,1.2,2.4-5,3.2-8.3s5.6-12.7,6-13.5,1.6-.2,1.7-.2Z"
      style={{ fill: "#032712" }}
    />
    <path
      d="M458,92c-.1,2.2,0,3.5,2.5,4,6,1.1,15.3.1,21.5,0,0,.4-4.4,2.4-1,5,1.1,1.3.5,1.9-1,1l-.9,2.3c-7.2-1.7-11,.9-11.2,7.7-2.9,3.6-15,3.2-16.2-1.5-2.5,1-4.1.4-3.7-2.4.3-3.9-.3-8.1,0-12,5.2,1.4,4.5-1.5,6.6-2.9s3-.8,3.4-1.1Z"
      style={{ fill: "#082113" }}
    />
    <path
      d="M36,72c1,.6.9,2.8,3.5,2,1-.9-.1-2.4,1.5-2-.1,1.2.4,2,1,3,3.4-.5,5.2,3.3,2,5,1.5,3.6,3.3,6.9,5.4,10l-1.5,3.4c3.4,2.3,3,5.4-1.2,9.2,2,2.5,3.7,5,5.3,7.5-1.8,2-1.9,4.3-.3,6.8,3.9-.2,6.2,4.1,2.4,6,1.2,5.6,2.4,11.3,3.9,17,5.4,3.7,5.8,5.4,5.1,11.9l1.5.6c0,5.9,1.5,11.4,4.1,16.6,2.1.4,3.2,1.3,3.3,2.8,1,1,1.6,2.6,0,3,.9,2,.2,2.7-2,2-2.7,1.8-4.7-.5-6-7-1.3,3.9-5.2.3-5.1-3.2,0-3.6.3-14-4.8-12.9l-1.9-1.7c-.2-3.8-1.3-6.9-3.2-9.2,0-1-.9-7.6-1.1-7.9-.4-.5-3.2,0-3.8-1.6,2.7-2.4,4.3,0,4-4.5s-3.2-2.1-3-4.5,2.6-.1,3-.5c1.8-1.7-1.9-2.2-2.4-3s.4-1.7.2-1.9c-.6-.7-3.1-.6-3.6-1.3-1.2-1.8,0-8.4-.2-8.7-.5-.7-4.1.4-5-4.6s3.1.2,3-1c-3.9-.9-.9-3-1.1-5.9-.3-4.7-1.3-11.3-1.8-16.2s-2.1-1-2.2-2.9.6-4.4,1.1-6.5Z"
      style={{ fill: "#0b2612" }}
    />
    <path
      d="M42,55c6.3-.5,8.7,8.3,8.4,14,3.7-.2,5.6.8,5.6,3,4.5.8,6.3,3.7,5.4,8.9,3.3-.1,4.1,2.2,2.6,7.1v5c2.8,4.6,2.5,12.4,0,17-1.4,4.6-5.2-.5-4-3.9-1.3,1.2-2.7,1.5-4,.9l-2.1-.9.2-8.1c-2.5-1.6-3.1-3.6-2.3-6.4-3.7-1.4-6.5-5.6-5.8-9.7l-2.1-1.8c0-2.3-1-3.2-2-5l4-1c0-3.4.3-6.6-.5-10s-3.9-6.7-3.5-9Z"
      style={{ fill: "#132b14" }}
    />
    <path
      d="M50,49c.4.3,3.3,3.7,3.4,4,.4,1,1.2,5.7,1.6,7.3s1.8.7,1.9,1.4c.4,1.8.1,6.1,0,8.3,2.4,1.9,1.9,3.2-1,2-.5-.7-6.6-.9-6.9-1.2-1.5-1.4-1-7-1.1-9.1l-6-6.7c.4-2.5,1.9-4.3,2-5.5s-4.1-1.1-3-3.5c3.5-.5,6.4.8,9,3Z"
      style={{ fill: "#172915" }}
    />
    <path
      d="M446,48c0,.5-3.3,5-4.4,7.7-2.3,5.8-7,13.5-10.1,20.3s-3.6,2.9-4,4,1.6,2,1.4,3.3-5.9,11.9-5.9,13.2v15c0,5.3-6.4,10.7-6,16.5-.3.9-.6.9-1,0l-1.1,1.6c-.5,3.6-3.5,10.9-6.9,8.4.4-1.8,1.5-2.9,2-4.6.8-2.8-1.8-5.2,3.1-4.4-4.8-5,.4-7.8,2.2-12.3,4-10.1-.8-22.4,5.8-31.7l3,3c-1-2.3.1-5.7,0-7.4s-1.9-.2-2.1-2.1c-.4-3.6,1.5-5.3,2.1-8.1s-.2-6.2,0-6.5c.4-.4,7.6.4,8,0s-.4-5.7,0-7.3,7.2-7.2,8-11.7c2.6.3,3.2,2.4,6,3Z"
      style={{ fill: "#023b14" }}
    />
    <path
      d="M409,82c4,1.6,4.8,2.1,3,6,.4,7.1,1.8,15.4.9,22.4-.6,5.1-4.2,10.2-4.8,14.2-.9,5.3-.5,8.7-2.1,13.8-.6,2-2.1,3.4-1.9,5.6-.8,2.8-3.2,2.7-4,0-3.5-1.4-2.9-8.6.6-10.3,1-5.7,1.3-11.6.9-17.7.4-2.6,1.5-3.9,3.5-4l1.1-2.4c-1.5-6.3-1.5-12.1,0-17.5-2.2-7.6-1.3-11,2.9-10.2Z"
      style={{ fill: "#024518" }}
    />
    <path
      d="M391,59c4.2-2.1,12.1-7.9,16.3-7s.6,1.8,1.4,2c2.1.5,5-.3,7.3,0,0,.2,1.8.6,1.9,1.6.4,3.1-3.5,6.7-6.9,5.9l6,1c2.6,2.3-.9,1.3-1,1.5-.7,2.1-3.1,2.8-7.2,1.9-.6,4.5-1.1,7.8-6.2,5.2l-2.6.9c-2.2,2-3.5.5-2-2-2.8-1.1-5.3-2.4-7.7-3.9l-.3-2.1c-1-2-1-3.7,1-5Z"
      style={{ fill: "#08421c" }}
    />
    <path
      d="M416,64c-1.1,2.2-.2,15.9-.9,19.5l-3.1,4.5c0-1,0-2,0-3l-4,1c0-2.6,1.4-1.7,1-4-2-1.2-3.5-3.6-4.5-7.3-2.3-.5-3.8-1.5-4.5-2.7,3.3-5.1,3.3-1.1,4.5-1.1,2.2,0,2.3-6.6,2.5-6.9.5-.5,7.3.3,9,0Z"
      style={{ fill: "#034419" }}
    />
    <path
      d="M416,128c3.4,3.3,1.8,8.7-2.1,11.4.6,3.8-9.4,21.1-11.6,21.4s-1.6-.2-2.3-.8c-2-1.6-2-3.4,0-5-1.9-.9-1.9-2.1,0-3,2-2.5,4-5.1,6-8,0,0,1.7.4,2-.7.4-1.6-.4-3.8,0-5.3,3.6-1,4.6-8.9,8-10Z"
      style={{ fill: "#033817" }}
    />
    <path
      d="M404,144h2c0,0,.9,1.4.7,2.3-.5,2.2-4.2,6.4-6.7,5.7-3-1.6-2.5-6.3,0-8,1.3-.1,2.7,0,4,0Z"
      style={{ fill: "#033f1b" }}
    />
    <path
      d="M391,53c1.3,2.7.1,3.5,0,6-.2,2.7-2,4.5-4,2-.5,2.7-1.1,2.5-2,0-3.6.4-7,2.1-10.3,5.1-1.7.7-6.1,0-6.7-2.1-4.6-5.8,3.9-12.6,10.2-11.6,3.7-2.4,7-3.2,9.8-2.4.6.8,2.4,1.8,3,3Z"
      style={{ fill: "#083c1f" }}
    />
    <path
      d="M387,48c.4.6.6,1.4,1,2-3.1,1.5-6,3.2-9.2,4.4s-3.2-.6-5.9.9-4.5,4.8-4.9,8.7l-1.2,2.2c-5.1,1.4-9.9,3.8-14.4,7.2l-2.3-1.4c-1.2,2.7-3.8,2.7-5,0-2.6,1.1-3-.2-1-2l.5-2.4c1.1-1.2,13.4-10.4,14.3-10.3s.8.3,1.3.7c.1-4.3,7.5-8.5,11.5-10.8s8.1-4.5,10.5-1.5l2-.7c.9,1,2.1,1.7,3,3Z"
      style={{ fill: "#08371d" }}
    />
    <path
      d="M382,42c2.2,1.6,1.6,2.6,2,3,0,7.4-4.2.9-4.5,1s-5.5,2.2-6.4,2.7c-4.4,2.2-9.4,6.6-13.1,9.4-7.5.4-9.9-.9-7-4,.1,0,.4-1,1.1-1.4,1.3-.8,3.3-.3,4.4-1.6s.9-5,1.7-5.8,4.2-1.1,5.8-1.8,1.9-2.4,2-2.4c.5-.2,9.7-.9,10.6-.9,1.3,0,2.4,1.1,3.4,1.9Z"
      style={{ fill: "#0a301d" }}
    />
    <path
      d="M353,54c-.9,4.7,7.9-.7,7,4-4.6,3.4-11.6,10.5-16,12,.3,2.7-5.7,3.7-8.2,4s-11.1.7-12.8-2c-1.4,1.3-1.9.8-1-1l.8-2.2c5.3-1.9,11.1-3.1,15.2-2.8,3.2-3.6,6.1-4.7,7-10,.5.4,6.7-1.4,8-2Z"
      style={{ fill: "#073018" }}
    />
    <path
      d="M260,32c4.5.6,3.7,6.2,5,9,.7,1.8.4,3.4-1,5,.9-3.2,1.9-3.9,3-2,.3.4.8.7,1,1,0,1.2.4,2.2,1,3-.1.3.1.8,0,1,.5,3.6.9,7.2,1,11l-2.7.5c.6,8.2-4.5,8-15.3-.5-3.6.6-1.5-3.2-2-5.3s-2-4-2.3-4.4c-1-1.7-7.8-7.3-5.7-10.3s1.3.3,1.8-.2c.8-.9.9-3.3,2.7-2.8s1,2.1,1.8,2.7,4.1-.9,2.6,3.7-4.5,1-1.9,3.1c2.1,1.5,4.6-.8,6.4-.6s.5,1.8,1.3,2c1.2.3,2.9-.4,4.1.2s.7,2.2,1.7,2.8c2.3,1.4,1.4-4,1.5-5-.7.1-2.1.4-2-.6.8-4.8-.9-8.9-2-13.4Z"
      style={{ fill: "#022e17" }}
    />
    <path
      d="M426,117c3.6.5,1.7,3,0,5,3,7.4-2.2,13.3-7.8,18,0,2.3-.8,3.6-2.2,4,2.7.6,2.5,1.8,0,3-3.5,4-6.1,8.8-8,13,1.4.8.9,2.1.2,3.5l-13.7,16.2c1.4,4.6-11.3,15.7-13.2,15.1s-.9-.4-1.2-.7c-.7,2.8-1.4,3.9-4,2-1.7,2.3-2.4,1.7-2-1-3.1-.6-1.7-2.6,4.3-5.9l1.7-1.1c-1-2.6-.6-3.1,2-2,1.2-1.4,2.5-2.8,4-4-.2-1.8.7-3.6,2.7-5.4,1.8-6.8,4.5-12.7,9-17.6l2.3,1c2.5-1.3,7.8-8.8,9.4-12.1s1.9-7.4,2.9-9.6,2.9-1.5,3.5-4-1.1-4.2.2-6.2c.3,0,.7,0,1,0,1.2-.2,4.8.3,5,0s-.3-3.9,0-5.3,1.7-.6,2-1.4c.3-1.2-.4-3.2.2-4.1s1.7,0,1.8-.2Z"
      style={{ fill: "#033216" }}
    />
    <path
      d="M445,80c-.5,5.2-3.9,14.8-5,20.5s.4,5,0,6.9-2.7.2-3,.6c-.6.7,1,4,.5,5.1s-3.5,3-4.5,3.9c-.2,3.3-3.6,6.9-7,5v-5c1.7-2,1.2-5,4-5,1.3.1,1.7,2.1,1.8,3.3s-1.4-2.9.2-3.3c.3-8,.4-10,2-16.7.6-2.4-.3-5.8,0-8.3l4,1c0-2.7,0-5.3,0-8l3.5-7,4.5-4c-1.2,5.4-.5,6.6-1,11Z"
      style={{ fill: "#022d13" }}
    />
    <path
      d="M432,112c0,1.1.4,4-.5,4l-1.5-4c.7,0,1.3,0,2,0Z"
      style={{ fill: "#032c15" }}
    />
    <path
      d="M586,35c1.5,4.2.5,1.9-2,5l-.3,1.3c-5.5,2.2-10.3,5-14.8,8.7-4.7-1-7.7.3-9.2,4.1-5.4.7-9.9,2.6-13.5,5.7.2,5.5-3.4,7.5-10.6,6-4.8,4-9.6,8.1-14.4,12.3-1.8,3.5-6.9,6.1-9.2,1.8-1.9-.6-1.9-1.4,0-2,4.6-.5,3.2-5.6,5.5-8s3.1-1.3,4.5-2.5c4.2-3.6,1.1-5.3,8.9-4,1.6-1.6,7.9-9.5,9-10.1,4.1-2.2,4.1,1.5,4.6,1.6,4.4.6,5.4-4.5,8-6s4.9-.9,5.3-1.3-.1-2.1.3-2.6,6.6-1.2,8.6-2c2.8-1.1,5.8-4.3,8-5s4.5.1,5.1-.4.8-4.2,4.6-5.8c.5.7,1.2,2.3,1.5,3Z"
      style={{ fill: "#032513" }}
    />
    <path
      d="M512,78c0,.7,0,1.3,0,2,4.8,3.3.3,4.9-4,5l1.9,1.1c-2.2,7.9-5.5,12.4-13.9,12.9-.2,2.4-1.8,3.9-4.8,4.3-2.9,3.4-9,8.7-11.2,2.7-1.9-.9-1.9-2.1,0-3,4.1-1.9,5.5-3.9,4-6,3.6-8.5,6.4-6.4,12.9-10.6,6.5-4.1,8.3-7.7,15.1-8.4Z"
      style={{ fill: "#032514" }}
    />
    <path
      d="M484,97c5.2,2.8-1.7,3.5-2.1,7l-1.9-1c-.9-.3-.9-.6,0-1,.3-.1.6-.8,1-1,.8-.4,2.7-.8,2.9-1.1s-.5-1.4,0-2.9Z"
      style={{ fill: "#123121" }}
    />
    <path
      d="M584,40c.3,0,1.9,1.5,3,1-1.4,1.4-2.8,3.8-3,4-4.2,3.8-14.4,6.3-19.3,9s-3.6,4.3-6,5.2c-4.6,1.8-4.2.6-9.3,3.8s-8,6.2-11.8,8.7-5.1.8-7.8,3.7.6,3.7-5.9,4.6c-4.5,5.3-9.6,7.7-15.2,7.1l-.8-2.1c1.4-2.6,4.1,2.2,4-5,1.6-.3,6.1.6,6.9-.2s.1-2.1.7-2.7c5.3-5.8,6.4-6.1,12.8-10.3,1.2-.8,1.4-2.6,2.3-2.9,1.8-.5,8.8.5,9.3,0,1-1.1-3-4.6,4.8-8.7s9.4-2.3,10.6-3.2,0-2.6,1.8-3.4c2.8-1.3,5.8,0,8.1-.6s1-2,1.8-2.4c3.3-1.9,9.2-5.7,12.9-5.6Z"
      style={{ fill: "#022914" }}
    />
    <path
      d="M194,36c2.4-1.5,5.8,2,7.6,4.2,4.6-1.1,7.4-.2,8.4,2.8.2,1.6,1.4,4.9,2,7-3.3-3.1-7.9-1.8-12-2-.4-1-2-5.6-2.2-5.8s-4.9-1.8-5.8-4.2v-2c.4.2,1.6-.2,2,0Z"
      style={{ fill: "#026102" }}
    />
    <path
      d="M192,32l4,1.5c1.2,3.2,8.6,2.3,12,2.5.9,2.4,1.3,1,2,6s0,.7,0,1c-2.8-1.9-6.4-.5-9.3-1s-4.2-4.8-6.7-6c-1.3-1.1-1.4-2.8-2-4Z"
      style={{ fill: "#016b03" }}
    />
    <path
      d="M170,53c2.8,4.1,5.7,2.7,6,3s-.2,6.4,0,8c-1.4.7-1.1,1.4,1,2,.3-.9.6-.9,1,0,.5,5.9-.8,7.9-4,6-1,2.8-5.8,2-6-1-.8-.2-2,.2-3,0-.5-.6.6-9.4,1-11s1.2-.4,1.4-1c.6-2-.6-4.8,2.6-6Z"
      style={{ fill: "#06230c" }}
    />
    <path
      d="M192,65c-.5,2,0,7.4,0,10,2.7,1.6,2.7,3.4,0,5,.1,1.7-1.3,2.6-3,2-1.7,1-2.9,0-3-2-4.9,1.6-5.6-11-2-13,0-1,0-2,0-3l8,1Z"
      style={{ fill: "#01310c" }}
    />
    <path
      d="M176,64c4.5-.2,5.3-.7,8,3,.2,4-1.1,10.2,2,13,1.1,1.4,1.8,3.4,2,6,1.9.3,1.8,1.1,0,2-.5.6-1.1,1-1.8,1.1,0,10.3-3.1,24.4-6.6,35.9l.5,6.7c.2,3-1.1,6.7-4.1,4.3-1.8,3.8-4,1.2-3-2-.7.4-4.7-2.6-5-3-2.7-3.4.9-5.1,3.8-6.3,2.3-5,2.9-10.7,1.8-17,.2-3.7,1.7-5.8,4.5-6.1.5-9.5.3-18.4-.3-27.7s0-4.8.2-7.8c-.2,0-.7,0-1,0-2,.7-2.7-.7-1-2Z"
      style={{ fill: "#042a10" }}
    />
    <path
      d="M560,96c-3.9,5.6-7.7,6.7-12.5,12.1s-3.2.8-3.5,3.9c0,2.9-2.3,2.7-3,0-1,0-2,0-3,0-.9-4.3,2.7-3.3,3.7-4.3s.5-2.5,1.7-3.8,3.9-1.4,5-3,1-4.2,1.7-4.8c2.7-1.9,8.5-2.3,11.8-5.2-.2.2-.7,3.1-2,5Z"
      style={{ fill: "#014f1e" }}
    />
    <path
      d="M588,77c-.8,2.5-1.6,2.7,1,4-4.9,2.4-18.3,7.6-21.8,10.3s-.7,2.1-1.7,2.7c-2.2,1.3-1-5.6-3.5-3,7-6.2,17-10.2,26-14Z"
      style={{ fill: "#024f20" }}
    />
    <path
      d="M151,72c.4-2.4,2.8-2.5,4.4-.8s-1,22.3-2,23.3l-2.4.8c1.8,2.8,3,5.9,3.6,9.5-.4,3.3-1.3,19.6.8,21.2,6.2-.4,8,.7,5.8,7l.9,2.1c1.5-2.2,2.5-1.7,2,1,1.3,7.6,3.3,14.9,6,22,1.6.3,1.5,1.2,0,2-.6,1.8-.1,4.9,1.3,9.3l-1.3.7c-.7,1.3-1.7,1.8-2.9,2.1-2.3,7.2-8.9,8.9-9.3,0-3.1-.6-5.9-3.9-6.6-6.9-2,3.3-6.2,2.6-5.2-1.3-3.2.9-4.6-1.9-2-4,.2-.8.6-1.7,1.3-1.8,5-1.1-2.1-4.8-4.6-5.1l-.5-1.2c2-6,2.2-11.7.6-17,1.4-1.5,1.8-3.6,1.2-6-3.5-.6-3.5-2.6,0-5.8.7-.5,1.7-.9,1.9-1.6,0-8.4,0-16.9,0-25.4s1.2-5.2.8-6.4-3.1-1.3-2.8-2.1,1.4-1,1.7-2.2c.5-2.5-1.8-6,3.3-5.4-.6-2-3.8-1.3-3.1-4.5l3.1-10.5c0,.2,1.1.4,1.4,1.1.9,1.7.6,5.1,2.6,5.9Z"
      style={{ fill: "#091b0e" }}
    />
    <path
      d="M174,72c4-1.7,5,2.1,2.8,5.3l1.1,2.6c-2.3,4.7-2.4,9.2-2.1,14.3,3.8,3.1.3,5.3-3.5,4.8l-.3,2.8c4,1,3,7.5-1,6.1-3.5,4.3-5.9-2.4-5-6.2h-1.3c-1.7-1.8-3.3-3.7-4.7-5.8-2.8.3-2.5-14.9-2.1-15.7,1.2-2.2,2.2-2.6,4.6-2.4.8-1.9,2.7-3.5,5.5-5,0-.6,0-1.3,0-2,1.8.4,4.9.6,6,1Z"
      style={{ fill: "#06210f" }}
    />
    <path
      d="M151,72c2.7-5.3-2.1-8.6,5.5-8.4,5.6.2,3.1,2.6,3.5,7.4,2.7-.5-1.1-6,3-5-.2,4.1-.9,10.2,5,7-.4,2.9-1.3,1.7-2.9,3.6s-.7,2.9-1.3,3.3c-1,.6-3.5-.2-3.8.1-.6.5.4,13.7,0,16-.3.7-.7,1.3-1,1.3-1.2,0-8.2-1.5-7.9-1.7.9-.8,14.9,2.4,6.3,6.9l3.5,3.3c1.1,4.6,1.6,9.5,1.2,14.6,2.6,2.7,3.2,5.6,2,8.7.2.9-.1,1.2-1,1l-.3,1.4c2.2,1.1,1.3,2.6-.7,3.6-.5-.2-1.8.2-2,0s1.3-5.7,0-6.9-5.8,1.3-6.8-1.7-1-6.7-1.2-8.8c-.4-4.1.7-10.3.6-13.6s-2.8-5.3-2.6-7.3,1.7-2.5,2-4.1c.9-5.6.6-9.4,2-16s-1-3.7-2.9-4.4Z"
      style={{ fill: "#091e0d" }}
    />
    <path
      d="M57,70c4.4,2.1,7,5.4,8,10,.9,1.4,1.5,2.7,2,4,0,1,.3,3.5-.1,3.9-.9.2-2,.2-2.9,0s-2.5-1.8-4-1l4-4c-1.2-.2-4.6.3-5,0s.6-1.7.4-2.5-1.1-1.5-1-2c0-1.2,2.2-1.8,1.6-3.5-5.9,1.2-3.4-2.2-4-3,1-.3,1-1.2,1-2Z"
      style={{ fill: "#122612" }}
    />
    <path
      d="M69,84c0-2.5-.9-4.7,2-4v8c-.5.1-1.3,0-2,0,0-1.3,0-2.7,0-4Z"
      style={{ fill: "#13200f" }}
    />
    <path
      d="M65,80c2.9-.6,2,1.5,2,4-3.6-.4-1.3-.9-2-4Z"
      style={{ fill: "#13200f" }}
    />
    <path
      d="M253,70c-.9-3.8,1.6-4.1,7.6-.9,2.1-2,4.2-1.3,6.3,2,2.9-.6,5.6-1,8.1-1.1,3-.2,4.3,2.8,8,3,3.5-.5,8,4.2,5,7-.7,1.7-2.4,2.7-5.3,2.9-1.8,4.3-4.7,6.3-8.4,2.1-4.5,1.1-8.9,1.2-12-2.8-1.5,1.3-3.1,1.7-4.9.8-4.2,3.5-6.2,1.5-9.4-2v-1c0-1.8.3-4.5,0-6,4.6-.8,3.6-2.5,5-4Z"
      style={{ fill: "#04371d" }}
    />
    <path
      d="M269,49c6.7,2.1,1,4.2,1,4.6,0,1.5,1.8,4.3,1,6.4l4-2-2,5.5c4-.2,3.7,3.6,2,6.5s-7.4,4.2-10.1,2.5-.8-4-2.9-2.4.1,1.9-.6,1.9c-1.3,0-1.9-1.3-3-1.5s-3,2.4-2.5-2.5c-.1-.1-2.6,1.7-3,2-1.7-2.2-1.2-7-1-10s-.5-2.8.5-3c1.7,1.9,11.3,10.1,13.5,7l-1-4.4c1.1-2.4,3.5,0,5-2.1,0-2.3-3-4.1-3-6s1.8-2.1,2-2.5Z"
      style={{ fill: "#05311a" }}
    />
    <path
      d="M292,67c4.5-3.8,4.4-2.2,10.4-3.1s2.5-1.5,3.1-1.5,3.5,2.7,4.5,1.6-.4-4.2,1.5-5l.5,5c3,1.3,5.8-2,7.5-2s.3,1.7,1.2,2c4.3,1.5,11.6.8,15.3-1.9l2,4c-.2.2.6,1.9-.6,2.1-5.4-.8-10.2,1.9-15.4,2.9-.1.5-.3,1-.5,1.5-.8,1.4-22.8,3.3-26.4,3.1-7.7-.4-10.2-1.3-12.1-8.6.3-.4-.2-2.2,0-3,4.4-2.9,10.1-3.4,7.2,3h1.8Z"
      style={{ fill: "#062d1a" }}
    />
    <path
      d="M283,67c4.8.4,2.7,4.8,3,5,.4.2,9,1.7,9.5,1.6,7.4-.5,19.5-1.2,26.5-2.6l1,1c3.2,2,0,4.1-3,4l-1.3,1.9c-5-1.5-8.1-.2-9.1,3.7-3.1.4-6.1,1.7-9.1,4-3.6-2.8-7.4-4-11.3-3.6l-1.2-2c-.5-2.9-3.2-4.5-5-7s.4-1.4-.2-1.8-2.8.4-2.8-.7c-.2-2.2,2.6-2.7,3-3.5Z"
      style={{ fill: "#03311b" }}
    />
    <path
      d="M280,41c-.8.2-2.7-.2-3,0s1.2,3.6,0,5c11.8-.1,6.4,2.9,9.1,7.3s3,1.2,3.9,2.3c2.5,2.9,2.8,7.7,1.9,11.3-.3.2-.3,1.1-1,1.5s-2.7-.3-4,.5l3-5c-5.7-1.2-3,4.7-7,0s-10.7-18.4-14-25c3.2,1.8,8.1,4.3,7-1,1.2,1,3,1.6,4,3Z"
      style={{ fill: "#042715" }}
    />
    <path
      d="M269,48c-3.9.6-4.3-1.3-1-3,1.2,1.7,2.3,0,1,3Z"
      style={{ fill: "#05311a" }}
    />
    <path
      d="M267,44h-1.4c-.9.5-.4,1.8-1.6,2,.2-1.7.4-3.4,1-5,.2.4,1.5,2.3,2,3Z"
      style={{ fill: "#05311a" }}
    />
    <path
      d="M274,16c1,.1,3.8-.2,4,0,.3.4.1,8.1,2,10h-1c-2-4-6.3-5-5-10Z"
      style={{ fill: "#042b12" }}
    />
    <path
      d="M262,382c2.7-.6,2.8,0,1,2,2.1,1.2,2.7,3.6,1.8,7.4,3,2,8.1,6.9,3.2,8.6-5.4.2-4.1.5,4,1,1.7.8,1.9,1.6,0,2-1,.6-1.3,1.3-1,2,1.4-2.4,2.4-1.4,3,3,1.1,1.3.7,2.4-1.5,3.2,2.9,2.9,1.7,7.4-2.7,6.8l.6,5.6c2.4,3.8,2.7,6.6.6,8.4-5,0-10,0-15,0-.3,5.5.4,11.1,0,16.5s-2.5,1-4,1.5c-4.2-.4-5.4-3.3-5.3-7.4-2.4,1.8-5,.9-7.7-2.6-1.3-5.6-1.7-11.3-1.1-17.1l2.1-.9c-4.2-2.5.6-7.3,3.8-9-.3-2.9.3-5.2,1.9-6.8-2.6-3.7-2.7-6.8,1.6-9.1l1.8-3.6c-6.3-4.7-3.8-13.4,5-11.7-1.4-11.3,6.5-9.1,7.9.2Z"
      style={{ fill: "#172317" }}
    />
    <path
      d="M239,440c.2.2,1.6-.3,2.2,0,.9.6,1,1.9,1.4,2,2.2.3,3.9-2.7,6.4-2,.2,2-.5,4.6.2,6.4s2.3.4,2.8,3.6.3,9.8.9,13.5,2.9-.1,3.1,1.1c.4,2,.4,14.9.9,15.5s3-.2,4.3.2c5.3,1.6,2.9,17.3,2.7,21.8-6.8,0-10.8-1.7-14.5,5-3.1-5.2-8.9-6.6-14.3-8.8s-2-2.1-2.2-2.2c-.2-4.3,3.7-6,11.7-5,2.3.2,2.3-11.5,2-12.6-8.8-4.6-8.4-19.8-7.7-28.8l2.1-1.6c.2-2.6-.1-4.2-1-5-2.8.3-3.3-1.4-1-3Z"
      style={{ fill: "#1a241a" }}
    />
    <path
      d="M274,408c.5-.7,1.2-1.3,2-1.2s9.1,9.8,9.9,12.2l3.4,2.3c-.5,5.4,3.5,11.4,7,15.8,4.8.8,11.5,6.9,7.7,10.8-4.7,0-5.7-5-8.4-7.6s-5.4-4-7.6-6.4.6-1.8-.6-2.1c-3.1-.7-12.4,0-16.4,0,.1-3.2-2.2-5.7-2.8-8.7-2-10.8,2.6-6.2,3.8-7.3s0-2.9,0-3c-.4-.5-3.9,1.3-3-2l5-3Z"
      style={{ fill: "#192419" }}
    />
    <path
      d="M275,402c5.1,2.3,11.1,7.4,14.6,12.1,6.3-1.4,9.8.2,10.4,4.6-1.1,11.7.6,15.4,5.1,11.3,4-.5,30.7-.3,32.1.8s1.5,6.4-1,7.2c2.2,1.8,2.8,2.7,0,4,2.3,2.8,2.9,6.4,1.6,10.9,3.9-.4,6.7.6,8.4,3.1,2-2.7,7.3-2.5,7.9,1.3-.4,5.3,1,10.2,4.1,14.7l3.7,8.2c-.1,13.2,10.8,20.3,22.3,23.8,1-2.7,2.1-2.7,3,0-4.1,4.6-10.3,1.1-14.6,0s-4.2.2-4.4,0,.5-2.9-.1-3.9-8.5-2.5-9.9-3.6-2.5-12.5-3.7-15.3-2.1-1.1-2.3-1.9c-.4-1.8.5-6.6,0-7.3s-2.4.2-2.9,0c-4.1-.3-8.2-1.9-12.2-4.9-8.7,1.1-12.3-3.2-17.2-9.6-6.7-.5-11.9-2.6-15.6-6.5,0-1,.2-2.1,0-3-.3-1.8-2.3-6-3.5-7s-3.6-1-5.6-2.4c-3.6-2.8-4.9-10.2-8.9-13.1-.5-2.4,2.6-.6,2-3-5.6-1.8-4.1-5.1-7.5-8.5l-2.5,2c1.2-2.9-1.5-6.9-4-8s-2.7.3-3,0,.7-1.5.7-2.4-.7-.6-.7-.6c-1.6-.8-1.2-1.5,1-2,0-.6,0-1.3,0-2,.8-1.9,2.6-.8,3,1Z"
      style={{ fill: "#19261a" }}
    />
    <path
      d="M436,472c.3,4.8-1.2,9.7-6,11.5s-4,.1-5.1.9c-2,1.4,1.5,3-3.9,3.5s-4.3-.4-4.9.2.2,1.9-.3,2.6c-.8.9-18.5,9.3-21.1,10.5-3.8,1.7-2,2.3-7.7,2.8-.7-2.5.7-4.7,4.2-6.7,6-2.5,11-5.9,15.2-10.1,6-1.6,9.8-3.8,14.7-7.2,2.7-2.1,5.7-4.1,9.1-5.9-.7-4.2,4.7-6,5.9-2.1Z"
      style={{ fill: "#192818" }}
    />
    <path
      d="M349,472c-1.9,1.2-6.3,2.2-8.4,2-3.1-.4-4.5-3.7-5.3-3.9-4.2-1.2-10.8-.9-15.3-1,2.1-15.9-3.6-11.7-16-12-.1-2,.2-4,0-6,4-.5,5.8,3.3,9.2,4.3s4.6.3,6.8,1.2c2.9,1.2,7.1,7,9.1,7.9s5.6,0,8.3.7,9,5.4,11.7,6.8Z"
      style={{ fill: "#192419" }}
    />
    <path
      d="M144,468c1.5-1.6,4-1.1,7.4,1.7h3c6.4-5.7,8-5.3,15.8-.9,8.6-1.3,18.4,3,21.8,11.1,6.3,2.9,11.9,7.5,16.5,13.6l-.5,2.4c3,1.1,9.9,6.1,5,9-.7-.2-1.3-.7-2-1-4.5-2-5.7-2.5-9.9-5.6-4-3-3.6-1.4-5.9-2.6s-6-9.3-6.3-10.7c-4.2.4-6.8,2.8-11.4,1.9s-4.3-3.6-6.6-4.4-6.5-.5-9.9-2.6c-1.1.8-2.3.5-3.3-1-4.4,3.3-18.5-7.5-13.7-11Z"
      style={{ fill: "#162518" }}
    />
    <path
      d="M123,407l2.1.7,1.9,13.8c3,1.5,4,4.4,3,8.7,1.5.5,2.2.9,2.1,2.8l1.9,1.7c.9,7.2,2.9,16.1,6.1,22.1l.4,5.1c3.1,1,6.5,3,3.6,6,.2,1-.5,2.6.2,3.8s8.3,6.5,8.8,6.8c1.4.6,3-2,5.3-1.4s2.2,2.6,2.7,2.9c.2,7.2-14.7-3.3-16.3-4.9s-4.4-6.4-4.9-7.4c-1.3-2.3-2.8-8.1-3.3-8.7-1.1-1.4-3.4-.5-4.3-1.2s-.7-2.7-1.7-3.8-2.4-.9-2.5-1c-2.6-3.4-3.5-9.5-4-13-1.8-2.2-3.1-8.8,0-10l-1-5c-3.5-3.1-4.9-14.9-1-18,.3-.9.6-.9,1,0Z"
      style={{ fill: "#152419" }}
    />
    <path
      d="M220,397c3.1-2.4,4.4-.8,4,5,3.3.8,3,12.6,0,14,.5-1.6,1.8-2.2,4-1.7-.7-9,7-10.6,6,0l1.6,1.5c-.3,3.5.4,10,1.1,13.9s.5.3,1,.4c.6,3.1,1,6.5,1.3,9.9.2.8.7,1.9,1,3,.9.7,1.3,2.4,1,5-.3,4.9-.2,20.8,1.9,24.6s5.2,3,5.6,5.4-.4,11.5-.6,12.6c-1,5.5-12.7-.4-15,5.5-.2-.1-.7,0-1,0l-2.1-.8.3-13.2c-3.8.3-5.9-.7-6.2-3.1-2.8-1.6-2.8-4.4,0-6-1.9-.6-1.9-1.4,0-2l-1.3-1.4c.6-7.8-.9-14.8-4.4-21-1.2-11.3-5.1-20.2-11.2-29.9l1-2.7c1.2-1.3,3.2-1.2,5.7.2l-1.4-9.2c-.3-4.3.6-12,5.7-11,1.2-1.7,2.1-1.1,2,1Z"
      style={{ fill: "#1b281b" }}
    />
    <path
      d="M224,479c1.5,1.2,7.7.7,8,1,.6.6-.4,13.6,0,16-.8-.2-2,0-3,0l-1.4,1.9c-8.2,2.4-14.7,1.8-19.6-1.9-4.2-4.7-11.6-12.2-16-16,.6-2.4,3.3-2.4,8,0,1.2-4.1,3.7-2.1,5.6.9,3.5.7,5.3,1.3,4.4,5.3.6,1.6,13.9-2.1,12.1-5.9l2-1.3Z"
      style={{ fill: "#172e19" }}
    />
    <path
      d="M229,496c1.5,7.5-4.9,11.2-11.6,11s-3.1-1.6-4.4-2c.6-3.9-2.9-5.1-4.4-7.1s-.4-1.8-.6-1.9c2.5-1.3,6.2,2,7.3,1.8,5-.8,7.1-2.2,13.7-1.8Z"
      style={{ fill: "#1a2918" }}
    />
    <path
      d="M449,459c2.5-1.3,4.8-1.3,4,2-1.5.9,1.8,2.4-5,3s-4.6-.5-5.8.2-.6,1.7-1.2,2.3c-.9.8-2.6,1.3-2.8,1.7-1.1,2,1.4,3.2-2.2,3.8s-3.8-.2-4,0c-.3.4.5,3.1-.1,3.9s-2.3.2-3.4,1.1c-3.7,2.9.7,2.7-7.4,3.1-1.8,2.2-3.6,2.8-5,0-4.1-.6-1.5-7.9,2-5,3.3-1.9,6.2-4.3,8.5-7.1.6-3.3,3-7.2,5.7-8.9s15.1-2.5,16.8,0Z"
      style={{ fill: "#162d16" }}
    />
    <path
      d="M128,320c5.9-5.3,5.5,8.1,2,10-.2.9-.6.8-1,0-1.8,1.6-2.2,3.1-1.3,4.3,3.9,2.8,2.5,6.8-.4,9.9,4.3,4,1.4,7.1-3.6,6.9.2,2.1.2,3.9-1.9,4.8,2.7,2.3,4.1,9.6.2,11,.4,2.8-.1,5-1.7,7.4,1.5,1,2.3,10.8,1.7,12.3l-1.9,1.5c.3,5.3-12.2,13.2-15,8,0-.1-1.1-1.3-1-2,.7-.4.9-.6,2-1,.9-2.3,2.1-4.7,3.6-7.1-1.5.4-3.7.3-4.8-.8s-2.6-12.1-2.3-12.6c.7-1.2,2.1-1.6,3.4-1.6-1.1-4.5-.9-9.4,4.7-10.1-2.4-4.6-2.5-8.3-.3-11.2-1.4-3.4,1.1-5.7,4.2-4.2-.9-2.8,0-5.2,2.7-7-.8-3.9.3-5.5,3.4-4.7l1.6-5.5c-.5-4.9,1-14.3,5.8-8.2Z"
      style={{ fill: "#0e2216" }}
    />
    <path
      d="M120,388c.5,0,.9,0,1.3,0,.7.2,1.7,13.5,1.6,14.2-1.1.5-1.4,2.1-.9,4.7-.7,6.1.6,12.1,1,18l1,5c.3,3-.7,5.3,0,10-3.2.4-2-4.1-3.2-6.3s-2.8-1.4-3.4-3.6c-1.2-4.1-.8-9.9-1.4-14.1s-7.8-2.4-11-5.5c5.6-.3,7.7-.5,7.1-7s-4-3.3-8.1-3.5c-.5-1.4,1-3.2,1-4,5.9.7,11.1-4.2,15-8Z"
      style={{ fill: "#112217" }}
    />
    <path
      d="M472,434c0,3.3.6,4.4,0,8s-1.4,7.5-3.9,9.6-4,1.2-5.7,2.3c-3.4,2.3-4.9,4.3-9.4,7.1-1.2-.9-2.4-1.9-4-2-.9-.3-.9-.6,0-1l.8-2.2c3.4-1.3,6.5-3.3,9.2-6.1,8.7-.8,9.9-6.1,11-13.7-.9-.5-.7-1.3,0-2,.3-2.7,1.2-2.5,2,0Z"
      style={{ fill: "#1c2b1b" }}
    />
    <path
      d="M548,312c1.5,1.5,1.2,3.6-.8,6.3l.8,1.7c-.5,1.3-4.5,1.5-6,2-4.7-.8-7.2,1.4-7.5,6.6-6,4-9.2,9.8-11.6,16.5l-2.9.9c-.9,1.3-4.1,3.4-4.5,4s-3.4,9.4-3.5,10c-.9.2-2.4-.3-3,0-2.2-1.6-1.9-4.2,1-8,1.3-6,4.6-11.3,10-16l6.5-9.6c1.5-5,4.9-10.2,10.5-7.4.5-1.5,2.1-2.2,5-2l2.3-2.1c.5-3.3,2-7.5,3.7-2.9Z"
      style={{ fill: "#0f2415" }}
    />
    <path
      d="M542,322c-1.5.4-4.7,1-4.9,1.1s-.7,3.8-1.3,5.2-4.7,7.1-5.1,7.4c-.8.8-3.3.5-4.3,2.3-1.5,2.7,2.4,4.8,1.6,6s-2.8,2-3,2c-1.2.4-3.5-.2-5,0,3.6-5.4,6.1-13.4,11.3-17.7s2.2,0,2.5-.4c1.4-1.6-2.4-7.9,8.2-5.9Z"
      style={{ fill: "#0c1e12" }}
    />
    <path
      d="M552,304c0-2.9,3.3-2.8,4,0-.7,1.7-1.3,3.4-2,5-1.7,4-4.4,7.1-6,11h-4c3.1-2.7,2.9-5,4-8-.2-2,.9-12.1,3.3-9.4s.5.8.7,1.4Z"
      style={{ fill: "#0b1f10" }}
    />
    <path
      d="M498,370c-.4.6-1.8,1.5-2,2,2.1.7,2.1,2.2,0,3-4.2.2-6.7,5.9-9.5,8-7,5.5-14.4,8.8-21.7,13.1-11,6.5,1,16.6,5.2,23.9,0,.7,0,1.3,0,2-1.2,1.6-1.6,1.6-3,.8s-5.3-7.2-6-8.3-.2-.9,0-1.5c-9.2-3.6-5.7-16.7,3-19,1.9-3.7,8-8.1,12-10,6.5-4.1,12.5-9.2,18.1-15.3l1.9,1.3c.4-2.7,1.2-2.6,2,0Z"
      style={{ fill: "#122c17" }}
    />
    <path
      d="M496,372c3.9-.5,3.1.5,3,4-1,.3-2.5-1-3-1,.2-.9-.2-2.3,0-3Z"
      style={{ fill: "#1a2e20" }}
    />
    <path
      d="M509,360c-1.5.6-4.2,4.7-5.3,5.7s-4,1.2-5.4,2.6-.1,1.5-.3,1.7c-.6.1-1.6-.2-2,0-2.9,0-2.7-3.2,0-4,2.5-4.1,7.8-10.2,11.5-13.7s.9-.3,1.5-.2c.3-.9.6-.9,1,0l.6.4-1.6,7.6Z"
      style={{ fill: "#142d1b" }}
    />
    <path
      d="M461,413c.3.4,2,.4,2.7,1.3,1.5,1.9,2.3,7.4,6.3,7.7,0,1.3,0,2.7,0,4,1.7.4.3-1.5,2-2,.3,3.1,0,6.8,0,10h-2c-2.7-5.8-6.2-11.4-10.5-16.8l.5-2.2c-.7-1.7-1.1-2.5,1-2Z"
      style={{ fill: "#113116" }}
    />
    <path
      d="M568,264c.6.2,1.4-.1,2,.4,1,1,2.3,11.9,1.9,14-.6,3.5-4.9,7.9-8,9.6-7.2.1-2.7,1.5-4,7.3s-1.8.7-2.3,1.9c-.8,2-.7,4.4-1.7,6.8h-4c-2.4-.7-2.1-3.2.9-7.6-1.6-11.4-1-18.6,13.3-17.9-.7-3.3-.4-6.1,1-8.6-3-1.7-6.9-6.1-2.1-7.9.8-1.3,1.8-.6,3,2Z"
      style={{ fill: "#062310" }}
    />
    <path
      d="M563,256c5.7.3,3.9,3.8,5,8-2.3-.7-1.2-.8-3-2-3,1.7-6.8-2.1-5-5,.5-1.2,1.9-1.1,3-1Z"
      style={{ fill: "#05220e" }}
    />
    <path
      d="M547,224h3c-1.1,1.5-5.9,6.6-6,7-.3,1.1.3,2.9,0,4.3s-3,.5-2,4.1,1.6-.5,2,3.7c2.8,1.2,2.7,3.7,0,5,1.9,3.5.2,4.2-4.9,2l-3.1,4c2,3.2.7,5.2-2,2-1.5.9-2.2-.1-2-3-2.7,1.2-3.2-3-1-4-1.3-9.2,6.9-18.3,13.3-24.8l2.7-.2Z"
      style={{ fill: "#03310e" }}
    />
    <path
      d="M549,244c3.1,2.1,1.8,6.6,2.1,6.9,1.7,1.4,6.6,4,8.9,6.1s1,2.2,5,5c-1.7,5.1,4.2,6,5,7,1.5,1.9-1.8,1.9-2,2.6-.5,1.8.6,7.7,0,8.3-1.8,1.8-11.1-1.6-13.4,5.1-1.4,4.1,0,8.5-.5,12.5s-1.8,1.3-2.1,2.1c-.3,1.3.3,3.8,0,4.3s-1.8-.4-2,.7c-.9,3.6-.7,3.8-2,7.3-2.6.2-2.8-5.9-1-7,1.2-4.4,2.1-8.7,2.8-12.9-1.4-1.1-2.2-2.2-2.4-4.1s3.1-13.8,3.6-14.3c3.4-.5,7-.9,10.7-1.2-2.3-3.9-4.6-7.6-6.9-11l.3-1.4c-1.3.8-3.2,0-5.6-2.2-3.1,0-4-2.2-4.4-5.3-1.4-.7-1.8-2.2-1-4.6-.3-1.5.2-3.4,0-5,1.3.2,4.1.4,5,1Z"
      style={{ fill: "#082912" }}
    />
    <path
      d="M552,216c-1.5,2-3.8,6.3-5,8-4.5,6.1-11.5,12.1-14.9,19.8s-.9,3.4-1.1,5.2c-3,.6-3.1-9,0-7.9l-.9-1.1c.2-.2-.1-1.9.6-2.9,3.5-5.4,10.4-10.9,13.8-16.8l7.7-4.3Z"
      style={{ fill: "#013c0b" }}
    />
    <path
      d="M533,224c1,0,2.1.1,3,0,.7,2.7-.7,2.5-1.8,3.7-5.1,5.1-9,8.2-8.2,16.3-.3,1.5-1,2.8-2,4-.9,1.2-1.6.5-2-2-2.9-1.3-2.9-4.7,0-6-3.1-1.9,1.7-10.4,4.1-12.6-1.1-4.8,5.1-7.8,6.9-3.4Z"
      style={{ fill: "#02530c" }}
    />
    <path
      d="M530,240c4-.3.5.9,0,3.5s.6,3.8,1,5.5.8,3.6,1,4c1.6,3,1.2,4-1,3-1.1,2.3-2.1,1.6-3-2-1.8-.6-2-3.7,0-4,.4-2.7-.7-6.9,0-9.3s1.9-.6,2-.7Z"
      style={{ fill: "#033e12" }}
    />
    <path
      d="M526,244c.1,1.1,1.9,4.9,2,6s0,2.7,0,4c1.6,1.5,1.6,2.5,0,3-3.2,2.3-5.5-6.5-4-9,.1-2.5-1-4.6,2-4Z"
      style={{ fill: "#024910" }}
    />
    <path
      d="M538,216c-.1.2,0,1.1-.4,1.6-1.8,2.1-4.7,2.9-4.6,6.4-1.1,0-4.8-.2-5,0-.3.3.3,3.1,0,4.3s-2,1-2.4,1.7c-1.1,1.8-3.5,8-3.6,10,0,2.9-3.3,2.8-4,0-1-2.9,2-11.4,3.8-14.6s5.2-6.5,10.2-6.4c1.2-.9,3-2,4-3h2Z"
      style={{ fill: "#02630d" }}
    />
    <path
      d="M572,120c.3,1.1,1.1,1.5,2,2-.1,2.1-5.9,8.2-6,8.7-.3,1.2.4,2.9-.2,4.1-1.1,2.1-7.9,2.9-9.7,3.7s-3.5,4.8-4.3,5.3-5.4-1.1-6.8-.3-2.2,3.2-3.3,4.1c-2.3,1.8-7,1.5-8.7,4.3-1.7,2.8-5.4,2.8-7,0-5.4-1.5,4.4-6.7,8-6.8,1.8-2.4,2.9-3.6,6-3.3,0-2.8,1.5-3.1,4-2.9.2,0,1.8-.8,2-1-.5,2.8,1.5,2.6,3,1.5s-.1-3.3,0-3.5,2.3-.1,3.5-1c2.9-2.1,5.2-6.6,5.6-6.9.6-.6,2.6.2,3.6-.4s7.9-7.3,8.2-7.8Z"
      style={{ fill: "#017921" }}
    />
    <path
      d="M535,152c1.3,0,2.2.9,3,1-2.1,3.1-7.7,3.1-8,7-.2,2-1.2,1.9-2,0-.4,3-5.4,2.8-6,0-2.3.2-2.3-.8,0-3v-2.3c2.5-1,4.6-1.9,6-2.7,2.3,0,4.7-.1,7,0Z"
      style={{ fill: "#017f28" }}
    />
    <path
      d="M544,160c2.2.2,5.5-.5,7.3,0s3.4,4.3,3.5,4.7c.3,1.1-1.1,7.5-1.5,9.1s-3.4,7.6-4.3,9.2c-2.1,3.8-4.7,6.2-6.6,9.4-.7,1.1-.3,2.7-.9,3.6-1,1.5-4,1.1-5.3,2.3-1.8,1.7-4.4,9-8.2,12.8-.4-.3-.4-.6,0-1-6.4-3.4,2.8-13.7,7.3-16l1.7-2c.3-.9.7-.9,1,0,3.2-5.2,5.8-10.7,7.9-16.3,3.8-1.2,2.9-7.6.7-10-3.3-.5-5.6-3.6-2.6-5.7Z"
      style={{ fill: "#028823" }}
    />
    <path
      d="M544,160l1.1,3.6c5.3-1,5.8,8,4.9,11.7s-2.6,1.1-3.5,2.7c-2.4,4.5-1.8,5.9-5.4,10.6s.4,2.8-3.1,3.4c-3.4-.5.3-8.4,1.9-10.8,5.9-3.6,1.5-9.3-1.9-13.2-1.4.2-2.6-.7-3.7-2.7-2.9.4-5,0-6.3-1.3-2.9,0-2.8-3.3,0-4,.7,0,1.3,0,2,0,5.8-.4,8.4-.5,14,0Z"
      style={{ fill: "#018e27" }}
    />
    <path
      d="M536,208c1.2,4.6-1.8,3.8-2,4-1.2,1.6-2.6,4.3-4,6-9.3,4.5-11.8,14.3-13.9,24.2.7,2.1.4,3.3-1.1,3.8-1.4,1.9-2.4,1.3-3-2-3.5-6.7-2.3-11.2.4-18s4.4-9.8,7.6-10c2.6-.2,5.4.3,8,0s1.7-1.3,3.5-3,2.4-.8,2.5-1c.4-.4,0-2.4,2-4Z"
      style={{ fill: "#027915" }}
    />
    <path
      d="M530,218c.1,0,1.3,1.1,2,1-1.5,1.1-3.2,1.1-4.5,2-3.8,2.7-4,6.5-5.6,10.2s-3.7,7.4-3.9,8.8c2.8,1,3,7.3,0,8-.6.1-1.3.1-2,0-2.6.3-2.9-.2-1-2,.2-1.7-1.2-3.9-1-5.4s1.9-.5,2-1.3c.6-2.8,1.5-11,2.5-13.3s3.5-3.3,3.7-5.9c3.1.6,5.5-1.9,7.8-2.2Z"
      style={{ fill: "#026f11" }}
    />
    <path
      d="M506,203c3.3,3.3,6.7,6.6,10.4,9.8,2-.2,3.2.6,3.6,2.2v1c-1.7,4.4-7.3,12.3-7.9,16.6s0,7.8,0,11.4c1.3,1.3,1.3,1.9,0,2-2.1.3-3.4-1.7-4-6-3.2-1.8-3.2-14,0-16-4.2,7.3-8-5-5.2-3.9s-.1,1.4-.8-1.1c-2.4-3.8.3-13,4-16Z"
      style={{ fill: "#018415" }}
    />
    <path
      d="M508,192l2,1.2c-.2,8.3.6,13.5,10,14l3.8,2.9c1.7-.8,3.1-.9,4.2-.1v1c-.6.6-.8,2-2,2.5s-5.7,1.5-6,1.5c-8,.5-6.7-3.6-10-6.3s-2.6.2-4-5.7c-3.3-1-3.4-8.4-2-11,0-2.9,3.3-2.8,4,0Z"
      style={{ fill: "#028a1b" }}
    />
    <path
      d="M49,143l4-.5c-.1,1.5.7,9,1.1,9.4,1.1,1,4.6-1.8,5.5,4.8s1.1,3.1,1.3,4c.5,2.3-.6,5.4.2,7.7s2.3,1.3,2.9,1.6c2.6,4.4,2.6,7.7,0,10,0,1.3-.1,5.7-1.5,6l-5.5-2c0-.1-1.9-.8-1-2s3.5-.3,3-2.5-6.6-3,0-6c-.6-.4-2.8-2.5-3-2.5,3.4-7.8-3.9-9.8-5.4-14s-2.4-7.4-2.6-8.6c-.5-2.6,1-4.5,1-5.4Z"
      style={{ fill: "#092110" }}
    />
    <path
      d="M56,193h1c2.6,3-1.3,5.2-2,2.5s1-2,1-2.5Z"
      style={{ fill: "#132818" }}
    />
    <path
      d="M142,123c3.2-.2,2.4.5.3,1.7-3.3,1.9-.8,3,1.7,1.3-.4,2.2.8,7.3-.3,8.8s-1.6.1-1.7.2c-.2.2,1.8,2.6,2.1,5.4s-2.5,11.1-2.1,11.6c.8.8,6.5-1.3,7,6.5s-1.4,1.8-5,1.5c-1.5,2.8-6.6,3-8,0-2.1,3.5-5.2-.8-2-3-.6-3-.8-9.6,0-12.4s2.1-.2,2.8-.8.6-2.2,1.2-3.1,2-.7,2-1.2c.6-4.2-3.7-2.8-4.1-4.1-1.4-4.7,1-9.1,4.4-12s1.5-.2,1.6-.3Z"
      style={{ fill: "#0b1c11" }}
    />
    <path
      d="M134,157c.9,4.8,1.6,2.3,2,3l2.2.6c.8.7.1,19.7-1.2,21.4,0,.9-.3,2.2-1.3,3.6,2,3.3,1.8,5.4-.7,6.4-.5,2.4-6,2-7,0-3.5-3.8-2.5-11.1,0-15,0-1,.3-3.5-.1-3.9s-5.4,0-2.9-2.1,2.3.7,3-.6c2.4-4.2-.1-9.2,0-12.9s1-3,1-3.5l5,3Z"
      style={{ fill: "#111a10" }}
    />
    <path
      d="M110,157c-.5,3.5,3.6,2.6,4,3,1.8,1.7-1.9,2-2,2.6-.2,1-.2,2.9,0,3.9.3,2.5,2.2.6,2.1,2.4l-2,2h8s-6,5-6,5c8-2.6,5.7,1.3,6.2,1.8,1.6,1.7,6.9,2.7,7.8-.8.3,5-.2,10,0,15-.3.6-.7,1.1-1.1,1.3-1.7.5-15.3-1.8-14.9-5.3-.4-4.7-4.7-10.7-5.8-16.7s.3-6.8-.2-9.5-1.5-1.1-2-1.7c2.2.9,5.2-1.1,6-3Z"
      style={{ fill: "#131812" }}
    />
    <path
      d="M109,287c2.4-3.9,7.6.7,3,3-.9.6-.9,1.3,0,2,1.3,2.3,3.4,2.1,6.5-.7l5.5,1.7c2.9.7,2.5,1.6,0,3l.9,1.9c-2.1,6.8-1.5,9.4-.2,15.5s-1.6,11.4-7.7,6.6c-.6,3-4.5,2.2-6.3.4-1.3-3.1-3.6-6.2-7.3-5.3-1.8,2.9-6.4,5.4-7.4.9,0-3,0-6,0-9l-7.5,5c-2.1-5.3-1.5.3-4.5,0-1.5-5.5,3.5-4.5,4-5,2.8-3.2-5.3-4.9-8-2.9,0-4.1-1.4-6.7,3-8.5-7.8-4.3.3-7.8,6-7.5,3-3.8,5.8-2.5,8.6,3.9-.4-2.3-.3-3.7,2.4-3.9,3.8,0,5.9-.7,9-1Z"
      style={{ fill: "#0b2012" }}
    />
    <path
      d="M86,256c2.7,0,5.3,0,8,0,.5,4.5-4.5,11.6-.5,15.1s2.5-.2,2.5-.1c.9,1.5-.7,16.2,0,17,1.3,0,2.7,0,4,0-1.9,4.7,1.2,8.3-5,9l1-6-5,5c-1.1-2.2,4.2-6.9,3-8s-2.4,0-3.3,1.2c-2.1,2.8-1,7.6-5.7,3.3,0-3.4,4.9-1.1,4-4.5s-3.9-4-4.5-4c-1.5.1-.6,6-5.5,4,2.3-6-1-9-7-7,1.1-4.4-2.6-2.6-3-3s-.1-2.9,0-3c.5-.4,4.1,1.5,3-3,2.1,0,.2,3.4,1.9,4.6,5,3.6,7.3-6.4,3.9-8.4s-10.3,4.3-13.8,2.8c.8-2.5-.6-6.3-3-7,4.6,1.1,2.9-3.2,3-7,6.9-1.6,14.8-1.2,22-1Z"
      style={{ fill: "#081e0f" }}
    />
    <path
      d="M103,258c0-.8,2.7-.7-.5-1s-3.2.2-3.5,0,2.2-3.1,0-4.5-9.4,2.8-7.5-4.5c9.9,0,2-2.3-.7-5s-.5-2.7-.1-3.4.9-5.4,2.3-1.9-1.4,1.8,2,2.3c.9-2.8,7.2-2.9,8,0,1.9.4,2.1,2.2.7,5.5,4.4,2,7.1,5.9,8.2,11.9,5,6.6,7.4,13.8,7.2,21.5,2,1.5,7.5,10.4,6.7,12.3s-1,1.4-1.8,1.8l-3.5,3c-.8-7.2-4.5,1.6-8.5,1,0-1.7.1-3.3,0-5-2-.2-1.9-1.2,0-2,0-1,0-2,0-3-1.2,1.2-2.7,0-3,0-.5-3.8-2.5-5.8,2-7.5-2.1-.8-6.7-8.1-7.2-8.4-1.5-.9-5.1,2.6-6.8-.6.4-4.4,4.4-.6,6-1.9s2.2-6.2,1.9-6.6l-4,1c1.7,2.3.8,4.5-1.9,2.8s-3.1-7.4-3.1-8.8l3,5c1.8.6,1.8-.9,2-1,.3-1,1-2,2-3Z"
      style={{ fill: "#082210" }}
    />
    <path
      d="M64,170c4.4,3.1,1.1,4.5,6,7,3.7-2.6,5,.9,4.9,4.6,2.1.3,2.8.7,4.1,2.2s4.2,12,4,14.2c1.1,1.5,2.8,2.8,5.1,3.8.2,7.9,2.8,13,7.4,19.6l.4,2.6c-1.3,4.2-.8,5.1,3.4,3.1,3.3,0,10,11.9,4.6,13-.2.9-.5.9-1,0-2.5,0-5.6.3-8,0,1.9-6-6.7-5.2-7.8-8.7s-1.3-10.2-1.2-10.7c.2-.9,3.2-.2,2.1-2.6-4.3-.4-8.5-5.8-10-9.5s1.9-.4,2-1c1.2-3.6-5-2.6-6-7s1.9-.3,2-.5c.2-.3-3.2-3.5-1-6-.3-.3-2.3,1.9-3.5,0v-4s-7.5-10.1-7.5-10.1c.2-3.3-.1-6.7,0-10Z"
      style={{ fill: "#08250f" }}
    />
    <path
      d="M107,287c-.4.3-3.7.6-3-1.5,1.1-3.1,4,.5,3,1.5Z"
      style={{ fill: "#122b1c" }}
    />
    <path
      d="M103,258c0,.4,1.1,1.3.5,2.1s-1.8.6-2.5.9c0-2.4-.7-3.5,2-3Z"
      style={{ fill: "#102c1a" }}
    />
    <path d="M88,239l.5-4c2.2,1.5,1.9,3.1-.5,4Z" style={{ fill: "#082210" }} />
    <path
      d="M64,192c-.3,1.4-1.6,2.3-3,2v-2s3,0,3,0Z"
      style={{ fill: "#091d0d" }}
    />
    <path
      d="M96,316c4.5,1.8,5.4-2.5,6.6-2.8s5.3.2,7.4-1.2c-2.9,5.9,1.2,3.2,1.9,4,1.9,2.1-3.9,3.8,5.1,4,4.1,1.8,0,6-2.8,7.9,2.3,1.2,2.6,3.6.7,5.4,5.1.2,3.2,7.7-.9,7,.9,5.5-2.6,7.5-10.5,5.8,4.6,3.6,5.3,16.4-3,12.5-1.1-4.7-1.7-4.3-1.5.4-.8,1.9-12.4,4.4-13,1-1.6.1-3.3-.1-5,0-3.8-4.7-10.2,1-11-7,4.7-2.2,1.6-5.7,2-6s2.7.7,4,.4c3.4-.9,4.4-6.2,8-6.4.2-1.8-2.6-.7-3.6-1.9-3.3-4.2,3.2-2.7,3.6-3.1s-.7-5.5.4-7.1,4.2-.6,5.1-1.9-.4-5.5,1.4-6.6c3.6-2.3,4.9,3,5.1-4.4Z"
      style={{ fill: "#092112" }}
    />
    <path
      d="M126,297l1,.6c-.1,4.2-.2,8.5-.3,12.7,2.8,1.5,4.2,4.7,1.2,6.7,1.9.9,1.9,2.1,0,3-1.2-.2-2.7.1-4,0,.9,7.1.3,12.1-3.5,18l-1-3c-2.1,1.3-1,3.8-1.6,5.3s-1.9.7-2,1.4c-.2,1.3,2.5,8.3-1,6.8l-3-2.5c-.5,2.6.6,4,2,6h-4s4,13,4,13c-2.6,1.1-1.8-2-2.5-2-7.2-.8-2.6,9.2-3.5,10-7.4-.7-2.8,2.2-2.1,5.5s0,3.7,0,5.5c8.3,1.3,3-6.3,3-8.5s1.6-2.1,2.5-.5,1.1,8.4,1,9.6c-.2,2.2-2.2,3-3,4.5s-.3,3.5-.6,3.9-2.3-.2-2.9,0c2.7-8.4-5.1-.1-6-1,2.4-7.2-.1-3.7-2.5-6-4-3.8,1.6-18.2-4.2-19.8s-5.9,1.4-7.3-6.2c1.7-.1,10.5-1.2,11-1.5,2.3-1.2-3.8-4.1-1-6.5s3,2.1,4.3,1.9,1.8-2.5,3.7-1.9c.8,2.3-1.5,3.4-2,4.5-.9,2.3,2.8,2.5,2.9-2.1s-1.9-8.7-2.9-3.9-3.7,1.8-4,1.5.3-2.3,0-3.2-3.3-1.3-2-4.8c3.2-.9,2.3,3.3,3,4,2.2,2,2.7-3.8,3-4s8.6.7,9.9,1v-9c2.7-1.1,2.4,2.9,3.5,3s2.1-2.2,1.5-3-4-.6-5-2.5c-1-1.8,3.8-1.4,2-3.5s-5.5,1.4-5-1.5c3.2-2.2,8.2-3.6,8-8.5,2,0,4,0,6,0,.8-4.5-1.5-9.2-1.1-13.5s1.9-5.6,2.1-10.5c1.9-1.9,2.4-1.6,2,1Z"
      style={{ fill: "#0c2115" }}
    />
    <path
      d="M54,320c3.3-.4,1.1-2.5,3.5-2s1.9,3.8,6.5,2c1.3-1.3-2.6-13.2,2.5-14l-1.5,6c4.9,1.4,4.6-1.5,5.1-1.9,2.5-1.8,2.3,4.6,4.9,2.4l-2-3.5h6s-1-4-1-4c7,1.5,2.5,4.7,2,7.5s.2,7.2,0,7.5-3-.1-4.3.2-2.6,1.6-3.4,1.6c-1.7.1-2.3-2.4-4.3-1.4-7.4,3.6-1,11.2-3.5,14.7s-4.1.6-4.5,1c-.8.7,1.6,4.8-2,4-.2-.5-1.6-.4-2-4s-.8-6.2-1-9,2-4.3-1-7Z"
      style={{ fill: "#082112" }}
    />
    <path
      d="M64,286c-1.4-3.5,5.9-8.5,7-4.5s-4.2,5.2-7,4.5Z"
      style={{ fill: "#081e0f" }}
    />
    <path d="M64,277v-5c.5-1.2,3.6,4.3,0,5Z" style={{ fill: "#081e0f" }} />
    <path
      d="M64,93c5.3-2.2,9.7,3.2,3,3-.6,1.9,1.7,2.5,1.9,3.7s-2,2.4-1.9,4.7,2.7,8.4,3,8.6,2.1-1.1,2.8-.8.2,1.1.5,1.3c2.2,1.2,7.6,4.3,6.1,6.5-7.6-2.4-2.2,1.9-2.4,4.4s-1.7,2.2-2.1,3.5c6.8-1.2,8.5,3.4,13,6,.8,2.5-1.3,1.7-1.7,2.2-1.3,1.6.1,6.8.4,8.4s-.6,2-.4,2.7c.9,3.1,4.8,4.2,7.7,3.7l-6,2.5c6.6,3.4.1,15.4,9,16.5,1.3,1.1.8,2.9-1,3,1.7.7,1.5,2.1,0,3-4.8,7.5-11.1-9.1-11.7-13.4.7-4,.7-6.3-.3-9.6-2.4-.2-2.7-.8-2-3-4.3.5-3.5-5.8-2-8-2.1-2.4-2.5-2.6-5.9-3.1-.4-3.7-1.7-7.2-3.9-10.5v-4.9c-7.1-1.8-9.4-4.4-6.9-7.8-1.4-2.2-1.9-4.4.8-5.7-.3-5.6,0-11.4,0-17Z"
      style={{ fill: "#172816" }}
    />
    <path
      d="M99,173c-1.3,2.7-2.3,2.7-3,0,0-1.1.5-2.1,1-3,.3,0,.7,0,1,0,.6.9.9,1.9,1,3Z"
      style={{ fill: "#1f2e20" }}
    />
    <path
      d="M112,188c2.6,1.4,5.8,1.4,8.3,2.1s1.9,1.6,7.7,1.9,4,.1,7,0c3.6-5.7,7.5,4.2,1,3-.4,2-1.4,2.2-3.1,1.9.5,2.3-.8,3.7-3,4.3l1.5,1.6c5.3.5,8.1,3.5,2.6,6.7,2.4,3.6,3.8,7.7,4.3,12.5,6.3-.7,9.9.6,10.7,4,3.1-1.5,4.9.2,5.5,5.1,6.3.8,9,5.9,3.5,8.9-1.2,4.1-8,2.3-6.9-2.5-2.1-.7-2.8-2.5-3.1-4.5-2.8,2.5-4.8,2.8-6,.9-3.3,2-7.1-4-7.7-7.3-3,.6-4.7-1.1-4.9-5.1-3-.5-5.9-1.3-6.4-4.8-.6-.9-5-8.4-5.1-9-.2-1.1.4-2,1.2-2.6-5.5-.3-7.4-4.7-7.2-9.3-.5-1.5.2-5.8,0-8Z"
      style={{ fill: "#151c14" }}
    />
    <path
      d="M112,196c0,.1,1.4.5,1.9,1.6s.9,3.6,1.3,4.2c2.2,2.5,11.6.6,3.8,6.2h4c-2.3,3.7.3,3.9,1.4,6.1s.5,3,1.1,3.9c1.1,1.6,4.2,1.1,5.3,2.2s1,4.6,1.2,4.8c.4.4,3.6-1.5,4-1s0,2.8.9,4.6c1.4,2.7,4.1,4,5.1,5.4,3.1,0,3.4,2.4,1,4,.3,2.2-1.2,2.9-3.5,3.8.7,3.5-.2,6.3-3.5,4.2-.9-.3-.9-.6,0-1-4.1-4-6.9-9-8.5-15-4.8-1.1-9.7-7.4-9.7-12-3.1,0-4.1-2-3.2-6.1l-2.6-4.9c-3.9-1-9.4-15.6-7.3-18.5l2.3-.5c6.3,2.2.8,8.7,5,8Z"
      style={{ fill: "#172117" }}
    />
    <path
      d="M99,173c0,.8-2.5,2.3.5,2,2.3-2.4.4-5.3,4.5-6,.6.7-4.5,6.8-1.9,12.2s2.2,1,2.4,2.6-.8,3.8-.5,4.2,2.3-.2,3,0c-2.7,6.6,4.2,14,5,19,2.8,1.1,3.5,10.4,0,12-2.9,4.4-8.7-4.6-9.6-8.4,0-4.8-.6-11.2-4.8-13.9.1-1.7,0-3.3-.6-4.7-5.5-.3-4.8-14.4-1-16,0-1,0-2,0-3h3Z"
      style={{ fill: "#142616" }}
    />
    <path
      d="M488,182c3.9,2,1.8,12.3-.4,15.2l-6.7,3.5.8,1.1c-.7,2.2-1.2,4.2-1.7,6.2,3.1,3.6,3.1,9.4,0,13l-1.7,3.4c-1.7,8.4-3.8,10.9-6.3,7.6-.3,2.7-1.2,2.5-2,0-2.2-2-1.5-4.6,2-8l-1.6-1.6c5-8.6,4.9-14.2,3.3-23.7l1.3-.6c-5-6,3-10,9.2-7.7-.8-4.3-.2-7.1,1.8-8.3h2Z"
      style={{ fill: "#025a19" }}
    />
    <path
      d="M486,168c-.3,4.6,0,9.4,0,14,0,1.6.4,9.7,0,10-2.1,2.1-11.2-5-11,6,0,2.9-2.3,2.7-3,0-1.4-.6-1.4-1.4,0-2-1.4-1.9-5.5-9.2-1.6-10.1,4.7,1.7,7.7-2.4,8.2-6.6l2.4-2.3c-2.4-3.1-.9-8.2,2-10,1-2.7,3.2-1.8,3,1Z"
      style={{ fill: "#02521a" }}
    />
    <path
      d="M544,112c-.2,1.3.1,2.7,0,4-10.2,5.7-19.6,15.7-29.4,21.6s-6.1,2.4-8.6,3.4c-2.4-.6-2.1-2.2,0-3,.6-.2,8.8-5.6,10-6.5,4.6-3.2,9.4-8.6,14.3-11.8s9.9-3.4,10.8-7.7h3Z"
      style={{ fill: "#02531b" }}
    />
    <path
      d="M506,138c0,1,0,2,0,3-.3,6.1-2.6,1.3-5.8,3.2s-.6,1.7-1.2,2.3c-.9.8-2.6,1.4-2.8,1.7-.6,1,.2,2.9-.4,3.6s-2.2,0-3.3,1.2c-1.5,1.6-6.4,12.9-6.5,15l-3-1c-.9-.2-.8-.5,0-1,.7-3.9,3-11.6,5.2-14.8s3.1-.6,3.6-1.4-.2-2.6,1.2-4.3,7.2-3.1,7.8-3.8,3-3.4,3.2-3.7c.6-.1,1.5.2,2,0Z"
      style={{ fill: "#02521f" }}
    />
    <path
      d="M492,175c1.8-1.3,2.4-.8,1,1,2.6.5,2.1,8.6,0,10-.8,2-1.1,4.6.7,6.1l-1.8,1.8c0,5.2-3.2,8.9-8.1,10.4-.4,3.5-1.3,7.9-3.8,3.7,0-1.6.4-4,0-5.3s-1.8-.6-1.9-.7c-2-2.8,8.4-5.3,9.4-6.9s.2-9.8.5-13,1.2-3.7,2-6,.6-1.2,2-1Z"
      style={{ fill: "#016019" }}
    />
    <path
      d="M520,144v3c-.3.2-.6.8-1,1v1.4c-10.2,5.1-19.9,11.6-23,23.6,2.3,1,2.3,2,0,3,0,2.9-2.3,2.7-3,0-.2-.8,0-.9-1-1,.1-2.7-.4-5.5.3-8.3s3.2-3.6,3.4-4c.7-1.5,1.2-7.4,2.5-8.5s4.5-.9,6.5-1.9c3-1.7,6.4-3.6,9.7-5.4s-.4-1.5,5.6-2.9Z"
      style={{ fill: "#026522" }}
    />
    <path
      d="M550,120c.5,2.7-.6,2-3,2,1-1.5-1.9-1.5,3-2Z"
      style={{ fill: "#026423" }}
    />
    <path
      d="M546,139c-2.9.7-1.7,4.7-2,5s-3.9-.3-5.3,0-.8,1.7-1.9,2.3-8.3,3.5-8.6,3.9,0,1.4-.2,1.8c-2.6,2-5.4,2-8,0-2.5-1.6-1.9-2.6,1-2,1.7-1.3,6.7-1.8,6.9-2.1.5-.5-.3-3.6,0-3.9s5.8.4,7.3,0,3-4.6,5.8-5.5,3.5.6,4.9.5Z"
      style={{ fill: "#017322" }}
    />
    <path
      d="M176,136c0-.2,1.7-.5,2-2.5.6-3.6-1.5-6.7,0-10.7s1.8-6,2.6-10.4,2.8-6.3,3.3-8c.9-3.7-1.2-17.7,0-19.3l4,3c.9.8,1.6,1.8,1.8,3,.4,1.8-3.6,26.5-4.4,27.7s-.9.9-1.4,1.3c-.2,4.6.6,10.3,0,14.6s-1.9,1-2.2,2.9c-.9,7.2-.9,32,0,39.1s1.8.5,2.1,5.4c2.6,2.7,3.3,7.3,0,10,1.4,1.1,2.2,3.5,0,4-2.9-1.1-4.6-4.1-4.9-8.9,0-4.6-1.1-8.2-3.1-11.1-2.4-1.9-2.4-6,0-8-3.1-10-2.8-20.7-2.2-31.1l2.2-.9Z"
      style={{ fill: "#033111" }}
    />
    <path
      d="M205,72c0,1.8,1,6.1.9,6.4-.4,1.6-3.9,1.6-5,3s-1.9,6.1-1.9,6.6c-3.8,2.1-4.7,4-3,8h-4c-3-4.3-2.9-11.7,0-16,0-1.7,0-3.3,0-5,3.3,1.2,3.1-2.3,4.6-2.8,2.2-.8,5.9.2,8.4-.2Z"
      style={{ fill: "#02390c" }}
    />
    <path
      d="M186,181c2.4.7,1.7,2.7,2.1,4.4,1.2,5.9,1,10.6,3.9,14.6h2.2c0,0,3.2,11.5,3.2,11.5,2.8,4.1,3.7,8.3,2.6,12.5.8.9,1,2.3,0,3-.5.4-.9.6-1.3.5-.7-.2-5.5-9.3-6.7-11.5-.9-.2-.9-.5,0-1-.4-2.6-1.1-4.9-2-7l-1.3-.3c-1.3-5.4-2.9-10.6-4.7-15.7-.1-3.3.2-6.7,0-10l1,2h1c0-1,0-2,0-3Z"
      style={{ fill: "#013e0f" }}
    />
    <path
      d="M192,80c0,5.3.2,10.7,0,16-.3,10.4-1.8,8.7-3.8,15.7s1.1,9.3-4.2,8.3c.4-11.3,3.5-20.3,4-32s0-1.3,0-2c-1.3-1.4-1.4-4.4,1-4,.6-1.2,1.5-2.4,3-2Z"
      style={{ fill: "#023411" }}
    />
    <path
      d="M185,169c3.7-.8,4.7,2.3,1,2-.2-.7-1.7.4-1-2Z"
      style={{ fill: "#0f4c1e" }}
    />
    <path
      d="M200,213c1.1.6,1.8,1.6,2,3,1.6,2.3,2.3,7.7-1,8-.3.9-.6.9-1,0-.5-3.6-6.4-21.9-8-24v-17c-1.6-1.8-1.4-5.3,0-7,2-1.1,2.7,0,2.1,3.2,1.9,11.4,3.9,22.7,5.9,33.8Z"
      style={{ fill: "#024b0e" }}
    />
    <path
      d="M192,136c0,2.3,0,4.7,0,7,2.4,7.4,2.5,14.6,0,22,2.4,5.3,2.4,9,0,11v7c-4-1.4-1-6-1.2-7.6s-3.6-.8-2.8-2.9c.2-.8,1.8-.2,2.1-1.2,1.2-5-1.8-11.4-2-14.9s1.8-4.3,2.1-6.9c.4-4.2-4-12.5,1.9-13.5Z"
      style={{ fill: "#044a15" }}
    />
    <path
      d="M349,75c3.5-3.7,5.9,1.2,3,5-1.1,3.8-2.2,5.8-6.2,4.7-1.9,4.7-7.1,5.4-11.7,5.3l-10.2-1c-3.4,2.3-6.4,3-8.9,2-8,10.3-19.2,7.4-28.3,0-2.7,1.6-6.1,1.1-8.7,0-4.9,1.5-5.6-3-6-3h-14.5c-3.7,0-6.3.4-9.5-3v-4c1,0,3.7-2,4-.3s-.9,2.7,1.5,2.3,2.4-2.8,2.6-2.9c.6-.4,2.2,1.7,3.4,1.6s2.1-2,3.9-1.7.7,2.1,2.3,2.9c4.3,2.2,7.1-.1,9.7.2s.6,3.2,4.1,2.1-1.9-4.7,8.5-5.1l1.4.2c-.4,6.2,6.8,8.9,11.4,5.4,3.3,2.3,4.5,2.2,8.3.9,1.5-6.9,13.5-6.8,19.2-5.6l1.5-1.8,12.3-1.8c2.6-1.9,4.9-2.6,6.9-2.3Z"
      style={{ fill: "#033e20" }}
    />
    <path
      d="M278,91c3.8-2.7,3.9-.3,6.3-1.1s2.3-3.8,3.2-3.9c2.2-.3.4,3.8.5,3.9.8.7,5.4,1.5,6.5,5.1,2.1.8.9-3,3-2s.7,3,3,3.1,8.9-2.1,9.8-2.4c1.3-.5,2.6-1.9,4.7-2.8,0,2-1.1,4.4-3,7-4.1,8.1-15.2,3-21.5-.5-3.3-.3-6.4-.8-9.5-1.5-1.1,5.2-15.7.5-9-3,.1-.1-.4-2,.5-2,1.5-.2,2.5,2.3,4,2s1.2-1.7,1.5-2Z"
      style={{ fill: "#044422" }}
    />
    <path
      d="M272,93c-1.5,4.6,5.1,3,9,3l2.2.8c3.8-.7,10.2.9,4.8,4.2l.2,2.3c-6.5.5-13.1-.6-19.8-3.4-3.7.5-7.4,1.2-11.1,2.1l-1.3-2c-2.5,2.2-9.1,0-9-4l1-4c.4.2,2.4,0,3.7.8s3.3,3,3.8,3,8.7-3.6,10-3.6,5.5,1.7,6.6.9Z"
      style={{ fill: "#024c23" }}
    />
    <path
      d="M216,100c1.9,2,.3,4-5,6l1.1,2c-3.3,7.7-7.7,14.9-12.1,22,.5.4.9.9,1.1,1.4.5,1.4-3.4,22-5.1,24.6,1.7,5.2,3.3,12.4,0,17-2.4-.1-3.7-2.8-4-8,0-7.3,0-14.7,0-22,1.9-2.4,2.9-6.7,3.8-9.7s1.7-8.8,3-12,5.4-10.8,5.8-11.2c1.1-1.2,3.2-.6,3.5-1.6.4-1.4-.6-5.7,0-6.3s6.7-1.7,7.9-2.1Z"
      style={{ fill: "#015516" }}
    />
    <path
      d="M237,94c4.1-.9,7.7,4.4,3,6l-1.7,1.8c-8.4,2.5-16.9,4.5-25.5,5.9l-1.8-1.7c.1-.2-.4-1.2.3-1.8s4.2-.7,4.7-4.2c2.9-.8,15.1-4.9,16-5,3.2-.4.7,2,5-1Z"
      style={{ fill: "#02551a" }}
    />
    <path
      d="M247,96c2.9,1.3,5,3.8,9,4,2.7.3,2.5,1.2,0,2l-1,1.7c-5.1-.7-10.1-1.3-15-1.7-1.9-.6-1.9-1.4,0-2,0-1,.3-2.4,0-3.3s-2.6-1.3-3-2.7c4.3-2.9,4.8.5,7.7,1.8s1.8,0,2.3.2Z"
      style={{ fill: "#035621" }}
    />
    <path
      d="M496,112c0,.3,0,.7,0,1-1.5,4.7-4.1,8.2-8.9,9.8-2.5,2.6-6.2,6.4-5.1,10-9.2,6.6-14.7,15.7-17.3,26.9-1.7,4.5-4,8.1-7,10.7.7,3,.2,4.8-1.8,5.6-3.8,5.8-5.8-3.8-6.2-7.7l-1.8-2.3c-.9-.2-.9-.6,0-1-3.7-2.7,1.4-6.2,4-3,3.4-3.8,6.2-7.8,8.5-12-1.2-3.9-.6-5.9,1.5-6-.8-6.7,1.1-8.2,6.7-11,1.2-2.5,2.7-4.2,4.3-5-.3-.5-.5-1-.4-1.3,2.3-5.3,6.3-13.8,12.4-11,.7-1.6,1.7-2.5,3-2.8-1.5-4.1,8.1-5.6,8-1Z"
      style={{ fill: "#042f19" }}
    />
    <path
      d="M496,114c0,1.8.5,5.8-.2,6.8s-7.4,3-7.7,3.3c-.7.8.2,3.2-.1,3.8s-3.1-.5-2,3l.7,2.1-7.7,8.1c.5,3.6-1.3,5.2-5.5,4.9l-1.3,12.7c-2.4,10.6-4.5,15.1-14.2,20.3-1,1.4-1.7.7-2-2-.9-.2-.9-.5,0-1,.1-2.1-.5-5.9,0-7.4s2.4,0,3.2-1.1.6-2.9,1.5-4.3c3.4-5.6,2-3.3,3.7-9.3s8.1-15.8,10.5-17.8,4.9-1.9,5.1-2.1c.7-.8-.2-3.7.1-5.2s5.5-6.8,6-7.3c1.3-1.2,3.1-1.2,3.7-1.8,1.9-1.9,3.3-5.4,6.3-6.7,0,.3,0,.7,0,1Z"
      style={{ fill: "#023319" }}
    />
    <path
      d="M524,80c-.6,5-9.9,8.2-11.5,11s-.2,4.7-.5,5-4.9-.4-7.3,2-1.2,4.3-3.8,5.5-3.4.2-5,.5c0,2.7,0,5.3,0,8-1.9-1.3-6.9-.7-8,1-.2,2-2.2,2.4-3.4.6-2.6-4,3.6-8,7.1-8.8,1.1-2.3,2.6-3.2,4.3-.9-3.5-.6-2.1-3.1,0-5,.3-.5-.4-2.5,0-2.9.8-.7,5.4.6,6.8-.2s3.7-6.6,4.6-8,.4-2.5.6-2.9c2.6.3,5.7.7,8.2-.3s3.1-4,4-4.5,2.6,0,3.8-.2Z"
      style={{ fill: "#022c17" }}
    />
    <path
      d="M493,127c1.6-.2,3.5.3,5,0l.5,4c-4.6-1.4-10.3,5.4-13.5,8.7s-.6,2.4-1.5,3.3c-1.3,1.3-4.9,3.2-6.4,5.6s-3.1,12.8-3.1,16.4c2.8.4,2,2.5-.1,3.7,1.3,7-.5,10.1-5.3,9.2-2.7,2.2-3.6,4.4-2.7,6.7l-1.9,1.4c-2.9.1-5-4.2-6-7,.5-6.2,7.4-4.3,9.5-11,2.4-7.8,3.7-11.6,3.7-19.5,0-7.4,5.2-3.6,5.8-4.5s-.5-2.5.2-3.7,7.3-8,8.8-9.3,1.3.4,1.8-.2,0-2.5.2-2.8,4.2-.9,5-1Z"
      style={{ fill: "#023b1a" }}
    />
    <rect x="499" y="122" width="3" height="2" style={{ fill: "#023b1a" }} />
    <path
      d="M479,163c.9.1.8.2,1,1,3.4,2.2,1,6.6-1.2,8.9.3,5-1.1,7.9-4.9,10.9,0,2.3-1.6,3.4-5,3.2.3,2.3,0,3.9-.9,4.9-.2.9-.6.8-1,0-.9,1.9-2.1,1.9-3,0-2.5-.8-2.2-5.1,0-6,0-1.6-1.2-6.7-.9-6.9s1.9,0,2.9-.6.9-2.2,1.8-2.4,4,.3,4.3,0-.4-6.6,0-8.3,2.5-.9,2-2.7c1.5-1.1,3-2.3,5-2Z"
      style={{ fill: "#02431a" }}
    />
    <path d="M499,135l-3,2c.4-.5-1.5-2.5,3-2Z" style={{ fill: "#0e5229" }} />
    <path
      d="M480,164c1.7-.3,2.2.8,3,2,0,.3,0,.7,0,1-.4,2.5-1.5,4.4-1.1,7.6s2.2,1.3,2.1,1.9c-.4,2-3.2,3-3.7,3.8s-.2,2.7-.9,3.6c-1,1.5-5.8,6.1-7.3,4.9s-1.3-4.2-2.2-.2,1.7,4.9,2,7.5c-.3,2.7-1.2,2.5-2,0-1.2-.5-1.9-1.8-2-4-.5-2.9-1.3-5.1,0-8l4,2c-1.3-4.5,3-4.4,4.4-8.1s-.2-4.3.8-6.2,4.4-1.6,2.8-7.7Z"
      style={{ fill: "#014b19" }}
    />
    <path
      d="M480,102v1c0,1,0,2,0,3,5,3.4,1.5,9.9-5.1,7.9-.1,3.1-1.1,4.8-2.9,5.1,2,2.9-1.5,4.9-4.5,4.6-1.9,6-2.9,7.2-9.6,6.6l-.7,13.3c-1.2.4-1.8,1.4-2.1,2.6,2,1.9,1.7,2.7-1,3-1.7,3.7-10.2,2.9-10.6-1.9-5.3,1.4-8.4-1.6-10.4-6.1-5.2,1.2,0-13.7.7-14.2,3.9.7,6.9-2,4.7-5.3l3.6-1.5c2.4-5,5.5-6.8,6-12,4.9,3.3,3.6-3.6,4-4s2.9,0,3,0c.5.5-1.7,6.4-1,8,3.3-.5,8.4.7,11.3,0s1.4-8,4.7-9.6,6.2.8,10-.4Z"
      style={{ fill: "#072316" }}
    />
    <path
      d="M442,120c-.8,1.7-1.9,2.5-2,2.7-.3,1.4.3,5,0,5.3s-4.4-.5-5,0-2.4,7.8-2.6,8.9c-.3,1.7.7,3.3.6,4,2.7,1.2,2.1,6-1,6,.9,2.5-1.1,5-5.9,7.5-3.2,7.3-5.2,8-12.1,7.5,1.6,3.3-3.1,12.8-6,8-3.3-1.1-2.6-4.5,2-10,4.3-4.5,6.8-9.4,9.3-15.1l2.7-.9-1-2c5-4.6,8-9.7,8.9-15.4l2.1-1.6c3.3-1.1,3.9-2.1,2-3-2.4-1.7-1.7-2,1-2,.3-.1,5.5,1,7,0Z"
      style={{ fill: "#052615" }}
    />
    <path
      d="M433,117c0,2.4-.7,3.5,2,3-.2,0-.6,1.5-1,2,1.3,2.1.2,2.8-2,3-.5,5.6.2,5.1-3.4,10.6s-5.8,7-6.6,8.4c-1.8,2.2-4.9,3.3-6,0,.1-1.5-.5-3.4.2-4.8s7.4-7.3,7.6-7.9c.6-2.1.6-7.4,2.2-9.4s5.4-3.4,7-5Z"
      style={{ fill: "#032c15" }}
    />
    <path
      d="M63,152c1.1-4.9,5.9,0,3,3,4.6,1,6,2.7,4.2,5.2,2.6,2.2,5.2,7.7,3.8,10.8.7,0,1.4.4,2,1,2.1-2.8,3.4-.8,4,2,4.5.3,7.7,2.4,6.3,7.3,4.6.6,6.4,2.5,5.5,5.7,1.9,1.9,3.3,3.9,4.2,6,1.9,1.8,4,8.3,1,10,0,.9.8,1.6,2,2,2,1.4,1.9,3.3-.2,5.9,4.7,1.2,5.2,8,.2,5.1-2.4-.2-2-1.3-1.2-.4,1.3,1.5-11.9,1.4-6.1-6.7-2.3-2.4-3.7-5.4-4.2-8.9-2.7.5-4.2-.1-4.5-1.9-.8-.1-1.9-1.2-3-1l2-1.5c-2.7-.9-4.1-10.5-5.1-11.4s-3.5.3-3.9-.1.2-4.6-.9-6.4-1.7-.4-2.1-.6c.3-.8,1.1-2,2-2,1.1-.5,1.1-1.5,0-3-.7-.3-2.3,0-3.7-.8-5.8-2.8-3.6-13.5-5.3-19.2Z"
      style={{ fill: "#0a2b12" }}
    />
    <path
      d="M44,80c6.7-1.2,2.8,3.1,4.3,6.7s5.4,3.1,5.6,3.4c.5.5-.8,3.1-.3,4.7s2.1,1.1,2.3,1.9c.6,2.9-.4,7.2,0,10.3,1.4,2.4,1.9,4.7,1.2,7.1,4.8,2.6,4.5,11.2.8,13.9,2,.2,1.9,1.2,0,2-2,2-3.4-.4-4-7,0-6.8-2-2.7-3.9-4.1s-1.5-5.5-3.1-6.4c5.9-3.3,2.2-2.6,1-4.2s-1.3-5-5-4.3l6-7.5c-5.4-2.3-2.3-4.5-1-8.5-2.4,1.9-4.9-5-5-6.5s1-1.4,1-1.5Z"
      style={{ fill: "#0f2c15" }}
    />
    <path
      d="M58,130c2.1,1.4,3.5,4.1,4.2,8.3,4.9,3,6.5,6.1,6.2,11.7,4.7,1.4,1.5,7.4-2.4,4.9-.2-3,1-3.3-3-3,0-.1-1.5-.5-1.8-1.7-.5-1.8.2-5.6-.3-6.2s-3.3.5-5-3.6-2-11.6-1.9-17.5c3.7,1.5-.6,5.6,4,7Z"
      style={{ fill: "#0d2b14" }}
    />
    <path
      d="M72,172c1.4.6,1.8.8,2,2.5.4,3-1.2.5-2,.5v-3Z"
      style={{ fill: "#13341b" }}
    />
    <path
      d="M56,107c1.2-1.4,4.1-4.7,6-3s-1.9,6.8,2,6c.3,6.2.4,3.1,1.9,5s-3.9,1.9-1.9,3.9,7.2,2.2,7.8,3.2-.2,4.2.4,6.2,5,5.4,2.8,9.7l5-1c0,1.7,0,3.3,0,5-.6,1.9-1.5,2-2,0-1.8.5-1.8.9,0,1l1.1.2c-.8,4.4.2,6.7,2.9,6.8,3.5,2.4.9,1.3,2,3,1.6,2.6-2.7,5.8-5,4-2.6.5-8-5.7-8.7-8,1.1-9.5-5.8-16.6-12.3-21-.1-2.8,1-8.2.8-9.4s-2.8-2.4-3.3-3.6c-.9-2,2.3-3.6-1.5-4.5.1-.5,1.7-3.1,2-3.5Z"
      style={{ fill: "#142d17" }}
    />
    <path
      d="M400,96c.1-2.7,3.8-2.8,5.2-.8s.9,15-.2,16.8c0,1.2-1.7,3.9-1.8,5.7-.2,5.6,1.6,11.9-.9,17s-2.1,1.2-2.3,1.9c-.4,2,0,5,0,7.3l-7,14.8-3,1.2-4,5c2,1.8,1.7,2.1-1,2-1.4.5-2.7.5-2.9-1s6.9-13.8,7.9-15c.5-2.2,1.6-4.2,3.2-6-.4-6.2.7-14,2.9-20.6-2.1-4.8-1.6-9,1.5-12.6-1.5-2.6-2.5-7.9,1.4-7.8-1.9-2.4-2.8-6.9,1-8Z"
      style={{ fill: "#024e1a" }}
    />
    <path
      d="M409,82c-4.1,2.1-.6,7-.9,10.5s-2.1,3.8-2.2,7,2,6.2,2.2,10c0,3.3-.1,2.6-3,2.5,0-.1-1-1.2-1-2.4,0-3.9.3-8.3,0-12.1s-2.5-1-4-1.5c-2.7-.9-2.7-6.5-2-9,1-3.5.2-5.8-1.1-9.2-.5-2.8-.3-6.6,3.1-5.8,1,.3,2.8-.3,4.3.2,3.1,1,4.2,6.8,4.7,9.8Z"
      style={{ fill: "#02481c" }}
    />
    <path
      d="M387,61c1.5.3,2.5,1.5,3,3,2.8.5,1.7,1.7,2.7,1.9s1.6-.8,2.4-.4,2,3.4,3,4.5c-1.2,2.7-2.8,2.7-4,0-.2.8-.6,1.6-1.4,2s-11.8,3.4-12.5,3.4c-2.8,0-4.9-.3-4.2-3.5-6.3-5.5,3.8-9.9,9-11,.1-.2,1.4.1,2,0Z"
      style={{ fill: "#0b4524" }}
    />
    <path
      d="M398,70c.5.5,1.5,1.9,2,2-3.3,5.1.5,7.1.8,12.8.1,2.2-1.5,1.3-2.8,2.2-2,3.6-7.3.6-8-3-4.5-2.2-.8-4.1,3-4-2.2-2-2.6-9.9,1-10,1.3,0,2.7,0,4,0Z"
      style={{ fill: "#074c1f" }}
    />
    <path
      d="M391,59c0,1.6,1.4,3.1-1,5-2.6-.5-3.4,1.4-3-3,1.7-.4,2.3-1.1,4-2Z"
      style={{ fill: "#084221" }}
    />
    <path
      d="M400,155c0,1.7,0,3.3,0,5-4.5,2.4-2.2,1.9-4.1,5.2s-1.4.7-1.8,1.6c-2.9,6.3-1.7,11.4-8.1,15.2-1.3,1.3-2.7,2.6-4,4-.7.7-1.3,1.2-2,2-1.2.9-6.4,5.1-6.9,5.1s-.7-.5-1.1-1.1l-1.6-1.7c2.6-2,5.1-4.1,7.6-6.3,1.8-1.6,3.8-3.3,6-5-2.6-.7-2.5-1.1,0-2l1.7-3.1c-1.6-4.7,10.5-20.9,12.6-20.7s1.3,1,1.7,1.9Z"
      style={{ fill: "#013d15" }}
    />
    <path
      d="M400,144c0,2.6,0,5.4,0,8s0,2,0,3c-3.7-.5-3.6,4.4-4.2,4.9s-2.9-.3-3.7.3-1.8,5.8-2.3,7-1.5.8-1.8,1.6c-1.1,2.8.7,7.5-4.1,8.2-3.1-1.5-2.4-7,0-9-1.4-1.4-.7-2.1,1-1,.4-.5.6-1.4,1-2l4-5c2.1-3.5,4.6-7.6,5.9-10.8,1.8-4.3-.9-4.8,4.1-5.2Z"
      style={{ fill: "#014619" }}
    />
    <path
      d="M385,61c-.2.4.4,2.5,0,2.9-.9.7-5.7-1.2-8,2.6s-.6,4.8-.9,5.6c.1,4.9-2.6,6.1-7,6.2-1.4,2.9-4.8,5-7,1.8-2.9,1.6-7.2,3.6-9,0-1.4-5,1.6-8.3,9-9.7,2-2.3,4-4.4,6-6.3,2.3-.2,5.2.4,7.3,0s4.7-4.3,7.7-5.5.6,1.8,2,2.5Z"
      style={{ fill: "#084221" }}
    />
    <path
      d="M350,72c2.7,1.3,2,3.4-1,3-6.2,6.4-9.4,4-16.5,5-1.5.2-2.6,1.7-4.1,1.9-4.3.7-13.2-.1-16.8,3s0,2.6-1.8,3.4-5.8,1.5-6.4,1.6-4.3-6.2-4.4,1.1c-1.6-1.6-10.2-4.1-10.8-5.2s.1-4.2-.2-5.8c2.3,0,5.1-.3,7.3.2s4.2,3.4,5.2,3.4c1.8,0,3.7-3.5,4.2-3.7,1.3-.3,3.5.4,4.2-.2s-.3-2.8,1.8-3.7,6.4-.5,9.3-.2l2.9-1.7c7.3,3,13.4.9,20.2-2.5l1.9.2c1.2-.4,3.6.3,5,0Z"
      style={{ fill: "#03371e" }}
    />
    <path
      d="M368,64c-.3,2.6.2,5.4,0,8-1.6.3-1.4-3-2.5-3s-1.2,2.2-2.3,2.9c-3.6,2.4-8.8.8-10.2,8.1-.1.9-.4.9-1,0,.4-3.3.2-4.2-3-5,.6-.6.8-2.1,1-3,5-.9,8-7.1,18-8Z"
      style={{ fill: "#073d1e" }}
    />
    <path
      d="M344,70l1,2c-.1,0-.6,1.3-1.7,1.7-4.9,1.8-9.8,5.7-14.8,3.2l-.5,3c-3.4-4.4-3.3-3.4-8-4,1.7-.7,2.8-3.9,3-4,1.5-.7,9.7.3,12.5,0s7.2-1.6,8.5-2Z"
      style={{ fill: "#05341a" }}
    />
    <path
      d="M422,144c-2.3,3.8-4.3,8.3-6.3,11.7s-4.5,4.1-5.7,4.3c-.2,1.2.4,2.9,0,4.3s-3.6,2-2,5.7c.8,4.3-1.9,8.3-6.3,9.6l-2.6,4.8c-.3,4.3-.6,7.3-5.4,8.1-1.2,3-3.9,4.6-8,4.7.1,3.4-1.4,6-3.6,2.7-4.7.6-5.6-3.2-2-6,1.5-1.1,11.4-9.5,11.7-10.2.6-1.5-.1-3.2.6-4.5,3.2-5.5,15-16.1,15.7-19.3.6-1.9,1.4-1.9,2,0l-.9-.8c1.1-4.6,3.4-8.6,6.9-12.2,0-1,0-2,0-3h6Z"
      style={{ fill: "#052915" }}
    />
    <path
      d="M376,196c2.9,2.3-2.7,7.9-6.1,8.3.2,1.9.3,3.3-1.9,3.7-.7,1.8-2.5,2.9-5.4,3.2-4.5,3.5-8,4.4-10.6,2.8,5.3,1.2-1,8.5-4,4-3.8-1.2,2.5-9.2,4-4-2.7-.4-2.7-2.4,0-6,7.1-2.2,12.8-6.6,16.9-13.1,1.8-.8,3.5-1.3,5.1,0l2,1Z"
      style={{ fill: "#023111" }}
    />
    <path
      d="M380,194c0,2.6-.7,4.8,2,6,0,.5-.2.9-.4,1.4s-6.2,7.7-6.7,8c-2.1,1.2-6.5,1.5-6.9-1.4,0-1.3-.2-5.8,0-6s2.4.3,3.7-.3,4-5.5,4.3-5.7c1.3-1.1,3.3-1.4,4-2Z"
      style={{ fill: "#042914" }}
    />
    <path
      d="M380,188c-.9,1-1.7,3.5-2.2,3.8-1.1.7-3.3-.1-3.8.2s.2,2.3,0,3c-.5,1.4-3.1.9-4.6,1.9s-4.4,5.6-5.6,6.8-5.5,3.6-5.8,3.7c-2,.8-5.8.3-6,.5-.4-.8-.5-1.7,0-2.1l9.9-5.9c-2.4-2.7,1.1-5.3,3.9-6,.2-2.4,2.7-4.4,4.1-2,.4-2.6,1.1-2.4,2,0,3.1-.3,4.6-4.1,8-4Z"
      style={{ fill: "#023a12" }}
    />
    <path
      d="M416,147c-.6,6.5-4.7,6.5-6,13-.7,0-1.3,0-2,0,.2-1.1-.2-2.6.2-4.2.7-2.5,4.6-8.6,7.8-8.8Z"
      style={{ fill: "#032c15" }}
    />
    <path
      d="M386,182c.8,2.4-3.5,3.5-4,4,0-3.4,2.4-3.1,4-4Z"
      style={{ fill: "#023a12" }}
    />
    <path
      d="M496,104c-3.1.6-1.9,1.7-3.1,2.4-2.7,1.6-5.7,1.3-6.9,5.1s1.8.4,2,1.5c-.2.3,0,1.9-.5,2.9-2.2,4-6.5.2-7.5,1.1s1.5,2.6,1,3-2.2-.4-2.8.2-2.1,6.9-5.2,7.8c-4.9,1.7-2.7-4.7-1-6.8v-2.2c.5-1.1.4-6.3,1.1-6.9s6.4.4,6.9,0,0-4.9,0-6c4.6,1.3,6.2,1.1,7-4,2-.2,0,2.5,2,2-.4-3.7,6.1-2.9,7-5v5Z"
      style={{ fill: "#032816" }}
    />
    <path
      d="M178,66c1.9.9.3,1.9.6,3.5.7,3.3,2.1,9.9,2.2,12.7.1,5.8-1.6,13.1-.7,19.2.4,2.7-3.8,1.9-4.1,3.1-1.8,6.4,4.1,21.5-6.9,23.4-.4,3.2.8,1.8,3,1l1,5c-.5,3.1-3.3,3.7-5,1-1.7,0-2.7-1-3-3,.3-1,.3-2,0-3-1.6-3.4-1-6.9,1.9-10.4l1.9-9.7,2.3-.8c.4-1.3,2.5-2.1,2-4-.8-.2-2.7.3-3,0s.2-5.8.4-6c1.2-1.5,3.9-.9,5.6-1,.2-1.8-1.8,0-2.1-2.4s-.2-7.5.1-10,2.1-3.8,2-5-3.1-.6-3.1-3.1,4.2.9,3.1-3-1.8-1.5-2-1.5c2.6.7,2.9-3.9,4-6Z"
      style={{ fill: "#06240f" }}
    />
    <path
      d="M176,64c0,.6.8,1.4,1,2-1.4.2-2.3,2.1-4-.5v-1.5c1,0,2,0,3,0Z"
      style={{ fill: "#06240f" }}
    />
    <path
      d="M186,80c.3.2,2.7.3,3,2-.4.8-1,3.2-1,4-2.6-.8-2.1-3.8-2-6Z"
      style={{ fill: "#033111" }}
    />
    <path
      d="M173,134c1,0,2,0,3,0v1s-3,.5-3,.5l3,.5c-1.2,3.9,0,26.1,0,32-1.4,2-4.5-6.1-4.8-7.5-.7-3.3-4.4-23-4-24.2s.5-.9.8-1.4c1.7.1,3.5-.9,5-1Z"
      style={{ fill: "#072910" }}
    />
    <path
      d="M170,160c3.7,2.6,4.6,8.6,4.4,13.2l1.6,2.7,2.1,1.3c.5,9.2,2.8,18.1,6.7,26.6l-.8,1.2c1.1,6.4,2.8,12.6,5.1,18.6,2.4,2.9,2,18.8-3.1,12.4-.6,2.2-1,2.5-1.1.9-1.2,5.5-5.2,1.2-3.9-3.1-.7.2-1.3-.4-2-1.8-2.5-.2-3.4-2.5-2.7-7l-2.3-3c1.9-10-.2-14.1-6.3-12.3-5.9-1.8,0-9.2,4.3-9.7-1-1.1-.7-3.1,1-3l-.5-4.1c-8.4-1.9-7.8-8-3.6-14.8-1-3.8-.6-6.5,1.2-8-.1-1,.1-2.2,0-3.4-.4-2.2-4.7-7,0-6.6Z"
      style={{ fill: "#0a1e0f" }}
    />
    <path
      d="M146,164c.6.6,1.9,1.1,2.5,2,1.6-3.2,5.8-6.4,6.5-1l-3,2c3.2-1.4,3.9,4.2,6,3s-.7-2.9,2-1.9c-.4,3.1-.6,11.9,3.4,6.9s1-6,6.6-4.9c.2,1.7,1,7.1.8,8.3-.4,2.8-4.1,5.8-2.9,10.1s7.5,2.6,5.1,8.6c-1,.3-1.7,1.3-2,3-1.4,1.3-3.1,2.3-4.9,3.2-.8,8-13.9.5-8.8-3.5,2.4,0,3.8,0,2.7-2.7,1.9,2.4,1.2,3.3-2,2.7-1.7,3.7-7.1-1.1-4.3-3.7s6.4-1,6.2,1c-1.9-1-3.9-1.3-6-1.1,0-2.3-.4-4.6-1-6.9-4.4-2,1.6-6.1,5.1-6.7l-1.2-2.4c-2.9,4.1-6.7,3-7.2-1.8-4.4-.8-4-4.9.4-5.4l-.2-1.7c-7.2,1.6-10.5-4.9-3.8-7Z"
      style={{ fill: "#0b1b0f" }}
    />
    <path
      d="M144,160c.2,1.4,1,3,2,4l-2,5h8c-.2,1.2.3,5.7,0,6-.5.5-4.2-1.1-4,1,4.7-.5,3.8,3.8,4,4,1.3,1.2,4.4,0,2-3,3.2-2,7.2,4.9,5.9,6.9s-3.8,1-4.9,1.1c.1,1.8,2.6.9,4.1,2.4s-.1,2.2-.1,3.5c-1.9-.3-1.3-3-3.6-3.9s-2.2.2-2.4,1.9c3,1.7.7,4.2-2.5,3.4-.5,6.5-6.7,9.2-7.8,1.6-2.7,0-3.9-2.5-4-5-3.9-.4-3.9-4.8-.7-6.2s-1,.9-.9-.8c-2-1.2.2-20-1-22,2.6.1,5.4-.2,8,0Z"
      style={{ fill: "#0e1e0f" }}
    />
    <path
      d="M163,130c1.1.3,2,.7,2,2,1.9,1.1,2.8,1,2.9,1.1.4.4-.1,1.5,0,1.9.7,1.5,1.7,2.8,1.9,4.6,1,7.1,1.7,13.4,2.3,20.7l3.8,7.7c0,2.7,0,5.3,0,8-1,0-3.8.2-4,0-.4-.3,1-2.5.9-3.5s-.7-8.2-.9-8.8c-.2-.9-1.7.4-2-3.7s0-1.3,0-2c-1.7-4.4-3.4-8.8-5.1-13.3,1.3-2.4.4-6.2-.9-8.7-.9-.7-1-.7-2-1-.3-2.3-.2-3.2,1-5Z"
      style={{ fill: "#0a2310" }}
    />
    <path
      d="M164,136c3,2.2,1.5,3.2,2.3,6.2,1.5,5.7,3.3,10.1,3.7,15.8-4.2-.4-3.5-10.3-6.2-11.8s-9.1,2-11.8.8c-1.7-1.3.1-1.8,1.5-2,3.7-.5,7.8-.5,11.5-1l-1-8Z"
      style={{ fill: "#0a1e0f" }}
    />
    <path
      d="M160,96c1.6.1,3.3,0,5,0l-1,4,4-1c1,1.3-2.2,9.2,3,9-.9,3.3-1,7.6-2.3,11.2s-4.5,5.6-3.7,9.8c-.3.9-.6.8-1,0,.9-3.5-3.2-5.6-3.8-7.7-1.2-4,2.2-10.5-3.2-9.3-.8-2.7,2.6-2.5,3-3,1.3-1.7-4.9-4-5-7.5s4-1,3-3.5l-7-2.5c2.9-.2,6,.3,9,.5Z"
      style={{ fill: "#0a220f" }}
    />
    <path
      d="M228,360c3-5.6,6.5.3,6.8,5.3,2.8.4,4.5,1.3,5.2,2.7,0-.5,0-1,.1-1.3.4-.7,14.1-4.9,14.9-2.7,5.2,2.2,12.1,12.9,7,18-.4-.8-4.9-7.2-6-6-.3,1.5.4,7.6,0,8s-6.5-.3-8,0c.2,2-.5,4.6.1,6.4s5.6.5.7,8-2.7,1.5-2.9,2.3c-.6,2.7,2.4,4.5,2.1,6.8s-1.8.4-2,1.2c-.3,1.4.2,3.1-.2,4.6-1,3.3-5.1,1.1-5.8,8.7-2.6,3.6-4.6,1.6-6-6,3-1.3,3.8-6.6,0-6.8l-.9-1.6c1.7-3.6.9-8.5-2-11.4l-.8-4.2c-3.3-5.7-5.8-10.2-3.7-17.1-2.8-2.5-6.8-8.5-2.8-10.8,1.3-1.3,2.6-2.7,4-4Z"
      style={{ fill: "#132516" }}
    />
    <path
      d="M210,352c2.5-5.4,9.9-.3,10.9,4.8,5.2,2.4,6.2,4.8,3.1,7.2.7,8.8,4.3,8,4.8,10.6s-1.2,4.8-.7,7.8,3.3,6.1,4.3,8.8-1.7,3.7,4.6,6.2c-4.3,2.8.1,5.2-.1,6.8s-2.5,1.8-1.9,3.7c3.9-1.7,3.7,4.6,3,7.3s-4.4.8-2,4.9,2.5,1.4,4,1.8c-.2,2.2-1.6,17.4-1,18,0-.2-.8-.6-1-1.5-.3-1.7.4-5.9-.1-6.4s-2.7.5-3-.6c-.7-3.8,0-10.6-1-14.8s-1.8-.6-2-1.4c-.5-2.1.3-5,0-7.3-5,4.4,2.3,9.7-8,8v-14c0-.2-3.3.2-4,0,.9-1.7,2.5-2.8,2-5-.6-.2-1.6.2-2,0l-.7-1.3c5.2-3,4.3-14.2-.8-17.6-6-.1-6-6.1-.1-6.1-4.1-1.2-4.3-8-4.1-12.2-7.4.5-8.2-1-6.6-7.6l2.3-.2Z"
      style={{ fill: "#162919" }}
    />
    <path
      d="M257,362c1.8,1.5,3.9,2.7,6.4,3.5l.6,2.5c5.3,4.2,9.3,8.9,6,16-.3,3-6.4,2.9-7,0,0-.1-.7-1.5-1-2,.4-6.9-2.7-13-7-18-3.3-.9-2-3.6,1-2,.3-.9.7-.9,1,0Z"
      style={{ fill: "#152a16" }}
    />
    <path
      d="M270,384c.7-5.2,7.6,3.8,2,6,1.9,3,2.9,6.3,3,10,1.1,1.3,1.1,2,0,2-.3-.2-2.3-.8-3-1l-4-1c1.5-3.9-4.8-7-5-7.7-.5-2.3.4-5.8,0-8.3h7Z"
      style={{ fill: "#17281a" }}
    />
    <path d="M272,403c0,.6-.9,1.4-1,2l-7-2h8Z" style={{ fill: "#192419" }} />
    <path
      d="M268,400c1.8-.8,3,.7,4,1h-8s0-1,0-1h4Z"
      style={{ fill: "#192419" }}
    />
    <path
      d="M240,443c4.2-.6,1.1,3.2,1,5-1.7-1.4-.7-3.6-1-5Z"
      style={{ fill: "#172317" }}
    />
    <path
      d="M248,446h-2c-.3-1.5.4-2.1,1.5-3l.5,3Z"
      style={{ fill: "#172317" }}
    />
    <path
      d="M275,400l2.4-.3c4.1,2.6,8.1,5.3,11.8,8.3,3.5-1.6,6.6-2.3,10.6-2.1,4.4-3.3,10.5-4.8,14.1.3,3.1-.7,5.1,0,6.1,1.8,1.7.6,1.7,1.7,1.7,3.2,5.5-1.1,10-.4,8.7,6.4-3.2,1.8-.4,4.6,2.7,2.4-5.1,3.5-5.2-4.9-1.1-4s4.4,3,1.1,4c1.6-5.8,10-1.6,9.7,4.1,0,5.3,0,7.7,3.8,11.4,0,6.2.4,12.4,1.5,18.6l-2,1.9c-3.6-.4-6.3-.8-9.9-2.1-.3-3.9,0-7.9-.1-11.9,1.1-1.3.6-2.5,0-4,0-2,0-4,0-6-9.5,1-21.4-1.3-30.6,0s-1.4,2.1-2,2.1c-9.5.9-3.2-15.8-6.6-17.9s-6.8.7-8.6-.4-1.1-2.4-2.1-3.3c-3.5-3-8-8.6-11.1-10.4v-2Z"
      style={{ fill: "#18281a" }}
    />
    <path
      d="M320,408c3.4-3.3,7-2.9,11,1,.5-.8,1.2-1.3,2.2-1.4,5.3,5.3,11.3,7.4,17.8,6.3l1,2.1c1.4-.2,1.8.9,2.2,2.1l-.5,27.9c2.2-.2,4.5.1,5.7,2.2.6,8,2.7,15.4,6.3,22.1,3.1,1.6,6.1,5.7,2.4,7.7-2.4,4.9-9.8-1.4-10-6-.2-.2-1.5,0-2.5-1-3.7-3.8-3.9-10-3.5-15-2.1.7-3.9.3-6,0,.3-5.3-1.1-10.4-1.9-15.6s.7-2.8.4-4.5-2.6-1.8-3.1-3.8c-1.4-5.4,1.8-15.3-8.4-12.1v-2s-3,0-3,0v2s3,0,3,0c-.2,0-.4,1.4-1.4,1.6-6.5.9-1.3-6.3-3.7-8.5-2.1-1.8-10,3.7-7.9-5.1Z"
      style={{ fill: "#192a1c" }}
    />
    <path
      d="M421,480c-1.5,5.9-9.9,5.5-13.7,8.3s-1.2,2.3-2.3,3.2c-7.9,6.3-10.2,3.5-18.1,12.5-1,0-2,0-3,0-6.1-1.7-13.3-6.5-13-13,0-3.1,7-3.8,8-1,1.4-.3,2.7.5,4.1,2.3,4.5-2.7,7.9-3.9,13.2-2.3l8.2-5.9c4.4-.9,8.2-2.3,11.4-4.1,1.7,0,3.3,0,5,0Z"
      style={{ fill: "#1b2b1a" }}
    />
    <path
      d="M368,478l2.1,1.9c-.7,4.1-.4,7.8.9,11.1,1.8,1.2,4.7,6.3,6.5,8s5.1,2.5,6.5,5c-5,0-12-1.5-15.7-5.3s-7.6-10-7.8-10.6c-.7-2.3,0-4.6-.5-6.5-.7-3-3.2-5.5-1.9-9.6,2.9,2.6,6,6.6,10,6Z"
      style={{ fill: "#1a281a" }}
    />
    <path
      d="M336,438c5.4,2.5,8.5,4.3,0,4,0-1.3,0-2.7,0-4Z"
      style={{ fill: "#192a1c" }}
    />
    <path
      d="M136,432l2.2,1.2,3.4,11.6c.2,3.8,1.2,6,3.5,9,2.7-3.3,5.8-6.7,9.8-8.3-.2-8.6,8.4-8.6,7,.3,2.3-.2,3.7.6,4,2.2,5-5.5,8,.3,7.8,6,2.4-.4,4.4,1.4,4.2,3.8,3.5,2.4,19.5,18.1,15.4,21.5s-.9.6-1.4.8c-2.7-2.3-10-8-13.6-9-3.3-.9-5.7.4-8.7-.2s-7.1-4.2-9.2-3.7-2.4,5.2-7.9,4.9-5.4-3.1-8.6-4c-1.2-4.9-4-3.1-5.1-4.6s-.5-4.8-1-6.6-1.8-.7-1.9-1.4c-1.1-4.7.4-4.5-2-10s-2.5-7.7-2-12.3c-.4-2.6,0-2.9,2-1,.7-2.6,1.3-2.6,2,0Z"
      style={{ fill: "#162918" }}
    />
    <path
      d="M138,336c3.9-.5,10,9.5,6,12l-.4,1.3c3.2,3,2.8,7.1-1.4,12.2.8,7.6-1,10.8-5.2,9.5,1.2,7.8-.2,14.6-4.3,20.3-.6,12.8.1,25.8,2.3,39.2l-.9,1.6c-.6.1-1.6,1-2,1s-3.8-.9-3.9-1.1c-.6-.6.4-5.6,0-7.2s-2.4-.9-2.9-2.3-2.2-14.6-2.2-15.4c-.2-.2-1.5-4.9-1.6-5.4s.4-.8,1-1.2c-1.2-5.5-.9-10.5,1-15.2-2.7-3.5-3-11.2,2.8-11.6l-.3-14.5c1.1-2.1,2.8-2,4.7-1.8l2.9-4.2c-3.5-3.8-.8-15.6,3.5-17.2.3-.9.6-.9,1,0Z"
      style={{ fill: "#15261c" }}
    />
    <path
      d="M133,310c2.5-.6,5.4,3.2,4,6.5-4.5,5.6-2.3,13.6,0,19.5l-3,10.5c-.9,5.7,1.5,5,1.8,5.7.6,1.1-3,6.2-4.3,6.9s-3.3-.4-3.5-.1c-.7.9.6,16.4,0,17s-3.2-.9-4.1.6c-1.7,2.8-.6,9.4,4.1,7.4.8,5.2-1.7,1.7-3.1,2.4s-2.3,11-2,13.1,2,3.8,1.9,4.8-1.8,2-1.8,2.7h-1c-8.4-6.7-.7-4.1-.2-6.4s-.9-2.6-.9-4c.2-5.2-.7-6-.9-8.5s.7-8.5,0-11.3-2-.7-2-1.2c-.3-2.2,3-4.6,2-7.4-.2-.2-6.2,2.2-6-.5s8-1.3,9-4l-3-3c5.2-1.9.1-2.5,0-3.1-1-3.6,2-3.4,2-4,0-1.2-1.9-2.8,0-3.9s4.7.3,6-2c-5.9-4.3-.3-4.5.1-9s-6.9-6.9.9-8.5c1.6,1.8,2.5,2.2,1,0,.8-4.3,6.1-8.4-2-10,0-1,0-2,0-3,.8-3.4,2.5-5.8,5-7Z"
      style={{ fill: "#112419" }}
    />
    <path
      d="M124,430c0-.7-.9-2.8-1-5,1.6.9.9,3.4,1,5Z"
      style={{ fill: "#112419" }}
    />
    <path
      d="M184,327c-.4,4,.3,5,2,3,3.8-1.3,6.4,4.9,3.5,7.6,4.5,3.5,5.1,6.3,4.3,11.9,4.2.2,8.1.8,8.4,5.9l2.1-1.4c-4-.4-5.7-7.4-1.3-8-1.3-.7-1.3-1.8,0-3.4-2.1-3.4-2.2-5.6,0-6.6,0-3,4.5-2.8,5,0l2,2.8c-.3,5.5-.3,9.9,0,13.2-.2.1-2.2,4.8-2,5,3.4,1.2,5.1,1.6,8-1,1.2,4-1.6,8.7,2,11.5l-2,2.5c3.5-1.6,5.6,2.3,4,4s-4.9-1.6-4,2c8.9-1.4,9.5,12.6,8.1,18.3s-3.5,1.1-4,2.7c-.7-.3-1.3-.7-2-1-4.6,3.4-4.9-1.2-3.2-5.1-3.5-1.1-3-3.6-1.3-6.3l-2.4-2c-5,.3-6.7-3.9-4.9-12.7-6.3,1.1-9-.7-8.1-5.3-2.3.4-3.6-1.4-4-5.2-2.7-.4-5.2-1.9-7.1-3.5-1.2-.6-1.7-2-1.6-4.2-3-3.5-3.4-6.1-1.4-7.8-1.3-3.1-1.7-5.8-1-8-1.7-.3-2.7-.9-3-2-1.7,2.2-2.7,2.2-3,0-1.4-.2-1.6-1.6,0-2,.1-4.9,1.4-7.2,4-7,2.4-2.1,4-1.1,3,2Z"
      style={{ fill: "#172417" }}
    />
    <path
      d="M187,356l5-1c-.2,3.8,1.5,4.3,5,4l-1,4c6.7-1.6,2.9,3.9,4,5s7.6-.4,8,0-.6,12.2,0,13,2.5-.8,3.7-.3,4,3.2,4.2,3.4c.9,1.3-2.6,3.1-1.9,4.9,6.2-1.4.6,5.2,2,7s1.5-.2,2,0c-7.2,7-1.9,15.4-2,24l-8-4c-2.5-.3-4.2-2.6-5-7-2.8-6.2-4.1-12.2-3.8-18-3.6-1.8-5.4-8.7-2.2-11-4.9-1.4-7.6-7.2-6.9-12.1-2.8-1.5-2.3-3.9-.4-5.9-3-1.1-5.8-3.5-2.8-6Z"
      style={{ fill: "#1c261a" }}
    />
    <path
      d="M197,380c1.3,4.1-1.4,9.2,6,10,1,2.5-1.8,1.7-2,2.6s-.3,5.5,0,6.7,1.8.6,2,1.4c2,8.3,1.6,7.6,5,15.3s6.9,12.2,9,16.8,2.3,12.9,3.7,17,2.7,3.5,3.1,4.9c1,3.3.2,12.2.2,16.3-5.2.6-7.2-3.7-6.2-12.9l-3.2-8,1.4-2.1c-1-.1-1.8-.5-2.6-1.1s-10.9-20.3-11.6-22.5c-3.5-10.7-6.5-23.9-8.3-34.9l-1.5-2.5-2.1-.7c-1.9-3.7-7.4-16.7-2.9-18.3l2.5,1.1c1.1,6.2,1.9,8.5,7.5,10.9Z"
      style={{ fill: "#1d2c1d" }}
    />
    <path
      d="M188,460c1.8-.3,3.8,1,6,4,4.7,3.4,11.1,8.4,13.2,14,6.7,2.7,8.4-1.4,5.1-12.4l1.7-1.6c2.7-4.5,4.5,1.1,4,4.9,2.3,1.3,4.2,2.7,6,4.1,0,2,0,4,0,6s.4,4.2-.2,4.9-9.2,4.4-10.7,4.6c-8.2.8-3.9-3.3-5.3-5.3s-2.2-.5-3.2-1.1c-1.3-.9-.4-2.9-4.7-2-4.3-2.8-17.6-17.1-17.9-21.3l1.9-1.7c2.6-1.8,3.3.5,4,3Z"
      style={{ fill: "#18321c" }}
    />
    <path
      d="M216,448c1,3,3.3,7.4,3.8,9.7.8,4.7-1.8,11.9,4.2,13.3,0,.7,0,1.3,0,2-2-.2-7.5-1.6-8-3.7-.7-3.6,1.7-5.1-2-5.3-2.4-1.5-3.8-4.4-4.3-8.7-2.4-2.8-3-5.3-1.7-7.3.6-3.2,6.7-2.7,8,0Z"
      style={{ fill: "#1c301e" }}
    />
    <path
      d="M176,445c2.8,1.3,2.7,3.7,0,5l5,6c1.7-2.5,3-2,3,1-.3,2,.8,3.2,1.8,4.7,3.1,5,8.4,10.3,12.4,14.6s2.7.6,1.9,3.6c-.7.1-1.7,1.7-2.5,1.7s-3.4-2.6-5.5-1.7c.7-3.3-2.5-8.3-4.6-10.9s-8.4-7.2-11.1-10.5,0-2.2-.4-2.5c-.7-.6-3.3.3-3.8-.2s-.8-7.6-1.1-7.9-3.8,0-5,0c-3-1.3,1.5-9.3,3.5-9.3s5.8,4.6,6.5,6.3Z"
      style={{ fill: "#172d1b" }}
    />
    <path
      d="M426,464c-2.6-1.3-4.9-6.7-2.9-9.1s14-5.7,13.4-.6c3-.8,5.1-.2,6.5,1.7,1.6-3,8.9-1.9,6,2v1c-2.8-.3-14.1.7-16.4,2s-.3,2.3-.9,2.8-2.1,0-2.5.4-.3,3.2-1,4.6c-1.5,3.3-6.8,5.3-10.3,6.3-.9-3.6,5-9.2,8-11Z"
      style={{ fill: "#153318" }}
    />
    <path
      d="M426,464c-.4,3.7-5.8,8.2-8,11s-1.4.4-1.8,1.7,0,2.3-.2,3.3c-3.6,2-7.4,2-11,0-2.1.2-2.5-1-1.4-2.8,3-1.5,5.3-2.7,7.4-5.2-.8-1.7.2-2.8,2.9-3.4-.3-2.5.4-4.1,2.1-4.6.6-3.1,9.2-3,10,0Z"
      style={{ fill: "#183619" }}
    />
    <path
      d="M130,330c.7,0,1.3,0,2,0,1.6,6.7-5.9,4.5-3,0,.3,0,.7,0,1,0Z"
      style={{ fill: "#12271b" }}
    />
    <path
      d="M112,410l2.5-1c3.1,2.2-1.2,4.7-2.5,1Z"
      style={{ fill: "#101c15" }}
    />
    <path
      d="M470,436c0,2.6-.5,10.1-2.3,11.7s-4.9,1.8-6.6,2.9c-4.3,2.9-6.4,6.4-12.1,7.4-1.3-3-3.4-1.8-6-2-2.6-.6-2.5-1.2,0-2-.8-2.6.2-3,2-1l1.7-2.1c4.5.7,6.8-1.1,9.2-4.7,7,.7,11.9-1.5,12-9.3l1.1-1c.3-.9.6-.9,1,0Z"
      style={{ fill: "#1f351d" }}
    />
    <path
      d="M460,415c2.4,2.8,1.6,3.5,2.9,5.5,3.6,5.4,6.3,5.4,7.1,13.5s0,1.3,0,2h-1l-1.1-.3c-.7-3.5-2-6.7-3.9-9.7-3.1-1.4-6.5-6.9-8-10-1.5-1.7-1.8-3-1-4,.3-.6.6-.6,1,0,1-3.7,4.4.4,4,3Z"
      style={{ fill: "#143a18" }}
    />
    <path
      d="M519,336h1c1.7,4.4-4.4,6.1-5.7,8.3-2,3.5-.3,3.3-4.3,7.7s-.9-.2-1,0c-.6-.2-1.1-.4-1.2-.8-.6-2.3,4.7-14.5,8.2-15.2.9-2.7,1.7-2.4,3,0Z"
      style={{ fill: "#122e1a" }}
    />
    <path
      d="M547,305c.2,1.7,1.3,6.8,1,7s-1.5-.4-1.9.1c-1.1,1.5,1.3,5-4.1,4.9l-1-1.4c1.6-3.4,2.3-7.3,2-11.6-.4-1.9,0-6.2,3-5,2.2.6,2.6,2.6,1,6Z"
      style={{ fill: "#0e3118" }}
    />
    <path
      d="M534,317c2.3-1.5,5.3-1,3,2-.7,0-6.6.9-6.8,1.2-.6.5-1.8,6.2-2.5,7.5s-1.3.6-1.6,1.1c-1.6,2.7-2.3,6.5-6,7.3s-.7,0-1,0c-.5-.4-.9-.7-.9-1.1,0-1.3,12.3-22.2,15.9-17.9Z"
      style={{ fill: "#0c2f15" }}
    />
    <path
      d="M542,317c-.4,1.3-.7,1.6,0,3-1.7,0-3.2-1.1-5-1-.6-1.3-1.5-2-3-2-.9-.3-.9-.6,0-1,.2-1,1.2-1.3,3-1,.3-.9.7-.9,1,0,0-1.3,1.9-1.6,3-1.5s-.9.7-1-.5c-1.7-1.4-.6-8.9,2-9,.3-.9.7-.9,1,0,3.8,4.9.6,7.7-1,13Z"
      style={{ fill: "#123a1a" }}
    />
    <path
      d="M496,366v4c-4.8,1.9-9,9.5-12.3,11.7s-2.2.2-3.4.7.4,1.3-4.3,1.7c-3.1-1-1.4-3,5-6,0-.7,3.9-6.6,4.7-7.1s1.6-.1,2.3.1c.8-1.6,4.2-7.1,6-7.2l2,1.2v1Z"
      style={{ fill: "#0d3b19" }}
    />
    <path
      d="M464,394c-7.5,4.4-8.3,12.6-3,19l-1,2c-1.2-1.4-1.6-3-4-3-6.9-5.5-5.3-12.2,2-16,.1-2.1,4.8-4.6,6-2Z"
      style={{ fill: "#13311a" }}
    />
    <path
      d="M472,384c1.6-2.5,2.6-2.7,4,0-4,5.2-5,7.3-12,10s-3.3-.4-6,2c-.3,1.6-1.8,1.8-2,0-1.2-.9-.5-1.5,2-2-.3-2.5,3.6-3.8,6-4-1-3,6-7.9,8-6Z"
      style={{ fill: "#103e19" }}
    />
    <path
      d="M515,336h1c1.1,3.7-3.2,4.3-3.8,5.2s.1,2.5-.5,3.5c-1,1.6-4.4,1.9-2.8,7.3-1.8,2-3.5,4.3-5,6.5l-8,7.5v-1c-1.9-.9-1.9-2.1,0-3,7.1-6.6,10.7-12.9,14-22-2.3-2.4-.8-3.3,2-4,1.5-1.6,2.2-2.5,3,0Z"
      style={{ fill: "#17381f" }}
    />
    <path
      d="M536,254c2.5-.7,4.5,1.4,6.1,6.3,3-3.9,6.6-4.1,10.8-.5l2.1.2c6.4,3.8,3.3,3.2,5.5,8s3.4,3.1,3.3,4.6c-.2,3.3-9.4,3.1-11.8,1.5,1.9,4.7-3.2,11-2.6,14.6s2,1.9,2.4,3.2c1.2,4-2.8,9.9-4.8,13.3-.2-2.1-.9-4.3-1-6l-2.1-1.1c.8-5.6.5-11-.8-16.1l1.8-3.1-12.1-20.7,1.1-2c2.1.2,2.2.1,2-2Z"
      style={{ fill: "#083114" }}
    />
    <path
      d="M534,256c1.9,3.5,12.5,20.1,12.9,22.7s-1.7,2.3-1.8,3.9.8,13.9,1,16.5l-3,5c-.1-.2-.9.2-1,0-2.1-5.8-1.9-10.8,0-16.3-3.7-7.6-7-15.2-9.9-23.1-2.8-2.1-4.4-6.9-1.1-8.6.2-.6,1.6-1.6,1-3,.4,1,1.5,2,2,3Z"
      style={{ fill: "#0b3817" }}
    />
    <path
      d="M536,254c-.4,0-1.7,1.4-2,1-1.3-1,3.8-6.6,4.2-6.7,3.8-1.5,6,4,5.8-.3.3,1.8,2.7,3,2.9,3.8,1.1,3.1-1.8,3.7,5.1,3.2l-5,2c4.1-.8,5.6,1.6,8,3-2.4,5.3-4.9-2.3-7.4-1.9-1,.7-2.7,2-3.2,3-1.1,2.3,2.1,4.3-.9,3.9-2.9-1-6.1-11-7.5-11Z"
      style={{ fill: "#072d12" }}
    />
    <path d="M552,252v2c-1.8.2-2.9,0-4-1.5l4-.5Z" style={{ fill: "#13351e" }} />
    <path
      d="M522,240c-.1,2,0,4,0,6,1.1,2,2.5,4.7,0,6,.6,1.5.6,2.9,0,4-2.3.3-4.4-5.9-4-8,.2-2.6-.3-5.5,0-8h4Z"
      style={{ fill: "#026115" }}
    />
    <path
      d="M524,248c2.1,3.2.9,7.2,4,9,1.8,1.1,2.5,5.1,0,6-.5.2-1,.4-1.5.4-1.8.2-4.5-9.2-4.5-11.4-.1-2,0-4,0-6,.6.7,1.5,1.3,2,2Z"
      style={{ fill: "#055219" }}
    />
    <path
      d="M531,256c-1.6,5.9,1.2,4.7,2.8,7.7s1.3,6.8,2.5,9.5c1.3,3,7.5,12.9,7.7,14.4.4,3-1.7,3.9-2,6-.5,3.3.2,7.1,0,10.5-.9.1-2.1.2-2.4-.4-2.2-4.9-2.3-12.8-2-18.1-1.5-4.8-3.4-9.6-5.7-14.5-1.9-1.1-3.2-3.5-4-7-.9-.2-.9-.5,0-1,0-2,.1-4,0-6s0-2,0-3l3,2Z"
      style={{ fill: "#0c431a" }}
    />
    <path
      d="M528,152c-.1.4.4,1.3-.2,1.8s-4.3,2.2-5.8,3.2l-.6,1.8c-5.8,3-11.1,6.6-15.9,11l-2.3,15.6-1.2.5c-2,2.2-2.5.4-2-2-3.5-6.2,0-15.5,4-21-5.6-2.6,2.1-6.2,6.1-4.8,1.7-3.7,5-5.7,9.9-6.2,2.7,0,5.3,0,8,0Z"
      style={{ fill: "#017828" }}
    />
    <path
      d="M522,160c1.8-.3,4.1.1,6,0v4l-.7,2.2c-3.1.7-13.9,4.1-14.7,7,2.1,3.2-1.5,7.8-4.6,4.8-1.1,1.2-1.8.5-2-2-2.2-1.2-.8-5.2.5-7.3s13.3-10.4,15.5-8.7Z"
      style={{ fill: "#02832c" }}
    />
    <path
      d="M522,157v3c-.6,0-9.4,4.9-9.8,5.3s0,1.9-.9,2.8c-4.4,4.3-4.7-2.3-5.3,7.9,1.4,3.6,1.2,12.1-2,14-.7,1.5-1.4.8-2-2-1.9-.3-1.8-1.1,0-2,.3-5.6.5-6.6,1.8-11.7s-.1-3.4.5-5,3.9-4.1,4.2-4.3c3.5-2.5,9.6-5.3,13.5-8Z"
      style={{ fill: "#027f29" }}
    />
    <path
      d="M512,188c2.6,1.2,2.5,2.8,0,4,2.2,2.5,3.9,5.8,5,9.9,2.3.5,4.6.9,7,1.1,1-2.7,9.3-15.5,12.3-12.2s.5.8.7,1.2c1.1,5.4-1.6,3.3-2.7,4.3-4.1,3.8-7.5,7.6-6.3,13.7-5,5-6.7,0-8.8-.9-4.7-1.9-7.8.7-10.2-6.6s-.9-6.8-1-10.5c-3-.6-2.7-3.9,0-5,.9-2.8,4-1.9,4,1Z"
      style={{ fill: "#029323" }}
    />
    <path
      d="M538,168c5.9.4,7.7,7.5,6,12.2s-2.2,1.4-3.2,3c-1.5,2.5-3,5.7-2.8,8.7-.3,0-.7,0-1,0-.8.2-1.8-.1-2.9.5-4.2,2.3-6.8,8.5-10.1,10.5-3-1.7.8-10.3,4-11l-.2-1.3c7-1.8,8.5-6.1,4.5-12.9l1.7-1.7c0-1.6-1.1-2.6-3.3-2.8l-.7-2.2c-1.2-3.5,6.3-6.5,8-3Z"
      style={{ fill: "#019428" }}
    />
    <path
      d="M538,168c-2.5-.2-6,1.6-8,3l-.6,1.2c-11.1-.2-15.1,2.9-15.3,14.5l-2.1,1.3-4-1c-3.1-.6-1.5-7.2,0-9l4-2c.3-.3-4-2.9,1.2-6.4s10.4-4.8,14.8-5.6,8.2-2.5,10,4Z"
      style={{ fill: "#028e2c" }}
    />
    <path
      d="M515,246c.2.3.8,1.6,1,2,2.5,1.8,6.3,9.7,4,12,.8.9.8,1.6,0,2-5.2-.9-7.1-6.3-8-11-1.5-2.6-1.5-4.3,0-5,0-.7,0-1.3,0-2,.7.7,2.6,1.4,3,2Z"
      style={{ fill: "#037821" }}
    />
    <path
      d="M518,248c2.2,2.9,2.5,4.9,4,8,1.5,1.7,5,7.2,2,8-2.1.9-3.5-1.9-4-4-.2-2.4-2.9-9.2-4-12,0-3.7.7-1.7,2,0Z"
      style={{ fill: "#03681f" }}
    />
    <path
      d="M504,190c1.9.6,1.9,1.4,0,2,.2,4,1.1,7.2,2,11,1.5,3.1-1.9,4.2-2,4.7-.7,3.4-.5,7.5-2,11.3.3,1.7-1.1,2.7-4.3,2.8,1.7,6.4.4,10.9-4,13.5-1.3,2.8-3.2,5.4-5.7,7.7l.2,2.6c-4.8,5.6-8.4,12-10.9,19.3l-1.2-.8c-1.1,2.2-1.5.9-2-1-1.7-.3-1.3-3.9,0-5-.3-1.1.4-2.3,2.1-3.6.3-6.7,2.7-12.1,8-16.1l.8-4.5c3.8-4.1,5.7-9.5,5.6-16.4,11.2-7.8,10.8-15.2,9.8-27.6s.8-1.5,1.7-1.8l2,2Z"
      style={{ fill: "#027f19" }}
    />
    <path
      d="M502,219c.6,0,1.3,0,2,0,0,4.6-1.1,5.6,4,5v16c0,.1,1.4-.3,2,.6,1,1.5.3,4.1,2,5.4,0,1.7,0,3.3,0,5-4.2-2.2-7.4-6-9.4-11.3-1.4-3.2-3.6-3-6.6.3-1.3,1.6-6.1,4.5-8,3l4.5-10c6.6,0,2-11.6,3.5-13s4.9-.9,6-1Z"
      style={{ fill: "#01881b" }}
    />
    <path
      d="M508,178c.4,2.5,0,6.2,0,9s0,4,0,5h-4c0-.7,0-1.3,0-2,0-6.4,1.6-8.3,2-14,.4.6,1.9,1.6,2,2Z"
      style={{ fill: "#018726" }}
    />
    <path
      d="M137,182c.7.4,2.5-1,2,1.5s-3.6,1.2-2,3.5,2.4-2.8,4-2c-1.6,10.1,3,5.9,4,7s-.4,3.7,0,4c3.2,3.2,3.5-5.7,4-6,3.8,0,3.6,2.7,4-1,4.6,1.1,2.7,2.4,2,6,7.6-1.8,3.8-.7,5,2h-5s0,2,0,2h5s0-2,0-2c.4.8,2.8,2,2,4-6.7-2.9-5,3,.7,3.9s.8-1.5,1.8-2.8,5.3-3.7,6.5-2c.3-.9.6-.9,1,0l-6,8c13.3-3.1,9.7,5.4,10,15,3-2.2,3.1-6,8-5,1.9,9.4-3.7,4.3-5.6,7s.4,5.9.6,7c2.7.4,2.8,3.3,1.3,5.3,3.4,2,5.6,3.9,4,8.3,5.3,3.6,2,7.8-2.1,6.4,3.7,2.5,5.6,5.5,5.8,9,.9.3.9.6,0,1-2.1,1.5-2.1,3.6-.1,6.4.5,4.6,1.5,10,4.1,13.6,1.9.6,1.9,1.4,0,2,0,2.3-1,2.3-3,2,0,1.2-3.4.7-3.8.4-.6-.6-6.5-13-7-14.4-1.4-3.8-3.2-10.8-1.2-13.9-3.4-.6-3.3-3.4-2.9-6.2-11.1-3-9.7-12.6-7.4-21.8-5.9-3.2-3.3-8.4-.6-12.9,0-7.3-4.1-9.1-6.7-1.5-.8.6-1.2.9-2.1,1.3-3.2,1.3-13.5-2.5-15.1-6.9-5.2-1.4-9.3-11.1-6.2-15.1,0-.8,2.5-2.6,2-3-1,0-2,0-3,0,.4-3.3-1.5-8.2,2-10Z"
      style={{ fill: "#0f1d11" }}
    />
    <path
      d="M112,238c3.1-2.9,8.5,2.5,4.6,6.2,2.4,4.3,3.9,8.5,4.5,12.7l-2.2,1.6c.7,1.8,1.1,3.7,1.1,5.6-3.3-5,3.6-4.3,5.2-.5,2.6,6.4-4.3,7.1-5.2.5,3.2,6.1,5.9,12.4,8,19,2.1,2,3.1,4.9,3.2,8.8,1.9,4.8,2.2,8.9.8,12.2-.3.9-.6.9-1,0-2.4,5.7-5.6-.5-4-5l-1-2c-.7-.2-1.3-.8-2-1,0-1,0-2,0-3-.8-8.4-6.7-12.5-7-13.7-.5-2.6.4-5.5-.3-8.5s-5.3-9.8-6.6-12.6c-2-4.2-2.3-12.2-9.1-10.2l2-8c.3,0,.7,0,1,0,1-2,3.7-2.6,8-2Z"
      style={{ fill: "#0a2614" }}
    />
    <path
      d="M112,290c0,.7,0,1.3,0,2l-10,.5c1.2-4,7-3.1,10-2.5Z"
      style={{ fill: "#081e0f" }}
    />
    <path
      d="M92,300h-4s0-2,0-2c1.9-.3,3.5,0,4,2Z"
      style={{ fill: "#172c1e" }}
    />
    <path
      d="M83,198c1.6.2,4.8-.5,5.9.1s1.6,6.3,2.7,7.8,3.4,1.9,3.5,2c.3.5-6.4,5.2-.1,6.5s4.3-2.3,4.1,1.5v1c4-2.3,6.7-.4,5.3,4.2.8,3.9,3.4,6.3,7.1,7.6l.6,2.3c.9.3.9.7,0,1,2.7,1.1,2.7,4.8,0,6-.2.2.2,1.8,0,2-.4.3-6.5,0-8,0,1.1-2.9-1.9-8.7-4-10.5s-1.8.5-2,1.8c-.6,3.3-2.7-1.3-3.1-1.7-.7-.7-2.9-1.5-2.9-2.1s2.4-1.5,1.8-4.7-5.2-7.7-5.7-9.5c-.8-2.9-.3-2.6-1.6-6.2s.9-2.9,0-4.1c-1.5-2.1-7.1,1.2-3.6-5Z"
      style={{ fill: "#092811" }}
    />
    <path
      d="M126,297c.5.1,1.6-.3,1.9,0,.8,1-.6,6,.2,6.9s2.5-.2,2.9,0c3.4,0,3.5,3.8,2,6-.7,2.7-1.7,6.9-5,7,0-5.8-2.7-4.3-3-5.7-1.1-4.2,1.3-10,1-14.3Z"
      style={{ fill: "#0e2216" }}
    />
    <path
      d="M96,176c-.3,5.5-2,11.6,1,16,1.3,1.4.7,2-1,1-.8-1.6-3.3-.1-4-1.8s1.3-2.9-4-2.2c-.9-2.5,1.5-1.8,1.7-2.2,2.4-4-2.9-1.9-4.8-4.2s.3-3.3-.3-4.6c-.9-1.8-3.4-1.5-4.3-2.3s-.1-1.6-.3-1.7l-2-2.2c-.3-.7-.5-13.9,1-14.8l5-4c.4.6,2.4.9,2.8,2.7s-.9,4.4-.6,6.6,4.5,11.6,4.9,12.2c1.3,1.9,3,1.7,4.9,1.6Z"
      style={{ fill: "#142b15" }}
    />
    <path
      d="M80,142c.1,3.5-1.2,5.8,2,8l-2.1.8c-5-3.3.2-4.3-1.9-7.8-.9-.3-.9-.6,0-1,.5.2,1.4-.1,2,0Z"
      style={{ fill: "#142b15" }}
    />
    <path
      d="M136,195c0,.6,3,10.5,3.5,12,4.9-.4,6.3,6.1,9.2,6.9s4.5-3,7.3-1.9l-4,3c1.6-.3,4,.4,5.4,0s2.4-5.7,2.7-5.9c.8-.7,6.2.2,6.7,1s1.2,6.7,1.2,7.3c-.2,2.2-4,5.2-2.7,8.9s3.6.4,3.8.6c.5.7-3.1,13.6-1.9,17.3s4.6,5,4.9,5.2c1.3.8,3.7.3,3.9.6.6.5-.5,5.2.1,5.9s2.6-.2,2.9,0c1.3,1.3-1.8,5.7-1.7,7.3.1,2.4,6.7,18.1,8.3,20.6s2.4,1.3,3.4,2c1.5-1.2,2.8.6,3,2,.9.2.8.5,0,1,1.5.8,1.1,3.8,0,5-1.9,4.5-5.2-.8-5.9-4.1-2.9.2-4.5-2.2-5-7.1l-6.8-9.9c.1-7.3,0-8.1-6.2-12.2-2.4-3.7-5.1-7.3-8.1-10.8-3.2.8-2-9.1-1.3-8.9s.3,2-.7-1.1l1-6c-5,0-7.9-1.3-7-7l-5,2v-5c-2,.4-9.3.5-10.1-.6-1.3-1.8-1.6-7.1-2.4-9.4s-2.3-2.7-2.6-4.5,3.8-1.5,3-4.4c-3.4,1.1-8.1-1.7-7-5.5s4,.8,3-2.5c-3.7,1.1-4.7.4-7-2.5l12,.5Z"
      style={{ fill: "#131c11" }}
    />
    <path
      d="M142,234c2.1.3,5.4-4,6.5-4s1.4,3.8,2.1,4.9,2,.7,2.4,1.8-.3,2.9,0,3.2,3.9,0,5,0,1.9-.1,2,0c.3.4,0,8.2,0,10,1.8.3,2.4,1.7,1.7,4.3,2.9.4,2.6,3.9,0,4.7,4.6,1.8,12.5,9.7,7.7,13.9.1,10.1,7.9,17.8,14.7,23.9-.5,3.3.3,5.8,2.2,7.7.8,3.9,1.9,11.7-.4,14.5-1.3,1.1-2.9.4-5-2-4.9,6.7-6.7-6.6-6.6-11-4.4.1-5.9-4.4-6.2-8.2-2.8-1.8-2.2-3.2,2.1-4.2l-.3-1.7c-.4.5-5,.9-5.3.8-1.1-.5-4.9-7.4-.3-6.6-6.2-3.4-7.3-8.5-4.4-14.7l-3.8-2.1c-4.2-6.8-7.7-14.1-10.7-21.8-7.3.8-6.1-7.6-1-8.7s-1,.5-1.5-.8c-.5-1-.5-3.3-1-4Z"
      style={{ fill: "#191b13" }}
    />
    <path
      d="M143,238c2.1-.9,2.8-1.2,3,2h-3c2.7,4-1.7,2.2-1,4.5,1.3,2.1,3.5-.2,5,1.9s1,4.1,2.1,6c3,5.5,2.8,3.4,5.3,10.7,2.4,6.8,6.2,5.6,7.5,7s-2.7,8.9-2,9.9l3-1c-3.4,5.2,2.8,4.5,3.1,5.5,1.4,4.9-3.2,3-3.1,3.9,1.1,3.9,4.8,2.5,7,3.5,1.3,1.9.5,2.6-1.7,2.5-.1,2.7-.9,3.5-2.3,2.5-.8.4-1.8.8-2.5.4s-3.6-7.7-3.5-9.4c-3.5-4.1-5.5-8.6-6-13.5-4.7-1-4.4-4.1-3.9-8.1-5.9-.9-8.3-5.3-9.1-10.4-.4-2.6,2.3-.4,2.2-.3s-2.3-.4-2.4-.4c-1-3-2.6-5.4-4.8-7.3-2.6-.5-2.5-1.2,0-2,5.1-2.8-.9-2.7,0-4.5s5.7-2.9,7-3.5Z"
      style={{ fill: "#1c2017" }}
    />
    <path
      d="M112,207l5,.5-3,1c3.4,2.4,2.8,3.8,2,7.5h6c.4,1.7-1.7.5-2,1.7-1.2,4.5,1.3,3.9,2.4,5.5s.6,2.2,1,2.5c2.4,2.3,7.3,3.1,7.7,3.4,2.1,2-2.6,1.6-2,3,2.7,0,3.3,6.5,4.3,9.2s2.3,2.5,2.7,3.8c-2.1,4.9-6-.5-6.6-4.2-3.4,2.2-5.2,2-5.4-.8,0,.5,0,.9-.1,1.3-1.6,4.1-5.8-7.8-5.9-9.7-2-1.9-3.3-4.4-4-7.6-4.6,2.9-6-3.3-2-5-.1-3.7.5-8.6,0-12Z"
      style={{ fill: "#172519" }}
    />
    <path
      d="M124,240c3.1,1.8,4.3-3,6-2s1.4,8.3,6,7c0,.3,0,.7,0,1,.1.6,0,1.3,0,2,3.2,1.6,2.3,10.3,0,12-1.2,1.8-2.5,3.2-4,4-.8.6-2.5,1.2-2.7.7-3-5.3-4.3-9.6-3-15.6-4.9.3-6-7.2-2.3-9.2Z"
      style={{ fill: "#19281a" }}
    />
    <path
      d="M97,192c1.2-.4,3.5.2,5,0l-3,2c-.9,2.5,2.7,2.4,3,3.7.9,4.1.9,3.4,1.9,6.1s0,5.2.6,7.1,4.5,8,7.4,8.1c.1,3.6-1.3,4.8,2,5,1.3,4.8.7,7.1-2,7-5.1-1.4-8.7-4.3-9.7-9.7s1-3,.7-3.3-4.8.3-5-1.5.9-.4.9-.5c5-.7-1.4-4.1-3-3.5,0-1.9,2.9-4.4,3-7.5-.9-.4-1.6-1-2-2-.1-2.6-2-6.8-1-10s.9-1,1-1Z"
      style={{ fill: "#0f2915" }}
    />
    <path
      d="M480,221c2.7.9,2.7,2.1,0,3,2.8.6,2,4.1,1.4,6.4s-7.8,17.9-8.7,19.2-.4.3-.6.4c1.1,2.6-1.2,6.1-4,6.5,3.2,1.5,3.1,13.7,0,15.5.9,3.1-7.4,3.1-8,0-.3,2.7-1.2,2.5-2,0-1.8-.4-1.5-1,0-2-3.3-1.4-1.5-5.4,2-5-1.3-1.3-.7-2.7,1.8-4-.2-2.7.5-4.4,2.2-5-1.2-.6-1.4-1.9-.7-3.8,4.3-5.5,7.5-12.5,7.5-19.7l1.1-.5c0-.1,1.4.3,1.8-.1,1.5-1.7,1.4-10.6,6.2-10.9Z"
      style={{ fill: "#02651a" }}
    />
    <path
      d="M470,232c1.7,3.1,0,8.2-1.5,11.6-2.7,3.3-6.5,8.2-4.5,12.4-.2,1.9.5,4-.5,6s-2.6,1.8-3.5,3c-2.3,2.7-4.6,2.7-4-1-2.7-.7-2.5-2.2,0-3-1.5-1.6,2.6-18.6,3.4-19.6,2.9-1.1,4.1-4.5,3.4-10.1.2-2.8,2.2-6.2,5.2-4.3.6-3.6,1.9-4.6,4-3-.7,2.4-1.3,5.5-2,8Z"
      style={{ fill: "#025619" }}
    />
    <path
      d="M493,186l1.3.3c-.4,2.6.2,4.5,1.7,5.7,5.7,5.5-3.7,16.8-9.7,16.1v13.8c-.6,3.9-4.8,6.3-6.3,2.1,0-1,0-2,0-3,.1-4.3.2-8.7,0-13,4.2.8,0-4,1-5s5.9-.8,8.1-5.4c.9-2,.5-3.6,1-4.9s1.7-.7,1.9-1.5c.4-1.6-.7-4.3,1-5.3Z"
      style={{ fill: "#016717" }}
    />
    <path
      d="M475,198c.1,6.4,3.8,9.9.7,17.2s-2.4,4.1-3.7,8.8c-1.3,1-2.8,1.9-4,3-1.9-1.3-1.9-2.7,0-4-3.3-1.6-2.9-7.8.6-9,1.9-4.8,2.7-9.7,2.5-14.9l.9-1h3Z"
      style={{ fill: "#015316" }}
    />
    <path
      d="M472,232c-.4.6.4,5.5,0,7.3s-4.5,10.6-5.2,12c-1.1,2.3-2.2,0-2.8,4.7-3.1.9-2.2-4.1-1.9-5.4,1.2-5.7,4.4-6.5,5.5-8.5s1.9-8,2.5-10h2Z"
      style={{ fill: "#025c1a" }}
    />
    <path
      d="M472,196c0,.7,0,1.3,0,2,.2,4.3-.1,12.9-1.7,16.8s-2.1,1-2.3,1.9c-.4,1.7,0,4.4,0,6.3-.2,2.9-2.5,3.6-4,1-1.8-.5-1.7-1.3,0-2-3.9-2.1-2-18.1,3.7-16,.3-3.9,1-7.2,2.3-10h2Z"
      style={{ fill: "#014b15" }}
    />
    <path
      d="M496,176c2.8,1.3,2.9,4.7,0,6,1.4,3.2,2.7,7.7,0,10-4.5,1-2.9-2.3-3-6s.5-7.7,0-10h3Z"
      style={{ fill: "#04681c" }}
    />
    <path
      d="M521,150c-1.1.9-.9,1.8-1,2-.9,1-1.4,2.7-3,3.4s-2.4-.5-3.6.5-1.2,3.6-1.6,4c-1,.9-6.8-.6-7.8.3s.2,2.4,0,2.9c.2.5.2,1,0,1.3-.9,1.8-5.8,9.2-8.1,8.7.5-5.2,2.4-7.7,3.2-12.8,5.1-1.5,9-7.6,13.6-10.1s3.9-.9,6.2-2.1l2,2Z"
      style={{ fill: "#026d26" }}
    />
    <path
      d="M504,163c1.7,3.5-1.9,5.4-2,5.7-.9,4.8-1.2,10.7-2,15.3,1.3,1.7,1.1,2.5-1,2-1.5,1-2.5-.4-3-4,0-2,0-4,0-6s0-2,0-3c2.2-2.9,3.8-6.3,6-9.3s2-.6,2-.7Z"
      style={{ fill: "#027225" }}
    />
    <path
      d="M176,176c6.6.3,3.1.7,4.3,7.2s2.1,9,3.7,12.8c2.6,1.1,4.7,7.8,2,9,.7,1.2.7,2.2,0,3-2.3.9-2.9-.1-2-3-1.4-3.4-5.4-11.3-6-13.7-.9-4.2-1.9-10.9-2-15.3Z"
      style={{ fill: "#06260f" }}
    />
    <path
      d="M184,192c2-.8,3.4,4.7,3.7,5.8.8,2.8,1.6,7.1,2.3,10.2.9,1.9,1.6,4.3,2,7,0,.3,0,.7,0,1,.7.2.7.5,0,1-.4.4-.8.7-1.2.8-1.8.3-4.2-10.5-4.8-12.8-.2-5.1-1.7-4.7-2-9s0-2.7,0-4Z"
      style={{ fill: "#043311" }}
    />
    <path
      d="M201,225l1.4.2c2.2,4.9,4,9.8,5.6,14.8,1.8,3.1,1.8,5.1,0,6l-1.3.6c-3.4-4.7-5.4-9.8-6.7-14.6-1.3-.9-1.8-4.6,0-5,0-1,.1-2.1,0-3h1c.7.2.7.6,0,1Z"
      style={{ fill: "#004009" }}
    />
    <path
      d="M192,216c5.2,1.1,5,8,8,11,.1,1.6,0,3.3,0,5l-1.4,1.8c-1.9-3.6-4.1-6.9-6.6-9.8-1.9-1.9-2.3-5.3,0-7,0-.3,0-.7,0-1Z"
      style={{ fill: "#033710" }}
    />
    <path
      d="M190,208c3.3-.6,1.9,4.4,2,7-2.8-1.6-1.5-4.9-2-7Z"
      style={{ fill: "#033710" }}
    />
    <path
      d="M196,173c2.9,0,2.7,2.3,0,3-.8,2.6-.8,5.2,0,8,2.8-.7,3.3,3.4,2,5l1.4,8.7c2.2,3.7,2.9,12.2.6,15.3,0-.2-1.5-.5-1.8-1.7-2.3-9-3.3-19.4-4.3-28.7s-.6-5.1-1.9-6.6v-11l4,8Z"
      style={{ fill: "#035310" }}
    />
    <path
      d="M202,199c1.5,5,4.2,14.1,2,19-.7,1-1.3.3-2-2-.2-.2-1.3.4-1.8-.2s0-2.3-.2-2.8c-.4-3.6.5-8.1,0-11.6s-1.9-1.3-2.1-2.1c-.7-2.9.4-7.2,0-10.3,2.1,2.6,3.4,5.9,4,10Z"
      style={{ fill: "#025c0a" }}
    />
    <path
      d="M204,218c1.5.8,2.8,2.8,4,6,.8,1.5,1.9,3.5,0,4,1.6,2.9,1.6,4.9,0,6l-1.3.7c-1.6-3.9-3.6-7.1-5.7-9.7,0-.3,0-.7,0-1,0-1.7,2-5.4,1-8,.7.5,1.3,1.4,2,2Z"
      style={{ fill: "#045806" }}
    />
    <path
      d="M352,88c.9-.1,2,.2,2,.8-.2,5.1-13.1,6.6-17.2,6-3.2,2.3-10.2,3-14.9,2.9-.3,3.9-4.6,4.2-8,4.1-3.1,3.6-11.7,4.7-14.9,2.1-2.6-.8-4.6-1.8-6-3-1.6,2.6-3.4,2.6-5,0,1.3-5.8-5.1-.6-7-5,2.9,0,6.8-.9,9.1-.6,4,.5,5.8,4.1,9.4,4.6s8.9.2,12.6-2.1c2-6.4,8.8-8.3,14.9-8.4,9.2,1.4,16.2-.8,24.3-5.1,2.3,0,2.4,2.1.8,3.4Z"
      style={{ fill: "#044923" }}
    />
    <path
      d="M352,80c.3,0,.7,0,1,0,3-.1,6,.2,9,0,.5,3.4-2.1,5.7-8,7,1.1,2.6,0,3-2,1,.2-3.6-.6-1.3-2.1-.6-3.1,1.3-6.1,2.9-9.5,3.5-7.8,1.6-16-.7-23.3,2.7s-3.8,3.6-5.1,4.4c-.7-3.2,1.3-4.7,3-7,1.8-.7,8-3.9,8.6-4,6.7-.4,15.1,4.7,20.1-2.4.6-.9.2-2.5.3-2.6.4-.4,4.4,1.6,5,1,1-1.1-2.6-2.5,3-3Z"
      style={{ fill: "#034521" }}
    />
    <path
      d="M256,100c4.2.3,8.4-2.5,12.5-2.1,6.5.6,8.1,4.7,17.1,4.1s2.3-1,2.4-1c1.6,0,3.4,0,5,0,1.5.5,2.5,1.7,3,3,2,1.4,7.6,1.7,12,1s7.7-1.8,8.4-3.5c4-1.6,5.3-1.5,5.6,2.5.5,1.8-4.2,5.8-6.2,6.6-4,1.6-26.8,1.7-27.8-4.6l-2,1.7c-8.1-2.5-15.8-1.9-24.1-1.3-2.8-.4-10.6-2.6-5.8-4.4v-2Z"
      style={{ fill: "#035324" }}
    />
    <path
      d="M240,100c0,.7,0,1.3,0,2,2.7.3,2.5,1.2,0,2-1.2,1.8-3.5,2.4-6.8,1.9-3.9,2.9-7,3.5-9.2,2.1,0,.8-.2,1.5-.9,1.6-3.8.8-6.8,1.9-10.2,3.8-1.4,5.2-5.2,11.2-8.9,14.5-1,1.8-2.6,4.7-4,2,.6-3.9,2.4-8.5,4.6-11.9s3-1.8,3.2-2.3c1-1.7.3-5.5,3.2-9.8,3.7-.8,8.8-1.1,12.3-2s4.7-1.3,8.2-2,5.2-2.8,8.5-1.9Z"
      style={{ fill: "#025b1c" }}
    />
    <path
      d="M198,158l1.5.2c0,3.9.2,7.8.5,11.8,1.4,4,2.3,9.3,0,13,.4,3.2,1.1,6.2,2,9,1.9.9,1.9,2.1,0,3,.9,1.4,1.4,3,0,4-2.2-2.7-2.9-6.6-4-10s-1.6-3.4-2-5c-.8-2.8-.8-5.5,0-8v-3c0-5.6-.2-11.4,0-17,.9-1.6,1.6-.9,2,2Z"
      style={{ fill: "#016211" }}
    />
    <path
      d="M204,128c1.9,0,1.9,1.6,0,2l-2,4c.5.4.9.9,1.1,1.4.5,1.1-1.9,21.1-5.1,22.6-.2-.6-1.5-1.4-2-2,.4-9.8,2.7-17.4,4-26,2.6.3,1.1-1.8,4-2Z"
      style={{ fill: "#035f18" }}
    />
    <path
      d="M256,102c0,0-.4,1.7.7,2,4.8,1.3,15.2-.9,20.9,0s6.4,1.5,10.4,2.1c2.8,1,3,5.2,0,6-1.5,2.9-6.4,1.3-8-1.1l-4.1-.8c-9.6.4-19-.9-28.3-3.9l-.5-2.2c-.8,2.7-6.2,2.9-7,0v-2c3.9-4.2,5.6-.5,8.5,0s6.9-.3,7.5,0Z"
      style={{ fill: "#015b25" }}
    />
    <path
      d="M448,166c2.7-.2,3.1,0,4,2.5s-.6,8.7,4,7.5c0,.3,0,.7,0,1,1.2,2.7,1.9,7.9-2,8-3.9,3.5-4.8,6.9-5.3,12-2.5,3.5-3.1,6.5-2.9,10.9-3,2.1-4.3,4.8-4,8.1-2,2-2.5,4.9-1.5,8.8.5,3.5-5.2,9.5-8.2,8.2-.2,2.6-.9,4.2-2,5-.4,1.4-2.9.4-3-1-3.1,2-5.5-2-1.5-3.9-.3-5.6,1.3-9.4,4.7-11.4-.7-3.9,0-7.1,1.7-9.7.4-3.1,1-5.5,4.2-6.5-1.4-11.8,2.2-20.4,11.2-28-2.1-4.4-1.8-7.5.7-9.4-2.6-.5-2.5-1.2,0-2Z"
      style={{ fill: "#032b16" }}
    />
    <path
      d="M472,119c4.5,2,.3,4.1,0,6.6-.2,1.4,1,2.3,1,2.4-.5,8-7.7,8.2-8.8,10.2s.2,3.6-.2,5.2-2,.6-2,.7c-1.1,1.9-3.4,2.6-7,2l1-18c1.7-.3,7.1.6,7.9-.1s.4-4.4,2.6-6,4.2.8,5.5-2.9Z"
      style={{ fill: "#072719" }}
    />
    <path
      d="M433,141c4.3-.6,1.9,3.4,7,4.3s3.3-.8,5.1.1-.4,2.9,1.5,3.4,5.1,0,7.4.1c3.3,2.8,1.1,10.1-2,13-1.9,1.1-4.7.2-4,3-1.6,2.5-3.9.8-6.9-5l-1.1-2c-1.2-.8-2-2.3-2.4-4.5-3.8-1.8-5.6-4-5.6-6.5.2-.5.8-4.8,1-6Z"
      style={{ fill: "#062619" }}
    />
    <path
      d="M440,158c4.6-.6,4.6,4.3,5.9,6.4s2,.5,2.1.6c.1.1,0,.6,0,1,.1.6,0,1.3,0,2,0,1.9-.5,2.1-2.1,2.4,2.1,6,.8,8.4-3.8,7.3-.8,2.8-3.1,6.5-6.1,4.3-2.5-3.2-5.9-9.4-6.2-13.6s.2-7.3,1.2-8.8,8.6-3.7,9-1.6Z"
      style={{ fill: "#042116" }}
    />
    <path
      d="M462,144c-1.3,2,3.2,6.7-2.5,12.1s-5.8,4.9-7.5,5.9c1.5-4,1.1-9.1,2-13s.4-1.9,1-3c1.8-3.1,3.8-1.9,7-2Z"
      style={{ fill: "#042916" }}
    />
    <path
      d="M458,179c4.9,6.3,5.5,11.2,1.8,14.8-.8,2.5-6.6,18.8-10.4,16.4s-1.2-1.4-1.5-2.2c-3.7-2.7-1-8.2.8-11.6.2-4.5,1.4-8.5,5.2-11.4,1.2-3.3,1.8-4,2-8,.7.6,1.3,1.4,2,2Z"
      style={{ fill: "#023214" }}
    />
    <path
      d="M464,186c0,1.9.1,4.1,0,6,1.8.3,1.7,1.8,1.2,3.3-5.3,9.2-9.1,18.6-11.4,28.2l-1.7,1.4c1.2,2.7-2.2,4.2-4,2-3-1.9-2.6-6.9,0-9-2.9-2.1-3.1-8,0-10,4.9,1,3.8-1.7,5.7-4.8s2.6-5.8,4.4-10.4,3.4-1.2,2.7-6.1-3.3-1.2-2.8-7.7c3.1,2.5,3.5,4.1,6,7Z"
      style={{ fill: "#023816" }}
    />
    <path
      d="M467,192c4.9,5,.6,12.1-3.6,16-.4,5.4-1,8.7-5.3,12.3,0,3.7-.6,6.9-2.1,9.7-.3,2.7-1.3,2.6-2,0-3-.4-4.1-2.6-2-5-1.1-3.9.6-7.3,2.1-11.2s9.5-17.8,9.9-21.8c1,0,2,0,3,0Z"
      style={{ fill: "#023d15" }}
    />
    <path
      d="M468,192c3.2.6,1.9,1,2,4s.4,11.6,0,12-5.6-.4-6,0,.3,11.6,0,14c-2.6,3-4.4,6.7-5.4,11h-2.6c-2.6-.8-2.5-2.1,0-3,.1-3-.6-7.7.2-10.3s4.6-4.4,5.2-6.7,1.5-6.4,3.1-10,2.9-2.1,3.3-3.7-1.3-4.9-.9-7.3c.3,0,.7,0,1,0Z"
      style={{ fill: "#034415" }}
    />
    <path
      d="M440,135c2,1.9-5.3,5-4,1.5s3.3-2.1,4-1.5Z"
      style={{ fill: "#0e2c1e" }}
    />
    <path
      d="M414,162c4.9.2,5.1,4.5,2.4,8.1,1.6,2.5,1.4,4.5-.4,5.9,3.5,2.9,1.9,5.6-4.8,8.2-1.9,2.9-3.5,5.9-5,9-3.4,0-5,1.9-4.8,5.4-3.9,3.7-7.8,7.3-11.6,10.9-7.3,1-11.4,6.5-12.6,13.6l-2.2.9c-.2,1.8-1.9,2.4-5.1,2.1.3,2,.4,3.5-1.9,3.9-2,.2-3,1-2.8,2.6l-2.2-.6c-2.9,2.1-5.9,4-9.1,5.6.6,4.2.1,14.8-3.9,8.4-1.4,2.3-2.4,1.6-2-1-5.6-.5-2-9.9,1.1-12.9.2-2.8,1.2-4.8,2.9-6.1-.5-3.3,2.8-3.9,5.6-3.9,1-4.6,4.3-5.7,8.4-6.4-.2-1.9-.3-3.3,1.9-3.7-2.8-1-2.8-3,0-4,1.7,0,4.6.5,5.8-.2s4.7-7,5.5-7.6,2,0,2.8-.2c4-.8.6-.9,1.5-3,1.4-3.2,5.5-1.8,6.4-2.4s1.4-2.6,2.5-3.6,3-.6,3.6-1.6c1-1.4.5-4.2,1.1-5.7s2.6-4.7,3-5.4,3.6-1.4,4.5-2.5c1.3-1.8.2-3,3.3-5.7s1.1.3,2.1-.6,2.4-5.1,3.9-7.4Z"
      style={{ fill: "#072213" }}
    />
    <path
      d="M432,147c4.3,4,9.7,3.9,8,11-.5,0-5.8,3.6-8,3-1.2,3.2,1.8,2.7,2,3.5.5,2.3-1.7,1.1-2,2.1-1.5,5.4,5.2,9.8,4,15.4.5,2.1-.2,4.7-2,7.6.8,4.7.1,7.8-2,9.4l-.4,1.8c-3.7,1.1-4.5,1.5-3.8,5.5,3.9,2.2,5.6,11.6-1.8,10.4.3,3.3,0,7.9-4,8.3-3.4,4.5-10.4-2-5-6,0-2.3,1.1-4.8,3.5-7.5-1.3-5.3-.8-9.6,1.3-13-2.4-5.3-2.7-11.1-1.1-17.4-3.1.5-4-2.3-4.7-5-.2-3.3-2.2-5.9-2.1-7.5s3-.1,2.1-3.9-2.7-.6-2-2.6c.2-.3-.6-1.8.6-2,1.7-.4,4.9.7,6.2-.1s2.3-5.1,3.8-6.7,6.5-4,7.4-6.1Z"
      style={{ fill: "#082116" }}
    />
    <path
      d="M432,125c.2-2.5,1.1-2,2-3,2-.2,3.8-.2,5,1.5-2,2.9-3.9,2.7-7,1.5Z"
      style={{ fill: "#032712" }}
    />
    <path
      d="M58,128c7-1,4.7.7,6.3,2.6s6.7,6.8,7.4,10.2-.3,6.4,1,9,4.2,2,3.3,5.2c2.2-1.6,2.1-.4,3,2,1.9,5.2.6,11.5,1,17-1.2-1-3.7-1.7-4-2l-2-1c-2.1-2.2-3-7.4-3.6-8-1.2-1.3-3.4-.9-4.4-2.5l4-2.5c-1-.2-3.6.3-4,0s0-2.2,0-3l4-3c-5.2.9-3.2-4.4-4.1-7.3s-4.8-4.1-5.7-5.6-2.2-7.5-2.3-9.2,0-1.3,0-2Z"
      style={{ fill: "#112f18" }}
    />
    <path
      d="M97,203c2.4-.2,2.1-.1,2,2h-3c-.3-.9,1-1.8,1-2Z"
      style={{ fill: "#092811" }}
    />
    <path
      d="M74,171l.5-1c2.3-.7,1.2,1.8,1.5,2l-.5,1c-.8-.4-1-1.5-1.5-2Z"
      style={{ fill: "#2a4b32" }}
    />
    <path
      d="M78,142v1c-1.5,1.4-2.8.7-3-1.5.6-1.5,2.3.3,3,.5Z"
      style={{ fill: "#213723" }}
    />
    <path
      d="M399,104c-1.8,5.7.8,6.3.6,8.1s-3.3,4.8-3.2,6.4,1.8,2.7,1.7,6-1.7,4.7-2,7c-1,7.3.5,13.8-6.1,19.4-2.3.3-.4-3.9.4-5.6l-.4-1.4-1.5-3.2c1.1-10.4,1.8-20.8,2.1-31.2-1.7-2.3-1.9-4.1-.6-5.5-.9-2.4-.8-3.8,2-3,1.8-3.3,7.7-1.1,7,3Z"
      style={{ fill: "#02541c" }}
    />
    <path
      d="M390,144c5.3.4.3,2.1,0,2.7-.8,1.8,0,3.4,0,4.3-.2,2.9-.9,6.2-2.3,8.7s-6.3,4.1-2.7,7.3c-.3.4-.8.7-1,1-2,10.1-7.3,16.4-16,19-.5,2.3-3.7,3.6-5,1-1.1-.8-1.2-1.4-.3-2.2,9.1-7.7,12.8-11.2,16.9-23,1.4-2.2,2.9-4.1,4.4-5.8,1-4,2.3-8,3.7-11.9l2.3-1.1Z"
      style={{ fill: "#025519" }}
    />
    <path
      d="M398,87c2.1.9,2.3,6.9,2,9s-.8,7.3-1,8c-1.1-2.6-4.7-2.2-7-3l-1.9-1c.5-5.3.6-7.5-1.4-11.8s.5-1.6,1.3-2.2c-2.7-.6-2.6-.8,0-2,2.1,2.9,6.2,2.2,8,3Z"
      style={{ fill: "#02501f" }}
    />
    <path
      d="M386,165c1-1.3,1.1-4.2,4-5-1.1,1.8-1.1,4-4,5Z"
      style={{ fill: "#014d17" }}
    />
    <path
      d="M394,70c-1.2,2.7.5,9.2-1,10-2.4,1.1-4.7,1.8-7,2.1-.5,4.7-3.9,3.7-6.6,1.2-5.7,1-10.7,3.6-14.7,6.3-2.3.6-4.1,1.1-4.7-1.6-2.5,2.3-6,3.1-6-1,.1,0-.3-1.3.2-1.7,1.2-1.1,6.4-2.6,7.8-5.3,6.3-.5,3.7-2.1,6-3.5s3.8-.1,5-1,.7-2.2,1.3-3.2,1.6-.2,1.6-.3c3.7,3.9,9-.9,13.8-1.8s2.8-.2,4.2-.2Z"
      style={{ fill: "#094b24" }}
    />
    <path
      d="M393,80c-.6.3-1.8,0-2.5,1s-.3,2-.5,3,0,1.5,0,2c-2.4,1.4-4.6,3.2-6.5,5.4l-2.5-.4c-2.5,2.5-6.4,3.2-8.2.2-1.9,0-3.5-.3-4.8-1.2-1.4,2.2-1.4-.7-1.3-.5,1.6,5.3-2.4,9.2-6.7,6.5-1.3,1.9-3.4,2.5-6.5,2-3.3,3.2-7.1,5.2-11.2,2.1-5.5,1.2-10.9,2.5-16.3,3.9-.6,1.9-1.4,1.9-2,0-4.6-3.9,6.9-8.9,10.9-7.7l.3-1.2c9.4.3,17-2.4,24.7-7.2,6.9.4,6.7-2.8,11.7-4.8s7.4-1.9,7.8-1.9c1.5,0,4.2,3.1,4.5,2.6v-4s9,0,9,0Z"
      style={{ fill: "#085225" }}
    />
    <path
      d="M384,168c-.2,3,.2,6,0,9s.1,1.4,0,2c-1.9,1.9-3.9,3.6-6,5-2.4,2.2-4.9,4.3-7.7,6.2l-2.3-1.2c-2.7-.4-2.5-1.2,0-2,1.8-.8,8-4.9,9-6.4,1.7-2.5,2.8-9.2,4.9-11.9s2-.5,2.1-.6Z"
      style={{ fill: "#014d17" }}
    />
    <path
      d="M384,179c-.3,1.3-2.7,4.4-3.3,4.7-1.2.6-2.3.1-2.7.3,0,.1.2,1-.3,1.7-1.1,1.7-5.4,3.3-5.7,6.3-.5,0-1.8,0-2,0-1.4,0-2.1-.6-2-2-.9-.3-.9-.6,0-1,3.7-1.6,6.2-3.4,10-5,0-.1-.2-1,.3-1.7.8-1.3,4-3.4,5.7-3.3Z"
      style={{ fill: "#024516" }}
    />
    <path
      d="M368,208c0,1.3,0,2.7,0,4l-.9,2.1c-5.6,1.8-10.5,5.2-14.6,10.1l-1.5-.2c-.5,3.8-1.7,7.3-3.4,10.3-.5,1-3.5,5.3-4.6,3.7-2.9-1-3.8-4.3-1-6-3.6-2.1,2.8-12.5,6-14,1-.4,3.4-.5,3.9-1.2s0-2,.1-2.8c7.4-2.2,8.1-5.5,16-6Z"
      style={{ fill: "#022b11" }}
    />
    <path
      d="M352,208c-.2.2,0,4.7,0,6-.8.2-2-.3-3,.4-1.4,1-.7,2.7-1,3.6-1.5,4.4-5.9,10.8-6,14-1.4,3.4-3.4,6.1-6,8,0,2.9-3.3,2.8-4,0-1.3.3-1.6-3.4-1.3-4.2,6.1-7.3,11.6-15.2,15.1-24,1.3-1.8,4.3-5.3,6.3-3.8Z"
      style={{ fill: "#033811" }}
    />
    <path
      d="M362,200c-2,3.5-6.3,4.9-10,8-5.6,4.7-4.9,5.5-8.1,11.2-3.4,5.9-6.4,11.4-10.4,15.8s-1.8,2.8-1.5,5c-.3,2.7-1.2,2.5-2,0-3.2-.5-2.5-2.8-1-5-.2-.6-.3-1.2-.2-1.8.1-1.2,10.6-16.7,12.6-19.6s1.4-1.9,2.6-2.5c-1.3-2.4,5.5-6.9,8-7,.7-2.6,7.9-6.4,10-4Z"
      style={{ fill: "#023e10" }}
    />
    <path
      d="M368,190c0,0,1.1-.2,1.7.3s.3,1.6.3,1.7c-3.6-.9-1.3,3.4-2,4-1.2,1-2.6-2-6,4-3.5,1.5-6.6,2-10,4-1.7.4-1.7-.3,0-2-.9-.2-.9-.5,0-1-.9-.2-.9-.5,0-1l6-4.1c1.6-4.2,6.8-8.4,10-5.9Z"
      style={{ fill: "#024613" }}
    />
    <path
      d="M165,129c2.4.8,3.2,3.7,0,3-.6-.4-2.6.8-2-2,.3-.4.5-.8,1-1,.3,0,.7,0,1,0Z"
      style={{ fill: "#112b19" }}
    />
    <path
      d="M186,208c2.6,4.6,4.2,9.6,4.9,14.9,0-1.3.3-.9,1.1,1.1,2.5,1.4,2.5,2.6,0,4,2.6,2.2,12.3,29.7,11.2,31s-.8.7-1.2,1c0,1.6.6,3.3,2,5,4.5-1.9,8.8,8.4,5,11-.3.9-.6.9-1,0-2.8,3-9.7-.6-6-4-3.6-.3-5.3-5.4-5.2-8.7-1.2-1.4-2.5-2.9-2.8-4.9-3.3,1.9-5.9-.5-4.1-3.6-3.5-2.1-4.1-6.3,0-8l-.3-4.5c-5.8.2-8-2.2-3.5-6.3.4-.1,1.4.3,1.9-.1,1.2-1,.5-12.6,0-14.2s-3.9-6-4-6.4c-.6-2.5,1.6-6.8-2-6.3l2-4c.4,1,1.4,2.1,2,3Z"
      style={{ fill: "#09220f" }}
    />
    <path
      d="M179,232c4.3-1.1,3.3,3.4,4,6,1.6.4.5-1.6,1.6-2s1.1.1,1.4,0c-2.1,6.4.8,3.5,6,4-1.2,1.7.3,8.7,0,9-.4.5-4.8-1.4-2.5,3s1.7,1.3,2.1,2.1c.6,1.3-.9,2.6-.6,2.9,2.4,2.6,1.4-5.1,4-3s.8,4.1,1.3,5.2c1.1,2.6,3.8,2.8,1.7,6.8h4c1,2.6-1.9,1.8-2,2.5-.6,2.7,1.2,2.4,2,3.5.2,1.4-.8,2.2-2,3-2.4,3.8-5.2.2-4.6-3.5-3.9,1.3-6.9-1.9-3.4-4.5-3-.1-3.1-2.1-1-4-.9-.9-1.9-1.6-3-2-5.2-1.7-1.5-2.9-2.4-4.1s-2.5-.6-3.3-1.3c-4-3.4-2.3-9.5,2.7-4.7,1-3.6-2.2-3.1-4-5.5-.7-1.9,2-1.3,2-2,0-4.4-4.8-4.2-5-4.5-1.1-1.1,1.6-3.8,1.8-4.7s-.7-1.8-.8-2.3Z"
      style={{ fill: "#0d2212" }}
    />
    <path
      d="M173,197c0,1-.5,2.1-1,3h-1c-1.5-2.1.2-4.6,0-7h1c-.7,1.5,1,3.6,1,4Z"
      style={{ fill: "#18291e" }}
    />
    <path
      d="M208,333c0-3.5,8-2.6,7.5,1.3,3.5.9,4.7,4,5,7.3,2,.6,3.2,2.7,3.5,6.3,2.4-5.2,6.3,2.4,2.4,5.7,2.3,1.7,4.3,4,1.6,6.3.3,2.1-.2,4.8,0,7-2.8.8-1.6-2.1-4-3-.5-6.5-.6-2.4-4.1-5.4s-2-4.7-3.3-5.7-5.8-1.4-6.6-.9c-3,.6-1.9-1.5-2-3.5-.2-4.2.2-8.4,0-12.5-2.9-.9-2.4-1.5,0-3Z"
      style={{ fill: "#132a18" }}
    />
    <path
      d="M228,342c4.9-1.3,6.4,3.9,6.3,8.1,1.9-.2,3.3-.3,3.7,1.9,4.8,4.1,8.9,11.4,2,16-6.8.4-7.9-.9-8-8h-4c-.7-4.5-3.9-4.9-4-5.5-.8-2.9,1.8-1.8,2.1-3.9.5-3.9-1.5-2.1-2.1-2.6-3.1-4-.3-8.4,4-6Z"
      style={{ fill: "#0d2615" }}
    />
    <path
      d="M240,352c2.8-4.1,5.8-2.1,9,5.9,3-.3,9.5.7,7,4.1-1.3-.1-.9,1.9-1,2-2.4,1.4-12.1,3.8-15,4-1.4-3.7,2.4-3.6,2.1-6.5s-4.9-6.8-4.1-9.5c.2-2,1.2-1.9,2,0Z"
      style={{ fill: "#112917" }}
    />
    <path
      d="M268,364c1.5.9,2.8,2.2,4,4,2.8,2.9,5.9,5.4,9.4,7.5l.6,3.5c1.7,0,2.4,1,2.1,3.3,3.8,1.6,5.7,4,6,7.3,2.7.6,3.8,1.8,4.9,4.2,4.2-.2,7.2.5,9.1,2.2,1.9,1.3,1.9,2.7,0,4-3.6,3.1-9.4,3.1-13,0-2.8-.4-3.8-1.7-5.2-3.8-7.1-1.1-11.8-2.5-10.8-10.7-1.5,0-2.9-.9-4.2-2.5,1.3-7.1-1.3-11-6.8-14.9-2.2-1.2-5.2-1.8-5.8-2.2-.9-.6-.7-3-1.2-3.8-1.8-2-1.5-2.7,1-2,2.2-2.2,9.4,1.3,10,4Z"
      style={{ fill: "#17321a" }}
    />
    <path
      d="M236,330c1.8,2,3.8,4.2,5.8,6.5-.4,5.1,1.9,7.3,6.2,9.5,2-.2,4,3.9,4,6,1.7.3,2.8,1.8,3.3,4.5,2.7.6,3.6,1.8,2.7,3.5l-1,2c0-.1-.9.1-1,0-.2-.3.5-1.8-.6-2-2.1-.5-4.5.5-7.1-.3s-3.7-7-4.5-7.5-2.6-.1-3.8-.2l-2.1-1.4c-.8-4.6-2.7-9.3-7.2-11.4-1.8-2.9.9-5.6,3.3-7.3-.5-2.9.2-3.6,2-2Z"
      style={{ fill: "#0d3215" }}
    />
    <path
      d="M264,368c2.1,1.1,4.2,1.3,5.9,3.7s2.8,6.6,2.8,7c.1,1.2-1.2,3.9-.7,4.4s4.3.3,4.9,1.1c.9,1.4-.6,6.3.3,7.7s8,1.5,9.8,2.7,2.7,4.2,4.1,5.4c-.1.9-.5,1.9-1.3,2-11,1.2-13.8-2-17.7-12,0-1.6.4-4,0-5.3s-1.9-.6-2-.7c-1-1.4.2-6.8-.9-9.5-1.8-4.6-5.8-1.8-5.1-6.5Z"
      style={{ fill: "#162f1a" }}
    />
    <path
      d="M291,400c4.3.1,8.7.2,13,0,0-.5.2-1,.4-1.4.3-.6,11.2-5.8,11.9-5.9,5.8-1,13.5,5.1,14.7,11.2,3.8-.6,4.1,6.1,0,5-.1,0-1.2,1.3-2.3,1.1s-.5-1.9-1.3-2.1c-1.9-.5-5.1,0-7.3,0s-5.6.5-7.3,0-.2-3.5-6.2-3.1-4.3,2.3-6.2,2.8-3.9,0-5.7.3-4,2.3-5.8,1.8-2.1-2.1-3.4-2.7c-4.2-2.2-7-4.3-10.4-7.1s-2.8-5.3-3-10c4.5.5,4.2,7.8,8,9.5s7.8.4,11,.5Z"
      style={{ fill: "#172b1a" }}
    />
    <path
      d="M352,416c.6-5.3,8.2,3.6,9.9,6.5.2,3,1.6,5.1,4.1,6.4-.3,6.6.3,12.9,2,19.1,2,1.6,1.9,3.4,0,5,1.3,3.7,2.6,7.4,4,11l2.2.4c.6,4.5,1,9,1.1,13.6,4.3-.4,8.2,2.1,3.7,5,2.9.6,1.4,5.3,0,7l-8,1c-.5-.3-2.7.7-3.1-.5-.7-2.6.4-9,0-12.5-.6-6.8-2.4-4-4.1-6.7s-3.7-7.6-3.9-8.1c-1-3-1.9-13.3-2.5-14.3-1.1-1.7-5.2-.6-5.6-.9-1.2-1.1.9-27.9,0-32Z"
      style={{ fill: "#182e1b" }}
    />
    <path
      d="M332,400c1.7-4.7,6.7-1.1,5.9,3.5,1.6.1,3,.9,4.1,2.5,5.4-2.3,17.3,0,16.1,8.1,6.9-1.7,9.9,1.9,8.9,10.9,3,.2,3.8,6.8,1,8l4.3,9.4c2.3,4.8.3,10.7-4.3,5.6-1,0-2.7.5-3.1-.6.2-3.7-.9-7.2-1-10.9s1.1-5.3,1-5.9c-.6-2.2-4.6-1.3-4.9-1.6-.4-.4.9-3.2.7-4.4s-5.5-8.1-6-8.4c-.9-.6-1.9-.1-2.8-.2-3.2-.3-7.4.6-10.3-.2s-7.6-6.4-10.7-6.8c2-1.9,0-3.9,0-5-2-2.5-2.4-3.6,1-4Z"
      style={{ fill: "#182f1d" }}
    />
    <path
      d="M416,480c-.1.8.4,2.2-.1,2.8s-7.8,1.9-9.8,2.8c-2.9,1.2-7.6,5.9-8.8,6.2-2.4.7-4.3-.8-6.6-.6-5,.4-7.9,7.4-11.7-1.2s-.2-4.3,0-7c0-4.6,4.2-3.1,6.5-.2,2.2-.7,3.7-.3,4.5,1.2l1.8-1.7c6,3,7,2.5,10.9-2.7l2.3.4c3.7,0,7.3,0,11,0Z"
      style={{ fill: "#1b301a" }}
    />
    <path
      d="M176,440c2.6,1.1,2.6,1.9,0,3,1.4,1,1.8,1.6,0,2-3.5-.1-4.7-2.5-6.5-5l-3.5,8c-1.3,0-5.8.2-6,0-.8-.7,1.7-7.4-1.5-7-2.2,1.3-1,4.6-2,6s-2.6.9-3.4,1.6-5.9,5.5-6.1,5.9c-1.2,3,5.2-.3,4,3.5-7.4,1.7-6.6-3.6-10.9-7.7.3-7.1-3.1-9.7-4.1-18.3-1.6-.1-1.5-1.6,0-2,.3-2.7,1.2-6.3,4.7-4.7-.8-11.7,7.9-12.3,17.1-11.4.2-1.6.5-3.1,2.3-3.9,2.1-2.8,4.5,1.5,5.7,3.8,1.9,9.9,5.3,18.6,10.3,26.2Z"
      style={{ fill: "#172d1d" }}
    />
    <path
      d="M144,348c2-1.5,3.3-.2,4,4,2.5-.7,4.3,6.7,2,8,.5,2.7.6,5.3.2,8,1.8,4.8.9,8.1-2.5,9.7,9,.6,7.3,13.1.6,14.2-9.2-3.7-13.1-2.5-11.6,3.6-2.3,3.4-2.8,7.9-1.4,13.3l2.2,18-1.5,3.2c0,.6,0,1.3,0,2-.6,0-1.4-.1-2,0-.8-5.8-.6-11.5-1.1-17.4s-2.9-17.3-2.4-22.7,3.7-5.7,4.3-8.6c1.2-5.6-1.8-10.1,1.2-15.3l4,2c.2-2.7-.4-5.8,0-8.4s3.8-5.9,4-8.1c.3-3.7-6.4-5.9,0-5.4Z"
      style={{ fill: "#18291f" }}
    />
    <path
      d="M136,274c1.5,1.6,3.6,4.7,1,6-.4,2.1-.3,4.3.2,6.4,2.6,1.4,2.9,3.2.7,5.1,2.9,5.9,3.6,11.2,2.1,16,4,0,4,4,2.1,6.8,1.2,6.3,2.5,12.5,3.9,18.7,2.6.2,3.8,4.4,1,6,1.9,2.8,3.5,10.9,1,13-.6-.9-3.5-3.1-4-4s-.3-2.8-1.1-4.4c-1.4-2.6-3.1-5.3-4.9-7.6-.6-6.6-1.4-15.3-.9-23.1-4.6-4.5-5-7.4-1.1-8.9-2.7-7-4.5-14.3-5.5-21.9l1.5-2.1c-2.5-6.6-1.9-10,2-10,3-1,4.4,1.9,2,4Z"
      style={{ fill: "#172c1f" }}
    />
    <path
      d="M136,304c-.2.2-1.1,5.1-1.1,5.3.1.9,2,.9,2.5,1.7,2.6,4,1.1,9,1.6,13.4s.3,3.6.3,6,1.5,1.7,1.6,3-2.9,2.4-3,2.5-.7,0-1,0-3.4-1.1-5,0c4.1-3.7.5-5.6.4-7.5s.5-7.5.6-8.9,2.8-3.6,3-6.1-2.1-1.8-3-3.4-1.7-5.8-2-6h1c0-2.9,3.3-2.8,4,0Z"
      style={{ fill: "#12271b" }}
    />
    <path
      d="M186,319c3.7-2.9,5.7-.7,6,6.7l1,2.3c5.9-6.7,8.1,2.9,6.9,9,2,2.6,3,5.6,3.1,9-1.8,7.8,2.5,5.6,3,6,2.6,2.7-3.8,7.1-5.9,4.9s.5-3.4-1-4.5-6.2-.5-6.9-1.6.5-6-.3-8.2-2.5-1.8-3.6-3.4c-2.7-3.9.7-3.3.8-3.8.4-1.4-2-4-3-5.5-2.2,1.1-3.2-1.6-2-3-.8-1-1.8-1.6-3-2-1.9-1.9-1.7-2.1,1-2-2.5-1.1-3.2-4.3-1-6,4.6-1.6,2.5.3,5,2Z"
      style={{ fill: "#181f16" }}
    />
    <path
      d="M192,267c.2.8-.2,2.8,0,3s5-1.4,6,0-3,5.1,2,5c1.5.6,2.1,2.6,1.7,5.8,3.4,3.2,4.1,5.6,2.3,7.2-1.3.6-2.9,1.4-4.8,2.1.6,4.8,2.2,9.7,4.8,14.7-1.2,9.7.6,18.3,5.4,25.8-.1,1-.6,1.8-1.5,2.4,0,1,0,2,0,3h-5c-1.9-1.1-2.9-3.7-3-8-.8,0-1.9-.3-2-.9-.2-1.3-.3-15.3,0-16.2l2-1.4c-.7.1-1.9.3-2.1-.2-2.1-4.6-5.5-15.6-4.5-19.2l-1.4-1.1c0-.3,0-.7,0-1-1.9-1.3-2-2.9,0-4,0-.7,0-1.3,0-2-2-1.1-2.5-3.6-1.7-7.4-4.5-2-3.3-12.5,1.7-7.6Z"
      style={{ fill: "#102614" }}
    />
    <path
      d="M192,289c3.9-2.8.7,3.4,1.7,5.7s1.8,1.1,2,1.6c.7,1.4,4.3,12.5,4.3,13.2v18.5c0,.1,1.5-.4,2,.7.7,1.8.8,5.2,1,7.3.5,3.8,2,5.5,2,6.5,0,1.9-1.9,3.1-2,3.5-3.9-2.6-2.2-3.2-3-6.3s-1.8-.7-2-1.4c-.5-2,.5-9.9,0-10.3s-3.9.3-5,0c-3.2-7.5-3.6-14.9-.9-22.7l-1.8-9.1,1.7-2.2c-.1-1.6,0-3.3,0-5Z"
      style={{ fill: "#111f13" }}
    />
    <path
      d="M160,296l1.4-.2c3.3,8.9,7.4,17.3,12.5,25.3l-.6,2.2c5.9,1.5,7.2,2.6,3.8,8.7.1.6,0,1.4,0,2,2.4-.2.2,4.2-.9,4.4s-3.2-3.9-3.6-5-.4-1.6-.5-2.4c-2.9,3.7-4.9.1-3.3-3.3l-3.7-2.2c-3-4.9-5.7-10-8.1-15.4-2.4-.5-2.7-2.2-1-4-6.7-2.3-8-4.3-4-6,1-1.5.7-2.4-.7-2.7l.7-1.3c-3.1-.7-2.8-4,0-5l1.4-.7c1.7,2.7,3.9,4.6,6.6,5.7Z"
      style={{ fill: "#232d1f" }}
    />
    <path
      d="M160,288c4.1,1.4,2.9,7.2,6,9,1.8,1.1,3.1,3.1,3.8,6.3,5.2,3.7,5.7,7.3,5.9,13.2,2.8,1.9,5,4,6.3,6.5-.1.6-.8,1.3-1,2-.8,2.1-3.2,2.9-1,7h-3c-.2-.8-2.2-3.9-2-4l3-.5c-1.5-2-5.5-1.2-6.6-3.5s.8-2.9.4-3.8-1.6-.4-2.2-1.4c-3.2-5.4-4.5-10.2-7.4-15s-1.1-5.5-2.3-7.8c-4.6-.3-10.3-10.8-3-8,.9-2.7,2-2.8,3,0Z"
      style={{ fill: "#1e2519" }}
    />
    <path
      d="M180,334c.7,0,2.8-.2,3,0s-.1,1.4,0,2c-.4,2.5,0,5.2,1,8,1,5.2,2.9,4.9,3.5,8s-.6,3-.5,4c.2,4,3.7,4.3,7,4-.8,1.6-4.8,4.8-3.9,5.9s1.7-.2,2,.7c.4,1.5-.3,3.6.2,5.6s3.9,5,4.8,7.7c-8.5,1.8-7.5-5.6-10-12-3.2.4-6.2-6-3-8-1.7-1.2-2.4-3-2.1-5.7-5-5.2-10-16.8-1.9-20.3Z"
      style={{ fill: "#1d2c1b" }}
    />
    <path
      d="M172,331c5.3,2.1,2.1,4,3,5,2,2.4,1.8-1.9,2-2s2.3,0,3,0c-1.7,3.1-6.4,10.9,0,10-3.4,5,4,9.1,4.1,9.7.2,1.2-.4,3.6,0,6.3-1.9,3.1-6.2-.4-5.6-3.2-2.8-2.8-5-5.3-4.4-9.9l-2.7-9c-3.8-2.2-4.6-7.4.7-7Z"
      style={{ fill: "#21311f" }}
    />
    <path
      d="M184,327c.4.5,1.3,2,2,3,.2,1.1-.1,4.3-2,3.5s-1.1-3.2-1.3-4.4-4.5-.4,1.3-2.2Z"
      style={{ fill: "#1e2519" }}
    />
    <path
      d="M183,336c.5,2.5.5,5.5,1,8l-3-7.5,2-.5Z"
      style={{ fill: "#1e2519" }}
    />
    <path
      d="M157,310c1-.3,2-.5,3-1-1.9,5.9,3,7.1,4.4,10s-.4,2.1.5,3.5,5,3.1,5.6,4.4-1,3.5-.5,4.1,1.5-.2,2,0c-4.3,4.7,0,3.7,1,5.8s2.6,8.2,3,9.8c.8,3.2-1.9,6.3,3.1,5.4-1.8,2.9,1.8,7.6,5,8,.6,4.4,1.5,4,3,8-1,6.6,4.3,13.3,5,19,2.1,7.2,3.8,14.7,5.2,22.5l-1.2,3.5c.5,2.9.2,5.2-1,7-5.9,2.4-5.6-8.1-4.7-12.8-4.9-1.5-4.4-5.6-3.3-9.7-2.6-2.2-3.3-5.4-2.1-9.5-3.1-3.7-8.2-9.9-3.9-13.9-1.9,0-2.5-1.4-1.9-3.8-6.4,0-5.8-6.2-4.8-10.9-2.9-.5-4.2-1.9-3.9-4.2-3.8-2.2-5-6.3-3.5-12.2-2.5-3.4-3.7-5.4-2.2-9.7-5.9,3.7-11-7.5-3.1-6.8.5-.2-7.3-5.8-8.7-5.2-1.8-5-3.8-15.5,4.1-11.2Z"
      style={{ fill: "#233423" }}
    />
    <path
      d="M192,387c4.2.1,2.8,7.5,3.7,10.8s1.7,3.9,2.4,5.4c2.7,5.7,3.9,13.3,5.3,19.8s10.2,18,12.5,25h-8l-2.3-.7c-2.6-1.3-10.8-29.1-9.7-34.3-2.3-8.8-2.9-17.1-4-26Z"
      style={{ fill: "#1f2f20" }}
    />
    <path
      d="M194,464c2.3-2.7,11.9-3.1,14,0,1.3-2.9,4.7-2.9,6,0,.8,3.9,4.1,15.7-1.6,16.8s-5.9-.9-6.4-1.3c-1-.7-1.1-3-2-4-3.5-4-7.9-5.8-10-11.5-5-.6-3.5-2-6-4-2.1-2.4-2.1-3.8,0-4,4-1.5,9.3,5,6,8Z"
      style={{ fill: "#18361c" }}
    />
    <path
      d="M176,443c.7-.6,1.6-1.5,2.3-1.2,3.2,1.4,11.7,10.6,9.7,14.2v4c-1.1-.9-2.8-2.4-4-3s-2.3-.7-3-1l-5-6c-.3-1.4.1-3.4,0-5s0-1.3,0-2Z"
      style={{ fill: "#17341e" }}
    />
    <path
      d="M208,448c3.5,6.7,4.5,8.1,6,16-2,0-4,0-6,0-2-.1-2.7-2.2-2-6.1-3.5-.1-8.9-2.2-6-5.9-3.1,0-2.8-3.3,0-4,.7-3.3,6.5-2.6,8,0Z"
      style={{ fill: "#1c361f" }}
    />
    <path
      d="M181,456c-2.4-1.1-4.4-2.7-5-6,2.4,1.3,4,3.5,5,6Z"
      style={{ fill: "#172d1d" }}
    />
    <path
      d="M427,444c.4-.7,1.1-1.6,1.7-1.4,2.5.7,8,4,8.5,6.7,3,.4,6.7,1.3,5.8,4.8.2.4-.1,1.4,0,2-2.3-.2-5.2.4-7.3,0s-1-2.8-3.2-2.6-5,4.1-8.5,2.7c0,3.2,2.4,4.4,2,8h-10c-5.4-1.1,4-18.6,5.8-20.2s3.9-2.8,5.2.2Z"
      style={{ fill: "#143819" }}
    />
    <path
      d="M392,472l2.1.8c.8,4.4,4.6,4.2,11.6-.8l2.3,1c.4-2.8,1.1-3.2,3-1,.9,4.8-4.1,4.6-5.8,6.3s0,1.6-.2,1.7c-.9.9-5.3,5.9-5.7,6-4.2.9-6.5-1.9-9.3-2-4.3.7-6.6-9.3-4-12-.4-3,5.5-2.9,6,0Z"
      style={{ fill: "#1c371b" }}
    />
    <path
      d="M410,464c2.1-2.5,3.9-2.5,6,0,0,1.4.4,5.2-.2,5.9s-3.7,1.6-4.8,2.1-2.3.6-3,1c-3.1-.5-3-8.3,0-9-.2-1.3.5-1.3,2,0Z"
      style={{ fill: "#183c1b" }}
    />
    <path
      d="M432,434c2.9-.1,5.9,1.2,9,4,2.2,0,2.9.9,2,3,3.1-.8,4.2,6.3,1,7,1.9,1.9,2.3,3.5,1,5-.5,0-1.4.8-2,1-2-3.6-6.6-2.1-7-2.4-.8-.7-.5-2.5-1.3-3.3s-7.2-4.2-7.7-4.3c-1.9-1.2-3.4-5.7-3-8,1.1-2.3,5.9-3.9,8-2Z"
      style={{ fill: "#173d1b" }}
    />
    <path
      d="M469,436c.1,2.2,0,3.6-.8,5.7s-4,5.8-4.5,6c-2.3.9-4.9-.3-7.1.3s-1.5,3.2-3.9,4.3-5.1.5-7.8.7l-3-4.5c0-1,1.8-.3,2-.5,1.2-2,3.6-2.6,7.3-1.8,2.6-5.3,4.8-6.7,6.7-4.2,9.4,1.3,8.1-5.5,6-13-1.4-2-1.4-3,0-3,.6.8,2.2,1.2,3.2,3.3s1.7,5.2,1.8,6.7Z"
      style={{ fill: "#203d1f" }}
    />
    <path
      d="M458,394c-.7.6-1.3,1.4-2,2v1.5c-6.7,4.2-4.5,9-1,14.5.8,1.2,1,2.6,1,4l-1.1.6c-2.7-2.6-5-2.8-6.9-.6-.3.9-.6.9-1,0-.2,2.1-1.5,2.1-2,0-2.5-2.9-3.3-1.8-2.5,3.4-5.5,3.1-7.9,7.3-6.6,14-.6,2.5-2,1.7-3.9.6-2.6,1-5,2.7-8,2-2.8,1.2-8.6-2.9-8-6h-3.2c-8.3-4.9-17-9.6-26.5-11.5l-2.3-2.5c-2.9,0-2.7-2.3,0-3,3.4-1.9,9.7.4,13,3,1.8-2.2,5.8-.7,6,2,1.5.8,2.5,1.8,3,3l1.2-.6c1.4,1.5,17.2,9.2,18.2,9s4.7-3.1,4.8-3.3c-.4-5.1-.6-10.5,0-14.6,5-3,10.2-6,15.6-9l2.1-3.4c-1.4-1.7.2-3.3,2-2,1-1.4,2.3-2.4,4-3,.6-1.4,3.6-1.5,4,0Z"
      style={{ fill: "#14461e" }}
    />
    <path
      d="M448,416c.2-3.6.5-6.7,5-2.5s2.4,2,3,2.5c3.2,2.9,5.7,6.7,8,10v3c-2.4,1.7-4.4-.5-3-3-.6-1.5-7.9-7.3-8.6-7.2s3.8,16.8,4.7,18.2l-1,1c-.6,1.9-1.4,1.9-2,0-4.2-2.8-5-7.3-4.9-12.3l-1.1-3.7c-2.2-.5-2.2-2.5,0-6Z"
      style={{ fill: "#194a1e" }}
    />
    <path
      d="M458,396c-.1,0,.3,1.2-.2,1.8-1.2,1.3-3.1.6-3.8,3.8s-.2,7.3,2,10.5h-1c-.4-.7-4.9-4.4-5-4.7-1.7-7.6,1.6-7.6,6-11.3h2Z"
      style={{ fill: "#123a1b" }}
    />
    <path
      d="M534,316v1c-2.2,0-3.9,2.3-5.7,3.3l-9.3,15.7c-1,.1-2,0-3,0s-.7,0-1,0c-1.3-2.2,5.4-11,7.5-12.9s.9-.3,1.5-.1c3.2-8.9,6.5-11.2,10-7Z"
      style={{ fill: "#0b3817" }}
    />
    <path
      d="M542,304c-.1,1.6-1,8.7-2,9-.6,1.1-1.7,1.1-1.5.4.7-1.9,2.8.7-.5,1.6-.3,0-.7,0-1,0-1.9.6-2.9.2-3-1-2.6-2.2-2.6-3.5,0-4,0-.7.8-.7,2,0,1.3-4.6,1-9.5-.8-14.8l.8-1.2c-1.4-3.1-2-6.5-1.8-10.1-2.8-2.1-3.5-9.3-2.2-11.9-.6-.3-.6-.6,0-1,1.7,3.1,6.1,8,6.8,11.7,1.2,6.6-2.2,13.2,3.2,21.3Z"
      style={{ fill: "#13521f" }}
    />
    <path
      d="M534,314c.7.3,2.4.9,3,1-.9.5-2,1-3,1s-.8-.9-1.5-.8c-6.3.8-4,2.4-6.7,6.2s-1.7,1.3-1.8,1.6c-3-2.4,1.4-7,4-9-5.9-2.9,5.2-5.9,6,0Z"
      style={{ fill: "#0f4217" }}
    />
    <path
      d="M540,313c4.3-.4.6,2.5-2,2v-3c.2-.3,1.8,1.1,2,1Z"
      style={{ fill: "#0f4217" }}
    />
    <path
      d="M490,365c3.9.8-.7,5.4-2,3,1.4.1,1.4,1.1,0,3-3.5,3.5-.4,4.2-7,7-2.9,2.6-5.3,3.3-7,2-.4-2,1.4-4.2,5.4-6.6,1.4-3.6,3.3-5.1,5.6-4.4-1.2-3,1.9-4.1,3-1-3.2-1.4-.9-5.1,2-3Z"
      style={{ fill: "#0a4b19" }}
    />
    <path
      d="M496,362v3c-2.8,1.3-5.8,3.8-8,6,0-1-.1-2,0-3,2-.5,2.3,1,2-3-2.5-.2-2.5-.9,0-2-1.7-2.6,2.3-4.1,4-2,.6-1.3,1.3-1.6,2-1,1.6-.3,1.6.4,0,2Z"
      style={{ fill: "#13471c" }}
    />
    <path
      d="M481,378c-.7,1.7-3.9,3.1-4.7,4.3s-.2,1.6-.3,1.7c-1.3.1-2.7-.1-4,0-2.5-1.1-.4-4.8,2-4,2-.5,5.3-1.3,7-2Z"
      style={{ fill: "#0b4719" }}
    />
    <path
      d="M484,368c1.4-.9,1.9-.4,1,1-.7.6-10.4,10.3-11,11-.9,1.1-1.8,2.5-2,4-1.6.2-5.7,4.4-8,6-2.5.3-4.4,1.2-6.3,2.9-1.6,0-2.8.4-3.7,1.1-1,1.6-2.1,1.7-3,0-.2.7-.6.6-1,0-2-1.7-1.6-2.5,1-2,2.3-4.7,6.6-7.4,13-8,3.8-1.5,5.6-4.7,7.2-8.3l6.3-4.3c1.3-3.9,3.6-7.1,6.6-3.4Z"
      style={{ fill: "#0a5919" }}
    />
    <path
      d="M464,390c-2.2,1.6-3.7,2.2-6,4h-4c-.6,3.6-1.5,1.3-4,3-2.4-.7-1.1-2.8,1-3,1,0,2,0,3,0,.7-3.8,1-1,3.2-2s2.2-3.4,6.8-2Z"
      style={{ fill: "#134f1d" }}
    />
    <path
      d="M512,336c-.6,1.1-1.7,3.1-2,4l-1.8,3.1c-1.3,6.9-5.9,14.5-12.2,16.9-.5.4-1.4.6-2,1-2.1-1-1.4-2.7,2-5l-1-2.2c5.1-4.8,8.5-10.5,10.3-17l2.7-.8c1.2-2.7,2.8-2.7,4,0Z"
      style={{ fill: "#1e4924" }}
    />
    <path
      d="M528,314c-2.3,2.7-4.1,5.3-4,9-3.1,4.8-1.8,4-5.7,7.2s-1.8,4.6-3.3,5.8c-1,0-2,0-3,0-2.7-2.8,1.7-7.5,5-8-1-.9,2.7-6.3,3.9-7.7s.6-.3,1.1-.3c.3-3.7,1.9-8.7,6-6Z"
      style={{ fill: "#0d491b" }}
    />
    <path
      d="M510,340c-1.4,3.7-1.7,8.6-3.1,11.4s-7.9,9.1-10.9,10.6v-2c1-.9,6.9-7.4,7.7-8.3,2.5-3.2,2.2-9.7,6.3-11.7Z"
      style={{ fill: "#1c3f22" }}
    />
    <path
      d="M528,263c0,.3,0,.7,0,1,1.5,1.7,2.2,4,0,5-2.5,2.6-4.8-2.6-4-5-.2-3-1.6-4.9-2-8s0-2.7,0-4c1.5,3.9,2.8,8.1,6,11Z"
      style={{ fill: "#095b1c" }}
    />
    <path
      d="M532,271v1c-1.2,2.4-3.6,0-4-2-.9-.2-.9-.5,0-1,0-1.7,0-3.3,0-5,3.3,1.1,2.7,4.6,4,7Z"
      style={{ fill: "#105421" }}
    />
    <path
      d="M502,186c0,.7,0,1.3,0,2-.2,5.8,1.5,20.2-2.5,24s-6.9,5.4-7.2,6.3c-1.1,3.3-1.4,9.6-2.3,13s-2.6,1.7-3.4,3.8-.3,4.4-.7,4.9-4.5,2.2-5.8,4.9c-1.2,2.5-1.7,9.9-2.3,11s-3.1,1.8-3.8,2.2c-.4,1.9-.6,2.4-2,1l-1.7-1.9c1.7-3,2.9-6,3.7-9.1.3-3.6,1.7-6.1,4.3-7.4.2-6.7,3-10.8,7.9-15-.6-10.7-.5-14,10-19.1,1.6-6.8,2.1-13.5,1.5-20.1l1.4-.4c0-.2.8-1,1-2l2,2Z"
      style={{ fill: "#027819" }}
    />
    <path
      d="M530,171c2,.5,5,.9,6,3.5s-1.7,1.2-2,1.5c0,1.8-1.2,2.5-3.7,2,0,2.1,0,4.2-2,5.4-5.3,1-8.7,3.2-11.1,8.3l-2.2.3c-.9,2.7-2,2.6-3,0,0-1.3,0-2.7,0-4,.2-4.3.9-11.9,4.5-15s10-2.9,13.5-2Z"
      style={{ fill: "#039831" }}
    />
    <path
      d="M515,192c1.5.4,3.2,1,5,1.7,2.1-2.6,6.1-5.8,8-1.7l-4,11c-2.7,1.7-6.1,2.7-8.6,0s-.9-3-1.5-4.3c-1.7-3.3-1.6-1.3-1.9-6.8.9.2,2.4-.3,3,0Z"
      style={{ fill: "#029829" }}
    />
    <path
      d="M534,176c-.5.4,3.5,7.2,2.1,12.4s-6.9,3.3-8.1,3.6-2.3-.3-3.9.5-2.1,2.8-3.5,3.1c-4.9.8-4.7-3.2-5.5-3.6.5-2.3,4.3-7.2,6.2-8.3,5.6-3.1,7.8.7,6.7-7.7h6Z"
      style={{ fill: "#029b2e" }}
    />
    <path
      d="M520,262c1.9.6,1.9,1.4,0,2,1.7,2,2,4.1,0,6-.9,1.9-1.7,1-2-1-2.6,1.3-7.6-6.6-6-9-2.6-2.2-3.3-4.2-2-6,.2-1.6.9-2.6,2-3,2.4,3.6,5.4,7.4,8,11Z"
      style={{ fill: "#048829" }}
    />
    <path
      d="M524,264c1.4,1.8,2,3.9,4,5,0,.3,0,.7,0,1,1.6,1.2,1.9,2.3,0,3,1.4,2.4,1.6,4.7,0,7-1.9,1.2-3.4-.3-4.5-4.5-.8-4-1.9-7.9-3.5-11.5,0-.7,0-1.3,0-2s0-1.3,0-2c1.2,1.1,3,2.8,4,4Z"
      style={{ fill: "#0c7325" }}
    />
    <path
      d="M496,240c.7-1,1.5-2.2,2.7-1.7,5.8,2.6-1.3,6.4-4.8,7.4-8.8,6.7-16.1,20.7-14.9,31.8l-1,.5c1.8.5,1.1,3.7-1,3-.8,0-1.7-.1-2.3-.7s-.2-15.4,1.3-16.3c0-.1,1.3-.4,1.7-1.7,2.4-8.2,3.5-13.2,10.3-19.3s4.5-3.3,8-3Z"
      style={{ fill: "#02881e" }}
    />
    <path
      d="M476,264c-1.2,4.7-.5,12.4,1,17,5.2,6.5-9.4,5.6-13,3-.9-.2-.9-.5,0-1-1.9-.9-1.9-2.1,0-3,0-3,8.9-2.7,8.7-.7.1-5.5.5-10.9,1.3-16.3l2,1Z"
      style={{ fill: "#018221" }}
    />
    <path
      d="M474,263c0,5.7,0,11.3,0,17h-10c-3.1,0-1.9-2.4,0-4,.3-2,2.3-2.6,6.2-1.9l-.2-13.8,2-1.3c.7-.4,1.4-.6,2-1v5Z"
      style={{ fill: "#037a1e" }}
    />
    <path
      d="M496,240c-.6-2.7,1.9-5,4.3-4.8s5.6,7,7.3,9.6,2.9,4.2,4.3,6.2c0,1.3,0,2.7,0,4l-2-1c-.8.3-1.8.4-2.4-.2-5.6-5.4-13.2-6.4-17.3,1.3l-2.3.9c-2.1,4.6-4.4,9.8-6.1,14.4s-1.6,4.2-1.9,7.6c-.3,2.7-1.2,2.5-2,0,.4-7.1-1.7-6.9,1.7-14.7s2.3-5.4,3.6-8.5,8-9.7,9.5-10.7c2.3-1.5,11.2-3.4,3.2-4Z"
      style={{ fill: "#028f21" }}
    />
    <path
      d="M188,262c.9-1.2,2.6-.3,3,1,.4,1.4.7,2.5,1,4-2.5,0-3.1,1.2-3,3.5s2.7.5,3.1,2.1-.1,6.9,0,9.5c-7.6,0-.6-8.4-8-8l3-2.5-4-9.5c1.6.1,3.4-.2,5,0Z"
      style={{ fill: "#102215" }}
    />
    <path
      d="M192,284c0,1.3,0,2.7,0,4-2,.4-2.4-1.6-3-2,.4-1.4,1.5-2,3-2Z"
      style={{ fill: "#102215" }}
    />
    <path
      d="M191,263c-1.2.5-2.6-.9-3-1v-1c.2,0,2.1-1.7,3.5,1s-.5.9-.5,1Z"
      style={{ fill: "#172b1d" }}
    />
    <path
      d="M112,232c3.3-5.8,6.1-1.9,6.5,3.2l3.5,6.4c-1.4,2.3-.8,4.4,1.8,6.3,1.1,6.6,3.2,12.9,6.3,18.9l-2.1,5.1c2.3,4.7,2.3,8.4,0,11-5.1-.9-4.4-6.9-5.4-10.1-1.1-3.6-3-3.1-2.6-8.9,5.4,6.1,5.8.9.5-2-.9,0-.4,1.3-.5,2-1.7-1.9-3.6-3.4-4-6.5,7.2-2.9,2.8-3,.6-7.6s.1-2.1-.2-2.9c-.6-1.4-3.1-2-2.3-3.9s3-.9,2.9-3.1c-1.6.7-4.8-2.2-5-2-.2-2.1-.1-3.9,0-6Z"
      style={{ fill: "#0f2915" }}
    />
    <path
      d="M132,280c2.3,7.6,2.1,16.9,6,23.5,0,1-1.8.2-2,.5h-4c-1.6-7.6-3.6-13.1-4-21s.1-7.4,0-11c2.5,0,3.8,2.7,4,8Z"
      style={{ fill: "#11291a" }}
    />
    <path
      d="M114,224c1.3,0,2.7,0,4,0-2.1,6.2.3,4,1.8,6.7s2.2,8.7,2.5,9.1,1.7.1,1.7.2c.5.7-.4,6.6,0,7s3.7-.4,4,0c1.5,1.5-3.4,14.2,4,17,3.1-5.5,6.5,4.3,2,6v1.3c-3.6,1.3-1.6,5.4-2,8.7-.9-2.9-2.6-5.7-4-8-3.6-6.2-.3-2.9,0-4.6.5-2.5-2.9-7.9-4.2-10.5-2.3-4.6-1-3.7-1.9-8.1s-4.9-4.3-3.9-5.7,1.8,0,1.9-.6c.5-1.5-2.6-4.4-3.4-6.6-1.5-4,1.3-6.3-4.6-3.9,0-.3,0-.7,0-1s.8-.4.9-1.4c.2-2.3-2.7-3.9,1.1-5.6Z"
      style={{ fill: "#142a18" }}
    />
    <path
      d="M160,250c3.9-1,4.3,3.4,6,4.4s1.9-.5,1.9-.4c.3.5,0,2.7,1,4.5s6.6,5.2,6.8,5.6c.9,1.6-.3,5.6.4,8.1s5.8,7.1,7.2,10.8.4,4.7.6,4.9c.4.4,3.2-.5,3.9.1s.2,6.4,4.1,5.9c.4,5.8,4.5,10.8,0,18.5l1.1,15.5c-3-.7-2.1-6.9-3.2-7.8s-2.2,0-3.8-1.2c-1.6-3.9-.5-9.3-1-12.4s-2.3-2.7-2.7-4.2.8-3.4-.3-5.6-4.4-2.7-5.7-4.2-7.8-11.8-8.3-13.4-.3-5.9-1.1-8.3l3-.5c-2.1-3.5-4.3-6.5-7.4-9.2s-2.4,0-2.7-.8c-1.5-5,3.1-3,3.1-4-4.6-.2-2.9-2.7-3-6.5Z"
      style={{ fill: "#141a11" }}
    />
    <path
      d="M170,292c1.4.7,1.3,2.7,1,3-.5.5-3.3-1-3,1,3.5-.4,1.6,6,4,7.6s3.7.2,4,.5c1.9,1.8-3.3,15.8,5,13,.8.4,1.1,5.7,1,6l-6-2,2-1.5c-5.8-2.5-2.6-4.6-3.5-7.5-1.5-4.6-8.3-7.2-8.5-15s.9-1.1,1-1.5-3.9-.8-2-2.5,5.7,3.2,5-1Z"
      style={{ fill: "#1c1f16" }}
    />
    <path
      d="M141,256c2.8,3.2,2.4,6.1,6.8,7.7s4,0,4.2.3c.5.5-.5,6,0,7.4s2.8.4,3.6,1.5.3,4.3.9,6.1c.9,2.8,5,4.5,3.6,8.9-.7-.2-2.1.2-3,0-4.7-3.1-7.4-7-10-12.2-2.9-3.6-5.3-7.2-7.1-10.8,2.5-.4,2.8-.7,1.1-1-2-2.7-6.5-12,0-8Z"
      style={{ fill: "#1e241b" }}
    />
    <path
      d="M136,248c6.1-1.6,3.8,5.2,8,7.5-.3,1.1-2.1.3-3,.5-4.9,1-.4,1.8.5,4s-.5,2.8-.5,4c-1.6,3.2-7.5-1.4-5-4-.7-2.2.2-8.9,0-12Z"
      style={{ fill: "#1d261b" }}
    />
    <path
      d="M136,260c.4,1.1,3.6,3.6,5,4s2.1-.2,3,0c.4,1.8-1.7.6-2,1-1.6,1.8,1.6,2.1,1.7,2.3,2.4,4.6,4.5,6.2,6.2,9.3s.8,4.4,1.3,5.2,7.5,2,5.8,6.2-2.4.8-2,2.5,3.4,2.3,5,5.5c-6.1,2.3-3.7-2.5-8-5-3.1,5.8-5.1-2.5-3.3-6.5l-.4-1.8c-6.5.4-10.7-2.5-12.4-8.6-.5-2.1-1.9-3.7-2-4-.3-1.4.4-5.4,0-6s-1.6.2-2,0c-.3-2.6,1.7-6.1,4-4Z"
      style={{ fill: "#1e2b1e" }}
    />
    <path
      d="M499,186c-1.1,7.4.5,4.7,1,6.7,1.1,4.3-.9,6.7-1.8,10.1s0,3.3-.3,4.5c-.7,2.3-9.1,5.3-9.6,7-1.2,3.6.4,8.5-.3,12s-5.3,5.4-5.7,6c-1.2,1.8-1.8,8.3-2.5,9.5s-2.5.7-3.3,2.2c-1.6,3,1.1,2.2-2.5,4-.2,4.1-3,7.7-2,2,.4-.7,3.2-12.2,4.5-15,2.5-5.5,3.1-1.9,3.5-11,2.3-.3,3.2,1.4,4-1.5,1.4-5-.7-12.8.6-15.5s3.9-.4,5.2-1.1,5-6.2,5.2-6.6c1.3-2.7.9-4.6,1-7.3s-.1-6.7,0-10l3,4Z"
      style={{ fill: "#026f17" }}
    />
    <path
      d="M460,265c-1.6,2-1.6,2.6-2,5-3.6,7.8-5.1,16.4-2.2,24.7l-1.8,1.3c-1.8,6.4-2.3,14-1.7,23l-1.3,1c-.3.9-.6.9-1,0-1.1-.1-1.6-1-2-2-3.1.8-3-5.7,0-6-3.5-10.9-2.9-20.9,1-31.4l-1-1.6c-.9-.2-.8-.6,0-1,1.1-1.8,1.1-2.2,0-4-.9-.2-.8-.5,0-1l1.3-1.8c0-2.6,2.9-9.5,6.7-7.2.2,0,2.3,1.9,4,1Z"
      style={{ fill: "#025a21" }}
    />
    <path
      d="M458,270c-.1.6.1,1.4,0,2l.9,1.3c-2.1,11.4-1.5,21.4,1.5,32.8,0,2.7-1,5.4-3.3,7,.4,3,0,5.3-1,6.9.2,2.7-.5,3.1-2,1-3-7.5-2.2-17.2,0-25-.1-4.8-2.1-8.9-2.1-9.5-.1-4.6,2.4-8.6,2.7-11.5s-4.5-1.7,3.4-5.1Z"
      style={{ fill: "#036523" }}
    />
    <path
      d="M474,248c-.3,2.3.5,5.2,0,7.3s-1.7-.4-2,3.7.6,16.4,0,17-6.4-.3-8,0c-.5,1.7-.8,2.6-2,1-4.3,3.8-4.5-2.8-2-5,1.2,0,8,.4,8,0v-15.5c0-2.5,3.8-6.1,4-6.5.9,5.3.4-1.2,2-2Z"
      style={{ fill: "#026f1d" }}
    />
    <path
      d="M460,272c.3,2.6-1.2,5.7,2,5,.6,2.3.2,5.5-2.2,6.7-.7,6.3.2,13.2,2.7,19l-.5,1.3c3.3,2.8,1.4,7.6-2,10.2.8,6.6-.5,9.2-4,7.8-1.7.2-1.2-1,0-2,0-2.5,0-5.3,0-7.4s1.8-.1,2.1-2c1.8-13.5-3.7-22,0-38.6h2Z"
      style={{ fill: "#026d24" }}
    />
    <path
      d="M468,227c-.6.5-4,3.4-4,3.7-.7,2.8.8,8.2,0,10.7s-2.6.5-3.6,1.6.1,4-.3,5.5-1.9,1.5-2.1,2.2c-.8,3.5-1.1,6.5-2,10.3-.4,2.3-1.4,2.3-2,0-3-.2-2.7-4.4,0-5-.8-1.8,0-5.1.9-7-1.1-7,.1-10.7,3.7-11l2.1-11.6c.6-1.6,1.5-3.1,3.3-2.4l4-1c0,1.3,0,2.7,0,4Z"
      style={{ fill: "#024f19" }}
    />
    <path
      d="M456,261c-.2.8.1,2.1,0,3-1.1,0-3.5,3.3-4.1,4.4-1.6,2.9.4,5.4-3.9,4.6-4.6-4.9-1.1-13.8,6-12,.4-.2,1.4.1,2,0Z"
      style={{ fill: "#02521f" }}
    />
    <path
      d="M464,222c0,.7,0,1.3,0,2s-1.4,1.7-1.6,2.9c-.4,2-1.5,12.1-2.5,13.1s-3.6-.3-4,0c-.6.5.9,13.8-2,16-3.6,4.7-3.3-1-3-4-2-1.7-1.6-2.3,1-2,.5-.9,1.1-1.6,2-2-4-.6-.9-13.8.6-14.6s.9-.3,1.4-.4c3.5-3.5,1.1-9.9,8-11Z"
      style={{ fill: "#014818" }}
    />
    <path
      d="M192,217c0,2.3,0,4.7,0,7-.6,0-1.5.3-1.9-.1-.6-.6-1.7-7.5-2.2-9.1s-1.5-1.2-1.9-6.8,0-2,0-3c2.9,3.4,1.9,9.6,6,12Z"
      style={{ fill: "#062b0f" }}
    />
    <path
      d="M200,232c3.6.6,1.1,1.1,2.6,5s2.7,2.7,3.1,3.3c1.1,1.9,0,3.6,2.3,5.7,1.5,2,1.5,3.3,0,4-1.3,1.7-2.4,1.2-3.8,0-1.6-3.8-2.9-7.1-4.2-10-1.3-2.1-2.3-6.4,0-8Z"
      style={{ fill: "#01370c" }}
    />
    <path
      d="M208,240c2.4.4,4.4,2.4,6,6,3.7,3.9,5.1,7.2,4,10-.2,2.2-.9,1.7-2,0,.1,1.7-.5,2.6-1.8,1.4-1.9-4-4-7.8-6.2-11.4,0-2,0-4,0-6Z"
      style={{ fill: "#014604" }}
    />
    <path
      d="M201,225c2.1-1.1,1.8-2.4,3,.5s1.5,6.8,4,8.5c.9.2.9.5,0,1,1.6,1.6,2.4,3.9,0,5-1.3-2.1-7-13.1-7-15Z"
      style={{ fill: "#014b07" }}
    />
    <path
      d="M192,224c1,.1,3.8-.3,4,0s-.3,2.3,0,3.2,1.5.6,1.9,1.6c1.1,2.2-1.8,2.6,2,3.2.1,2.7,0,5.3,0,8,3.8.6,1,.9,2,3.2s1.4.6,1.9,1.6c1.7,3.5-.6,2.6,4.1,5.2,6.7,10.5,10.1,23.7,11.6,36.2l-1.6,1.8c-.3,2.7-1.2,2.5-2,0-.2,2-1.2,2-2,0-.8-.1-1.6-.5-1.7-1.2-.3-3.5-.5-5.2-1.1-8.3,0-5.5-.5-9.7-4.8-14.1-1.4-3.2-8.6-20.6-8.2-22.4s1-1.6,1.8-2c-1.7,3-3,1.2-3.9-5.4-1.7-1.9-3.1-4.1-4.1-6.6,0-1.3,0-2.7,0-4Z"
      style={{ fill: "#042e0d" }}
    />
    <path d="M196,184c-.6-2.3-2.5-5.6,0-8v8Z" style={{ fill: "#015516" }} />
    <path
      d="M208,221c1.1,1.4,1.9,2.4,0,3-.6-.2-2.2.5-2.8-.1s-.9-4.6-1.2-5.9c-1.5-7.1-1.8-11.5-2-19s0-2.7,0-4l1.5-.6c1.8,7.6,3.9,15.5,6.2,23.7l-1.7,2.9Z"
      style={{ fill: "#016903" }}
    />
    <path
      d="M208,228l1.4-.2c.6,2,1.4,3.4,2.6,4.2,3.7,6.6,10.5,8.3,16.5,3.9l1.5,2.1c4.4,0,6.3,7.4,4,10,.6,2.6,0,2.7-2,1-.7,2.6-3.9,2.5-6.1,2-6.8-1.7-10-5.5-13.9-11-1.8.1-3.2-1.5-4-5,0-.3,0-.7,0-1,0-2,0-4,0-6Z"
      style={{ fill: "#016203" }}
    />
    <path
      d="M212,232c1.7-1.1,4-.5,6.8,1.8.8-4.2,7.5-5.6,9.2-1.8,2.5.5,4,4.2,2,6-3.4-2.6-1.3,1.4-2.1,1.9s-2.6-.4-3.7.4-.2,1.6-.3,1.7c-.5.3-1.6-1.5-2.7-1.7-3.6-.9-6.2,0-8.4-4.6s-.6-3-.9-3.6c-.3-.1-1.3.3-1.8-.1s-.6-3.2-2.2-3.9c0-1.3,0-2.7,0-4,1.9-1.7,7,6.6,4,8Z"
      style={{ fill: "#026a01" }}
    />
    <path
      d="M360,88c-4.1,7.4-12.1,7.5-19.6,8.9s-3.6-.6-5.4-.3-11.1,4-11,7.3c0,2.3-1.5,2.2-2,0-2.2.9-1.6-1.9-2.5-2-2.3-.4-.8,1.4-2.7,2.3-3.6,1.8-16.2,2.8-20.1,1.7-1.1-.3-.6-1.9-.7-2-.4-2.1,2.2-1.5,3,0,3,0,6.4.4,9.3-.3s4-3.6,4.4-3.7c1.9-.4,5,.6,6.4-.5s.4-3.3,1.5-3.6c1.6-.4,4.7.4,6.9,0s2.6-2,4-2,.8,3.3,3.5,2c-1.3-5.3,6.1-2.2,10.3-3.2s6-2.8,6.4-3.1.2-1.6.3-1.7c.2-.2,1.2-.4,2-1,1.9,1.3,3.9.9,6,1Z"
      style={{ fill: "#054e24" }}
    />
    <path
      d="M293,101c2.2.1,6.9.4,6,3h-3c-1-.6-3.9,1.6-3-3Z"
      style={{ fill: "#024c23" }}
    />
    <path
      d="M324,104c.6,0,1.3,0,2,0,3.7-2.4,6.4-2.4,8,0,.2.4.4.8.4,1.3.1,1.4-9.8,5-11.8,4.1.6,3.1,0,3.7-2.8,4.9s-22.9.3-24-.2c-.7-.9-1.6-1.6-2.9-2-.6,2.7-4.4,2.5-5,0v-6c4,.5,3.9,2.3,5.7,2.8s16.2,1.4,17.8,1.2c4.1-.5,7.7-5,10.5-6s1.4,0,2,0Z"
      style={{ fill: "#045725" }}
    />
    <path
      d="M240,104c1.9,0,5.6-.4,7,0,2.8.9,1.9,4-1,4l-.8,2-9.7.3c-3.3,5-12.2,5.1-16.4,1.7l-.6-1.3c2.8-.3,4.6-1.2,5.6-2.7,2.4-.8,8.2-3.9,8.7-4,1.8-.4,5.2,0,7.3,0Z"
      style={{ fill: "#016221" }}
    />
    <path
      d="M224,108c.6,4.2-.8,2.7-4.3,3.2l-.7.8c1.2,1.4.6,1.9-1,1,.8,2.2-3.1,7.9-4.7,9.9s-4.4,5-6.3,5.1c-1.2,2.6-1.5,2.5-3,0,2-5.3,3.4-7.4,6-11.7s.6-3,1.3-4c1.6-2.3,10-3.4,12.7-4.3Z"
      style={{ fill: "#02611b" }}
    />
    <path
      d="M205,192c1.2,0,1.6.4,1.7,1.4.5,5.5,2.3,23.5,8.9,25.3,4.3-1.8,7.1-1.4,8.4,1.3,1.7,1.6,2.8,3.3,0,4-.2,1.1-1,1.7-2,2.1-5,12.6-10.5,2-14-5.1-.7-7-2.9-14.3-4-21.5s-.2-3.5-2-4.5c0-1,0-2,0-3-1.5-.7-1.8-5.3,0-6,3-1.2,5.6,3.9,3,6Z"
      style={{ fill: "#027802" }}
    />
    <path
      d="M206,144c-1.1,10.1-1.8,20.2-2.1,30.3l-1.9,1.7c2,.4,2,1.4,2,3,.7,2-.4,4.9-3,4-.3.9-.6.9-1,0,0-4.4.4-8.5,0-13-.8-7.7-.3-18,1.7-25.4s.6-.5,1.3-.6c.7-2.8,2.2-2.7,3,0Z"
      style={{ fill: "#017018" }}
    />
    <path
      d="M207,128l1.5,1.8-3.4,13.3-2.1.9c-.8,4.4-.6,7.3-1.1,11.4s-3.2,9.2-1.9,14.6c-.2-2.6-1.2-9.8-2-12-.7-2.9,1.6-5.1,1.9-7.6.8-5.3.9-11.1,2.1-16.4l2-4c.2-.6-.2-1.5,0-2,1,0,2,0,3,0Z"
      style={{ fill: "#016617" }}
    />
    <path
      d="M202,186v6c-3.3,1-2-5.9-2-9h1c2.6.1,3.1,1.3,1,3Z"
      style={{ fill: "#016903" }}
    />
    <path
      d="M202,134c.7-3.3,1.9-3.8,2-4,.2,1.8-.2,3.5-2,4Z"
      style={{ fill: "#02611b" }}
    />
    <path
      d="M247,104c4.5,1.2,5.7,1.2,10.5,2s3.4,2.5,5.2,2.7c3.1.4,8.5-1.4,12.8-.7s6.4,3.4,12.5,4,3.3,0,5,0l3.4,1.1c4.9,1.3,6.5,3,4.6,4.9-.2.5-.5.9-.8,1.3-.8.8-21.3-3.6-22.1-4.4s.4-2.9,1.9-2.8l-1.1,2c-3.7.4-12.5.7-14.4-2-.1.6-.3,1.2-.6,1.3-.7.3-14-2.4-15.4-2.9s-.4-.7-.4-1.4c-1.8,1.9-2,1.6-2-1l1-4Z"
      style={{ fill: "#026227" }}
    />
    <path
      d="M432,199c3.4,4.1,2,8.5,0,13,0,3.2.6,8.9,0,11.4s-2.4.2-2.9.7c-.9.9-1.3,8.3-1.9,9.6s-2.2,1.1-2.3,1.3c-.5.8,1.8,1.6,2,2,1.9.9,2.1,1.5,0,2-.2.9-.5.8-1,0-1.2-.8-1.4.5-.5,3.9l-5.5,16.1c1.8,3.3,2.5,5.4,1.2,9.2,1.5,3.5,2.4,7.4,2.6,11.9,1.3.8,2.1,1.8,2,3.3s-2.8,6.5-3.9,6.7c-.2,2.2-1.2,3.2-3,3-1.5,2.6-2.9,1.9-3-1-2.7-1.3-2.8-3.7,0-5l-2-2.5c-.7-14.9-1-28.7.7-43.4,0-.3-.4-.7-1-1.1.9-3.5,2.8-6.8,5.5-10.1.5-2.4,1.4-4.1,2.9-4.9.5-1.8,1.5-2.9,1.9-4.6s-.4-3.8.5-5.5,4.2,1.3,5.5-1.5-2.6-1.2-3-1.5l2-4c-3.8,1.4-3.8-3.6-2.9-6.4s4.1-2.1,5.9-2.6Z"
      style={{ fill: "#082519" }}
    />
    <path
      d="M454,185c-3.3,9.3-5.5,12.5-6,23-.2,3.3.1,6.7,0,10-2,7.7-5.3,14.4-9.8,20.2,1,5,.2,14.5-6.2,10.8l-2-1.1c.1-4.9.8-9.9,2-14.9,2-1.7,1-1,2.6-2.9s2.8-1.5,3.3-2.8c1-2.8-.5-7.7.2-10.7s1.8-.6,2-1.4c.4-1.6-.3-3.2.3-5.1s3.5-2.2,3.7-3c.4-2.1-.4-4.4.2-6.6s3.1-2.6,3.7-4.4-.8-4-.1-6,4.1-5.2,6.3-5.3Z"
      style={{ fill: "#023018" }}
    />
    <path
      d="M448,168c0,2.1-.5,5.9,0,7.5s4.3-.2,3.1,2-2,1-2.7,1.8c-2.8,3.6-2.4,2.4-4.9,4.7s-5,9.1-5.4,11.6.9,7.6-.2,10.8-5.3,2.5-5.9,5.6c0-4.3-.1-8.7,0-13,.2-8.5-1.7-10.3,4-17,.6-.7,2.1-1.2,3-2.5,2.2-3-.5-4.5,7-3.5l-3-7.5,5-.5Z"
      style={{ fill: "#042515" }}
    />
    <path
      d="M432,249c3.2,1.8,4.1,12.3-1,12,.1,2-.8,4-2.7,6,.8,3.6.1,9.2-3.3,5-3-1.5-3.5-10.3-3.2-13.7.4-4.9,1.4-10.1,5.2-13.6-2.2-2.2-2.6-4.2-1-5.7.3,0,.7,0,1,0,1.7-.2,2.7-.5,3-1,.5-1.5,1.1-4.2,2-5v16Z"
      style={{ fill: "#052e1a" }}
    />
    <path
      d="M430,238c-.2.5.1,1.4,0,2-1-.4-1.9-.8-3-1-.1-.6.2-1.7,0-2l3,1Z"
      style={{ fill: "#19402e" }}
    />
    <path
      d="M448,218c0,3,.2,6,0,9,3.8,2.5.4,11.8-1.3,15.6s-4.5,7-6.7,7.4l1,2.5c-2,1.6-4.7,7.3-2.2,9.2.7,1.1-1.5,9.8-1.7,10.3-1.4,2.4-4.2,2.9-5.1,0-2.9-1.9-2.2-8.1-1-11,3.4-4.4.3-6.9,1-12,7.4-2,3.5-8.5,4.1-11.3s5.1-6,6.2-8.8c1.6-4,.5-10.3,5.6-10.9Z"
      style={{ fill: "#023519" }}
    />
    <path
      d="M454,230c-.3,2.5-.9,5.3-2,8.3,3.4.1-4,12.2-4.9,12.8-2.2,1.3-3.4.6-5.1-1.1-.6,1.9-1.4,1.9-2,0,2.4-6.2,3.6-5.5,5.7-9.8s2-9,2.3-13.2l4-2c.5,1.8,1.6,3.2,2,5Z"
      style={{ fill: "#013b1a" }}
    />
    <path
      d="M456,230c0,1,0,2,0,3-.3,5.4-1.9,4.9-2.1,6.4-.3,2.6.3,5.8,0,8.6-1.6.8-2.2,1.5-2,2,0,.4-.7,1.4-1,2,.8,2.6-.3,5.6-3.2,8.9-1.4,6-2,12.4-2,19l-3.9,5c1,4.9,1,9.3,0,12.9,1.3,3.1,4.4,11.9.3,13.1-.5,3.1-1.7,4.6-4,2-4.3.7-1.5-5.5.2-7.7-.5-5.7-1.1-11.3-1.7-17-.7-.3-1.3-.5-1.4-.9-.8-6.6,1.1-16.2,4.1-22.1-2.3-5.9-.6-10.4,2.9-15.3,7.2,0,4.3-2.5,6.6-5.9,1-1.5,4.2-1.6,3.3-4-3.7.1-.9-8.9,2.1-10.1s1.4.1,2,0Z"
      style={{ fill: "#02421b" }}
    />
    <path
      d="M416,176l4,1-2,2h5c-.6,4.5-1.7,10.2-1,14.6s1.8,3.4,2.1,5-1.9.8-2.1,2.9c-1.1,8.9,4.4,9.2-4.9,17.6l-.9,2.6c-2.1,4-4.6,8.5-8.1,2.4-2.3,1.4-5,1-7.5.6-2.7,2.1-2.8-1.7-2.5-4.2l-11.8.7c.1,4.4-8.4,6.3-10.3,2.9-.3.9-.7.9-1,0,.9-4.4,1.9-8.2,5-11.5,4.8-5.2,6.5-3.2,10.3-5.4s1.9-3,3.1-4.1,6.1-4.8,6.2-5.2c.6-1.8,0-5.6.3-5.8.4-.4,4.1.6,4.9-.1s2.5-7.2,5.4-9.6,6,0,5.7-6.3Z"
      style={{ fill: "#091e15" }}
    />
    <path
      d="M375,224c.3,0,.7,0,1,0,2.2,1.4.9,4.1-4.1,8.2,6.9,4.5,7.4,8.8,1.4,12.7,5.8-4.1,9.8,6.9,8.2,9.6s-.8,1.3-2.6,1.5c-1.1,2.6-1.9,2.6-3,0-1.7,4.2-12.1,2.9-10-3.7-4.5.3-8.8-.2-12.7-1.6l-1.3,1.3c-3.4,4.8-6.4-4.4-2-6,2.7,1.8,1.9,1,2-1.5s-.5-7.4.2-8.4,9.1-4.7,10.8-4.2l.9,2c-2,1.8-1.4,2.4,1.6,2-2.4-3.1-1.3-5.5,2.5-6,.4-1.3-.4-5.6,0-6s5.6.1,7,0Z"
      style={{ fill: "#0b1f16" }}
    />
    <path
      d="M348,245c.2,0,2,.9,2,1,.7,1.9-1.7,6.5,2,6,2.5,1.8,5.3,6.5,1,8,2.2,2.2,1.3,13.1-3,8-3.1.5-4.2-2.4-4.4-5.1l-1.6-.9c-.6,1.9-1.5,1.9-2,0-.8-.1-1.9-.5-2-1.2-.3-2.7,0-12.5,3-10.8-1.2-2.8,2.5-8.1,5-5Z"
      style={{ fill: "#0d261c" }}
    />
    <path
      d="M351,224c2.5-.3,2.5,0,1,2,1.1,6.8-7.5,11.2-4,19-2.1-.2-2.7,3.8-5,5-2,.8-2.6.2-2-2-3.1-1.4-.8-9,2-10,1.2-1.1.8-2.6,1.7-4s3.1-1.9,3.3-2.7c.3-1.7-.3-6.8,0-7.3s2.3.2,3,0Z"
      style={{ fill: "#052715" }}
    />
    <path
      d="M368,212c0,1.3.2,5.8,0,6s-2.9,0-4.2.3c-5.5,1.6-3.3,5.1-3.8,5.7s-6.8-.3-8,2c0-.3-1.1-1.2-1-2,.8-.3.9-2.2,1.8-3,4-3.8,9.6-8.9,15.2-9Z"
      style={{ fill: "#042511" }}
    />
    <path
      d="M368,230c-.1.4-2,1-2.1,1.7-.3,2.2,2.7,3.9,2.1,6.3h-8c-1.1-1.1,2.1-5.1,3-6,1.7-1.6,2-2.6,5-2Z"
      style={{ fill: "#0a1d14" }}
    />
    <path
      d="M417,219c-.7,5.5,0,5.1,5,6-.2.8.3,2.3,0,3.4-1.5,4.6-4.5,7-6.9,11.1l3,.5c-1,1.7-1.8,2.3-2.1,4.4-1.5,12,.3,29.8,0,42.6-.7,1.8-2.7,1.8-6,0,.6,4.9-5.7,6.3-5,1-1.4-1.8-.9-4.6,1.6-8.5-2.3-4.2-5.1-11.4,1.2-12.6.9-3,.5-6.4-1.4-10.2l1.7-1.7-2-.9.2-12-2.1-.8c-.9-3-2.2-5.8-4-8.3-1.8-1.1-2.1-2.6-.3-3.5-4.3-5.3-.9-5.8,4.3-4.5l4-1c1.3,0,4.8,1.2,5,1s-.3-2.7.5-4,2.7-1.3,3.5-2Z"
      style={{ fill: "#0b1f17" }}
    />
    <path
      d="M389,104c.2-.9.6-.9,1,0,.2.2,2.4,4.6,2.5,4.9.3,1.6-.6,5.3-.5,7.7.4,8.5-.5,18.7-1.9,27.5-.9,5.2-3.2,8.7-6,13-2.8-1.2-2.1-4.4,0-6-2.2-7.2-2.9-15-2.1-23.4-2.9-1.5-4.9-2.6-1.9-4.6-.8-2.9-.2-4.8,2-5.5-.7-3.2,0-5,2-5.5,1-8.8,2.6-11.5,5-8Z"
      style={{ fill: "#025c1e" }}
    />
    <path
      d="M390,86c.4,2.3,1.8,1.7,2.1,3.5s.3,9.4,0,11.5-1.6,2.1-2,3c-.1-.1-.6,0-1,0-3-.7-3.7-5.1-4-8-2.9,1.6-7.7-3.1-4-5,3.2-2.6,3.9-6.3,9-5Z"
      style={{ fill: "#035524" }}
    />
    <path
      d="M384,151c0,2,.1,4,0,6-.4,6.7-.9,3.3-2.7,6.8s-6.1,13.7-9.3,16.8-7.9,3.1-9.1,7.4c0,2-3.1,5.4-5,5-3.9,4.7-7.2,6.3-10,5l-.7-1.2c9.2-8.5,18.1-17.4,26.7-26.8-2.5-1.5-.2-9,2-10,0-1.1.7-1.8,1.9-2.3.3-3.6,2.7-11.4,6.1-6.7Z"
      style={{ fill: "#015e1a" }}
    />
    <path
      d="M368,187c0,.6,0,1.4,0,2s-.1.9,0,1c-1.9-.5-1.6,1.3-2.8,1.9s-2.1-.2-2.9.3c-1.8,1-5,9.2-10.3,7.8-1.6-1.2-1.2-2.2,1.3-3.2l4.7-3.8c1.9-2.5,1-3.3,5-5s3.9-.5,5-1Z"
      style={{ fill: "#015315" }}
    />
    <path
      d="M368,90c2.6,1.2,5.7,1.9,9.2,2.3,0,3.5,1.1,6.7,3.4,9.6l-1.5,2.1c.4,2-1.1,1.5-2,0-2.7,3.7-13.9,3.1-17,0-2.3,2.7-11.2,1.2-6-2l-.5-2.3c2.5-.9,4.7-2.2,6.5-3.7,0-1,0-2,0-3l3,3c.8-3.2,4.3-2.8,3-7,.2-.4,1.7,1,2,1Z"
      style={{ fill: "#0a5c2a" }}
    />
    <path
      d="M360,96c0,1,.3,3.7,0,4s-2.8,0-4,.5-1.7,1.5-1.9,1.5c-.2,1-.7,1.9-1.5,2.5s-17.7,2.3-18.5-.5c-2.7,0-5.4-.2-8,0l4.8-3.9c3.1,1.1,10.5-2.1,11.7-2.1s2.4,1.9,3.8,1.8c2.9-.2,6-3.7,6.4-3.8,2.2-.5,5,.2,7.3,0Z"
      style={{ fill: "#075628" }}
    />
    <path
      d="M368,90c.2,0,1.2-1.1,2.5-1.1,5.4,0,3.9,2.6,4.6,3.1s3.9-2.9,6-1,0,1.4.7,1.9c1.2,1.1,2.3,1.7,3.3,3.1,2.7,5.9.4,9.1-6,8-.7-4.5-5.2-5.4-3-11l-7,1-1-4Z"
      style={{ fill: "#095a26" }}
    />
    <path
      d="M342,232c0,2,1.1,4,1,6s-1.7,7.2-2,10c-1.3,3.1-4.6,2.5-5-1-2.9-.9-3.1-6.3,0-7,.5-4.5,2-6.5,6-8Z"
      style={{ fill: "#032e16" }}
    />
    <path
      d="M332,240h4c-.2,2.2,0,4.7,0,7v1c-.3,1.9-.4,2.6-.7,1-.5,4.9-6.7,3.1-7.3-1-3.5-1.1-.6-6.9,2-8h2Z"
      style={{ fill: "#033817" }}
    />
    <path
      d="M304,230l2.1,1.3c-.7,1.7-.8,3.3-.1,4.7,2.1-3.1,3.4-1.8,4,4,2.3-2.2,4.6-3.6,7-4,1.9,1.5,3.9,2.8,6,4,1.4-3.7,3.4-5.4,6-5,.4,1.6.9,3.3,1,5,.1,4.3-1.7,3.3-2,8,2.7,1.6,3.3,7.1,0,8,.5,3.9-.2,6.5-2,8l-.7,1.8c.1,6,1.3,13,3.6,21l-.9,1.2c1.6.4,1.7,1.8,0,2-2.7,4-5.3-5.6-6.1-8.6l-1.6-2c-4.1,2.1-7.4-1.6-3.3-3.4.5-1.5.5-2.8,0-4-1-3-2.8-4.3-5.4-3.8l-1.6-2.2-1.3-.2c-.8-3.3-2.4-2.9-4.7,1.2-.8,3.5-7.4.1-6-3-3.3-.2-7.9-4.7-4-7-2.9-.7-2.8-3.3,0-4-2.7-1.4-2.9-3.7,0-5-1.6-.9-3.6-3.1-4-5-2.9.4-3.3-1.4-1-3,1.3-2.7,3.7-2.7,5,0,3.2-3,6.4-6.7,7.8-10.9h1.2c1.4-1.4,2-.8,1,.9Z"
      style={{ fill: "url(#linear-gradient1)" }}
    />
    <path
      d="M344,211c-.4.6-1.9,6.1-2.3,6.7-3.3,5.5-8.1,12-12.7,17.3-.9,1.1-2,1.6-2.7,2.4-1.6,1.9,1.6,2.2-3.3,2.6-3-1.9-2-5.1,3-9.5,3.4-6.5,7.3-12.7,12.5-17.8l1.5.3c1.5-2.8,2.9-3.5,4-2Z"
      style={{ fill: "#024610" }}
    />
    <path
      d="M352,202v2c-1.2.7-7.7,6.5-8,7-1.4.2-3,1-4,2-.5-.2-.9-.5-1.1-.9-.4-.8,10.5-12.1,13.1-10.1Z"
      style={{ fill: "#024810" }}
    />
    <path
      d="M358,193c-.1.2.2,1.1-.3,1.7-1.7,2.2-4.9,1.4-5.7,5.3s0,.7,0,1c-9.3,5.8-17.1,13.8-23.4,24l-6.7,6.3c-.5,2.6-2,6.5-4.9,4.7-3,3-2.7,4.1-7,4-1.7-.5-1.4-1.7-.9-3.1,8.1-10.9,17-20.9,26.9-29.9.9-3.9,8.3-9.2,12-9,1.3-.9,4.1-1,4.8-1.4s2.3-3.8,5.2-3.6Z"
      style={{ fill: "#025813" }}
    />
    <path
      d="M352,201c0,.3,0,.7,0,1-4.3,2.8-10.9,5.8-12,11-6.5,6.1-7.6,10.4-12.1,18.2-1.4,2.4-5.6,4.7-4.9,8.8-5.5.4-5.9,1.2-6-4,3.4-3.5,2.8-5.2,3.3-5.8s4.3-3.6,6.2-5.8c6.2-7,8.9-11,16.1-17.4,3-2.7,4-6.9,9.5-6Z"
      style={{ fill: "#024f12" }}
    />
    <path
      d="M202,272c.2.3-.1,1.9,1,2.5s3.7.1,5,1.5c1,.8,1.3,2.3,0,3,2.8,1.1,4.4,3.4,4.9,6.9l1.1,2.1c.6,0,1.3,0,2,0,1.9,1.1,2.5,3.4,1.9,6.8,4.6,6.9,4.7,13.1,3.9,21l-1.9,1.2c-.7,1.4-3.3,2.6-4,1-1.8-.1-2.3-1.4-1.3-3.9-5.4.4-5.1-3.5-4.6-7.7l-3.2-1.1c-.7-4.5-2-8.2-4-10.8-6.6.2-4.8-5.9,1.1-6.5-.2-3-3.5-4-4.4-7.2s.6-4.2.4-5.8-2.5-2.6-2-3h4Z"
      style={{ fill: "#0a2511" }}
    />
    <path
      d="M192,228c3.6.4,5,5.7,5.9,8.6s-1.5,2.8,2.1,3.4c.3,7.1,3.5,12.7,6.3,19.7,1.7,4.2,2.2,4.4,4.7,7.7s3,7.1,2.7,7.4-1.8,0-1.8.7c0,1.4,1.7,2.3,2,4,.5,2.6-.2,5.8,0,8.5-5.4-.5-2.4-1.3-4.1-5.2s-1.7.2-1.9-3.8l1-3c-1.3-6.4,0-6.6-5-11-2.4-2.5-3.1-4.2-2-5-1.1-7.9-3.5-10.7-5.7-17.8s-.1-10.8-4.3-8.2c0-2,0-4,0-6Z"
      style={{ fill: "#06270f" }}
    />
    <path
      d="M202,260c.4,2.8,2.7,1.8,2,5-1.7-1.5-7.7-3.7-2-5Z"
      style={{ fill: "#0a2511" }}
    />
    <path
      d="M209,276c.4,1.8,2.7,3.4-1,3,0-1,0-2,0-3h1Z"
      style={{ fill: "#14321d" }}
    />
    <path
      d="M204,288c0,.7,0,1.3,0,2-3.4,0-4.6-.9-4,3,8.8-3.1,7.2,10.1,8,11s3.7-.4,4,0-.5,7.5,0,8,4.3-.6,4.9,1.6-.9,3-.9,4.4l1.3.7c-1.5,5.8,6.2,10,10.7,10.9,2.8,1.4,2.4,5-.7,5.5,1.8,2.1,3.5,5.3.7,6.9-2.9,1.8-4.9,1.9-4,6-2.3-1.7-1.5-3-2.3-3.8s-2.2.2-2.7-.9-.3-4.2-1.9-5.9c-2.3-2.6-5.4.4-3.1-4.4h-6c-.3-3.2-3.8-6.9-5-10.5-2.1-6.2.2-12.7-1-17.8s-3.7-7.7-3.8-8.2c-.3-2.1.2-6.1-.2-8.5h6Z"
      style={{ fill: "#0e2a16" }}
    />
    <path
      d="M220,317c-.2-1.9,1.7-3.6,3.4-2.9,5.3,2,2.6,8.1-3.4,4.9-1.8,1.5.2,2.3,3.1,3.4l.9,2.6c1.8.7,3.8,1.2,6.1,1.3l1.9,3.7c1.3-3.8,4.4-1.2,2,2-.2.7.3,2.2,0,3.3s-3.1.3-2,2.7c4.7,1.1,7.9,9.6,8,14-.7,0-1.3,0-2,0-1.3,0-5.8.2-6,0s.4-4.7-.2-6.4-2.9-1.9-3.8-3.6-.4-3.1-1.2-4.3c-1.1-2-3.6-.9-2.8-3.6l5-1.5c-8.4-6-12.9-2.1-13-14.5,1.3-.3,2.7-.6,4-1Z"
      style={{ fill: "#0b2a13" }}
    />
    <path
      d="M288,368l8-2.1c4.6-.6,13.3-.4,8,5.1,2.7.9,2.4,5.5,2,8,1.1,2.2.4,6-2,7,.4,5.8-6.8,2-10,0-6.5,1.5-11.5-3.5-6-8-2.6-1.1-5.7-3.6-5-7.2l-10.1-.7-1-2.1c-1.3-.1-2.7.1-4,0v-4c1.4-1.5,7-1.1,9.4-.7s10,2.7,10.6,4.7Z"
      style={{ fill: "#123e1a" }}
    />
    <path
      d="M288,378c0,1.3-.2,5.8,0,6s5-.3,6.5,0c2.3.4,3.6,2.6,6.2,3s3.2-1,3.4-1c2.3-2.6,5.1,1.8,3,4,2.6.1,1.6,2.1-3,6-1.6.3-7.8.3-9.3-.2-2.2-.7-2.2-3.2-2.9-3.6-1.1-.7-3.4.1-3.8-.2s.5-3.3-.3-4.8-5.7-3.5-5.8-3.8c-.3-1.1.5-3.6,0-4.4-.5-2.9,5-3.7,6-1Z"
      style={{ fill: "#16381b" }}
    />
    <path
      d="M304,396c3.8-4.5,15-15.2,18.5-5.4l4.5,1.7c6.9,2.9,8.5,5.5,5,7.7-.7,1.2-1,2.6-1,4-3.9-2.3-7.5-9.9-12.5-10-.5,0-6.4,3.3-7.8,4-3.2,1.7-.9,1.7-6.7,2,0-1.3,0-2.7,0-4Z"
      style={{ fill: "#162f1a" }}
    />
    <path
      d="M254,352c.5-3.6,2.1-2.3,5,4,2.2.2,3.8.9,5,2,7.5,1.4,15.5,3.4,24,6,2.9,0,2.7,2.3,0,3,.9.3.9.6,0,1-4.6-.5-8.1-3.5-10.7-3.8s-3.6,1.6-9.3-.2-8.6-3.2-10-4-4-3.2-5.1-4.5-.8-3.3-.9-3.5c-.3-1.7.3-1.7,2,0Z"
      style={{ fill: "#13421d" }}
    />
    <path
      d="M272,368c1.9.2,12.6.5,12.9,1.1s-.2,2.9.2,4.3c.7,1.9,2.5,0,2.9,4.6l-6,1c-.2-.4-2.7-.7-2.9-1.1s1-1.4.8-1.7c-1.3-2-9.8-2.5-7.8-8.2Z"
      style={{ fill: "#133819" }}
    />
    <path
      d="M224,304c2.5-4.5,6.3-1.6,4,3,.6,0,1.1,0,1.6.3.9.4,8,17.9,8.1,18.9s-.9,1.4-1.6,1.8c2.7.4,2.5,1.2,0,2-.4.6-1.9,1.5-2,2,.4-3.1-.7-1.5-2-2-4-7.3-7.5-15.9-9.8-24.2l1.8-1.8Z"
      style={{ fill: "#05330f" }}
    />
    <path
      d="M244,330c2.1,1,2.1,3.8,2,6,.4,0,.9-.1,1.2,0,.8.3,7.4,12.4,7.9,14.1l-1.1,1.9h-2c-1.6-2.1-2.5-3.2-4-6-3.2-1.4-7.1-4-5.9-8-3.7-4-4.4-6.6-2.1-8,0-3,3.2-2.7,4,0Z"
      style={{ fill: "#0a4116" }}
    />
    <path
      d="M240,328c2.5.3,2.4,1.3,0,2,1,6.6,3.8,5.5,4,6.7s-.3,3.1,0,4.6,2.9,2.7,4,4.7c-3.4,1.3-3.8-1.6-4.8-2s-2.9.2-3.2,0,.3-5.6,0-7.3-3.4-2.5-3.7-3c-.6-1.5-.1-2.5-.3-3.7s0-1.4,0-2c0-3,3.1-2.4,4,0Z"
      style={{ fill: "#0a3915" }}
    />
    <path
      d="M368,433c.4-.3.8-.6,1.2-.7,4.2-1.2,7.3,16.3,8,19.8,3.5,4.2,5.8,8.9,6.8,14,1.5,1,2.2,3,2,6,.8.9,1.5,10,4,12-1.5,0-3.4,1.3-5.3,1s-2.5-4.8-5.7-2c.4-5-1.1-2.1-4.3-3.1s-1.5-9.4-1.7-12.4-.9-2.8-1-3.5c-1.2-3.5-2.6-7.1-4-11,0-1.7,0-3.3,0-5,4.8.1,5.5-.7,3.2-5.8-2.1-4.7-2.6-2.1-3.2-9.2Z"
      style={{ fill: "#17311a" }}
    />
    <path
      d="M372,464c-4.6,1.5-4-7.3-4-11,2.8,1.5,4.9,5.5,5.1,8.5s-1.1,2.3-1.1,2.5Z"
      style={{ fill: "#182f1d" }}
    />
    <path
      d="M332,391c1.7-3.4,8.4-1.1,11,1,2,0,3.6,1.9,4.8,5.6,4.4,0,7.3,2.8,9.2,6.4,4.6,3.1,8.8,6.8,12.6,11l.6,3.3c2.7.9,4.7,3.4,5.9,7.7,3,.5,2.8,4.4,0,5-2.8,4.7-5.9-2.9-6.8-6.4l-2.2.4c-2.6-1.3-1.7-8.7-2-9s-8.6.4-9,0c-.8-.8,2.6-6.7-4.5-8s-5.1-.7-9.4-2.1l-3-1.5c-1.9-5.5-5.8-10.3-12-11.5-1.4-4.6,2.6-4.3,5-2Z"
      style={{ fill: "#17361e" }}
    />
    <path
      d="M367,425c0-.2,1-4.8,2.5-4s2.3,7.1,4.5,9.3,1.9.6,2,.7c.4-.2.9-.4,1.3-.4,1.2,0,7.7,13.5,8.1,15.6l-1.5,1.8,2.1.9c.8,2.9.8,15.8-2.1,17.1l-5.2-8.4c-5.3-3.5-4.2-11.5-6.3-17.6s-2.8-5.7-4.5-7c-.2-2.2-1-6.6-1-8Z"
      style={{ fill: "#16361e" }}
    />
    <path
      d="M307,390c3.8-6.8,10.8-10.4,19.2-8.2s4,1.4,4.3,5.1c3.1.3,4.9,2.9,1.5,4-1,0-4.2-.7-4,1,7.7,0,10.9,8.2,14,14-1.1-.3-5.2,0-5.9-1.1s.3-4.4-.1-4.9-3.7.3-4,0,.5-2.2-1-3.5-10.5-4.3-10.9-4.7,0-1.9-.4-2.5c-2.9-4.2-12.4,6.1-15.8,6.7,0-6,.4-2.8,2.7-4.3s.3-1.6.3-1.7Z"
      style={{ fill: "#15321c" }}
    />
    <path
      d="M150,360l2.5,2.3c2.3,12.1,5.3,27.7,7.5,37.7,2.6,2.6,3,7.5,0,10,0,2,0,4,0,6-16.1-1-18.4-1-17,16h-3s0-6,0-6l-4,4c-1-7.7-4-26.7-3.1-33.4s1.7-.6,1.8-.9c.5-.8-.7-2.3-.4-3.8,2-8.5,13.9-.7,15.5-2.1s2.1-6.1,3-7.3c-.5-2.4-12.6-3-8.9-6.6s4,.8,4.9-1.6-.6-5.1-.4-6.9,2.5-4.6,1.5-7.5Z"
      style={{ fill: "#192b1f" }}
    />
    <path
      d="M176,438c1.7-1.8,3.9-1.1,6.5,1.9,4.8,5.6,10.6,11.2,17.5,12.1,1.4,4.9,7.7,3.7,8,4s-.2,6.5,0,8c-4.5-.1-9.8.5-14,0-2.9-7.7-1.6-3.3-6-8-1.2-1.2-3.8-6-5.1-7.4s-4.6-5.1-6.9-5.6c-.1-1,.1-2,0-3-1.5-.7-1.8-1.6,0-2Z"
      style={{ fill: "#183a20" }}
    />
    <path
      d="M168,413c2.7.6,5.3,9.2,3,11,3,3.1,8,9.8,5,14,.1.6,0,1.4,0,2-2.3-.2-4.9-2.8-5.8-4.8-2.9-6.2-3.4-15.9-7-21.9s-1.6-2.7-3.2-3.3c.1-3.3,0-6.7,0-10-1.6-1.8-1.9-4.5,0-6l1.7-.3c1.7,6.6,3.8,13,6.3,19.3Z"
      style={{ fill: "#1a3522" }}
    />
    <path
      d="M147,339c2.6-4.7,6.2,2.8,3.9,5.9,1.4,4.7,2.3,9.7,2.6,14.8,2.3,2.2,2.3,5.7,1.5,8.3,1.8.3,2.6,1.9,2.5,4.8,2.8,6.6,3.6,12.5,4.1,19.5s-.8,1.3-1.6,1.7c0,2,0,4,0,6-2.7.6-2-1.3-2.3-3.1-2.1-11.7-4.6-26-7.7-36.9s.4-4.2-2-8c.5-4.4-.6-8.7-1-13Z"
      style={{ fill: "#1b2f23" }}
    />
    <path
      d="M137,280c1.9,0,3,1.7,3.4,5.2,4.3,1.8,7.9,9,3.6,11.8.9.2.9.5,0,1,0,1.6,0,3.2,0,5,3.4-.4,2.8,7,0,8,2.1,1.5,2.8,7.6,3.2,10.8,1.1,4.6.7,8.3-1.2,11.2-4.3-2-1.3-6.7-1.9-9.3s-2.4-.9-2.9-2.3-1.3-8.3-1.2-8.8,3.7-.8,2.1-2.6-3.9-.4-4.1-1.6c-.3-1.9,1.7-3.7,1.7-4.9s-3.6-10.7-3.6-12,1.7-2.2,2-3-1.7,0-2-1.1c-.4-1.5-.5-6.1,1-7.4Z"
      style={{ fill: "#1a3022" }}
    />
    <path
      d="M149,311c.8,1.5.9,3.7.3,6.6,2.7,4.7,6.1,10.8,3.5,16,.9.3,1.3,1.1,1.2,2.4,3.1,1.1,2.8,7.3.6,9.5,1.5,3.5,2,7.6,1.5,12.3,3.3,5.7,3.3,9.1,0,10.2-.3.9-.6.9-1,0-.3-1.7-1.7-3-1.7-3.5,0-.9,1.6-1.7,1.6-2,0-.8-2.4-1.1-2.8-1.6s.6-2.3.2-3.6c-1.8-5.8-2-7.3-2.7-13.4s0-5.6-2.7-4.8c-.2-2.2-.9-4.7-1-6-.6-8.1-.8-13.9-2-22,1.2-1.8,3.8-2,5,0Z"
      style={{ fill: "#1f3327" }}
    />
    <path
      d="M136,274c2.4.3,4.6,5.4,5.2,5.8,2,1.1,5.8-1,8.3,1.2s-.6,8.5.5,10,1.6-.2,1.9,0c.2,1.5-.3,3.7,0,5-1,1.9-5.8,1.5-8,1,0-.3-.4-5.5-.5-5.9-.8-2.9-4.4-3.7-5-5s-1-4.2-1.5-6-.6-4.2-1-6Z"
      style={{ fill: "#1f3022" }}
    />
    <path
      d="M146,341h-2s0-3,0-3c1.4.3,2.3,1.6,2,3Z"
      style={{ fill: "#15261c" }}
    />
    <path
      d="M156,306c.6,1.3,1.5,2.2,1,4-1.4.4-2.8,1.7-5,1,2.4,1.6,1.7,8.7,2,9s2.6-.5,3.7.2.6,1.6,1.3,2.1c1.2.9,2.8.8,3.8,2.2s1.6,4.9,1.1,5.4c-2,2-3.1-3.1-5-2,3.1,6.9,2.9,3.6,5.7,3.1,3.9-.7,1.2,4.9,1.3,6.3s2.3,2.6,2.9,5c1.1,4.9-4.7,10.7,4.2,10.6l-1,4c9-1.3,4.6,0,4,3.5-1.7,11.7,3.6,6.1,5,7.5s-.2,4.7,0,6c-.5,1.2-1.9,2-4,2.4-.2,3.9-1.2,6.4-3,7.6-2.9,2.1-5.1-3.9-3-6-1.1-2.1-1.4-5-.9-8.5l-4.4-5.7c1.3-3.5-6-25-7.2-25.6-2.2.3-4,.6-4.6-2.1-1.6-.3-4.3-1.1-5-2.5-1.3-2.6,3.6.5,3-4.1s-2.1-1.3-2.1-2c0-1,1.2-2.2.9-3.7s-2-2.1-2.6-3.4c-2.1-4.6-.9-5.2.8-9.3-3.9-4.6,3.2-9.5,7-5Z"
      style={{ fill: "#243727" }}
    />
    <path
      d="M152,296c.3,1.2,1.9,1.7,2,3.5s-1.7.2-2,.5c-2.3,2.7,3.1,3.6,3.8,4.2s0,1.4.2,1.8c-6.5-.6-5.4,1-7,5-1.7.6-3.4,1.2-5,0-.3-2.2.5-6.4,0-8-2.5-5.1,5.1-4.9,8-3-2.9-.6-5.6-1.2-8-2,0-.3,0-.7,0-1l8-1Z"
      style={{ fill: "#233224" }}
    />
    <path
      d="M181,374c.3,1.8.8,6,1.2,7.3.6,2.1,4.1,3.4,4.6,6.4s-.9,4.4-.6,5.7,2.3,2,2.7,3.3c.6,2.1-1.5,4.3-.2,7.1s3.1.6,3.4,1.8c1.3,5.1-3.4,11.9,2.9,14.4,3,3.2,7.1,14.7,3,18-1.5,5.3-6.1-2.9-6-6l-1.8-1.5c.2-4.4-.7-8.4-2.8-12-4.1-1.6-4.6-3.6-5.4-7.5-7.9-1.3-9-2.5-8.7-11.3.8-3.5.7-6.8-.3-9.7-3.1-.7-2-5.8,1-6,.8-5-1.2-11.4,7-10Z"
      style={{ fill: "#223925" }}
    />
    <path
      d="M196,413c2.6,9.9,7.6,26.5,12,35h-8c-3-.1-3.6-8-2-10,.3-6.3-1.9-12-3-18s.4-2.6,1-7Z"
      style={{ fill: "#1f3522" }}
    />
    <path
      d="M171,424c.9-4.6,4.3.2,5,3,2.8-3.1,15,.7,16,5,3.5,1.7,2.8,3.4,4.3,5.7s1.6.2,1.7.3c2,3.5,1.5,6.6,2,10s-.3,3,0,4c-4.8,3.6-4.5.3-6.8-.9s-2.5-.4-3.5-1.1c-4.2-3-8.4-10.2-13.7-12-.7-3.3-3.8-10-5-14Z"
      style={{ fill: "#1c3d23" }}
    />
    <path
      d="M416,430c2.6,1.8,5.8,3.8,8,6,2.7,2.7,3.5,3.6,3,8-5-.9-6,5.8-7.6,8.9-3.1,5.9-3.1,2.5-3.4,11.1-2,0-4,0-6,0-3.1-.2-4.4-2.4-3.9-6.3-2.2-1-3.4-2.3-2.1-4.7l-1-3.2c-4-2.9-5-5.5-3-7.8-1.4-1.5-2.9-9.6-1.1-11.2s14.5-1.1,17.1-.8Z"
      style={{ fill: "#113c19" }}
    />
    <path
      d="M408,464v9c-6,3.1-15,10-16-1-2.9-.7-2.6-2.7,0-4,1.1-1.6,4.2-3.5,6-2,2-4.1,7.1-5.8,10-2Z"
      style={{ fill: "#1c401c" }}
    />
    <path
      d="M388,448l1.4.4c.6,5.1,1.3,10.4,2.1,15.7,2.3.8,2.7,2.6.5,3.9.2,1.3-.1,2.7,0,4h-6c-.1-.1-1.7.5-2-.7s0-3.7,0-5.3c-.1-5.2.8-13.5,0-18,1.2-2.7,2.8-2.7,4,0Z"
      style={{ fill: "#163a1a" }}
    />
    <path
      d="M398,466c-2.1-5.3-.7-29.1,6-13,2.7,4,3.4,2.6,3.9,3.1s-.5,6.4,2.1,7.9c-.7,0-1.3,0-2,0-5.3.2-5.4-.6-10,2Z"
      style={{ fill: "#15441a" }}
    />
    <path
      d="M445,416c.5,1.1,1.2,1.1,2,0h1c-.1,2,0,4,0,6,1.4,4.2,2.9,8.8-.2,11.9s-6.2,4.3-6.8,4.1c-3.4,4.3-2.1,1.3-3.7,0s-3.8-2.3-5.3-4c.5-.2,1.8.2,2,0s-.8-2.6-.6-4,1.7-7.5,2.8-8.7,4-.9,4.6-3-1.2-5.3.1-6.3,4.9,1.4,4,4Z"
      style={{ fill: "#11411a" }}
    />
    <path
      d="M454,438c.7,0,1.3,0,2,0,2.3.4,3.9,1.9,2,4-3-.5-4.1,5.6-5.7,6s-5.8-.4-8.3,0c.3-.3-.7-6.1-1-7,0-3.7,9.3-6.4,11-3Z"
      style={{ fill: "#1d431d" }}
    />
    <path
      d="M448,422c2.9,1.8,2,6,3.1,9.2s3.6,3.8,2.9,6.8c-3.6.2-7.7,1.5-11,3,0-.3-2.5-1.3-2-3,1.2-1.5,2.1-3.9,3.5-5.1s3.3-.6,3.6-1.5c.5-1.8-.1-6.9,0-9.4Z"
      style={{ fill: "#17431b" }}
    />
    <path
      d="M464,429c5.1,6.3,3.1,17.2-4.4,14.8s-1.1-1.2-1.6-1.8-1.7-3.1-2-4c.9-2.8,1.6-2.3,3,0l2.1-1.1c.6,1.4,1.6-1.3,2.9-7.9Z"
      style={{ fill: "#264724" }}
    />
    <path
      d="M384,416c11.6,1.9,22.5,7.6,32,14-5.1,3-10.3,1.9-16,2-.4,2.6.8,5,.9,7.5s-.9,2.2-.9,2.5c-.8,2.9-6.8,2-7-1-.7.3-1.8.6-2.1.1-1.3-2.1-12.6-22.4-12.1-23.7s1.3-1.2,2.2-1.5c.4-2.8,2.1-2.7,3,0Z"
      style={{ fill: "#10401e" }}
    />
    <path
      d="M354,380c1-3.3,2.4-1.9,4,4,.9.1,1.9.6,2,1.4.8,7.3,1,7.6,6.6,12.5s1.1,1.4,1.4,2.2c10.5,3.8,21.5,8.1,33,12.8l1,1.2c1.4-2.2,5.3-.3,6,2,.9.3.9.6,0,1,1.5.9,1.8,1.7,0,2-.6,1.9-1.5,2-2,0-.6.9-1.6.6-3-1-2.1-.7-3.9-1.4-6-2-3.5-2.9-8-3.6-12.4-3.9l-.6-1.1c-3,2.4-10.2.2-12-3-6.9-1.6-15.6-7.3-17-14-2.8-1.1-5.8-13.9-1-14Z"
      style={{ fill: "#174f24" }}
    />
    <path
      d="M448,399c1.1,5.6-3.3,5.1-6,6.6s-.7,2.2-1.8,2.5-3.4-.5-4.2.1-.1,2.3-1.1,3.4-3.7,1.2-4,1.6c-.4.6,1.9,11.1,1,14.3s-2.4.8-3.6,1.6-1.8,2.3-2.5,2.6c-1.6.7-14.8-8.4-18-8.1-1.2-.3-1.5-2-2-2.4-1.2-1.3-1.2-2,0-2,.6.1,1.4,0,2,0,5.7,2.6,11.7,4.2,18.1,5-.6-3.5-.3-6.5,1-9.1-1.5-2.1-2.1-4.7.9-4.9,4.4-2.9,9.2-5.6,14.4-7.9,2-2.5,3.8-3.5,5.6-3.1Z"
      style={{ fill: "#144f1f" }}
    />
    <path
      d="M372,408c4.2.7,7.9,2,12,3,2.6.7,2.6,1.1,0,2v3c-.9-.1-2,0-3,0-.4,2.9-4.4,3-5,0-4.1-.2-13.3-4.2-8-8,.5-1.4,1.8-1.4,4,0Z"
      style={{ fill: "#184423" }}
    />
    <path
      d="M450,394c.3,0,.7,0,1,0l-1,3c-1.1.8-1.5,1.6-2,2-5.7,4.3-12.7,8.9-20,11-.6,2.7-3,2.4-4,0l.2-1.7,17-7.6c2.1-2.9,4.4-5.2,6.8-6.8.2-2,1.2-1.9,2,0Z"
      style={{ fill: "#1e5927" }}
    />
    <path
      d="M397,416c-4.5-1.3-8.1-3.2-13-3v-2c4,1,10.6.2,13,5Z"
      style={{ fill: "#144a22" }}
    />
    <path
      d="M447,416c.5,3.9-.5,3.1-4,3-.1-.1,1.8-2.9,2-3,.3-.2,1.4.1,2,0Z"
      style={{ fill: "#194a1e" }}
    />
    <path
      d="M406,419v2c-1.1-.9-2.5-1.4-3-3,1,.3,2.2.8,3,1Z"
      style={{ fill: "#144a22" }}
    />
    <path
      d="M459,438c-5.8-4.8-10.2-26.9,2-12,.3.2,2.8,2.8,3,3,0,3.7,0,7.3,0,11-2.2.8-3.8-1.7-5-2Z"
      style={{ fill: "#255324" }}
    />
    <path
      d="M461,426c-1-.8-3.9-2.6-5.1-3.4s-1.8.2-1.8.8c0,3.2,3.6,11.1,4.8,14.5-.8-.2-2.1,0-3,0-1-2.7-6.8-21.1-6-22,4.8.4,9.5,5.6,11,10Z"
      style={{ fill: "#1d5122" }}
    />
    <path
      d="M532,272c1.3,3.1,1.4,6.6,2,9.3s1.8.7,1.9,1.4c.7,3.1-.5,7.9,0,11.3-.8,1.1-1.5.4-2-2-4.5-2.7-5.2-10.7-5-15.9l-1-3.1c0-1,0-2,0-3l4,2Z"
      style={{ fill: "#0c5f21" }}
    />
    <path
      d="M528,304c3.9-.2,6.6.5,8,2,2.6.6-.5,4.2-1,2.9s2.5-2.7,1,1.1c-.6,0-1.4,0-2,0l-1,1.2c-3.3-.8-8.2-1-8.9,3.2l-2.2.6c-.5,2.2-1.1,2.5-2,1-1.5-.5-1.5-3.2,0-4,.9-1,1.6-2.3,2-4-4.5-2.5,3.5-9.7,6-4Z"
      style={{ fill: "#10641e" }}
    />
    <path
      d="M534,310v4c-1.4-.5-2.1-2-4.5-2.1s-1.3,2-1.5,2.1c-2.3,1.7-4.7,1.8-6,6-1.5-1-1.5-3.8,0-5,1.4-7.9,6.7-5.6,12-5Z"
      style={{ fill: "#0f5219" }}
    />
    <path
      d="M536,294c2.3,4.8,2.1,10.8,2,16-.7,0-1.3,0-2,0l-2-1.5c.2-1.1,1.7.7,2-2.5-3.1-2.3-4.4-11.5-2-14,.5.7,1.7,1.4,2,2Z"
      style={{ fill: "#156222" }}
    />
    <path
      d="M488,359c1.8.5,2.4,1.8,2,4-.2.3.2,1.6,0,2-.4.7-1.6.2-2,3-1.2.3-1.4-.3-3,1-.5-.1-.9-.5-1-1-2.9-.9-2.8-1.8,0-3-2.1-1.9-.2-4.3,2-5-1-2.4,0-2.7,2-1Z"
      style={{ fill: "#0d5b1d" }}
    />
    <path
      d="M492,356c1.7-.6,1.4,1.3.9,1.3s.6-4.3,3.1-1.3c-.7,1.4-1.4,3.5-2,5-1.1.8-3.9,1.8-4,2l-2,1c0-1.6-.2-3.4,0-5-.9-.2-.9-.6,0-1,1.5-2.9,2.8-3.5,4-2Z"
      style={{ fill: "#1a5522" }}
    />
    <path
      d="M480,365c1.4-1.6,2.7-2.2,4,0-.1.6-.1,2.4,0,3-6-1.1-2.9,1.8-4.9,4.6s-5.9,3.8-7,4.9c-2.3,2.2-3,7.9-8,6.5l-.6-1.5c2.5-2.6,4.8-5.3,6.6-8.5.7-4.1,2.3-5.4,6-6.8,0-3.4,2.5-5.9,4-2.2Z"
      style={{ fill: "#066618" }}
    />
    <path
      d="M464,384c-.2.3.4,1.6-.1,1.9s-6.5,1.8-7.6,2.4-2.5,4-5.3,3.7c-2.6.8-2.7-.1-1-2-.5-5.9,6.9-10.3,12-8,.1-3.6,4.3-9.2,8-8-.9,5.5-3.6,6-6,10Z"
      style={{ fill: "#096d1a" }}
    />
    <path
      d="M448,390c1.2-1,1.9-1,2,0,.3,0,1,1.9,1,2,0,.5-.8,1.4-1,2-.7,0-1.3,0-2,0-1.4,1.2-2.6,2.2-3,0-2.6-1-1.8-3.9,1-4,1-1.6,1.4-1.9,2,0Z"
      style={{ fill: "#136c25" }}
    />
    <path
      d="M506,336c1.2-1.1,1.9-1.1,2,0-3,2-3.1,9-3.9,10.3-1.7,2.7-6,5.7-8.1,9.7l-4,2v-2c-1.3-1.5-1.1-3.5.8-6.1l8.1-9.6c.3-2.7,1.4-4.1,3.2-4.3.6-1.9,1.4-1.9,2,0Z"
      style={{ fill: "#215427" }}
    />
    <path
      d="M517,328c-2.2,3-3.3,4.6-5,8-1.3,0-2.7,0-4,0s-1.3,0-2,0c-1.8-2,.4-5.7,3-6-.3-1.1.7-1.8,3-2,.3-.9.6-.9,1,0,2.2-1.8,3.6-1.8,4,0Z"
      style={{ fill: "#145422" }}
    />
    <path
      d="M522,320c-.9,5.6-2.6,4.6-5,8-1.3.1-2.8-.2-4,0l-.9-2c3.7-3,6.3-6.3,7.9-10l2-1c-.3,1.5.2,3.5,0,5Z"
      style={{ fill: "#07591b" }}
    />
    <path
      d="M488,256c.4-3.1,3.4-6.9,6.7-7.8s3.3.1,4.6-.2.3-1.8,1.2-2c2.1-.5.9,1.3,2.3,2,4.1,2.1,4.1.7,7.2,6s2.6,3.4,2,6c-1.9.7-4.2.7-7,0-2.3,0-4.9-.8-7.8-2.1-4.2,1.5-7.2,1.5-9.2,0-2,0-1.5-1.1,0-2Z"
      style={{ fill: "#019426" }}
    />
    <path
      d="M512,260c.9.6,5.4,7.6,6,9,2.9,3.4,6,9.8,2,13-.6,1.8-1.3,1.8-2,0-2.3,1.8-3.5.5-2-2-1-.8-1.7-2.3-2.2-4.6-1.9-3.1-5.1-7.4-8.9-7.3l-.9-2.1c-2.7-.4-2.5-1.2,0-2-2.7-1.4-2.1-4.2,1-4,1.6-3.1,4.6-1.7,7,0Z"
      style={{ fill: "#039a2e" }}
    />
    <path
      d="M520,264c2-.8,3.4,4.6,3.7,5.8.5,1.8.1,3.7.3,5.5l4,4.7c1.5,1.4,1.7,2.8,0,4,1.3,3.7,1.3,6.7,0,9-2.3.9-3.6-.8-4-5-1.8-4-2.7-8.6-2.8-13.9-1.5-1.8-1.9-3.1-1.2-4.1,0-2,0-4,0-6Z"
      style={{ fill: "#0c8028" }}
    />
    <path
      d="M520,270c3.9,3.7,4.3,13,4,18-.7,2.6-1.1,2.5-2,0-2.7-.5-3.9-4.2-2-6-.4-2.6.5-5.9,0-8.3s-1.7-.6-1.9-1.4.2-2.7,0-3.3c.6.4,1.6.6,2,1Z"
      style={{ fill: "#0a8b2d" }}
    />
    <path
      d="M534,292c.4,1.8-.3,4.3,0,6.4s2.4,3.3,1.9,7.6c-2.7,0-6.2.6-8-2,.6-1.5,1.9-1.8,4-1-2.2-5.9-3.5-12.2-4-19,0-1.3,0-2.7,0-4,0-2.3,0-4.7,0-7,3.5,1.6,1.5,6.2,2.2,9.3s3.1,5.8,3.8,9.7Z"
      style={{ fill: "#126f25" }}
    />
    <path
      d="M480,278c1.1,1.1,1.7,2.7,2,5,5.2,12.2-1.6,9.8-9.9,5.6-3.1,1-5.8,1.4-8.1,1.4-2.2-1.3-1.8-4.5,0-6,3.6-1.9,8.4.9,11.4-.1s1.2-2,1.6-2.9c.6-1.2.9-1.2,1-3h2Z"
      style={{ fill: "#028926" }}
    />
    <path
      d="M464,280c0,1,0,2,0,3-3.1,5.5-1,12.7,1.1,19.2s-.2,1.4-1.1,1.8c-.3,2.7-1.2,2.5-2,0,0-.2-1.3-.9-1.6-1.9-1.3-5.7-3.3-13-2.5-18.7s1.5-1.2,2-2.2c.8-1.6-.9-2.4,2.1-4.2s1.5-.9,2-1v4Z"
      style={{ fill: "#017826" }}
    />
    <path
      d="M464,283c0,.3,0,.7,0,1,0,2,0,4,0,6,1.9.2,2.3,2.2,1.1,5.8l.9,3.2c.5,0,1,.2,1.3.5.6.4,4.1,12.6,1.7,12.5-1.1,1.8-2.1,1.1-3-2-3.7,1.9-3.3-3.9-2-6-1.1-4.3-2.9-10-2.9-14.4s.3-7.6,2.9-6.6Z"
      style={{ fill: "#03832b" }}
    />
    <path
      d="M488,256c0,.6,0,1.4,0,2,.5.2.9.4,1.2.7.7.6-3.8,15.8-6.2,16,.4,3.1.8,6.2,1,9.3-.6,1.8-1.3,1.5-2-1-.1-.1-1.6.3-1.9,0-.5-.7,0-3.6,0-4.9-1.6-12.3,1.3-7,3.4-13s.1-8.4,4.6-9Z"
      style={{ fill: "#029425" }}
    />
    <path
      d="M451,252c3.8-.5.9,1.5,1,3.4s2,.5,2,.6c.3.5-.2,3.9,0,5-.6.3-1.4,1.5-2.4,2.1s-3.2.8-3.4,1.1c-.5.6-.1,7.1-.2,8.9s0,.7,0,1c1.3,1.2,1.4,2.8,0,4,0,.3,0,.7,0,1l1.1.7c-4,10.9-4.7,20.8-1.1,32.3,0,1.1-.2,5.8,0,6l1,1c-3.7,2.5-6,4.9-6.4,9.4.2,4.6-1.2,8.2-4.3,10.7,0,6.1-10.4,10-14.2,5-2.6-.2-2.3-1.9-.5-3.5-2.3-1.2-3.7-2.5-1.5-4.5,1-1.9,3.6-2.6,7.7-2l5.7-10.1c0-4.2,1.7-6.2,4.8-6.1-.6-3,0-5.3,1.9-6.9,0-3,.7-7.1,0-10.3s-1.8-.6-2-1.3c-1-3.7,1.8-3.3,2-4,1-3.7-1.8-3.3-2-4-.3-1.4-.3-5.4,0-6.9s3.8-4.5,4-5.2c1.3-4.4.3-13.9,1.3-17.5s4.8-7.2,5.8-9.7Z"
      style={{ fill: "#044b1e" }}
    />
    <path
      d="M448,318c.2.3,2.8-1,2,2-4.1,7.2-4.6,12.8-3.8,21.3l-1.2.7c2.1,3.5,0,4.1-3.4,4.8,3,4.8-14.4,4-15.1,3.5s-1.3-1.5-1.5-2.3c-1.4,2.9-6-.2-5-3-1.4-3.5,3-4.4,4-1,2.5,0,6.1.5,8.3,0s.7-1.3,1.2-1.6c.7-.5,2.1-.3,2.3-.6.6-.8.1-2.7.8-3.7s2.8-2.2,3.3-3.8c.8-2.8,1.1-9.6,2.7-12.3s4.7-1.5,5.5-4Z"
      style={{ fill: "#055320" }}
    />
    <path
      d="M448,279c4.1,0,2.4-1.3,2.3,1.7-.2,5-1.8,7.1-2.3,10.7-.9,6.7.2,14.1,0,20.6-4.1-6-5-15.6-4.1-22.6s3.5-5.8,4.1-10.4Z"
      style={{ fill: "#02521f" }}
    />
    <path
      d="M450,320h1c2.8-.6,2.5,3.2,0,4l1.3,1.6c-1.5,2.2-2.4,4.9-2.5,7.6,2.1,1.4,1.7,3.6-.8,2.8.3,1.7,0,2.7-1,3,1.1,1.6,2,6,.1,6.9s-5.2-1.3-3.1-3.9c-.5-2.3-1.3-10.4-1-12.4s2.8-10,6-9.6Z"
      style={{ fill: "#095823" }}
    />
    <path
      d="M454,296c.3,8.3,0,16.7,0,25,.9.2.9.5,0,1,0,3.2-.9,5.1-3,2,2-1.8,0-3.4,0-4-.6-8.3-1.4-12.6.7-20.8.5-1.7-.6-3.8,2.3-3.2Z"
      style={{ fill: "#055e24" }}
    />
    <path
      d="M448,278c.1-1.3,0-2.7,0-4,2.6,1.1,2.8,2.8,0,4Z"
      style={{ fill: "#02521f" }}
    />
    <path
      d="M456,322c3.5-1.2,4.2,3.4,3,6,1.5-1.4,2-.8,1,1,1.5,1.1,2.3,3.3,2.6,6.5,2.4,2.3,2.9,4.1,1.4,5.5,2,2,2.7,3.6,2,5-3.5,2.2-17-.6-10-5-4.7-.9-6.7-5.3-4.1-9.3-1.7-2.1.5-7.2,2-9s.2-.4.1-.7c0-.3,0-.7,0-1l2-1c0,.6-.1,1.6,0,2Z"
      style={{ fill: "#0f6928" }}
    />
    <path
      d="M464,304c.5,2.1.1,4.1,2,6,4.2,3.6,1.7,6.4-1.9,9.3-.7,5-2.4,7.9-5.1,8.7-.6-2.6-2.8-5.5-3-6,3.4-1.4,1.5-5.6,2-8.3s5.5-3.3,4-9.7h2Z"
      style={{ fill: "#06742a" }}
    />
    <path
      d="M454,248c-.2,1.9.7,2.3-2,2,0-.2-1.3-1.1-1-2h3Z"
      style={{ fill: "#0b4e24" }}
    />
    <path
      d="M208,246c5.7,5,3.6,6.4,6.3,9.7s1.7.2,1.7.3c4,1.9,6.4,9.8,6,14.4,1.3.3,1.7,1.3,2,2.6,2.5,2.6,3.3,6.3,2.3,11.1,1.8.1,3.4,5.5,3.9,7.3.7,3,2.2,11-.1,12.5,1.1,2.2.7,4-2,3-.9-2.1,2.1-3.7-4-3-2.9-1-2.8-4.9,0-6-1-.5-1.7-1.4-1.8-2.5-2.3-15.1-6.5-32.9-14.2-45.5-.1-1.3,0-2.7,0-4Z"
      style={{ fill: "#023c09" }}
    />
    <path
      d="M212,240c2,1.3,4.6,4.5,2,6,2.2-.5,4.6.8,7,3.8,3.1.2,4.9.7,5.5,4.2,3.9-.1,5.1,1.1,5.5,5,2,1.6,2,3.4,0,5,2.7.3,2.5,1.2,0,2-2.5,2.5-7.4-1.3-8-4-2.9,1.5-4.9-.5-6-6-1.1-1.4-3.6-8.1-4-10-2.8-2.1-3.7-2.3-6-6,0-1.7,0-3.3,0-5,2.8,1.1,3,2.9,4,5Z"
      style={{ fill: "#025102" }}
    />
    <path
      d="M218,256c0,.1,1.4-.3,1.8.2,1.5,2-1.4,2.1,4.2,5.8,2,1.8,2.6,2.5,0,4,2.6,1.9,2.9,5.2,0,7-6.2-1.1-3.1-2.3-4-6.3s-3.6-9.9-4-10.7h2Z"
      style={{ fill: "#014403" }}
    />
    <path
      d="M208,221c2.2.7,7,8.9,7.6,9,1.3.4,3.9-1.7,4.1-2.3,1.2-2.9-1.4-4.6,4.3-3.7,2.1-3.4,8.8,4.3,4,6,1.5.9,1.9,1.7,0,2h-6.4c-1.7,7.4-5.8,1.8-9.6,0-.8-1.8-2.1-7.2-4-8,0-1,0-2,0-3Z"
      style={{ fill: "#027104" }}
    />
    <path
      d="M212,240c3.3,0,4.3,4.3,6.3,5.7s2.3,0,3.4.6.5,1.6,1.5,2c2.3,1,6.3.9,8.8.7,3.1.6,3,9.2,0,10-1.4-4.2-7.7-2.7-8-3s.4-3.5,0-4-2.7.1-4-.5-4.3-4.2-6-5.5c-.7-3.2-.4-2.9-2-6Z"
      style={{ fill: "#025a02" }}
    />
    <path
      d="M234,248c.5-2,1.4-1.9,2,0,2,1.9,2,4.1,0,6,1.1,3.5,2.2,6.9,0,10,0,2.9-3.3,2.8-4,0,0-1.7,0-3.3,0-5v-10c.1,0,1.2-.8,2-1Z"
      style={{ fill: "#015909" }}
    />
    <path
      d="M228,230c1.6-3.1,4-.7,4,2,2.5,0,5.2,5.9,4,8,2.2,1.6,3.2,5.1,0,6,1.5,1.3,1.5,2,0,2-.6,0-1.4-.1-2,0,.5-3.9-2.4-6.8-4-10s-1.5-2.1-2-6,0-1.3,0-2Z"
      style={{ fill: "#026b0f" }}
    />
    <path
      d="M354,102c-.9,3.1,3.2,1.9,6,2,2.6,2.5,0,3.5-8,3l-1.8,1.4c-5.5-.6-12.1.6-15.9,4.5l-2.3-1c-.4,4.9-5,6.8-9.8,5.9-1,3.8-19.8,2.6-20.6,1.5s-.4-.9-.6-1.4c-.8-2.6-3.8-2-6-2,2.1-2.7-.9-1.7-2-4,3.7.1,26.9.9,28,0s-2.5-6,4.3-4.4l8.7-3.6c7-.2,13.4.2,20-2Z"
      style={{ fill: "#055e29" }}
    />
    <path
      d="M246,108c1,0,1.7.9,2,1,2.8.6,2.7,2.3,0,3-.6.9-1.3,1.8-2.4,2.2-4.9,1.8-18.3,5.2-23.5,6.1l-3.1-1.3c-.5-1.8-.8-3.8-1-6,.3-.3.5-.9,1-1,3.3-.7,9.2.8,12.3,0s1.3-3,3.7-3.6,10-.5,11.1-.4Z"
      style={{ fill: "#026b24" }}
    />
    <path
      d="M219,119c.9,1.6.6,2.4-1.1,2.6-.3,3.9-1.6,6-3.9,6.4,1.2,1.4.8,3-1.1,4.8l-4.9,9.2c1.5,1.4,1.1,2.1-1,2-.3.9-.6.9-1,0h-3c.8-4.5,2.6-11.7,4-16s3.2-4.2,4.7-6.3,3.4-6.1,6.3-8.7c3.3,1.3,3.2,3.6,1,6Z"
      style={{ fill: "#026b1d" }}
    />
    <path
      d="M208,192c2.8,1,2.8,3,0,4l1.2,1.1c0,7.5,3.2,12.8,8.8,16.9,1.9-3.1,4.7-.5,6,2,2.7.7,2.7,2.8,0,4-8.7-2.1-7.6,5.4-14-5.3-3.8-6.3-3.1-15.2-5-22.7,0-2.9,2.3-2.7,3,0Z"
      style={{ fill: "#027f03" }}
    />
    <path
      d="M204,179c1.2-3.3,2.6-2,4,4,.9.2.9.5,0,1,2.3,1.3,2.4,2.7,0,4,1.5.8,1.4,3.1,0,4h-3c-.8-3.3-2-3.8-3-6s-1.1-2.1-1-3,2-3.7,3-4Z"
      style={{ fill: "#017f0d" }}
    />
    <path
      d="M224,220c2.9-1.5,5.6.5,8,6,1.9.8,2.6,2.5,2,5,1.1,2.6-.2,3-2,1,0-.2-3.4-2-4-2-.4-3.3.3-5.3-4-6v-4Z"
      style={{ fill: "#017916" }}
    />
    <path
      d="M206,174c1.7,3.9-2.2,5.1-4,2-.2-3.9-.5-11,0-14.6s1.7-1.3,2-2.2c1.7-5.2,2.7-9.7,2.1-15.2h1l1.7,1.4c-1.2,9.2-1.7,18.6-1.6,27.9l-1,.7Z"
      style={{ fill: "#017716" }}
    />
    <path
      d="M206,174c.9.3,1.5,1,2,2,1.4,1.5,1.7,5.6,0,7h-3s1-4,1-4c-.7,0-1.3,0-2,0s-1.9.2-2,0,0-2.2,0-3c2.1.4,3.1-.2,4-2Z"
      style={{ fill: "#028116" }}
    />
    <path
      d="M248,109c3.6.6,13.9,3,16.5,3h15.5c.2,0-.6,2.7.6,3.1,2.3.7,7,0,9.5.4,4.2.8,6.2,2.1,11,2.5s15.5.7,18.3,0,.6-1.8,1.4-2c2.3-.5,4.8.6,7.3-.5s2.3-2.5,4-3.5c2.8-.9,3.2,1.5,1,3,1.1,1.8-.7,4.8-3,5,.1,1.3-3.9,3.8-5,4-6.1,1-12.4.6-18.5.1-18.6-3.5-37-6.9-55.2-10.1l-1.3-2c-.4,1.5-1.3,1.5-2,0v-3Z"
      style={{ fill: "#03672a" }}
    />
    <path
      d="M426,239c-.5,2.2,4,4.9,4,5.5s-2.7,2.4-3.4,3.6c-4.9,9.1-2,15.3-1.6,23.9,1.9.6,2.9,2.9,3.1,6.7,4.8,3.3,3.7,13.3-2.1,14.3-2.4,2.2-3.7-.6-4-3-.1-.2-.2-4.7,0-5.3.3-1.1,2.5.2,2-2.1s-1.7-.1-2-1.2.2-3.1-.2-4.6c-.7-2.6-3.8-4.1.2-5.2-4.8-2.9-1.7-5-2-8s-1.8-2.5-1.9-4c-.3-4.4,6.9-16,5.9-21.5.2-.4,1.4.8,2,1Z"
      style={{ fill: "#072919" }}
    />
    <path
      d="M422,290c0,0,1.3-.2,1.7.4,1.4,2.2,2.2,2.5,2.3,2.6.9,0,2.1.2,2.2.8.8,9.4-2.5,18.6-12.2,21.2.1,3.1-6.3,4.4-8,2-2.7-1.5-4.1-3.1-1-5-2.2-1.6-1.1-3.1,1-4,.8-2,2.8-3.1,6-3.4-.1-3.7,1.6-8.8,3.4-11.6s.8-.2,1.5,0c.8-1.6.6-3.6,3-3Z"
      style={{ fill: "#0b2a1c" }}
    />
    <path
      d="M419,293c-2.9,5.6-3,8.7-3,15-2.8-1.8-5-.2-8,0-2.7,3.5-8.1,2-6.4-2.4-1.4,0-1.6-1.1-1.6-2.6-2.7-1.8-3-5.3,0-7-.9-.2-.9-.5,0-1l2.2-.4c1.2,2.7,3.5,2.6,7.1-.4,2.9.5,5.1-.3,6.7-2.2l3,1Z"
      style={{ fill: "#0f251a" }}
    />
    <path
      d="M416,287c0,1.7,0,3.3,0,5-.5,9.5-.9,3.1-6.3,4.2s-4,6.3-9.7-1.2c-2.6-.9-2.1-6.2,1-6-2.3-1.8,0-3.5,2.5-2.3.3,3,.9,3.4,1.5,1.3l3,2v-6s8,3,8,3Z"
      style={{ fill: "#0d211a" }}
    />
    <path
      d="M432,272c2.5,5.4,2.5,10.6,0,16l2,1.3c1,6.4,1.4,21.1-5.2,23.4-1.6,3.7-3.6,6-6.1,6.8-.7,3.1-5.7,3.8-6.7.4-2.8-1.1-2.9-4,0-5,.3-.2-.3-2.8,0-3s2.6.4,3.8-.2,5.5-7.3,5.7-7.8c.6-1.7,1-10.2.5-11,.6-2.6,4.3-5.9,4.1-8.4s-3.3-3.3-3.9-5.3-1.1-5.8-1.2-7.3c3.8.4.4-2.8,1.5-5.9s3.4-3.7,4.5-5.1c1.8,2.6,1,7.5,1,11Z"
      style={{ fill: "#07311c" }}
    />
    <path
      d="M442,250l2,3-5,3h5c-6,1.6-3.3,6-4,10.5s-1.9,2.9-2.1,5.1c-.1,2.4,2.4.7,2.1,2.5-.7,0-1.8-.2-2,.7s-1,9.5-1,10.9c0,6.6,2.3,14.3,3,20.1s-3.8,4.2-2,7.4c0,3.2-3.7,9.3-5.9,12.3-1.5,2.1-5.6,5.8-8.1,4.7-3.7-.6-6.4-2-8-4-2-.2-1.9-1.2,0-2,3.1-1.4,5.9-3.3,8.6-5.8,1.5.7,8.1-7.9,8.6-9.2,1.8-4.8,2.1-17.7-1.2-21,0-5.3,0-10.7,0-16,4.6.6,3.2-2.3,4.4-4.7s2.6-.3,1.6-3.7-1.8-.5-2-1.3c-1.2-5,2.5-8.5,4-12.3.7,0,1.3,0,2,0Z"
      style={{ fill: "#043a1c" }}
    />
    <path
      d="M442,311c0,1.5.3,8.7,0,9s-3.5-.5-4,0-.2,3.2-.8,4.7-5.8,10.8-6.4,11.1c-1,.6-6.9.1-8.8.2-3.4-1.9-1.9-6.3,2-6,1.4-1.7,4.9-3.8,6.6-6,3.2-4,2.8-7.8,7.4-11s3.1-.8,4-2Z"
      style={{ fill: "#04441f" }}
    />
    <path
      d="M376,224c1.3-.1,7.5-.9,7.8-1.2.5-.5,0-3.3,1.8-3.8s2.6.9,3.7.6,2.3-1.6,5.1-1.8c8.5-.5,4.5,5,5.5,6.1s6.4,0,8,0c-2.2,3.1-5.1,1.7-8,1-1.4,5.1,5.4,1.4,6,6.5l-6-.5c0,.7,0,1.3,0,2,5.6,7.3.9,10.5-14,9.4v12.2s-2,1.4-2,1.4c-1.8,2.5-3.2,2.5-5,0,1.7-1.3-.6-9.8-1.5-10l-7.5,2c-1.1-3.1,6-8,6-8.5-.2-3.6-7.4-3.7-5.9-8.8s6-3,5.9-6.7Z"
      style={{ fill: "#0a1d14" }}
    />
    <path
      d="M420,181c.3.3.6,3.8-1.5,3-3.6-1.4-.3-4.7,1.5-3Z"
      style={{ fill: "#082116" }}
    />
    <path
      d="M352,252c0-1.3,0-2.7,0-4,2-.3,2.6,1.6,4.5,2s11.1-.4,11.5,0-.4,5.6,0,6,6.5,0,8,0c1.8.9,2.6,2.9,2.4,6.1,5.3-.8,4.9,3.5,4.5,7.5,4.4.7,7.2,4.1,8.5,10.4,4.1,1.8,8.9,4.2,9.6,9-.3,1.8-.9,4.2-1,6s0,.7,0,1c-.6,4.1-5.8,2.5-5.7-1.6l-2.6-1c-3.2-4.4-8.4-5.6-15.4-3.4-5.1-2.8-8.5-5.5-10.3-11.5l.2-4.3c-7.1,1.2-9.8-2.8-8.2-12.2-2.7.5-4.3-.1-4.9-2,.9-6.3-.4-2.9-1-4.7s0-2.2,0-3.3Z"
      style={{ fill: "#0f1e1a" }}
    />
    <path
      d="M408,255c.2,2.3,4.7,10.8,1,13.5s-7,1.3-1,2.5c.5,1.7-1.4.6-1.8,1.1-1.2,1.5,2.4,5,2.4,7.4s-2.7,5.8-3.6,8.5.2,1.8,0,2c-2,1.6-2-2.1-2.6-2.1s-1.2,1.1-1.4,1.1c-2.8-.2-3.2-3.2-5-4.5s-5-1.9-6-3-3.2-8.7-4.2-9.3-4.4.2-4.9-.2.4-7.6,0-8-2.9.5-3.9-.1-1.3-6.3-1.2-7.9c1,0,2,0,3,0,1.7,0,3.3,0,5,0,3-4.7,7.3,0,5.9,4.9-.6,1.4,3.3,7,3.7,7.1.7.3,4.6-12.5,5.1-14.1,1.6-1.6,8.9-1.8,9.3,1.1Z"
      style={{ fill: "#0c1b18" }}
    />
    <path
      d="M353,260h7c.3,1.9-.4,11.6,0,12s7.6-.4,8,0c1,1-2.3,9.7,3,10.5l-2,1.5,6-1-3,3c2.3-1.1,3.3,2,3.6,2,3.1.1,9.3-1.7,13.1-.3s3.2,6.5,8.3,4.3c.8.6-3.9,5.5,3,4,0,2.3,0,4.7,0,7-2.1,1-4.8,1-8,0-6.3,1.1-12.8-2.4-17.6-7.1-5.5-.8-8.7-1.4-12.4-5.9-1.4,4.9-4.5-.5-4.4-4.2l-3.8-2.8c-.2-2.9-.7-5.9-1.6-8.8-2.8-.6-4.7-4.3-2.1-6.1,3.5.8,1.5-2.8,2-5.6s1-2.2,1-2.4Z"
      style={{ fill: "#122320" }}
    />
    <path
      d="M350,268c1.9,3.5-.4,4.8,6,4,.6,1.9-1.7,2.5-1.9,3.7s1.4,2,1.6,3-1,2.6-.6,3.2,3,1,4,2.6.5,4.5,1.2,5.4,1.7.1,1.7.2c3.3-.1,2.8,6.3,0,7,.2,2.2-2.1,5.9-4,7-2,5.4-6.1-.7-7-4.3-2.7,0-4.1-1.2-4.4-3.4,1.5-3.6-.2-6.2-2.5-8.3-.9,0-1.6-.4-1.6-1.2-.4-7.8-.4-15.6-.4-23.4l2-1.4c.5,0,1.7-2,3-.5.3,3.1,1.8,4.3,3,6.5Z"
      style={{ fill: "#132a21" }}
    />
    <path
      d="M334,259c1.9-.7,2.9.3,3.2,3,2.1-1.5,3.7-1.5,4.8,0,.5-.2,1.4.1,2,0v26c-.9,1.9-2.1,1.9-3,0-2.1,0-3.2-2-3.5-5.9l-1.5-2.1c-1.4-.7-4.5-8.1-4.8-10s-1.7-11.8.6-11.8,1.5.4,2.2.8Z"
      style={{ fill: "#0f2f1f" }}
    />
    <path
      d="M341,248c.9.3,1.9,1,2,2s-2.5,1.5,1,2.5c-2.6,2.3-1.8,6.3-2,9.5-2.1.6-4.2,2.8-7,2,.9-1.6-.4-3.3-1-5-1.4,0-2.1-.7-2-2,1.8-1.9,2.5-4.1,2.1-6.4l1.9-2.6c0-.3,0-.7,0-1,1.4,1.3,3.7.6,5,1Z"
      style={{ fill: "#0b2e1d" }}
    />
    <path
      d="M400,233c5.7.1,4.6,6.7,5,7s2.5-.3,2.9.1-.2,12.4,0,14.9c-1.6.3-7.4-.5-8,0s-.3,4-1,5.5c-1.7,4-4.6,5.5-3.1,11.4-5.9.8-3-1.1-4.5-3.9s-2.7-2.4-3.3-4.8c-1.4-6.1,2.8-7.1-4.2-7.3v-16c2.3-.4,15.4.6,16,0s0-5.6,0-7Z"
      style={{ fill: "#0b1a15" }}
    />
    <path
      d="M376,120c.3-4.1,5.8-1.5,3.7,2.1s-.9-2.6.3.9c.5.5-1.2,4.3,4,0-.3,9.3.2,18.7,0,28-4.1-.6-4,7.7-5.1,8.9s-2,0-2.9.1l-1.9-1.4c2.3-8.1,2.5-16,.4-23.7l1.5-1.9.7-1.8c-2.6-3.4-3.6-8.1-.7-11.2Z"
      style={{ fill: "#026622" }}
    />
    <path
      d="M377,104c3.3.2,4.8,2.5,4.9,5.8l2.1,2.2c-.4.3.3,8.3,0,10-1.6.4-.5-3.6-3-2s1.4,2.7-1,3c-.5-.4-3.4,1.3-1-3h-3c-2.1-.7-2.7-2.7-2-6.1-2.4.3-4.5.3-6-2-1.1-.4-2-1.1-2.6-2.1l-11.9-.7-1.5-2.3c2.8-1.1,8.9.3,8-3,5.2.1,11.9.5,17,0Z"
      style={{ fill: "#066129" }}
    />
    <path
      d="M389,104c-.6,0-1.3,0-2,0-.4,1.7.6,7.2-.1,7.9s-2.6-.2-2.9,0c-5.8.9-3.2-1.2-4.7-4.8s-3-1.1-2.3-3.2c.6,0,1.5.2,2,0s.7-1.4,1.8-1.9,2.6.3,3.1-.1.3-4.8,1.1-5.9c1.8,2.3,4.1,4.6,4,8Z"
      style={{ fill: "#035d23" }}
    />
    <path
      d="M374,170c-.7,2.1-.7,4.4-2.5,6l-23.5,22c-4.2,2.8-7.5,6-12,9-1.9,2.4-4.3,4.2-6.8,5.9l-1.2-.9c-2-.2-1.9-1.2,0-2l-1.3-.7c2.1-2.9,5.2-5.3,9.3-7.3,1-1.3,11.2-11.4,12.1-11.2s1.4.6,1.9,1.2c1.5-1.4,2.3-3.2,3-5.2,6.6-6,13-12.1,19.1-18.3l1.9,1.4Z"
      style={{ fill: "#03641a" }}
    />
    <path
      d="M376,160c-.4,4.4-.6,5.8-2,10-1.7.2-4.4,3.1-5.7,4.3-4.7,4.8-9.3,8.6-14,12.9s-.3,2.8-4.3,4.7c-2.4-1.6-.8-5.9,2-6,1.2-3,3.1-5.6,5.5-7.8.6-3.3,2-4.4,5.3-4.4l6.8-9.8h2.4c.2-3.7,1.2-9.3,4-4.1Z"
      style={{ fill: "#026b1f" }}
    />
    <path
      d="M328,248c3,.7,5.1,4.1,6.6,0h1.4c-.1,5.5,2,7.8-4,9-1.9,2.3-3.4,1.9-4-1,.3-.5,0-6.4,0-8Z"
      style={{ fill: "#05371d" }}
    />
    <path
      d="M304,267c0-3,0-6,0-9,3.2-.7,1.9,2.8,3,3.5s2.5.3,2.9.7,0,2.8.1,3.8c1.8.4,2.4,1.8,1.8,4,3.2,1,4.4,4.2,3.5,9.5,1.8,2,2,3.9.7,5.4l2.1,2.9c2.1.2,2.6,2.2,1.7,6,2.5,1.1,2.9,2.2,2.2,4.7,2.2,4.1,4.1,8.2,5.9,12.4,3.2,1.2,4.4,6.9,2,9-.8,1.5-1.4.2-1.2.2,6.4.1,2.3,8.7-.8,3.8-1.1,1.9-3.8-1.4-4.4-2.9l-1.3-7.4c-4.2-.8-5.4-3.2-3.6-7.2-6.4-6.4-7.6-16-8.3-24.6-1-.2-1.7-.8-2.4-1.9l-2.1-.7c-.1-2.7-.7-5.1-1.9-7.3-2.6-1.8-2.6-3.2,0-5Z"
      style={{ fill: "#045824" }}
    />
    <path
      d="M298,264c1,.9,4.5,2.9,6,3,0,1.7,0,3.3,0,5,1.1,4.4,2.7,8.8,4.7,13.2,2.6,6-6.2,6.1-8.7,2.8l-2.2-.4c-2.1-4.8-4.7-9.3-7.7-13.3l.9-2.3c0-1.7,1-2.4,3-2-.6-3.6,0-10.3,4-6Z"
      style={{ fill: "#014f19" }}
    />
    <path
      d="M328,256c1.3.4,2.7.6,4,1s1.5-.3,1.9,0,0,1.5,0,1.9l-2.5,2,2.5.5c-2.2,3.1-1.9,5.8-.3,9.3s2.1,3.6,2.3,9.2c2.7,2,2.9,6.1,0,8-.3,3-2.3,2-6-3-3.4-4.3-5.2-14-5.1-20s.5-.7,1.1-1c0-2.1-.4-5.7,0-7.3s2-.6,2-.7Z"
      style={{ fill: "#103822" }}
    />
    <path
      d="M317,272c.8,1.1.8,2.4,0,4,.2.5-.2,1.8,0,2,2.1,2,1.9-3,3-2s3.5,4.1,3.7,4.3c1.2,2.7.5,9.7,4.4,9.7,2.2,1.9,4.2,8.9,2,11-.8,1.4-1.4,1-2-1-1.7.6-2.6,0-3.3-1.5l-3.3-9.6c-1.8,1.1-3.6-.2-5.4-3.9-.7-2-2.3-3.9-2.6-5-.5-2,1.5-5.1-.3-7.5s-2.8,0-3.2-1.2.2-3.8,0-5.4c7.4,1.4,8.1-2.3,7,6Z"
      style={{ fill: "#085428" }}
    />
    <path
      d="M328,210c0,.7,0,1.3,0,2,.9.2.9.5,0,1-2.4,2.7-5,5-8,7l.6,2.2c-4.2,5.8-9.1,10.4-14.6,13.8-3.9-.5-1.3-2.2-2-6,0-4.7,3.3-7.5,8-9,1.1-2.2,13.1-13.1,14.8-12.8s1,1,1.2,1.8Z"
      style={{ fill: "#026b17" }}
    />
    <path
      d="M294,257c.4,3.9,1.3,2.9,3.6,5.4s.3,1.5.4,1.6c-4-1.4-1,7.1-2,8h-5c-1.3,1-2.3,0-3-3-2-.9-2-2.1,0-3-5.2-8,.8-16.6,6-9Z"
      style={{ fill: "#035213" }}
    />
    <path
      d="M336,207c-1.3,3.6-5.9,6.3-8.4,9.1s-1.2,2.7-2.2,3.8c-5,5.9-10.2,10.6-13.5,17.3s-2.4.9-1.9,2.8c-1,0-2,0-3,0l1-4c-.7,0-1.3,0-2,0,.6-3.3,4.3-4.8,6.3-7.2,3.3-4,3.7-4.8,7.7-8.8,2.8-2.1,5.5-4.4,8-7,0-.2,0-.7,0-1,2.9-1.2,5.4-3.3,8-5Z"
      style={{ fill: "#016215" }}
    />
    <path
      d="M290,243c.2,0,3.8.9,3.9,1.1.4.4,0,2.9,0,3.9-1.9,1.8-4.8,2.5-8.8,2.1-1.9,2.1-3.6,4-5.2,5.9-1.9,4.5-4.1-.7-3-4-2.8-.2-2.5-1,0-2-2.3-1.2-1.6-2.5,1-2-.8-4.3,9.7-8.3,12-5Z"
      style={{ fill: "#01700f" }}
    />
    <path
      d="M312,214c1.7-.3,1.7.4,0,2,.9,3.2-2.7,7.1-6.2,8.8-.5,2.2-1.4,3.6-2.8,4.2-2,4.9-3.3,10-9,11-.9-2.1-.8-5.6,2-6,1.6-4.1,9.4-21.1,14.1-21.3l1.9,1.3Z"
      style={{ fill: "#037f1e" }}
    />
    <path
      d="M294,253c0,1.3-.1,2.7,0,4l-6-1c0,3.3,0,6.7,0,10-1.4,4.1-6.3,1.7-3-2-3.5-1.6-3.8-6.6-1-9-4.2-4.6,9.7-7.9,10-2Z"
      style={{ fill: "#015e11" }}
    />
    <path
      d="M288,240c.2-.9.5-.9,1,0l1,3c-1.6,0-5.8,1.4-7.4,2.1s-4.4,2.7-4.6,2.9c-3.8.7-3.9-6.3-2-8,2.6-2.8,9.4-2.9,12,0Z"
      style={{ fill: "#037c0b" }}
    />
    <path
      d="M326,264c0,7.2,2.7,13.3,4,21,2.8.4,2.6,2.1,0,3-.6,1.9-1.4,1.9-2,0-1.7-7.3-4.2-12.9-4-20.6,0-1.9-1-4,2-3.4Z"
      style={{ fill: "#0d4223" }}
    />
    <path
      d="M294,248c.1,1.7,0,3.3,0,5-2.8-1.5-5.9-1.6-8.6,0s-1,1.8-1.4,2.1c-.4,3-1.5,4.7-4,2-.9-.1-.9-.4,0-1,0-1.3-.3-2.3.3-3.7s3.7-3.9,3.9-4c2.7-1,6.8,0,9.7-.3Z"
      style={{ fill: "#026710" }}
    />
    <path
      d="M316,214c.4,2.4-1,4.7-4,7-.2.7.3,2.8,0,3-.4.3-2.6-.4-3.8.2s-3.1,4.9-4.2,5.8c0-.2-.8-.6-1-1,.7-1.9,1-5.6,1.2-5.8.3-.4,2.2-.2,3.3-1.2s4.3-5.7,4.6-6c.6-3,1.6-4.5,4-2Z"
      style={{ fill: "#02781a" }}
    />
    <path
      d="M296,234c-.4,2.2.1,4.1-2,6-1.4.3-3.4,0-5,0s-.7,0-1,0c-2.9,0-2.8-3.3,0-4-1.9-.6-2.1-1.5,0-2,.7-3.1,7-2.8,8,0Z"
      style={{ fill: "#018718" }}
    />
    <path
      d="M330,288c1.4,1.4,4.5,12.8,3.4,13.5s-.9.4-1.4.5c-1.3,1.4-1.7.7-2-1-.5-5.3-1.4-6.8-2-11s.1-1.4,0-2c.7,0,1.4,0,2,0Z"
      style={{ fill: "#0c4926" }}
    />
    <path
      d="M317,272c3.9-.4,1.4,2.1,0,4-1.4-3.7-.2-2.3,0-4Z"
      style={{ fill: "#125c30" }}
    />
    <path
      d="M218,288c3.2-5.9,3.8,3.2,4,6.6l2,3.4c.1,2,0,4,0,6,.3,6.6,4.8,12.5,6.9,19.6s1.1,4.2,1.1,6.4c-2.3-.8-2.3-1.9-2.7-2-1.5-.3-4.6.5-5.3-.1s.2-2.4,0-2.9c-1.3-1.8-2.8-3.2-4.4-4.4l.4-1.6c1.1,0,3.8.5,4-.5.7-4.1-1.5-2.2-4-1.5-.6-3.9.8-9.8,0-13.3s-3.8-7.9-4-8.4c-.5-2.1.3-5,0-7.3h2Z"
      style={{ fill: "#062b0f" }}
    />
    <path
      d="M220,319c.5,1.6,3.5.6,4,1s-.2,3.8,0,5c-.2-.4-7.8-2.2-6-6,.7,0,1.3,0,2,0Z"
      style={{ fill: "#0a2511" }}
    />
    <path
      d="M336,383c1.8-1.8,2.4-1.6,2,1,2.9.6,7,5.7,5,8-3.8.5-7.3-.8-11-1,.8-3.1-2.3-1.4-3.1-2.6s-.3-4.1-1.5-4.5-11.7-.2-12.7,0-3.9,4.8-5.1,5.7-2.4.1-2.6.3c-.8-.9-2-4-3-4-.3-2.2.2-5.7,2-7-1.6-3,10-6.6,12.5-6.6s16.5,6.6,17.5,10.6Z"
      style={{ fill: "#12361a" }}
    />
    <path
      d="M328,368c3.1-2.8,8.8,3.3,6.2,7.3,3.8,1.5,6.3,6,1.8,7.7-4.6-1.4-4-2.6-6.6-3.9s-10.3-4.7-10.9-4.7c-.9,0-11.4,3.8-12.5,4.6-2.7-1.8-1.9-5.3-2-8,.6-2.2,1.5-4.2,4-3,0-.5,0-.9,0-1.4.3-1.2,16.8-1.2,19-.7l1,2.1Z"
      style={{ fill: "#0e3a1b" }}
    />
    <path
      d="M308,364c1.7.7,1.7,2.1,0,4-.3.1-1.8,3.6-4,3,0-.8.1-2.9,0-3-.6-.5-13.3.3-16,0v-1c1.8-1.7,12-8.9,13.6-8s.9.6,1.4,1c2.4-6.9,5.1-.6,5,4Z"
      style={{ fill: "#0f421a" }}
    />
    <path
      d="M307,336c2,1.4,1.5,2.4.3,4l1.3,2.1c-.6,5.9-3.3,12.7-5.6,17.9-5.5,1-9,7.7-15,7v-3c-1.9-.6-1.9-1.4,0-2,3.1-1.2,5.9-3.1,8.5-5.7l1.5,1.7c-.8-.1-1.9-.3-2-.9-.2-.9.9-12.4,2-13.1.2-3.2,3.6-15.7,7.6-14.7s1,.4,1.4.7c2.8.9,2.9,5,0,6Z"
      style={{ fill: "#0a471d" }}
    />
    <path
      d="M247,333c1,.5,2,1.5,3,3,1.6,1.4,2.9,3.4,4,6,3.5-.8,6.4,7.5,4,10,1.7.3,2.6,1,1,2,.5.9.5,1.6,0,2-.5-.2-2.5.4-2.9,0s.4-2.9-.3-3.8-1.7,0-1.8-.1c-1.2-1.4-6.8-13.3-8-16-.5-2.1-.2-3.1,1-3Z"
      style={{ fill: "#094f1a" }}
    />
    <path
      d="M259,354c2.5-.4,7.8,1.5,5,4,.4-1.8,1.2-1.6,2,0,.2-1.8,7-2,8.9-1.9s6.1,1.7,7.1,3.9c2.3.4,4.3,1.1,6,2,0,.7,0,1.3,0,2-5,1.3-8.5-1.3-12.4-2.1s-3.5.4-8.3-1.9-2.5,1.8-3.2-2c-4.4-.4-3-1-5-2-.1-.6.2-1.5,0-2Z"
      style={{ fill: "#124e1f" }}
    />
    <path
      d="M230,304c3-2.9,3,1.4,2,4,1.3-.4,1.8.3,2.3,1.4l2.5,10.1c3.8,3.6,4.9,6.4,3.2,8.5h-4c-.5-3.5-6-16.2-8-21l2-3Z"
      style={{ fill: "#023d11" }}
    />
    <path
      d="M236,296c1.5-4.2,4.8-.3,2,2,3.7,1.5,4.4,7.5,2.2,10.9,5.6,3.1,7.6,10,5.2,15.7,1.3,3.1,1.8,5.8,1.6,8.3l-1,3c-1.1-2.6-1.2-3.4-2-6l-2.2-.8c-.2-3.9-1.8-7-4.8-9.4-.7-4.6-2.1-8.9-4-12.9-1.3-2.5-2.5-7.5,1-8-1.8-2.8-.2-6.8,2-3Z"
      style={{ fill: "#035014" }}
    />
    <path
      d="M233,307c6.7,1.6,3.1,8.2,5,12.2s3.3,1,3.8,1.9,1.5,6.9,2.1,8.8h-4c0-.6,0-1.4,0-2-.6-4.7-1.6-2.4-2.8-4.6s-4.4-12-5.2-15.4c0-1,0-1.4,1-1Z"
      style={{ fill: "#014512" }}
    />
    <path
      d="M381,416c-.9,3.4,1.8,4.5,2.8,6.8,1.5,3.3,2,3.7,3.1,5.8s6,11.7,6.1,12.4c5.4,2.3-1.3,13.7-5,7-1.3,0-2.7,0-4,0-.3-1.4-7.3-16.4-8-17-.1-1.6.2-3.4,0-5-2-2.3-4.3-9.1,0-10,.5.3,3.8-.1,5,0Z"
      style={{ fill: "#133c1e" }}
    />
    <path
      d="M338,384c1.6-3.7,7.9-2.6,8.4,2h2.6c.3-.9.6-.9,1,0,2.9.4,3.7,6.2,2,8,2.9,1.8,9,6.4,7,10,.6,1.6,0,1.6-2,0-4.9-1.4-3.8-3.1-5.8-3.9s-2.8.2-4.2-.6c-2.1-1.2-2.9-5.7-4-7.5-1.7-2.7-4.1-5-5-8Z"
      style={{ fill: "#143f1e" }}
    />
    <path
      d="M359,404c1.8-3.3,7.1,2.1,9,4-.7,4.7,4.3,4.7,7.3,6s.6,1.9.7,2c-.7,3.9-.4,6.4,0,10-2.9.4-1.9-2.8-3.9-4.6s-3.7-1.3-3.9-1.5c-.5-.6,0-3.2-.7-4.8-1.3-3-6-4-7.2-5.3s-1.5-4.5-3.3-5.7c.6.2,1.4-.2,2,0Z"
      style={{ fill: "#183b20" }}
    />
    <path
      d="M173,390c2.4.7,1.8,2.7,2,4.5s.5,13,1,13.5,5.2-.6,5.9,0-.2,2.4,0,2.9c3.1,4.9,2,7.7-4,6.9.8,4.8.2,7.8-2,9.1-2.6-.5-2.2-1.2-3.7-3h-1.3c-1.5-4.9-2.3-5.2-3-11-.8-1-1.3-1.5,0-2,1.3-2.4,1.6-5.7.9-9.8l2-10.1,2.1-1.1Z"
      style={{ fill: "#213f29" }}
    />
    <path
      d="M156,368c3.3-5.7,6.5,2.3,5.7,6.3,2.4,1,1.8,1.8.3,3.7l2.2,1.7c.3,7.7,1.4,15.3,3.1,22.8l-1.3,2.5,2,4c1.7.5,1.5,1.2,0,2,0,.7,0,1.4,0,2-6.8-3.9-2.3-14.8-8-19-.2-8.7-1.2-11.5-3.9-18.8s-.8-5.3-1.1-7.2h1Z"
      style={{ fill: "#203829" }}
    />
    <path
      d="M144,298h8s0,2,0,2h-8s4,2,4,2l-4,1c0-.2-2.2-.7-1.9-2.3s1.7-1.8,1.9-2.7Z"
      style={{ fill: "#1f3022" }}
    />
    <path
      d="M154,336c5.5,1,5.7-2.2,7.9,5.6s5,12.7,5.9,16.2-.7,3.9-.2,5.3,4.3,5.3,4.4,5.7c.5,2.1.6,7.4-1,9.3-2.5,1-5.5,1-9,0-.3-2.7-1.6-1.7-1.9-2.7-1-3.2.7-8.4-4.1-7.2,2.1-3.7-1.6-7-2-10.5s.7-7.1,0-9.9-2.8-.2-2-3.2,1.8-.4,2-1.2c.5-2.1-.3-5,0-7.3Z"
      style={{ fill: "#25392c" }}
    />
    <path
      d="M171,378c1.2,1,1.4,4.8,3,6-.5,2.8-.8,2.7-1,6-.4,6-1.1,7-2,10.7s1.6,10.3-3,10.3c0-.7,0-1.4,0-2l-2-4c-3.3-10.9-2.8-16.5-4-27,1.7-2.7,7.2-1.6,9,0Z"
      style={{ fill: "#243d2a" }}
    />
    <path
      d="M151,304c-.5,2-2.1,2.2-4,2v-2s4,0,4,0Z"
      style={{ fill: "#2f3e31" }}
    />
    <path
      d="M182,411c2.3,4.2,6.6,5.8,6.9,6.2s2,3.4,2,3.6c1.3,3.8,1.2,7.3,1.1,11.2-4.3-2.1-11.4-4.2-16-5,.3-1.8-.4-10.6,0-11,1.3-1.3,7.9,3.3,6-5Z"
      style={{ fill: "#1f3d25" }}
    />
    <path
      d="M400,442c0,2.3,5.1,7.1,4,11-1.2-1.7-1.8-4.5-4-4-2.1,6.2,1.4,11.1-2,17-1.5.8-2.3,3.2-6,2-.4-2.9-1.5-1.8-2-2.7-2-3.6-1.8-12.9-2-17.3,1.5,0,3.4.4,4.5-1s.5-5.8.5-6l7,1Z"
      style={{ fill: "#153f1b" }}
    />
    <path
      d="M368,352c4.5,1.4,1.1,6.2-2,8-.5,1.1-1.6,2-3.4,2.6l-2.6,9.4c.7,2.8.5,7.3-2,9,1.5.7,1.5,2.3,0,3-4.1.9.1-4.9-4-4-1.5-2.1-1.5-5.4-.1-9.9-1.3-2.2-1.3-6.1.1-8.1-.8-2.5.5-4.3,4-5.4,1.3-4.1,7.5-9.9,10-4.6Z"
      style={{ fill: "#125228" }}
    />
    <path
      d="M428,410c-1.5,3.3,2,4.2,2,4.5.2,2.5-1.7.9-2,2.1-.5,1.8.5,8.9,0,9.4-2.7,0-5.5.4-8.3-.3s-3.8-3.5-4.5-3.7c-3.1-.8-5.2,1.3-7.3-3s.1-1.4,0-2c3.5.2,7.1.9,10.8,2,.1-3.2,1.4-4.9,3.8-5.2-.5-1.9-.5-2.7,1.4-3.8,1-.6,3.8,0,4,0Z"
      style={{ fill: "#145822" }}
    />
    <path
      d="M352,360c.8-3.9,4.7-1.8,2,2,.8,3-.2,4.1,0,5.4s2,.6,2,1.1c0,.8-1.8,9.2-2.1,11.5s-1.6,2.9-1.6,3.5,1.3,1.8,1.5,3.1c.4,2.5,0,5.1,1.1,7.4-.8,2.4-1.7,1.8-3,0-.5-2.7-1.7-6.1-2-8-1-4-1.7-13.3,2-16-1.5-2.5-1-7.3,0-10Z"
      style={{ fill: "#124a23" }}
    />
    <path
      d="M424,410l1,6c-5.6-.6-3.2.4-4.2,3.3-1.6,4.7-9.9-1.5-12.8-2.3v-1l.6-1.3c3.8,1,6.2.8,7.4-.7.3-3,5.7-5.5,8-4Z"
      style={{ fill: "#176123" }}
    />
    <path
      d="M373,400c7.8,1.2,15.6,3.3,22,8l8.3,4.1c.3,1-.2,1.6-1.3,1.9-6.2-2.4-11.2-4.5-17-6.5s-14-3.5-16.4-5.5-.5-1.1-.6-2.1c1-2.8,3.9-2.7,5,0Z"
      style={{ fill: "#1a5c27" }}
    />
    <path
      d="M355,394c.6.2,7.8,7.7,9.5,9s5.6,3,7.5,5c-1.2-.2-2.7.1-4,0-5.6-.5-4.1-2.7-9-4-1.5-3.3-6.4-7-7-10,.9.2,2.3-.2,3,0Z"
      style={{ fill: "#184521" }}
    />
    <path
      d="M358,381c.9-4.2,2.2-3.2,4,3,1.9,1,2.5,3.2,1.8,6.6,5.9,4.9,7.3,8,4.2,9.4-6.7-.6-3.3-1.3-6.4-5.6s-3.2-2.4-3.3-2.6c-.8-2.1-.2-5.4-.3-7.7s0-2,0-3Z"
      style={{ fill: "#165b26" }}
    />
    <path
      d="M397,408c.3-.7.6-1.2.7-1.2,3.3.8,13.2,4.4,16.9,5.7l1.4,1.5c-.1,0,.5,1.7-.7,2s-5.8.2-7.3,0c-2.5-.3-3.8-1.1-6-2,.5-3.7-5.9-2-7-6,0-.6.6-.6,2,0Z"
      style={{ fill: "#206329" }}
    />
    <path
      d="M448,394c-.6,1.2-5.6,7.4-6.3,7.7-6.7,2.9-11,4.5-17.7,8.3-1.8,1-5.8,2.3-8,4-2.8.2-2.7-3.1-1-4.8,11.2-3.5,21.1-8.6,30-15.2.8-.2,2,0,3,0Z"
      style={{ fill: "#226329" }}
    />
    <path
      d="M532,303c-1.4,1.6-2.4.8-4,1s-3.7-.5-5.1.4-.6,3-.9,3.6c-.3,1.8-1,3.2-2,4-.5,1.9-1.4,1.9-2,0-.6-.6-.6-1.6,0-3-.9-.3-.9-.7,0-1,.7-9.2,9.8-14.4,14-5Z"
      style={{ fill: "#107321" }}
    />
    <path
      d="M522,308v5c-.2.4-1.4-.8-2-1-.2,1.3.2,2.7,0,4s.4,3.4-.5,4.9l-6.5,7.1c-.4,0-.9-.1-1,0-1.2,2.3-4.2,1-3-2-2.6-.4-2.5-1.1,0-2l1-2.1c4.6-3,6.2-4.8,8-9.9.6.1,1.5-.2,2,0,.5-3.7,1.3-2.7,2-4Z"
      style={{ fill: "#076a1e" }}
    />
    <path
      d="M486,351c.8-5.5,4.2-5,2.9,1s-.4-2,1.1,0c1.6,1.8.9,3.8-2,6,0,.3,0,.7,0,1-.7.2-1.4.9-2,1-.3,2.1-1.5,2.1-2,0-2.8-.5-2.9-3.4,0-4-.9-2.2-.7-4.4,2-5Z"
      style={{ fill: "#196d26" }}
    />
    <path
      d="M484,360c.7,0,1.4.1,2,0,.7,2.2-1.7,3.8-2,5-1,.4-3.5,1.4-4,0-2.7-.7-2.7-2.1,0-3-2.1-1.5-1.8-2.2,1-2,.6-2.6,1.8-2.4,3,0Z"
      style={{ fill: "#09671d" }}
    />
    <path
      d="M504,336c-1,2.2-1.9,5.3-3.2,7.3-3,4.8-7.1,6.6-8.8,12.7l-4,2c.3-1.5,1.4-4,2-6-2.2-3.2-.2-5.5,2.4-7.6.1-2.5,2.3-4.1,6.6-4.9l1-3.5c.2-.9.5-.9,1,0,1.1-2.4,2-2.5,3,0Z"
      style={{ fill: "#23602a" }}
    />
    <path
      d="M480,362c-.2.8.2,2.7,0,3s-1.4-.3-1.8.2c-.7.9.1,3-.4,3.6s-3,.4-4.3,1.2-2,2.7-3.6,3.9c-4.3-2.1-2.2-7.6,2.4-8.9.2-7.7,2.8-9.4,7.6-3.1Z"
      style={{ fill: "#056e1d" }}
    />
    <path
      d="M472,351c4.4.5,7.6,4.8,9,9-.2.7-.9,1.4-1,2-9.2-4-4.5-1.7-5.2,2.2s-4.4,3.6-4.6,3.8c-.6.7,0,4.4-.1,5.9-3,2.4-6.6,7-8,8-3.6,0-2.9-2.5-2.2-5.3,7.9-9-3.8-11.3-11.2-10-2.5,1.8-7.3,1.5-9.6-.8-2.4,1.1-3.2.4-2-2-.6-1.2,3.7-3,4.4-3.2l16.8-.6c2.8-2.4,6.9-3.2,12.2-2.3-.4-3.2.1-5.5,1.5-7Z"
      style={{ fill: "#06751b" }}
    />
    <path
      d="M439,366c5.7,1.3,13.3-2,18.5-1s6,3,6.2,3.3c4.3,3.8-5.1,8.6-1.7,13.7-3.9,2.7-9.4,1.1-12,8-.7,0-1.3,0-2,0-.1-1.5.3-3.2,1.2-5.1,0-4,1.4-8.3,5.8-6.9l-.6-2.2c6.6-5.4-3.1-6.9-6.4-5.8-.6,2.9-6.1,1.9-6-1-1.4,1.2-2.4.8-2-1-2.1-.6-2.5-1.3-1-2Z"
      style={{ fill: "#087c1c" }}
    />
    <path
      d="M448,370c2.2,1.6,4.8,3.5,7.6,5.5l-.6,2.5c-2,2.2-3.3,2.7-3.8,3.2s-.8,7.3-3.2,8.8c-.6,0-1.3,0-2,0-2.3-1.4.2-8.6,2-10-3.8-1.3-5.8-3.3-6-6-3.5-.7-5.1-1.2-1.4-2.5s-.2-.2-.6-.5c-2.2-1.8-.3-2.6,2-2,1.4.3,5.8.8,6,1Z"
      style={{ fill: "#098520" }}
    />
    <path
      d="M446,390c-.4,1.2-.5,3-1,4-2.7,3.7-5.4,5-8,4-.9-.3-.9-.6,0-1-.6-1.5.4-2.5,3-3-1.5-2.4-.7-3.7,2-4,.6-2.8,3.3-2.8,4,0Z"
      style={{ fill: "#27812d" }}
    />
    <path
      d="M507,330c1.1-1.3,1.6-1.8,2,0-.3.2-1.4,5.1-3,6-.7,0-1.3,0-2,0-1,0-2,0-3,0-2.3-3.6,2-8.9,6-6Z"
      style={{ fill: "#196227" }}
    />
    <path
      d="M509,326c.8,1.2,1.3,2.3,3,2-.1.1.3,1.4-.2,1.8-.8.7-2.4,0-2.8.2-.7,0-1.3,0-2,0-1.7-1.3-.8-1.6,1-2-1.6-2-1.5-2.3,1-2Z"
      style={{ fill: "#0e6523" }}
    />
    <path
      d="M505,260c-.4.7-.9,3.1-1,4l-2.1,1.2c-8-1.2-12.6,2.1-13.2,10.5-3.6,4.2-4.3,6.1-2,11.5l-.8.7c-2.1,4.8-4.6-1.6-2-4-.7-3.2-3.6-8.4-3-11.5s2.4,0,2.9-.6,1.6-7.2,2.2-9.1,1.2-.2,1.9-4.8c6.9-1.9,11.5-4.3,17,2Z"
      style={{ fill: "#019b29" }}
    />
    <path
      d="M504,264c0,.6,0,1.3,0,2,5,3.5-2.6,5.5-6,2,.2,1.8-1.1,3.3-3.7,4.6.3,4.6-1,9.2-6.2,9.9,1.4,2.5,1.3,4.4-.2,5.6-.6,1.9-1.4,1.9-2,0-.3-1.4-2-8.1-2-8.5,0-1.8,2.3-3,3-4.9.9-2.4.5-4.8,1.9-7.1,2.1-3.8,11.2-6,15-3.5Z"
      style={{ fill: "#01a02c" }}
    />
    <path
      d="M516,280c.2.4,1.6,1.7,2,2,2.8,2.2,1.7,7.7-.9,9.9-2.2,5.2-3.5,10.6-4.1,16.1.7,5.5-7,2.7-9-1-1.9.6-2.3-.4-1-2-.9-2.5.1-2.8,3-1,2.5-4,4.3-8.5,5.4-13.6-2-3.7.3-7.5,2.6-10.4.4-1.9,1.1-1.6,2,0Z"
      style={{ fill: "#119e32" }}
    />
    <path
      d="M520,282c.3,2.1,2.3,3.7,2,6,4.2,1.1,1.4,5.3-2.1,5.6-.5,3.3-1.8,5.1-3.9,5.4l-1.5,8.9h-1.5c.4-1.7-.7-3.9-.5-5.4.6-4.4,2.4-8.3,3.8-11.7s1.6-6,1.7-8.8c0,0,1.2-.9,2,0Z"
      style={{ fill: "#10962f" }}
    />
    <path
      d="M504,266c.3-.3,7.7,1.8,7.9,2,.5.5-.3,3.9,0,5.3s2,1,2.4,1.7c.8,1.6.8,3.4,1.6,5-.7,0-1.4,0-2,0l-2-1c-.4-5.5-6.7-6.1-11.5-5.7l-.5-1.3c-2-.8-2.6-2-2-4,3.1-.9,3.9.3,5.4,0s.5-2,.6-2.1Z"
      style={{ fill: "#03a231" }}
    />
    <path
      d="M524,288c3.6-.2,1.6,2.9,4,5,2,.9,2.7,4.6.9,6-7.4-2.2-8.8,1.8-10.9,8-.4,1.6-1,2.6-2,1l-1.3-.4,1.3-8.6c.3-.9,1.9-6.5,2.1-6.8.5-.8,3-.5,3.7-1.4s0-2.5.2-2.7,1.4,0,2,0Z"
      style={{ fill: "#12872b" }}
    />
    <path
      d="M532,303c-2.9-2.2-5.2-3.9-9-2.6s-2,5.1-5,7.6c-.9-.1-.9-.5,0-1,.6-6.3,2.9-13.5,10-9,0-1.7,0-3.3,0-5,0-3,0-6,0-9,1.1,3.2,5.8,17,4,19Z"
      style={{ fill: "#157a27" }}
    />
    <path
      d="M484,284c.6,2.7-1.6,4,2,4l1,.2c1.5,4.6,4.7,9.2,9.7,13.5l-.7,2.3c-.3,1.4-.9,2.6-1.1,1.4s5.3,5.7,1.1,6.6c4.4,2-1.8,6.2-4,2,0,1.5-.7,1.5-2,0-1.6,1.2-2.6-.4-2-2-1.8-.1-1.8-1.6,0-2l-4.8-5.3c2-.5,2.9-1,2.8-1.7l-3-1.3c-8.3-11.4-13.3-13-15.1-4.8l-1.9,1.2v1c-3.2-1.3-1.9-6-2-9,4.3-4.9,13.1-1.3,18,1-.3-1.5.5-7.4,0-8l2,1Z"
      style={{ fill: "#019028" }}
    />
    <path
      d="M466,310c.1.1,2.8,1.9,3,2,.3-.9.6-.8,1,0,2.6.7,3.1,7.3,0,8-2.5,1.9-4.4,4.5-5.6,7.9h-1.4c0,3-1.3,3.5-3,1.1,0-.4-1-.8-1-1,2.8-2.4,2-7.6,3.6-9.9s5-2.1,3.4-8.1Z"
      style={{ fill: "#0c7c2e" }}
    />
    <path
      d="M466,298c1.5-2.3,2.8-1.6,4,2,.4.3.8.5.9.9.4,1.5,2.9,10.3,2.4,10.6s-.8.4-1.3.5c-.2,2.7-1,2.5-2,0-.3,0-.7,0-1,0,0-4.5-2.6-8.5-3-13s0-.7,0-1Z"
      style={{ fill: "#068f31" }}
    />
    <path
      d="M420,336c.3-1.9,1.1-1.8,2,0,.5,3.7,7.8,2.9,2,6.6v1.4c-1.3,0-3.3-.5-4,1-.9,2.7-3,2.6-4,0,.6-1.9.6-3.9,0-6-4.6-2.3.1-6.2,4-3Z"
      style={{ fill: "#094c22" }}
    />
    <path
      d="M449,352c.1,3.3-14.5,6.2-16.3,5.4l-.7-2.4c-1.3.6-1.9-.7-1.8-.7,1.5,1.8-6.5,1.6-6.2-1.3-.2-1,0-2,1-3-2.7-.1-2.6-1.1,0-2,4.4,1,10.5-.5,15,1-1.8-6.6,4.5-2.5,5-7,.2,1,2.1,3,3,2s0-3.9,0-5c5.5.4,5.9,11.1,1,13Z"
      style={{ fill: "#055a20" }}
    />
    <path
      d="M420,345c.6.2,3.8,2.7,5,3,.1.6-.2,1.5,0,2,0,.9-1.5,2.6-2.3,3-2.9,1.5-16.9,2.3-18.7-1-1.5,2.5-2.5,2.5-4,0-2.7-.9-2.7-2.1,0-3l.7-2.2,8.9-.7c1.9-1.9,4.4-3.8,6.4-1.1.9-.1,3.2-.2,4,0Z"
      style={{ fill: "#095527" }}
    />
    <path
      d="M440,338l-.5,3c-1.1-.9-1.8-1.5-1.5-3h2Z"
      style={{ fill: "#044b1e" }}
    />
    <path
      d="M456,341c.3.6-.4,2.7,0,3s8.4.2,10,2c.8,1,.8,1.7,0,2-1.8,5.9-13,8.2-17,4,.8-3.7,2.8-10.7-1-13,0-1.1-.3-2.5,1-3l1.4-.2c1.1,3.1,2.5,4.3,5.6,5.2Z"
      style={{ fill: "#096322" }}
    />
    <path
      d="M454,322c0,2.5,2.3,4.9-2,6.5,4.1,3.3,0,6.1.2,7.8s3.3,3.4,3.8,4.7c-4.5.7-5.3-1.7-7-5s.9-.4.9-.5c-.3-.7-2.8.2-1.8-4.9s2.2-5.9,2.9-6.6,2.7-.2,3-2Z"
      style={{ fill: "#0d6027" }}
    />
    <path
      d="M463,328l1.3.2c1.6,6.1,4.2,12,7.8,17.7l-1.1,1.1c-.5,1-.8,1.2-1.4.6-2.3-2-4.2-3.9-5.6-6.6-3-5.6-3.8-5.7-4-12l3-1Z"
      style={{ fill: "#1a7831" }}
    />
    <path
      d="M464,341l7,6c.9.2.9.6,0,1,2.2,1.5,1.5,2.4-1,2-2,1.6-3.3.3-4-4-1.2-1.5-1.3-3.7-2-5Z"
      style={{ fill: "#117227" }}
    />
    <path
      d="M230,286c.9.2.9.6,0,1,.8,1.5,1.4,2.8,2,4,2,1.1,2.7,3.8,2,8-.2,2.7.1,5.3-1,8s-.6.7-1,1c-1.3-5.8-1.3-2.8-2-4s-1.8-12.4-2-14.5-1.7-1.2-3-1.5l3-2c-6.5.7-3.1-1.3-3.3-5.1s-.6-5.1-.7-7.9,0-4.7,0-7c1.7-2.7,4.7,0,5.7,2.4s1.3,14.6,1.3,16.7-.4.6-1,.9Z"
      style={{ fill: "#02470a" }}
    />
    <path
      d="M208,250c.3.1,1.1-.3,1.8.2,1.4,1,7,16.6,8,19.6,2.7,8.2,5.5,18.5,6.3,28.2-5.9-.3-3.2-9-4-10s-1.8.2-2,0c-.5-.7-1.5-13.1-2-15.3s-3.3-11-4.6-14.5-2.8-3.1-3.4-8.2Z"
      style={{ fill: "#02350b" }}
    />
    <path
      d="M236,264l2,1.1c.2,3.4,1.4,29.2.2,30s-1.3.8-2.2.8c-.4.6-1.6-1.7-2,3-2.7-1.8-1.8-5.3-2-8-.8-1.5-1.4-2.8-2-4,0-.3,0-.7,0-1,.8-3.8.8-9.2,0-16.3l2-3.7v-2h4Z"
      style={{ fill: "#01520b" }}
    />
    <path
      d="M232,266c0,5.5.2,11.1,0,16.5s1,4-2,3.5c-.5-3.1-1.6-5.3-1.9-7.6-.7-4.4,2.2-11.6-4.1-12.4,0-1.3,0-2.7,0-4,2,1.3,5.7,3,8,4Z"
      style={{ fill: "#024d09" }}
    />
    <path
      d="M236,254c.6-4.6,6.4,0,2,2,3.5,2.1,4.1,6.6,4.3,10.4,2.9,3.3,4.2,5.7-.7,8.2l.3,15.6-2,1.8c3.3,3.3,1.8,10.7-2,6-.2-2,.7-2.3-2-2,.9-1.3,0-27.4,0-32s0-6.7,0-10Z"
      style={{ fill: "#015812" }}
    />
    <path
      d="M236,246l2.1-1.2c5.9,5.1,9.7,11.9,12.9,19.2.3-.9.6-.9,1,0,1.5,1.7,2.6,3.1,0,4-2.3.7-4-.7-5-4l-5.2-1.1c-.9-3-1.7-4.9-3.8-6.9-.3-.2,1-2.8-2-2,0-2,0-4,0-6v-2Z"
      style={{ fill: "#01631b" }}
    />
    <path
      d="M234,231c3.7-2,8.1,5.7,9,9,2.9.3,10,7.6,7,10,.4,1.6-.9,1.7-.8,1.2.6-2.3,5,3.4,1.8,4.8-.4,2.8-.9,2.4-2,0-2.9-.9-5.2-3.5-7-7.7-2.9-1.7-4.9-4.5-6-8.3-3.4-3.9-2.7-4.6-4-8l2-1Z"
      style={{ fill: "#027320" }}
    />
    <path
      d="M236,240c.1.2,3,1.1,3.7,2.3s0,2.9.5,3.5,2.2,0,3.3,1.2c1.6,1.7,1.6,7.6,4.5,5-.2,1.5.6,2.7,1,4,2.6,1.3,4.6,6,2,8-5.1-4.6-8.2-15-15-18v-6Z"
      style={{ fill: "#016a1c" }}
    />
    <path
      d="M352,107c4.3.9,9.6.7,14.5,1s1,2.5,1.5,4c-2.1,3-5.8,2.7-8,0-1.8,3.3-7.6,2.9-10,.1-3.8,1.2-7.6,1.8-11.5,1.8-1.8,2.1-5.2,4.4-5.6,1.1l-1-3c8.4-4.9,10.2-6.9,20-5Z"
      style={{ fill: "#06642d" }}
    />
    <path
      d="M248,112c.7,0,1.3,0,2,0,3.7,2.6.2,3.4-3,3,2.1,2.8-1.7,4.4-4,3-1.2,2.3-3.8,3.3-7.8,3-2.1,1.5-4.9,2-8.5,1.5-2.3,6-4.9,7.8-7.7,5.5-1.5,2.1-4.1,2.8-5,0,1-2.2,1.8-7.2,2.2-7.8s2.3-.6,2.8-1.2c9.8-2.4,18.8-6.2,29-7Z"
      style={{ fill: "#017325" }}
    />
    <path
      d="M216,136c.7.5,1.3,1.3,1,1.9-4.8,8.6-7,13.9-8,24.1,2,.2,1.9,1.2,0,2,2,5.1,1.7,9.1-1,12-2.7.3-1.8,0-2-2-.7-7,0-23,1-30s-.2-1.2,1-2c.7-2.8,2.8-7.3,6-6,.2-2.3,1.4-2.2,2,0Z"
      style={{ fill: "#027e1d" }}
    />
    <path
      d="M219,128c.7.5,1.6,1.2,1.5,2s-4.3,4.8-5.2,5.6-.8.4-1.4.4c-1.7,2.4-4.2,4.8-6,6-1.5-6.3,4-9.4,6-14,1.5-.2,3.6.3,5,0Z"
      style={{ fill: "#01771e" }}
    />
    <path
      d="M208,196c.3-1,1-1.9,1.9-1.8s5,8.6,5.6,10.7c1.9.8,2.4,1.8,1.5,3,3,.4,4.7,5.4,1,6-1-.3-2.6.5-3.8-.2s-4.7-5.8-4.9-6.1c-1.6-3.3-1.2-8.1-1.3-11.7Z"
      style={{ fill: "#028802" }}
    />
    <path
      d="M208,188c2-4.2,3.2-.5,2.9,2.9.2-1.3.5-.9,1.1,1.1l2,.6c2,5.1,4.2,10.1,6.5,14.9l-1.5.6c-.2,2.6-.9,2.6-2,0-.6-1.2-2.5-1.9-2.7-2.3-.6-1.2,0-3.1-.4-3.6s-2.2.2-2.6-.2c-1-.9.5-6.6-3.2-5.9,0-1.3,0-2.7,0-4s0-2.7,0-4Z"
      style={{ fill: "#019106" }}
    />
    <path
      d="M226,216c2.4.3,3.4.9,3,2,4.6,2.9,5.6,5.3,3,7,.9.2.9.6,0,1-2.9-.2-5-5.3-8-6,0-1.3.2-2.8,0-4,.4-2.6,1.2-2.5,2,0Z"
      style={{ fill: "#01821d" }}
    />
    <path
      d="M219,208c.3-.9.6-.9,1,0,3,.6,7.5,6.5,4,8-2.1-.7-4.4-1.6-6-2,.2-2.2,0-4-1-6h2Z"
      style={{ fill: "#018b0e" }}
    />
    <path
      d="M212,170c2.7,1,2.9,5,0,6-.7,2.2-1,2.5-1.1.9.5,7.6-.5,10-2.9,7.1,0-.3,0-.7,0-1,0-2.5,0-4.7,0-7,.1-4.4.7-8,1-12,2.2-.9,3.2,1.1,3,6Z"
      style={{ fill: "#018b1a" }}
    />
    <path
      d="M212,176c.5,2.3,2.3,11.7,1.8,13.5s-1,1.8-1.8,2.5c0-.1-1.4.3-1.8-.2-1.1-1.4,1.2-4.7-2.2-3.8,0-1.3,0-2.7,0-4,3.7.9,1.4-5,2-8,.7,0,1.3,0,2,0Z"
      style={{ fill: "#019113" }}
    />
    <path
      d="M232,225c3.5-3.9,12.3,5.8,11,10,2.9,4.3,2.9,6,0,5l-9-9c-2.9-1.2-1.7-2.9-2-5s0-.7,0-1Z"
      style={{ fill: "#027e22" }}
    />
    <path
      d="M250,112c8.8-.1,7.8,2,11.7,2.8,8.2,1.6,16,.6,24.6,3.3,10.6,3.3,26,5.6,38.3,4,1.8-.2.4-1.9,5.4-2.1,1.5-2.3,2.8-2.7,4,0,.4.6.4,1.3,0,2,0,4.2-9.9,7.9-14,8.2-8,.6-19.6-1.7-26-6.2l-5.8.5c-14.1-2.4-27.8-5-40.9-7.8l-.3-1.6c1.4-1.1,3.7-.6,3-3Z"
      style={{ fill: "#02702c" }}
    />
    <path
      d="M333,115c.3-.2,2.3.5,2.8-.2s-.4-2.7.7-2.9,3.3.3,4.9,0c2.5-.4,8.3-2.1,9.9-2s.5,1.8,1.2,2c1.9.5,5.1,0,7.3,0,3.7.5,7.4,6.8,4,9-8.6-2.4-17.2-3.4-26-2.7l-4,1.7c-1.3,0-2.7,0-4,0,0-1.3,2.2-4.5,3-5Z"
      style={{ fill: "#056a2e" }}
    />
    <path
      d="M407,312c-.1,1.7.6,3.4,1,5,.9.3.9.6,0,1-1.6,1.7-3.3,3.1-5,4.1l-9.7-.2c-1,1.3-2,1.2-3.3.1-4.9.9-12-2.7-6-7-2.7-.9-2.6-2.1,0-3,.9-3.7,10.6-1.9,14.3-.7,0-3.9,3.7-5.6,5-1.7,2.5-.6,4-.7,3.7,2.4Z"
      style={{ fill: "#142e22" }}
    />
    <path
      d="M400,303c1.7.7,3.9.9,4,1,.4.3-3.9,9.7,3.5,2l.5,2c.8,1.5-1,3.5-1,4l-6.5-3-.5,5-5.6-1.9h-10.4c-3,2.3-4.9-2.1-2-4.1-1-6.9,2.2-6.6,7.8-4.5l2.2-.5c3.7-2.8,4.8-1.4,8,0Z"
      style={{ fill: "#142720" }}
    />
    <path
      d="M416,315c0,1.7.1,3.3,0,5,2.8.7,2.8,3.1,0,4,0,.7,0,1.3,0,2,.9.3.9.6,0,1-1.5,1.5-3.5.9-6-2-4.1,2.8-4.8.4-2-7v-1c1.3-.7,7.7-1.7,8-2Z"
      style={{ fill: "#0e341f" }}
    />
    <path
      d="M432,288c1.5.4,3.8-1.4,4,1.5.6,8.9,1,17.3-4,24.8s-2,1.3-2.9,2.3c-1.9,2.1.2,4.6-6.1,3.4,2.3,2.3-4.3,6-7,4,0-1.2-.1-2.3,0-4,3,.2,5.9.5,7.7-2.3s1.4-4.5,5.1-7.7,2.8-.7,3.1-1.1c.6-.9.1-17.8.1-20.9Z"
      style={{ fill: "#06361c" }}
    />
    <path
      d="M416,326c2.6,0,8.4.9,8,4-1.4,1.8-2.3,3.7-2,6-.7,0-1.3,0-2,0-3.9-.3-5.9-1.6-6-4-4.8.4-2.3-6.5,2-5,0-.2,0-.6,0-1Z"
      style={{ fill: "#084021" }}
    />
    <path
      d="M362,290c3.6-2.8,2.8,1.2,5.2,2.3s5.5.8,8,1.8,5.5,4.6,6.9,5.3c3.1,1.5,6.9,2,9.9,3.6-.1.1.5,1.9-.6,2.1-1.6.3-5.1-1.9-7.4-1.1l-2,4c-1.9-1.8-3.9-2.2-6-1.5-5.3-2-10-5.2-14-9.5-.3-1.5.5-6.3,0-7Z"
      style={{ fill: "#162523" }}
    />
    <path
      d="M362,297c5-.3,10.8,6.9,12.2,7.3,4.4,1.3,6-3.7,7.8,3.7s.7,2.2,2,4,0,2.2,0,3c-2,2.7-14.4,1.2-16.3-.7l.3-2.3q1.3-1.3,0,0c-2.4,1.8-5.6-1.4-6-4-3.1,2.1-3.8-1.5-4-4-.9-2.3,1.9-6.9,4-7Z"
      style={{ fill: "#172a26" }}
    />
    <path
      d="M344,288c.9,0,3.7-.2,4,0s.7,6.1,2.1,7.4c-4.1,1-.8,3.6,1.9.5-.1,1.3,2.7,7.5,3.2,7.8.8.6,2,0,2.8.2,3.7.8,1.2,2.5,4,4,3.2,2.4,2.7,6.1-1.5,6.8,1.6,2.6,1.4,4.3-.5,5.2-.6,2.6-1.1,2.5-2,0-.8-.9-1.5-2-2.2-3.3-6.6-4-12-9.1-11.8-17.3-2.8-2.7-6.3-8.4-3-11.4,1,0,2,0,3,0Z"
      style={{ fill: "#143024" }}
    />
    <path
      d="M336,280c3.7-1.3,4,4.5,5,8s.4,4.3,1.1,6.2,3.9,4.1,3.9,4.4c0,3.5.5,6.5,3,10s9.7,5.9,9.1,11.4c6.9,4.6-6.5,6.5-4.9-.4l-1.1-2.6c-4.9,3-9-5.8-10.8-9.8s-5.5-13.7-6-16.5l.8-2.6c0-2.7,0-5.3,0-8Z"
      style={{ fill: "#123524" }}
    />
    <path
      d="M339,258c.6,2.4-1.1,2-3,3v-3s3,0,3,0Z"
      style={{ fill: "#0f2f1f" }}
    />
    <rect x="394" y="259" width="2" height="3" style={{ fill: "#1d2a27" }} />
    <path
      d="M368,112c2.7,0,5.3,0,8,0,.2,2.6-.3,5.4,0,8s1.3,2.9,2,4.5-2,.3-2.1,1c-.3,2,5.1,7.7,0,7.5-2.7-1.4-5.4-3.1-8-5-.5,2.8-2.1,2.6-3,0-2.1-1.5-4-5.9-1-7-1-4.8-1.8-4.7-4-9,2.7,0,5.3,0,8,0Z"
      style={{ fill: "#03692a" }}
    />
    <path
      d="M368,128c4.2.6,6.5.1,8,5,1,3.4,2.7,13.2,2,16.3s-1.5,4.8-2,10.7c-.8,0-2.6-.3-2.9,0s-.4,2.4-1.1,3.9c-1.9-1.3-1.9-2.7,0-4-3.1-1.1-2.1-6.1-.2-8.2l-.8-.8c2.4-8,.5-14.6-3-22-.9-.3-.9-.6,0-1Z"
      style={{ fill: "#016e24" }}
    />
    <path
      d="M352,184c1.3-.3,1.3.4,0,2-.4,2.5-1.1,3.8-2,6-4.9,2.4-7.8,9-14,10-1,1.7-2.5,2.1-3,0,.4-3.3,16.5-20.6,17.8-20.2l1.2,2.2Z"
      style={{ fill: "#01731d" }}
    />
    <path
      d="M336,202c-.4,3.3-5.3,4.6-7.7,6.4s-.2,1.6-.3,1.6c-.4.2-2-.4-2.8.2-2,1.5.8,1.2-4.9,4.2s-4.5,5.9-8.3,6.7c.6-2.9,2.6-4.6,4-7,2.2-5.6,11.3-10.6,17-12,1-.1,2.1.2,3,0Z"
      style={{ fill: "#01721e" }}
    />
    <path
      d="M372,160c0,1.3,0,2.7,0,4-.7,1.5-7.6,11.4-8.2,11.8-1,.6-2.9-.2-3.6.4s-.3,2.4-1.1,3.3c-1.9,2.3-4.7,4.7-7,6.5.1-.6,0-1.4,0-2l-1.7-1.6c.1-.7,6.1-10.7,7.7-10.4,2.6-4.1,6.1-8.9,10-12,0-2.9,3.3-2.8,4,0Z"
      style={{ fill: "#027321" }}
    />
    <path
      d="M308,280c6.6-1.6,3.3,5.2,4.1,9.4s5.3,14.6,9.9,17.1c.8,1.8-1.9,1.5-2,2-1.2,3.7,2.9,2.4,3.8,3.7,1.5,2.2,1.3,7.9,1.9,9.5s2.1,1.9,2.3,2.2c4.9.7,3.2,8.7,0,4-2.2,1.6-5.6-2.4-7-4.3s-3-5.7-3-7.2c-.7-.3-1-1.1-1-2.4-6.6-6.6-8.8-19.5-8.2-28.4-1.6-2.5-1.8-4.3-.8-5.6Z"
      style={{ fill: "#025420" }}
    />
    <path
      d="M316,285l3,3c1.6.4.5-3.6,3-2s3.4,8.4,4,10.5,1.9,2.7,2,3.5c1.1,2.7,3.7,10.1,0,11-5.5-1.2-4.2-5.1-5.2-8.3s-4.5-2.9-1.8-6.7c-5.2,1.1-2.2-3.7-3-7.3s-1.1-1.4-2-3.7Z"
      style={{ fill: "#075f2a" }}
    />
    <path
      d="M330,332c-1.3,5.2-7.2-2.3-2-4,4.5-.2.5-.8,0-1.7s.2-1.9,0-2.3c3.2.6,2.9-2.8,0-3.5-.1-.9,1.3-.4,2-.5,1.3-1.2,1.3-4.5,0-9.8l2-1.2c2.7.7,6.4,13.9,6.2,16.6-.3,4.3-5,10.5-8.2,6.4Z"
      style={{ fill: "#0e5427" }}
    />
    <path
      d="M330,301c1.9,1.4,1.8,1.6,0,3,.9.4,1.6,1.4,2,3,1.9.3,1.8,1.1,0,2,0,1.9.3,10.6,0,11s-1.5,0-2,0c0-1.4.3-3,0-4.3s-1.7.4-2-4.7c-.2-3.3.6-8.1,0-11,.7.3,1.3.7,2,1Z"
      style={{ fill: "#0c5b2c" }}
    />
    <path
      d="M304,272c2.5-.8,3.2,5.2,4,8,3,10.1,2.8,19,7.2,28.3.8,1.8,1.4,3.7,1.8,5.7l-2,1.5c-3.8-1.2-7.5-2.7-11-4.5-2.8,2.6-5.2-1.3-2-3-.8-2.5-.5-5,.9-7.5-1-2-2.4-5.5-2.1-7.4-3-.9-4-4.3-.8-5.1h8c1.4-4-3.1-5.4-3.8-7.7s-.1-5.7-.2-8.3Z"
      style={{ fill: "#01501d" }}
    />
    <path
      d="M291,272c2.7,5,6.8,11.7,9,16s-.5,2,.2,2.7,3,.4,3.8,1.8-1.8,1.1-2,2c-.5,2.3,2,1.1,2.1,2.1.2,1.6-.9,10.4-2.1,11.4-2.9-.7-7.4-2.2-3.5-6.2-.8-.9-.6-2.2.5-3.8-3.7-2.5-5.9-6.3-4.1-10.5l-.9-1.5c-2.2-.5-3.5-3-3.9-7.5-3.6-1.7-6.3-7.3-2.1-9.5,1,.7,2.5,2,3,3Z"
      style={{ fill: "#04551d" }}
    />
    <path
      d="M330,285c5.4-.8,3.2,1,4.3,2.7s1.7.2,1.7.3c4.1,6.6,5.2,15.3,8.6,21.9s4.1,5.7,7.4,7.1l1.9,1.3c-1.5,3.9-1.4,4.7,2.7,5.7,1.8-1.3,5.5,1.6,5.4,4,5.6,3.9,6.6,6.6,3,8l-2.3,1.4c-4.9-2.8-9.4-4.8-14.8-5.8-.5-3-1.9-5.3-3.9-7.6-1.8-1.7-2.8-4.4-3.1-7.9-4.9-8.8-8.3-17.7-10.1-26.8l-.8-1.3c-.1-.9.2-2.1,0-3Z"
      style={{ fill: "#123c26" }}
    />
    <path
      d="M328,213c-1.4,4.3-3.5,6.3-8,7,0,0-.2-1.2.7-2,1.9-1.7,5-4.4,7.3-5Z"
      style={{ fill: "#03641a" }}
    />
    <path
      d="M285,264c.1.6-.2,1.8,0,2,.3.3,2.2-.1,3,0,0,1,0,2,0,3,.3,10.3.5,4.6,3.5,8s2,8.4,2.5,9c3.1,5.1-1.8,5.2-4,0-5.2.9-10.2-13.1-8-17-2.4-2.3-.4-6.9,3-5Z"
      style={{ fill: "#035d1c" }}
    />
    <path
      d="M258,234c.3-.8.9-1.8,1.6-1.7,1.7.3,14.1,12.4,14.4,15.7,2.4-1.1,2.6,0,3,2,0,.5,0,1.4,0,2,0,1.1-.4,1.8-1,2-1.6,2.4-6.3,5.6-9.1,4.1s-4.6-4.6-5.8-5.9-1.6-2-2.1-3.2c-1.9,1.7-2.3,1.5-2-1-2.1-.7-2.5-1.4-1-2l-1.3-2.8c1.6-3,2.7-6.1,3.3-9.2Z"
      style={{ fill: "#02791d" }}
    />
    <path
      d="M277,252c.1,1.3,1.3,5.1,3,4,0,.3,0,.7,0,1,3,2.9,2.8,8.1,0,11,1.9,1.8,2.1,4.3-1,4-2.1,1.5-3.1.1-3-4-2-.2-1.9-1.2,0-2-2.9-2.8-3.3-9.5,0-12,0-.8,0-1.7,1-2Z"
      style={{ fill: "#036b1b" }}
    />
    <path
      d="M276,240c.1,2.9-1.2,5.5,2,6.7v1.3c0,0-1,1.9-1,2-1.9.5-2.1-1.2-3-2-1.7-2.5-3.8-6.6,0-8,.6-1.9,1.4-1.9,2,0Z"
      style={{ fill: "#037c14" }}
    />
    <path
      d="M358,172c-1.4,5-5.2,6.4-6,12-2.8-.7-2.5.5-3.7,1.8-5,5.5-9.5,11.5-15.3,16.2s-11.5,7.8-17,12-2.7.9-4,2v-2c-1.9-1.3-1.9-2.7,0-4l.4-1.3c8.7-4.2,16.6-9.5,23.6-15.9,1.5-3.2,4.2-7.5,7.9-6.8,1.2-3.3,3.4-6.2,6.7-8.7,1.4-7.1,3.9-10.1,7.3-9.3,2.2,1.1,2.3,2.8,0,4Z"
      style={{ fill: "#027c21" }}
    />
    <path
      d="M312,210v4c-3.9,1.7-8,6-9.7,9.8s-1.4,9.7-6.3,10.2c-2.9-.3-3-4.7,0-5-2.6-1.3-1.2-3.4,1-4.7.8-3.1,3.4-9.2,7-8.3.7-3.4,2.2-7.7,6-7,2.2-1.6,3.3-1.5,2,1Z"
      style={{ fill: "#028b24" }}
    />
    <path
      d="M284,255c0,3,.5,6.1,1,9-1.4,2-3.8,2-3,5-1.8,2-2.2,1.7-2-1,0-3.6-.2-7.4,0-11,1.1-.1,3.1-1.4,4-2Z"
      style={{ fill: "#036415" }}
    />
    <path
      d="M262,224c.9-4.3,6.9-1.9,6.1,2.9l5.5,5.2c2.4-.4,4.1,0,5.4,2.1,2-1.9,4.1-3.8,5-.2,2.1-1.1,3.7-.5,4,2v4c-3.8.1-8.2.2-12,0s-1.4,0-2,0c-.9,1.5-3,1.9-4.7,1.5s-5.3-6.8-5.1-7.6c-2.4-.6-4.1-1.6-2.1-3.9-3,0-2.9-5.3,0-6Z"
      style={{ fill: "#01850a" }}
    />
    <path
      d="M296,229c-.4,1.2.2,3.5,0,5-2.5.3-5.4-.2-8,0-2.8-1-2.7-5,0-6,1.8-2.7,6.3-1.4,8,1Z"
      style={{ fill: "#02901c" }}
    />
    <path
      d="M284,228c.7-3,2.8-2.7,4,0,.2,1.9,0,4,0,6v2c-1.3-.2-3.5-.6-4-2-1.4-.7-4.3-4.4-2-6,.3-2.7,1.2-2.5,2,0Z"
      style={{ fill: "#038f10" }}
    />
    <path
      d="M332,302c.3-.9,1.5-1.6,1.7-1.3,2.6,5,7,14.6,4.3,19.3,1.9,2.7,3.3,5.9,4.3,9,2.7,2,3.5,14.9-2.3,10-4.4,5.5-8,2.3-10-3-2.9-.3-2.7-3.3,0-4,11-2,4.9-16,2-23,0-.7,0-1.3,0-2-.5-1.2-1.2-2.2-2-3-.1-.9,0-2,0-3,.6.3,1.5.6,2,1Z"
      style={{ fill: "#114d27" }}
    />
    <path
      d="M330,288c3.8.5,1,1.1,2,3.2,2.1,4.4,2.7,8.4,4.8,13.5,3.5,8.6,7.3,7.9,7.2,19.3,1.9,1.3,1.9,2.7,0,4,2.1.8.4,3-1.5,1.7-1.1-3.4-2.6-6.7-4.5-9.7-1.5-5.1-2.3-11.6-4.2-16.3s-1-1.1-1.8-1.7c1-5.1-1.3-9.3-2-14Z"
      style={{ fill: "#114325" }}
    />
    <path
      d="M333,364c1-3.3,4.8-1,3,2,.8,1.1,2.7,1.1,5.8,0,2.8,3.4,4.9,7.7,6.2,12.9,1.5,1.4,1.9,3.7,1,7-.8,2.2-2.9,2.2-5,2,.9-6.4-1.9-3.2-6-4s-1.5-.9-2-1c1.8-6.6-3.2-5-4-6s1.4-3.9.9-5.4-4.7-3.5-4.9-3.6c-4-2.7,1.7-6.7,5-4Z"
      style={{ fill: "#0e3f1d" }}
    />
    <path
      d="M308,364c-3.3-6.2.5-22.3,7.6-24.1s7.1,5,6.3,10.1c3.7-.4,6.2,2.2,7.5,7.8,3,2.1,4.2,4.2,3.6,6.3-2,.7-5.5,1.5-5,4-1.4-.3-19.1-.4-20,0-2.3-.6,0-3.6,0-4Z"
      style={{ fill: "#083d19" }}
    />
    <path
      d="M324,336l1.9.9c.2,6.9,1.5,8,8.2,9.2,4.6,1.9,9.1,11.4,6.3,15.7,2.3,4.1-1.8,7.2-4.5,4.2-1.5-2.2-2.6-1.6-3-2-1.4-1.3-4.2-3.8-5-4.8-1.4-1.7-2.8-6.9-3.1-7.1-1-.7-4.5.3-4.9-.1s.3-8.3,0-10c-1.6.3-4.7-.5-5.8.2s-3.9,7.3-4.2,8.3c-1.4,4-1.8,9.3-1.9,13.6,0-.2-1.8-4.4-2-4.6-1.2-1-2.3.5-3,.6-1.7-5,3.6-13.4,3.8-16.7s-1.8-2.2-1.7-3.6,1.6-1.9,1.8-3.7c5.1-3.2,12-3.2,17,0Z"
      style={{ fill: "#08411b" }}
    />
    <path
      d="M307,328c-3.2-7.4,0-12.5,8.9-9.3,3.6-3.1,6.2,5.1,5.5,8.8,5.2,3.3,6.7,6.2,4.6,8.5-.2,2-1.2,1.9-2,0-5.7-.2-11.3-.2-17,0,.2-1.9-.3-4.1,0-6-2.6-.4-2.5-1.1,0-2Z"
      style={{ fill: "#05481b" }}
    />
    <path
      d="M301,324c1.7-.4,2.3.2,2.6,1.7,2.6-.6,4.5-.7,3.4,2.3,0,.6,0,1.4,0,2-1.2.3-5.5,5.8-5.7,6.3-.7,2.1-.1,4.4-1,6.5s-2.3,1.1-2.3,1.2v14c0,.2-2.7.3-3.4.9-2.3,1.8,2.7,3.1-6.6,3.1-2-.2-1.9-1.2,0-2l.9-1.8c3.7-1,5.7-4.6,6-10.8-1.1-5.7,0-9.5,3.2-11.4-1.4-2.9-1.6-11.5,3-12Z"
      style={{ fill: "#084e1e" }}
    />
    <path
      d="M248,299c6.3,1.9,5.3,17.8,1,21l1.1.9c-1.3,3.9-1.5,10.2.8,14.2l-.9,1c-1.9.6-2.3-1.4-3-3-3.3-7.4-1.8-10.8-3.3-17.3s-5.2-4.7-5.6-6.4,1.7-3.9,1.8-4.9-1.5-1-2-6.5c3.1.9,1.9-3.2,2-6l1.1-.9c2.6,1.8,5,4.4,6.9,7.9Z"
      style={{ fill: "#015617" }}
    />
    <path
      d="M256,321c.9,0,1.5.5,1.9,1.2-2.8,4.3-2.3,9.4,0,13.8,1.8.5,2.6,2.3,2.4,5.4l1.6,5.6c3,.8,6.1,4.8,3,7-.3.4-.6.4-1,0-2,.9-4,.2-6-2-1.1-2.8-2.3-8.1-4-10-3.4-5.6-8.3-16.2-1-20,1.4-2.4,2.4-2.8,3-1Z"
      style={{ fill: "#076220" }}
    />
    <path
      d="M258,352c1.4-.7,5,1,6,2,2.9.4,4.6,1.9,2,4-.7,0-1.3,0-2,0-.1-.6.2-1.8,0-2-.7-.5-4.2.9-5-2,0-.3-.7-1.2-1-2Z"
      style={{ fill: "#0c5721" }}
    />
    <path
      d="M252,320c3-3,5,0,1,2-.5,2.6-3.3,10.5-3.1,11.3s2.2.8,2.9,2.3.3,4.5,1.2,6.3c-3.1-3.3-2.2.8-4-6s-2.1-7.1-4-11.5,2.2-1.3,3-4.5c.9-2.7,2.1-2.7,3,0Z"
      style={{ fill: "#025d19" }}
    />
    <path
      d="M265,354c.5-3.4,3.1-2.5,5.2-.5,2.7-2,7-1.5,7.8,1.5,3-.5,4.3-.2,4,1,2.4-.6,2.8,1,2,3,0,1.9-.5,1.9-2,1-3.1.6-4.5-1.7-6.6-1.9-3-.4-6.4.1-9.4,0-.2-1.4-1-3-2-4h1Z"
      style={{ fill: "#0f5f21" }}
    />
    <path
      d="M296,336c.6-1.9,1.4-1.9,2,0-.3,4.1-1.8,2.8-2,3.7-1.5,6.3,2.7,20.3-8,20.3,0,.7,0,1.3,0,2-2.4,0-6.6,1.1-6-2,.1,0,.5-1.5,2-1,1.8-1.9,3.4-1.5,4,1-3.3-1.3-1.7-5.4,1.6-6,0-2.3.7-3.6,2.4-4,1-4.5,1.4-9.4,1.3-14.5.7-1.4,1.7-.7,2.7.5Z"
      style={{ fill: "#09551e" }}
    />
    <path
      d="M330,336c3.6.8,3.1,3.5,4.6,4s3-1.5,4.1-1.7,1.2.8,1.3.8c6.9,1.5,9.4,4.2,7.4,8,2.8,6.7,4.6,16.1,4,21.3l.6,1.7c-.2,4.6-2.3,13.7-2,16h-1c-.9-2.3-1-4.5-1-7-2.2-6-3.5-12.3-4-19.1-1.6-6.2-4.7-13.6-11.8-14.8-2.4-1.8-6.6-6.6-4.2-9.1.3-2.7.9-2.4,2,0Z"
      style={{ fill: "#0f4623" }}
    />
    <path
      d="M168,409c-2.4-.6-1.6-2.6-2-4,1.5.9,1.8,2.4,2,4Z"
      style={{ fill: "#213f29" }}
    />
    <rect x="185" y="420" width="2" height="3" style={{ fill: "#2e4a33" }} />
    <path
      d="M362,346c1.3-1.8,4-2.5,7.9-2,9.3-4.5,18.4-4.8,28.5-3.9l1.6,1.8c2.7.3,2.5,1.2,0,2l-3,2-1.3,2c-9.8-.7-17.3,1.3-25.7,5.7l-2.1-1.8c-1.5.2-3.3-.5-4.7.3s-3.2,5-3.9,5.8c-1.2,1.2-4.4,1.6-5.3,4-.4-1.4.9-2.4-2-2-5.3-5.9.2-15.9,8.5-11.6l1.5-2.4Z"
      style={{ fill: "#144c2b" }}
    />
    <path
      d="M397,346c3.6-3,7.4.6,3,3,0,1,0,2,0,3-1.9,2.8-5.1,2.8-7,0-3.4,1.8-7.7,2.5-12.7,2.1-3.4,3.2-10.1,9-14.3,5.9.9-2.8,2.7-4.8,2-8,6.5-.7,3.9-1.1,8.1-3.4,4.9-2.7,14.6-2.5,20.9-2.6Z"
      style={{ fill: "#11532d" }}
    />
    <path
      d="M393,352c2.8,3-.7,5.3-3.9,5.8-5.6-.4-9.6,1.6-13.9,5.1h-2.2c-.2,2.2-2.2,3.3-6.1,3.4-2.2,3.3-4.5,5.2-6.9,5.7,0-3.2-1.1-10.9,2.8-12s2.7.2,3.2,0c2-.8,7.2-2.6,8.9-3.6s2.9-3.7,4.8-4.2c3-.9,9.6-.1,13.3-.2Z"
      style={{ fill: "#10592c" }}
    />
    <path
      d="M376,364c2.9,0,2.8,3.3,0,4-1.2,1.8-5.8,6.8-8,4-.3,1.1-1.4,2-3.5,2.8-.8,3.2-1.7,6.2-2.5,9.2-.6,0-1.3,0-2,0v-4s-2,1-2,1c.2-4-.7-5.6,2-9s.8-1.1,2.3-3.7,3.5-3.6,3.8-3.8c.4-.2,6.2-1.5,7-1.5,2.3-1.8,3.4-2.3,3,1Z"
      style={{ fill: "#0d5e29" }}
    />
    <path
      d="M344,328c2.6-4.3,4.8,1.4,3.2,4.8,3.6.9,5.4,3.4,6.7,6.7,2.4.3,3.7,1.2,4.1,2.5,2.1.1,4.2,1.7,4,4,2.5,7.7-5,2.9-6.4,2.9s-3.2,3.1-3.3,3.3c-.8,1.6-.2,5.6-.3,7.8-.1,3.3.1,6.7,0,10-3.9.6-1-4.2-1.2-6.3-.4-4.6-5.7-14.4-5.5-17.2s2-1.9,1.7-2.5-7.8-2.1-7-5c2.4-.1,2.5-.1,2.4-3.1,0-6.2-2.8-10.5-4.4-15.9,2.6,1,5,8.9,5.5,9s.4-1,.5-1Z"
      style={{ fill: "#134827" }}
    />
    <path
      d="M366,383c2.4.8,2.4,3.1,0,7,1.5.1,2.6,1.3,3.4,3.5,4.4,1.8,6.6,4,6.6,6.5-.8.6-1.8.6-3,0-1.7,0-3.4.1-5,0-.1-1.1.5-2.7-.2-3.8s-5.6-4.2-5.8-4.9c-.4-2,0-5,0-7.3.4-1.8.7-2.6,2-1,1-1.1,1.6-1.3,2,0Z"
      style={{ fill: "#126a28" }}
    />
    <path
      d="M376,400c1.6-2,3.4-2,5,0-.1-2.7,2.4-2.2,3,0,4.1,1.2,9.7,4,13.1,6.5s0,.7-.1,1.5c-.5-.2-1.5.2-2,0-7.3-2.4-15.1-4.5-22-8,1,0,2,0,3,0Z"
      style={{ fill: "#1e692b" }}
    />
    <path
      d="M445,394c-1.3,2.5-2.6,4.8-5.1,6.4l-23.8,9.7c-.7.8.2,3.5-.1,3.8-2.2,2.5-16.1-5-19-6l-13-8c0-1.3,1.2-1.3,4,0,1.7-1.3,6.6,1.8,7,4,7.6,3,15,3.5,22,1.4,6.6-3.2,13.2-5.7,20-7.4,2.8-1.2,6.1-3.6,8-4Z"
      style={{ fill: "#286d2d" }}
    />
    <path
      d="M518,308c0,.3,0,.7,0,1-.3,8.2-4.6,10.9-10.8,13.9l-2.2,1.1c-.6,2.1-2.5.7-3-1-1.7-.3-3.6-1.2-4-3-2.9,4-6.6-2.8-6-6,5.8.1,3.5-1.7,4-2,.4-3.5,8.7-3,10,0,1.5-2.7,3.9-1.5,5.5.7.9-3,2.4-4.5,4.5-4.7.2-.3,1.5-.6,2-1,0,.3,0,.7,0,1Z"
      style={{ fill: "#0a8327" }}
    />
    <path
      d="M518,312c-.1,2,.6,5.6-.5,7.1s-2.4.7-3.1,1.4c-2.5,2.3-1.3,3.3-5.4,3.6-1.1,2.8-3,2.8-4,0,.7-5.4,2.4-3.1,4.9-4.6,4.4-2.5,6.3-5.9,8.1-10.4,0,1,0,2,0,3Z"
      style={{ fill: "#057a22" }}
    />
    <path
      d="M480,352c3.6.8,4.9,2.2,4,4-.3.5.2,3,0,4-.9,0-2.1,0-3,0-5.1-.5-1.9-1.2-3.3-2.7-1.9-2.1-3.7-4.1-5.7-6.3-1.5,2.2-2.1,1.6-2-1,0-.8.9-1.6,1-2,.4-.8,1-1.2,1.8-1.2l7.2,5.2Z"
      style={{ fill: "#0e7823" }}
    />
    <path
      d="M500,336c1.3,6.1-3.7,4.1-5.5,5.9s.2,2.4-.4,3.3c-2,3.2-2.1,0-4.1,6.7l-2,1v-4s-1,0-1,0l-1,2c-2.9-.3-2.7-2.2,0-3-1.6-2.6-1.2-4.1,2-4l3.6-4.6c2.8-.7,5.3-1.9,7.4-3.4.3-.9.7-.9,1,0Z"
      style={{ fill: "#256c2d" }}
    />
    <path
      d="M486,348c-.2.8.2,2.1,0,3-1,6.1-.5,2.8-2,5-.5-1.6-3.4-.6-4-1.1s.1-2.2,0-2.9c-.7-2.7,0-3.7,2-3,.4-3.7,4.9-5.3,4-1Z"
      style={{ fill: "#1b7a29" }}
    />
    <path
      d="M504,328c.7-2.8,3.3-2.7,4,0-.3.7-.7,1.3-1,2-3.5.1-5.5,2.5-6,6-.3,0-.7,0-1,0s-.7,0-1,0c-2.8-.9-1.9-5.5,1-6-.6-4.2,1.3-5.1,4-2Z"
      style={{ fill: "#1a6e2a" }}
    />
    <path
      d="M470,350c.4.4,1.6.6,2,1,3,13.8-3.8,7.3-11.3,9s-.3,1.8-2.1,2.1c-2.9.4-15.6-.7-16.6,0l1,2h-6c-1.8,2-4.6.3-5-2-2.6-.6-2.5-1.2,0-2l2.6-2.1c6.4,0,12.8-.5,19.1-1.6,1.9-2.2,4.6-3,7.9-2.2,1.4-2,2.8-4.1,4.4-6.1,0-.6.2-1.8,0-2,.6.8,2.9,2.7,4,4Z"
      style={{ fill: "#056e1d" }}
    />
    <path
      d="M432,362c2.5.2,3,.6,5,2s1.5,1.4,2,2,.6,1.4,1,2l-.4,1.3c-7-1.4-13.4,0-19.6,1.7-2.4,1.4-10.3,2.4-12,0-.6,1.9-1.4,1.9-2,0-5-1.4,0-6.3,3-3l1-2.1,11-1.3c4.1-3.4,7.7-4.3,11-2.6Z"
      style={{ fill: "#087629" }}
    />
    <path
      d="M448,370c-.6-3,1.5-1.9,3.5-2s4.4,0,6.6,0c-1.2,2.3,1.1,3.7.6,6.1s-2.6,2.8-3.6,3.9c-1.1-2.5-4.6-3-6-4.5s-.9-3.3-1-3.5Z"
      style={{ fill: "#05821a" }}
    />
    <path
      d="M440,368c1.1.1,1.7.9,2,1l-2,2c-.4.7-.9,1.4-1.6,1.3-7-.8-10-.7-16.8,2.3l-1.6-1.7c-.4-.5-.7-1-.6-1.2.8-1.2,2.1.6,1.2,0s-.4-.5-.6-.8c1.3-.3,1.9-2.8,2.7-3,2.7-.6,14.1-.4,17.3,0Z"
      style={{ fill: "#058228" }}
    />
    <path
      d="M440,371c.6.1,1.4,0,2,0,.5,1.8-1.7.3-2,1.5.3.7,1.3.9,2,1.5l-.9,1.5c-6.5-1.9-10.8-1.5-16.6,1.9-2.7,1.1-6.1,1-8.5-.3-3,1-8.3-.6-4-3,.6-2.8,6.5-2.7,8-1,5.4-.9,6.7-2.4,9.5-3s7.3.2,10.5,1Z"
      style={{ fill: "#0d8a2a" }}
    />
    <path
      d="M442,374c2.2,1.9,5.6,3.3,6,6-.3,2.9-2.2,2.7-3,0-.4.9-1.1,1.3-2,1.4-3.4-3-7.1-5.8-11.8-4.3-4.8,2.8-11.3,6.1-15.2,1-.9-.3-.9-.6,0-1,7.2.8,11.6-3.4,16.7-3.8s6.2,1.5,9.3.8Z"
      style={{ fill: "#179127" }}
    />
    <path
      d="M448,380c.8,5.5-.8,6.2-2,10h-4c-.9-.2-.8-.6,0-1l.7-4.7c-1-3-.2-4.4,2.2-4.2.7.2,2.1-.2,3,0Z"
      style={{ fill: "#228a2b" }}
    />
    <path
      d="M437,397v1c-7.3,3.1-19.2,10.3-26.6,10.9-5.6.5-10.5-2.6-15.4-4.9.8-1.9,3.8-1.4,5,0l1-1.2c6.5.6,13.2.6,19.1-2.6,1.7.7,3.6.7,5.9-.2,1.1-3.3,8.3-5.6,11-3Z"
      style={{ fill: "#347b33" }}
    />
    <path
      d="M432,394c1.6-1.3,7.4-3.2,8,0-.9,1.2-1.4,2.5-3,3-3.3,1.1-6.9,1.5-11,3-4,1.1-11.1,2.5-6-3,0-1.9,1.9-2.6,5.7-2.1,1.9-1.6,4.4-2.7,6.3-.9Z"
      style={{ fill: "#398734" }}
    />
    <path
      d="M442,384c.8,1.1.8,2.7,0,5,0,.3,0,.7,0,1-.3,1.7-1,2.6-2,4-2.6.2-5.5-.3-8,0-2.7-.3-2.5-1.2,0-2,.2-2.2,1.8-2.3,3.6-2.2-.5-3.6.3-5.5,2.4-5.8,0-3.1,2.4-1.9,4,0Z"
      style={{ fill: "#369130" }}
    />
    <path
      d="M505,324c1.1.2,2.8,0,4,0,0,.6,0,1.4,0,2-.2,1.2-.7,1.4-1,2-1.3,0-2.7-.1-4,0-2.2,0-6.4-3-2-5,1,0,2.2.9,3,1Z"
      style={{ fill: "#0f7426" }}
    />
    <path
      d="M498,268c1.8.9,2.4,1.9,2,4-3.3,5.8-6.1,14.2-4,20,.9,1.8.9,3.1,0,4-2.8,3.5-11.2-5.4-8-8-.5-1.3-2.8-7.2-2-8,4.4,1.3,6.3-1.6,6.8-5.7s-1.1-4.1-.8-4.3,2.3.3,3.3,0,.2-1.2,2.7-2Z"
      style={{ fill: "#03a52f" }}
    />
    <path
      d="M496,304c1.2-2.5,3.2-2.5,6,0,1.5-1.2,2-.7,1,1,.4.6.7,1.3,1,2,3.4,1.9,4.1,3.5,2,5-1.4-.1-9.6-.3-10,0,.2-3-2.8-4.9-2-8,.7,0,1.3,0,2,0Z"
      style={{ fill: "#068d2a" }}
    />
    <path
      d="M516,308c-.7,1.1-3.4,7.6-4,8-3.6-2.4-1.4-3.6-6-4-.6-1.6-1.2-3.5-2-5,2.9.6,6.3,3.5,9,1s2.3-7,3-9v9Z"
      style={{ fill: "#0c912c" }}
    />
    <path
      d="M508,280c2.9-1.2,4.9-1.2,6,0,.1,3.5,0,5.3-3,7.5-.2.9,1.4.2,1.8.7,1.5,1.6-4,12.7-3.8,15.8l3,2c-2.7.5-4-.7-6-2-2.6.5-5.7-2.7-4-5-1.2-1.9-1.2-2.9,0-3,.9-3.2,3.7-2.5,4.6.6,1.7-5.2,1.7-10.1-.1-14.7l1.5-1.9Z"
      style={{ fill: "#13a533" }}
    />
    <path
      d="M496,292h1.5c1.5,2.3,3,4.7,4.5,7,1.2,1.4,1,3,4,5l-3,1c-.2-.3-.7-.6-1-1-3.5-.8-8.4-4.3-6-8-1-2.9-.3-2,0-4Z"
      style={{ fill: "#09a030" }}
    />
    <path
      d="M500,272c2.2,0,8.5-.5,9.8.3s4,4.2,4.1,4.4c.4,1,.1,2.2.2,3.3-2.9.4-1.4,2.1-3.5,2.1s-1.8-1.6-2.5-2.1c-5.2-7.5-9.9-3.7-8.8,4.6l-1.2,3.4c.1.8-.1,2.2-.6,2.1-1.4-.2,1.4-2,0,1.3s-.7.5-1.4.6c-.4-.5-1.6-.2-2-2.5-1.2-7,2.5-11,2-17.5,1.3,0,2.7,0,4,0Z"
      style={{ fill: "#08a731" }}
    />
    <path
      d="M470,300c-3.7-1.8-2-8.5,1.4-8.6s8.9,5.5,10,6.5c.7,2.9,1.6,4.4,4.6,5.1,1,.6,2.8-1.3,2,3l-4-1.5c1.9,1.9,3.4,2.7,4,5.5l-1.1.9-6.9-6.9c-.9-.3-.9-.6,0-1-5.7-5.1-7.1-2.9-6,4,1.5.4,2.3,2.9,1,4,1.7,3.1-.4,3.8-2,1-.3.9-.7.9-1,0,1.4-3.2-1.9-4.2-2-4.7-.4-1.9,0-5.1,0-7.3Z"
      style={{ fill: "#079b34" }}
    />
    <path
      d="M488,312c1.3.5,1.4,1.1,2,2,1.9,1.3,5.9,6.5,3,8,3,11.8-6.6,8.6-11,2-2.5-1.1-3.8-6.1,0-6l1.4-.4c3.6,4.2,4,2.9,1.2-3.8l1.4-1.8c0-2,1.3-1.9,2,0Z"
      style={{ fill: "#2a9c3a" }}
    />
    <path
      d="M466,298c0-3.4,2.1-8.9,6.1-8.6s3.9,3.2,6,5.1,3.1,1,3.7,1.7.7,4.5,4.2,6.8c-5.8.4-4.1-2.4-5.1-3.9s-1.5.6-1.9.2c-2.2-2-3.9-5.2-7.5-6.2-3.2,1.5-1.5,3.9-1.5,6.8l-4-2Z"
      style={{ fill: "#029630" }}
    />
    <path
      d="M488,288c.2.6-.3,1.8.5,3s5.8,4.6,7.5,5c.9,2.5,4.1,5.3,6,8-2,0-4,0-6,0l-9.1-11.4c-1.3-1.3-.6-3.3-.9-4.6.7,0,1.3,0,2,0Z"
      style={{ fill: "#01992b" }}
    />
    <path
      d="M492,314c.5,3,3.1,5.4,6,6,3.1.3,2.6,5,0,6,1.4,1.9,0,5.1-2,6-.4,1.4-1.2,1.3-2,0-2.2-.7-2.8-3.8-.5-4.5l-.5-5.5c-.5-2.5-1.4-6-3-8,.7,0,1.3,0,2,0Z"
      style={{ fill: "#228a32" }}
    />
    <path
      d="M488,310c.1.6,0,1.4,0,2-.5-.2-1.5.2-2,0l-5.1-4.5c-2.2.3-2.8-2-.9-3.5,3.7.7,4.8,4.3,8,6Z"
      style={{ fill: "#19a237" }}
    />
    <path
      d="M470,320c.2-2,1.2-1.9,2,0,3.5.4,2.5,4.4-.1,6.1-4.9-.1-6.2,2.6-3.9,6.9,2.5,1.8,3.5,4.8,3,9l5.4,4.2c3.3,0,5.3,2.7,3.6,5.8-2.2.6-6.8-2.9-9-4,0-.3,0-.7,0-1-.2-1.8-3.8-8-5-10.3s-1.5-.7-1.9-1.5-1.2-6.4-1.1-7.2c.2-1.3,2.4-7.3,3.2-7.8s2.6-.1,3.8-.2Z"
      style={{ fill: "#1b8030" }}
    />
    <path
      d="M472,312c.3,0,.7,0,1,0,2.7.9,4.7,6.1,2,8-1.1,2.5-1.9,2.5-3,0-.7,0-1.3,0-2,0v-8c.5.1,1.4,0,2,0Z"
      style={{ fill: "#138b31" }}
    />
    <path
      d="M414,332c1.2,0,5.4,2.5,6,4-6.8.4-2.7.9-4,3,.3.4.6.8.7,1.2.4,1.6-15.5,7.6-16.7,3.8v-2c-2.6-1.1-2.6-1.9,0-3-2.8-3.9-.6-5.6,6.8-5.2,3.4-3.3,5.8-3.9,7.2-1.8Z"
      style={{ fill: "#0d4625" }}
    />
    <path
      d="M416,339c3.7,2.7,4.8,4.5,0,6-3.1.3-5.4,2.9-5.7,3-2.7.6-7.3.2-10.3,1,0-1,0-2,0-3-1,0-2,0-3,0l-1-2c1.3-.1,2.7.1,4,0,2.6-.3,15.1-3.5,16-5Z"
      style={{ fill: "#0b4e27" }}
    />
    <path
      d="M466,348c.4,4.1-1.6,3.8-2,4.8s.2,2.9,0,3.2-5.7-.4-7.3,0-.6,1.8-1.4,2c-7.8,1.8-14.8,1.7-23.3,2-2.3-.3-3-1-2-2,0-1.6.6-2.6,2-3,2.9,2,13.5-2.6,17-3,5.7-.7,9,.8,14.2-2.1s.9-2.4,2.8-1.9Z"
      style={{ fill: "#06651f" }}
    />
    <path
      d="M425,350c0,.3,1.1.6,1,1.3s-1.7,1.2-2,1.7c2.6,1.5,2,3.1-1,3-2.8,4.7-14.6,6-18,2-2.9.5-3.7-5-1-6,7-.1,14.6,1.2,21-2Z"
      style={{ fill: "#075c27" }}
    />
    <path
      d="M424,353c1.4.6,7.6,0,7.8.3s0,1.6.2,1.7c0,2.4.7,3.5-2,3-2.1,2.3-7.3,2.4-7-2,.7-.8.6-2.4,1-3Z"
      style={{ fill: "#056123" }}
    />
    <path
      d="M400,352c1.3,0,2.7,0,4,0l1,6c-.3,2.4-3.3,3.8-5,2l-1.2,2.1-14.4-.2c-2.4,2.4-6,5.5-8.4,2.1-.2,0-1.2-1.1-3-1,1.5-2.4,5.8-5.4,8.3-6.2,4.5-1.5,10.5.8,11.7-4.8,2.3,0,4.7,0,7,0Z"
      style={{ fill: "#0d5c2e" }}
    />
    <path
      d="M230,287c3-.6,1.8,1.5,2,4-3.1.6-1.8-1.5-2-4Z"
      style={{ fill: "#024d09" }}
    />
    <path
      d="M238,256c.3.2,2.1-.4,2.8.1s.6,3.7,1.4,4.6,3.3.6,4.5,1.5.2,1.6.3,1.7c2.3,2.7,4.5,5.9,1.6,8.9,3.2,5.8,1.2,7.4-2.8,10.4.4,4.4,1.2,8.6,2.2,12.7,2.7.4,2.6,2.2,0,3-4.2-.3-4.6-5.3-8-7,.1-4.7-1-16,0-19.5s3.9.5,3.1-2-2.1-1.9-2.5-3.5c-.8-2.7,0-5.1-.5-7.4s-3.2-1-2-3.7Z"
      style={{ fill: "#025c19" }}
    />
    <path
      d="M255,266c3.6,1.6,7.4,9.7,8.9,13.9,2,3.5,2,6.2.1,8.1,1,3.1,2.5,5.9,4.6,8.4-.2,6.1.6,12.3,2.4,18.6,3.2,11.6-.2,13-11.1,14.7.2,2.5.3,4.7-1.9,6.3-2.5.7-3.7-6.2-3.9-7.6-.7-5.2,1.9-3.2,1.9-7.4v-1.4c12.8-3.2,6.8-15.5,2.3-23.6l.2-4.6c-3.2-2.7-5.4-5.5-1.8-8.9-.7-4.8-2.2-9.6-4.7-14.5,0-1.3,0-2.7,0-4,1-1.1,2-.5,3,2Z"
      style={{ fill: "#076625" }}
    />
    <path
      d="M252,268l2.6,7c2.4,3.5,4,9.8-1,11,.5,2.9,0,4.9-1.6,6,0,2-2.1,3.7-4,4-3.4-2-5.4-12.5-3.6-15.1s4.9,1.1,3.3-5.2-3.4-1.8.4-5.2l-1.1-6.6c1.4,1.2,3.3,1.8,5,4Z"
      style={{ fill: "#035e1c" }}
    />
    <path
      d="M251,256c3.2-1.9,5.6,2.7,3,5,1.3,1.3,1.6,3.4,1,5-1-.6-2-1.4-3-2s-.8.2-1,0c-.5-2.6-1.2-5.5-2-8h2Z"
      style={{ fill: "#066c22" }}
    />
    <path
      d="M257,248c1.2.2,1.5.8,2,1l9.2,13-1.2,2c-.2,1.1-1.5,1.4-4,1-5.5,3.6-7.3,1.6-9-4-1.6-4.9-2.1-3.6-3-5s-.9-3.5-3-4.5v-1.5c.6-.2,1.7.2,2,0-1.6-4.1,4.6-5.6,7-2Z"
      style={{ fill: "#077526" }}
    />
    <path
      d="M249,239c2.2-.5,7.8,3.3,7,7,.4.4.6,1.4,1,2-4.7-.8-4.4,0-7,2-1.7-3.9-5.4-5.4-7-10s.8-.6.7-1.3c-.3-1.3-2.2-2.2-.7-3.7,2.4-.6,6.6,1.3,6,4Z"
      style={{ fill: "#027e24" }}
    />
    <path
      d="M247,115c2.2,1.4,4.8.8,7.2,1.3,13.1,2.8,25.8,5,39.8,7.7.9.3.9.6,0,1-1,2.3-3.5,3-7.5,2.3-10.3-1.8-20.5-3.5-30.5-5.3-3.7-.7-7.5-.9-11.3-.6l-1.7-1.4c-2.8,1.9-3.4-.8,0-2,2.8-.9,1.8-1.3,4-3Z"
      style={{ fill: "#01792d" }}
    />
    <path
      d="M243,118l-1,1.5,1,.5c5.5,1.8-2.5,4.9-6.1,3.9-1.3,3.5-7.4,5.3-10.9,5.9-.2,1.7-1.1,2.5-2.9,2.2-2.5,3.7-4.8,5-7,4h-2c.1-.2-.2-1.9.5-3,1-1.7,4.4-2,4.5-5,5.6-1.3,4.1-3.5,5.5-6s5.4-1.1,7.5-1.5c3.1-.6,8-1.6,11-2.5Z"
      style={{ fill: "#018027" }}
    />
    <path
      d="M224,134c2.9,0,2.8,3.3,0,4l.3,1.9c-5.9,6-8.8,12.9-10.3,21.1,1.6,1.7,1.2,2.4-1,3,1.9.4,1.8,1.4,0,2,2.4,1,1.5,3.5-1,4l-3-6c0-.7,0-1.3,0-2,.5-12.5,4.9-21.9,13-28,1.3-1.7,1.9-1.7,2,0Z"
      style={{ fill: "#028d24" }}
    />
    <path
      d="M223,132c1.6,1,.8,1.6-1,2-3.5,5.5-8.9,9.1-8.3,16.2l-4.7,11.8c.3-3.7-.2-9.4.4-13,.9-5.1,4.8-8.1,6.6-13l7-4Z"
      style={{ fill: "#028523" }}
    />
    <path
      d="M216,192c3.6,3.3,9.1,11.6,6,16,.4,1.5-.3,1.5-2,0h-1c1-3.2-2.2-3.4-2.7-4.3-1.1-1.8-3.5-10.7-4.3-11.7,0-2.9,3.3-2.8,4,0Z"
      style={{ fill: "#019a0b" }}
    />
    <path
      d="M229,218l1.2-.9c4.8,1.6,9.3,4.4,13.5,7.3,1.6-2.2,2.5-2.9,4.3-.4.3-2.9,7.4-2.8,8,0,2.5,1.2,2.5,1.8,0,3-2.4,2.9-3.7,5.6-3.9,10.2-1-.2-1.8,0-2.1.8,1.3,1.5.7,2-1,1-2.1-1.4-2.6-.1-6-4s-6.2-9.4-11-10c-.6-4.2-.6-3.3-3-7Z"
      style={{ fill: "#028823" }}
    />
    <path
      d="M228,214c.3-.4.6-.7,1-.9.9-.4,17.9,4.7,18.5,5.5s.4.8.5,1.3c1.2,3.8-1.3,2-.4,1.1s3.5,1.8.4,2.9c-2.6-.8-2.2,3.5-4.5,3s-1.5-1.9-2.6-2.4c-5-2.3-7.5-4.6-11.9-6.6s-2.8-1.9-3-2c-.6-1.7,1.4-2.1,2-.9s-1.9.3,0-1.1Z"
      style={{ fill: "#019325" }}
    />
    <path
      d="M222,208c.3-1.9,1.1-1.8,2,0,2.6.4,5.5,3.5,4,6l1,2h-3c-.5-.2-1.5.2-2,0-.5-3.7-2-5-4-8h2Z"
      style={{ fill: "#029119" }}
    />
    <path
      d="M216,166c1.9-.1,1.5,1.3,0,2,3,1.6,2.8,6.3,0,8,2.7.8,3.1,2.5,1,5,.2.6.5,1.3,1,2,2.1.4,1.7,2.2,0,3-.2,2.6-1,2.4-2,0-2.5,2.5-3.2,0-3.2-2.8.5-3.9.2-6.3-.8-7.2,0-2-.2-4.1,0-6s.8-2.9,1-4c0-1.6,2.7-1.5,3,0Z"
      style={{ fill: "#029921" }}
    />
    <path
      d="M212,176c.7,0,1.3,0,2,0,.3,2.4-.4,5.2,0,7.5s1.9,2.3,2,2.5c2.6.7,3.1,5.2,0,6h-4c-.7-5.5-.1-10.6,0-16Z"
      style={{ fill: "#049917" }}
    />
    <path
      d="M334,122c.3-2,1.2-2.8,3-3.6,3.5-1.4,21.2.5,23.9,2.2s2.7,4.8,3.1,6.3,0,.7,0,1.1c4.9.7-1.2,9.1-4,5l-2-1.1c1.8-1.7-9.4-6.4-10.1-6.3-2.9,1.7-6.1,2.7-9.5,2.9-1.3,2.2-2.8,3-4.4,2.5,1.6,3.1.3,4.6-3,3-9,.5-18.2.2-27.5-.7l-7.2-4.1c-3.6-.1-5.1-2.6-1.3-3l-1-1.2v-1c7.9,1.6,19.4,5.2,27.6,4.1,3.3-.5,9.5-4.5,12.4-6.1Z"
      style={{ fill: "#027730" }}
    />
    <path
      d="M334,120c.1-.4-.3-1.2.2-1.7,2.2-2.6,13-2.8,16.4-2.3s1.3,1.9,2.1,2.1c4,.9,8.8-2.1,11.3,3,.4,2.1.4,4.8,1,7-.3.9-.6.9-1,0-2.5-.9-3.3-5.2-4.2-5.8-3.2-1.8-18.4-2.8-22.3-2.3s-2.4,1.5-3.4,2.1l-.7-.5c0-.6.6-1.1.7-1.5Z"
      style={{ fill: "#04702f" }}
    />
    <path
      d="M368,312c-.8-4,4.8,0,7-1l-7,1c4.3,3.9,10.5,1.1,16,3,0,1.2-.3,4.6,0,4.9s5,.2,5.9,2.1c2,1.7,1.6,2.3-1,2l-1.7,1.5c-8.4-2.4-17.3-3.5-26.1-3.6l-1.2-1.9c-.5-4.5-5.6-9.9,2-7v-5c3.4,1.7,2.7,1.1,6,4Z"
      style={{ fill: "#183029" }}
    />
    <path
      d="M408,318c3.6,3.2-.2,3.2,0,6.4s2,.5,2.1.6c5.5,5.3-.4,5.5-7.5,4.8-2.9,3.2-6.8,1.4-8.5-1.8-2.9-.6-4.6-1.9-5-4,.4-.5.6-1.7,1-2s2.5-1.9,2.7-2c2.1-.5,10.9.4,11.3,0s-.2-3,0-4c.6-.7,3.3,1.4,4,2Z"
      style={{ fill: "#133525" }}
    />
    <path
      d="M416,327c-.4,1-4.2,2.3-2,5-2.5-.2-4.6,3.2-6.7,3.8-5.1,1.3-7.1-2.3-7.3,3.2-1.7.7-7.6-1.6-8-4-3-.9-1.9-6.5,1-7,.2-.9.5-.9,1,0,4.1.2,3.2,1,5.5,2s.1-1.7,1.1-2c2.7-.7,7.3.5,10.3,0,.4-1.7-1.3-.6-1.8-1.1s.8-1.8.8-1.9c1.4-2.8,3.5,2.4,6,2Z"
      style={{ fill: "#113e26" }}
    />
    <path
      d="M352,317c.9.4,3.1-.4,4,1.5.6,1.2-4.7,2.8-.5,4.5s2.1-2.4,2.5-3,1.6,0,2,0c7-.5,22.5,1.6,29,4s4.3.4,5,4c-.3,0-.7,0-1,0-2.5,3.8-6.7,3.8-12.4-.2l-11.1,2c-2.3,2.8-6.1,1-7.5-1.9-3.9-1.5-3.6-2.2-6-3.5-4.1-2.1-4.6,1.5-4-7.5Z"
      style={{ fill: "#163629" }}
    />
    <path
      d="M368,128v1c2,3.9,7.5,19.5,0,17-4.1,2.2-6.9-4.3-6-8.1l-2-1.9c-1.9-.9-1.9-2.1,0-3,2.3,0,4.6-2.9,4-5,.3,0,.6,0,1,0,1,.1,2-.1,3,0Z"
      style={{ fill: "#01782a" }}
    />
    <path
      d="M372,160h-4c-3.5-.7-1.3-6.8,0-9.1l-.9-.9c-4.3,2.7-3.8-4.4,1-4,.3-.4,1.9-.4,1.9-2.4.1-4-3-10-1.9-14.6,1.6.6,2.4,1.9,3.2,3.3,6,10.7.7,17.2.8,27.7Z"
      style={{ fill: "#017326" }}
    />
    <path
      d="M367,150l4,1.5c-4.6,1.6-2.5,4.9-3,8.5-.7,5.5-1.6,3.8-4,6.8s-1.5,4.8-6,5.2c0-.2-1-3,0-4-1.2-3.4,4.1-10.2,7-12-5.7-2.1,1.7-8.6,2.7-4.6s-2.8.4-.7-1.4Z"
      style={{ fill: "#027a26" }}
    />
    <path
      d="M317,314c4.9,1.1,1.9,3.4,3.2,5.8s7.5,7.7,7.8,8.2-.3,2.2,0,3.3,2,.6,2,.7c.3.5-.2,3,0,4-.6-.1-1.3,0-2,0-.6,1.9-1.4,1.9-2,0,0-3.5-5.7-5.8-6.4-7.9s.6-6.8-.6-8.1c-2.8-3-1.8,6.2-1,7-6.9,1.1-2.7-2.6-3-7-1.4.2-6.7-.3-7,0s.3,4.2,0,5.6-1,2.3-1,2.4l-.6-.8-4.4.8-1-4c-2.3-1.3-1.2-3.1.3-4.7-1.6-3-.1-6.7,2.7-8.3.1,0,.9-1.2,2.2-.8,3.7.9,6.3,2.8,10.8,3.8Z"
      style={{ fill: "#054d1d" }}
    />
    <path
      d="M332,307c-1.7-.5-1.8-1.5-2-3l2-1c0,1.3,0,2.7,0,4Z"
      style={{ fill: "#085428" }}
    />
    <path
      d="M304,311c1,2.1-1.7,4.7-1.6,5.5s2.3,2.5,1.6,3.4-1.2-.9-3,4-2.4,4-3,12c-.7,0-1.3,0-2,0l-1.2-.5c-1.5-9-1.4-19.1,1.8-27.4,1.9-1.2,3.8-2.1,5.4,0,.4.7.2,3,2,3Z"
      style={{ fill: "#06531e" }}
    />
    <path
      d="M290,286c0,0,1-.1,1.6.4s1.9,4.7,2.4-.4c0,0,1.8-.4,2,.6.9,3.8-1.3,7.4,5,9.4.5.7-1.6,1.6-2,2,.7,2.7-.5,5.5-3.4,8.5.8,3-.5,5.6-2.6,7.7,1,6.2,1.3,12.7.9,19.5l-2,1.3c-.4,1.8-.7,2.7-2,1-3.1-1.9-4.1-15.7-3.3-18.9s.7-.8,1.3-1.1l-1-5.2c-2.2-3.2-1.9-7.9,2.2-9.2l-.9-13.7,1.7-1.8Z"
      style={{ fill: "#065f22" }}
    />
    <path
      d="M299,298c-1.1,3.4,4,.8,3,3.5s-3.8,1.7-4,3.5c4-.3,3,1.3,4,3-1.1.9-4,.7-5,2.5,4.4,4.2.4,3.1-.5,4.5-2.6,3.9.6,16-.5,21-4,.5-1.3,7.6-2,11.5s-1.9,2.3-2,2.5c-1.8-4.1-1.4-10.5,0-15-.1-6.7-.4-7.8.7-14.3s-1.9-5.3-1.6-6.9,2.4-2.7,2.7-4.5-1.1-5-.9-5.3,2,.4,2.7-.2,1.5-3.9,3.3-5.8Z"
      style={{ fill: "#065a20" }}
    />
    <path
      d="M362,328c7.6,3,5.3.2,6.6,0,4.4-.6,4.9-.7,9.2-1.7,7.4-1.7,5.7,2.1,8.9,2.5s4.1-.8,6.3-.8l-1,7c.2,3.9-8,5.6-10.3,2.2l-11.5.8-1.2-2c-2.4,1.8-3.7,1.8-4,0,1.5-4.7-3.7-3.9-3-8Z"
      style={{ fill: "#173d2b" }}
    />
    <path
      d="M344,324c6.3-.5,3.7,1.3,4.1,3.4s3-.2.9,3.6c5.7-2.1,11.4,4.2,16,5s2.7,0,4,0c2.3,4-8.6,10-11,6-7.7.5-5.1-2.8-8.5-5.9s-3.4-1.2-4-2c-1.4-1.6,3.2-6.3-1.4-6,0-1.3,0-2.7,0-4Z"
      style={{ fill: "#144128" }}
    />
    <path
      d="M282,269c2.6,5.4,2.4,11.5,8,17,1.5,6.2-.2,12,2,18-1,.2-3.7-.3-4,0-.4.4,0,10.1,0,12-1.4.8-2.2-.5-2.3-4-5.7-2.7-7.5-10.2-2-14.4,2-4.9-2.6-16.7-4.9-21.6-1.9-1.8-2.1-2.5.3-4,0-.4,1-1.8,1-4,.5.4,1.9.7,2,1Z"
      style={{ fill: "#046321" }}
    />
    <path
      d="M259,249c1.4.6,5.5,3,6.3,3.9s.3,2.9,1.2,3.1,5.1,0,5.4-.2c.9-.9-2.1-4.6,4.1-1.9-.2,4,.1,8,0,12-.3,3.2-2.2,1.8-3.9,0-2.7.3-4.4-.3-5.1-1.9-2.5-5-8.1-8.8-8-15Z"
      style={{ fill: "#017221" }}
    />
    <path
      d="M262,230c0,0,.4,1,1.2,1.4,2.6,1.3,3.5-.2,2.8,4.6l2-1,2,5c1.8.5.4-2,1.5-2s-.7,1.6,2.5,2v8c-4-3.5-9.6-8.2-14.1-13.3s-1.8-.7-1.9-.7c-3,.7-4.5-2.1-2-4,1.1-2.8,5.3-3.2,6,0Z"
      style={{ fill: "#037f16" }}
    />
    <path
      d="M258,234c.9,4.7-1,7.8-2,12-1.6-1.7-5.5-6-7-7,.2-.4,1-.8,1-1,1.3-3.7,3.1-3,4.3.5-1.2-3.3.8-8,3.7-4.5Z"
      style={{ fill: "#027e22" }}
    />
    <path
      d="M279,272c-.3,6.4,8.9,13.9,6.8,25.3s-2.8,2.5-3.3,4.6c-2.5,9.2,2.4,6.8,3.7,8.7s.3,4.2,1.9,5.4c-.1,7.9,2.1,14.7,2,20,2.2,2.5.9,15.7-.6,17.9s-5.7,2.5-7.4,2.1c-.2,0-2.8-.6-4-1,.5-1.6,3.3-4.1,5-3,1.6-2.8,1.4-4.8,1-8.1,1.4-.4,1.5-.9.6-1.7-3.5-2.6-4.8-7.3-3.9-11.2,2.3-1.8,3.9-5.7,4.4-9-2-1.3-2.6-4-1.8-8-3-.5-4.9-3-2.2-5-3.6-2.5-4.3-4.2-2-5-.9-5.1-2.3-9.9-4.1-14.8,4.4-4.7,4-6.3,1.3-11.7-3.5-1.4-4.4-8.2-.2-9.5l3,4Z"
      style={{ fill: "#056824" }}
    />
    <path
      d="M267,264c3,0,6.6-1.1,7.7,2h1.3c0,.7,0,1.4,0,2-.4,5.4-1.4,3.4-1.8,4.2-1.2,2.5,3,3.1,3.6,4.1s2.3,4.9,2.3,5.2c.1,1,0,6.3-.2,6.5-.7.7-3.9-1.8-2.8,3.5s1.3,7.2,1.9,12.6c-2.3.7-3.5-1.1-3.5-5.4-6.1-8.4-6.3-14.7-4.4-24.7-4.2.8-5.2-1.2-5.6-4.9-1.8-1.2-2.6-2.6-2.5-4,.7-1.5,2.7-1,4-1Z"
      style={{ fill: "#056d26" }}
    />
    <path
      d="M356,154c1.9-3.4,3.2-.7,4,2,3.9,1,1.4,4.5-.5,6.8-5,3.1-7.8,7.8-8.5,14-3.2,2.7-5.5,5.7-7,9.2-9.8,9.3-11.9,14.2-25.1,21.4-1.8,1-5,2.9-6.9,2.6s-.6-1.6-2-1c1-5.1,9.3-8.8,14.3-10.4,2.6-2.5,5.5-4.6,8.8-6.2,1.4-4.4,3.7-7.7,7.1-9.9-.8-3.3-.2-5.4,1.8-6.5,1.9-3,2.6-6.8,2-11.4,0-5.2,2-6.4,6.7-6.5,1.4-3.1,3.2-4.5,5.4-4.1Z"
      style={{ fill: "#018829" }}
    />
    <path
      d="M365,156c-.8,3-5.6,10.9-7,12-4.9,3.7-4.7,9.2-6.3,10.7-2.4,2.3-5.4,5.1-7.7,7.3-2.4-4.1,5.5-9,6-10.7.8-2.9-.2-4.4,1.8-8s6.9-5.9,8-7.5,0-2.6.2-3.8c1-2.5,3.5-1.9,5,0Z"
      style={{ fill: "#028126" }}
    />
    <path
      d="M342,176c0,2.1.5,5.6,0,7.3s-5.3,4.2-5.7,5,0,2.5-.5,3.5c-2.6,4.5-20.6,11.9-25.8,17.3s-4.7,5.3-6,7c-3.2,0-1.9-2.3,0-4-2.7-.3-2.5-1.2,0-2,2.2-5.5,8.7-10.2,13.9-13.1,8.5-3.2,14.7-7.2,16.8-16.6l2.3-.2c-.3-4.7,1.5-9.2,5-4Z"
      style={{ fill: "#02922c" }}
    />
    <path
      d="M304,212v4c-1.9,2.5-7.2,10.2-8,13-2.8.6-5.5-.7-8-1-2.7-1.3-2.7-2.8,0-4v-1.4c3.4,0,6-.9,8-2.6-3.4-2.7,5.5-11.5,8-8Z"
      style={{ fill: "#029823" }}
    />
    <path
      d="M262,220l-.8-.2c1.3-8.6,17.4-2.7,18.8,4.2,2.6.5,4.3,2.4,2,4,.8,2.5,1.8,2,2,2.7.3,1.1-.2,2.6,0,3.3-3.4-1-2.3,2.5-5.5,2.1s-1.2-1.8-2.2-2.1-2.5.2-3.5,0-6.3-5.8-6.5-6.2c-.6-1.3,0-3.5-.3-3.7s-3,0-4,0c-.3,1.9-1.1,1.8-2,0-3.2-1.4-1.2-4.7,2-4Z"
      style={{ fill: "#018f03" }}
    />
    <path
      d="M260,224c.7,0,1.3,0,2,0v6c-1.6-.4-4.2.3-6,0-2.5-1-2.5-2,0-3,0-1,0-2,0-3,1.2-2.7,2.8-2.7,4,0Z"
      style={{ fill: "#018612" }}
    />
    <path
      d="M282,211c.5,0,1-.1,1.5,0s5.3,9,5.8,10.1.3,2.1-1.3,3c.2,1.2-.1,2.7,0,4-1.2-.1-2.7,0-4,0-1.6-.8-3-2.9-1.1-4.1.3-2.1,0-2.8-.9-3.9-1.4-2.5-2.8-5.1-4-7.9l2-1.1c0-2.1,1.6-2,2,0Z"
      style={{ fill: "#009d19" }}
    />
    <path
      d="M276,208c1.5-4,4,.3,4,3,.2.3-.3,1.5,0,2.2,1.7,2.9,2.2,1.3,1.9,6.8.4.2,1.7-.4,2,.7.5,2-.4,5.1,0,7.3h-2c-.5-1.7,1.1-2.7-2-1-.2-.8.2-2.8,0-3-.1-2.3.5-3.6,2-4-3.2,4.6-11.8-11.5-6-12Z"
      style={{ fill: "#019c0d" }}
    />
    <path
      d="M326,336c.7,0,1.3,0,2,0,1,1.7,3.2,5.9,4.5,7s3.5.9,5,2c4,2.8,7.5,9.9,8.4,14.6,1.2,6.6,2,12.5,2.1,19.4-3.3-.7-5.6-10.5-6.1-10.9-1.2-.8-4.9.5-5.8-.2s.1-1.6,0-1.9c2.7,0,3.4.7,3-2.6s-5.7-3.3,1-3.4c.4-.4-3.7-10.4-4.6-11.1-1.7-1.2-8.1-2.4-9.2-3.1s-2.6-7.9-2.2-9.8c.7,0,1.3,0,2,0Z"
      style={{ fill: "#0c4320" }}
    />
    <path
      d="M252,292l2.2.3,5,11.6c10.5,2.2-.6,14.5-7.2,16.1-1,0-2,0-3,0,.9-3.6,1.6-12.9,1.1-16.6s-1.8-.4-2.1-4.4-.1-2.3,0-3c.5-2.5,1.3-3.8,4-4Z"
      style={{ fill: "#055b1e" }}
    />
    <path
      d="M271,315c1.9-.1,2.9,1.1,3.2,3.6l5.9,1.4c1.8,2,2.3,2.1,4.9,1.9,0,.1.9.7.9,1.5,0,2.5-2.5,5.3-.9,8.5h-4s3,3,3,3l-2,1.5c3.6,1.7,5.3,13.8,3.9,15.4s-2.5-.2-2.9,0c-3.8,1.6-2.3-2.6-1-5-.9-1.6-2-3.2-3.4-4.7-1.3-4.1-4.2-8.3-7.4-11.6-1.3,3.4-3.1,5.9-5.5,7.6,0,2.5-.9,5.6-3.7,5.7,1.3.7,1.5,2.5,0,3-4.1-2.6-3.8-6.9-4-11s0-5.3,0-8c4-1,17.2-2.1,11-7.5,3.2-.6.6-2.9,2-5.5Z"
      style={{ fill: "#086d27" }}
    />
    <path
      d="M252,268c1.1,1.4,5.5,9.2,5.7,10.7.3,2.2-1.4,5.5-.7,8.6s2.9,1.8,3.5,3.7-.6,3-.5,4.3,1.7.6,2,1.4c1.1,3.8,5.1,17.1,3.2,19.8s-6.4,3.5-9.1,4.5-1.9,1-3,1c.1-.6,1.4-1.6,1-2-.7,0-1.3,0-2,0,.8-4.1,6.4-4.5,7-7.5s-2.3-.1-2-1c.8-2.4,4.4.8,5-3s-2.2-1.3-4.1-3.2-5.4-11.1-5.9-13.4c-2.2-10.5,2.4-7,3.9-9.1s-2-5.6-2.7-7.7-1.1-4.6-1.3-7.2Z"
      style={{ fill: "#076020" }}
    />
    <path
      d="M262,344c2.2-3.2,3.6-1.5,3.2,2.1l3.8,1.9c2.7-2.5,5.7-2,8.8,1.6l2.2-.6c.6-1.9,1.3-2.5,2-2,0,1.6-.6,4.1,1,5-1,.5-2.4,3.5-5,3-7.4-2.3-5.5.8-6.5,1-2.7.7-2.6-3.4-6.5-2-.2-2.8-2.5-4.3-3-7s-.1-2.2,0-3Z"
      style={{ fill: "#0e6d27" }}
    />
    <path
      d="M292,350c-.4,1.3.1,4-.9,5.4-1.5,2-2.6-2.4-3.1,4.6-2.1,0-2.2,0-4-1s-1.8-2.9-2-3c.5-2.7,5.6-2.7,5.9-3.1.5-.7-.2-2.9.1-4.2s2-.4,2.1-2.2c.2-2.7-.2-7.3-.1-10.5l2-1c.1,5,0,10,0,15Z"
      style={{ fill: "#065f1f" }}
    />
    <path
      d="M369,336c3.8,0,9.9-.2,13.5-1s-.8-2.1-.5-3l2,5c2.7.9,6.2-1.9,8-2s5.5,1.1,5.8,1.2c.5.3.8,2.5,2.2,2.8,0,1,0,2,0,3-4.2.7-11.7-1-15.4,0s.1,2.6-2.1,2-.3-1.4-.7-1.8c-1.4-1.2-3.6,1.3-5.4,1.7-2.7.7-5.7,1.9-6.1,2-1.6.4-6.5.2-8.3,0s-4-1.9-4-4c4.2-.3,8.4-2.7,11-6Z"
      style={{ fill: "#15462d" }}
    />
    <path
      d="M400,360c2.7.3,2.5,1.2,0,2,3,0,2.8,4.5,0,5-2.7,3.3-7.8,3.7-11,1-5.3,2.7-9.6,2.7-13,0v-4c4.3,0,7-3.8,7.7-4,4.3-1,11.4.5,16.3,0Z"
      style={{ fill: "#0e652f" }}
    />
    <path
      d="M376,368c4.3-.3,8.7.3,13,0,1.3,2.4-.3,3.7-5,4-.6,3.1-3.2,4.7-7.8,4.8l-5.4,2.6c-.2,2.3-2.4,5.2-4.8,3.6-.8,1-1.9,0-2,0-1.5-2.8-2.1-7.3,2.1-7.6,0-1.8.7-3,1.9-3.4,4.5-1.6,1.9-3.6,8-4Z"
      style={{ fill: "#0a6a2c" }}
    />
    <path
      d="M368,372c1.6,6.7-3.2,5.2-4,6s1.6,3.9,0,5c-.6.1-1.5.9-2,1,0-3.6-1.1-10.5,2.8-11.9s2.6.2,3.2,0Z"
      style={{ fill: "#086427" }}
    />
    <path
      d="M384,372c1.2.2,1.8.8,2,2,1.4,5.9-3.7,6.3-8.6,6-2.8,3.7-5.9,6.4-9.4,8,1.7,1.9,0,3.8-2,2-.2-1.4-.3-5.9,0-7s3.4-2.9,3.3-4.7c4.5-2.7,10.7-3.6,14.7-6.3Z"
      style={{ fill: "#0a722b" }}
    />
    <path
      d="M371,388c1.5.3,1.9,1.5,1.3,3.7l10.3,6.6c-.2.9-.7,1.4-1.6,1.7-1.7,0-3.3,0-5,0-2.8-3.4-9.4-5.3-10-10,1.1-.3,1.5-1.1,2-2,1.8-1.7,2.8-1.7,3,0Z"
      style={{ fill: "#19752a" }}
    />
    <path
      d="M375,388c2.1,1.4,2.8,3.1,2,5,2.9-1.3,10.8,3.4,11,7h-7c.2-2.7-1.5-2.5-3.2-3.3s-8.2-3.8-8.8-4.7,2.4-2.6,2-4c1-2.7,3.1-2.7,4,0Z"
      style={{ fill: "#1e7b2c" }}
    />
    <path
      d="M384,388c1.4,1.1,2.7,2.3,3,4l1.4-.4c1.8,5.1,5.6,8.6,11.6,10.4,2.7.3,2.5,1.2,0,2-1.6-.2-3.4.1-5,0-3.2-1.5-3.8-2-7-4s-9-5.2-11-7c-1-2.6.3-4.2,3-3,.8-1.5,2.6-3.1,4-2Z"
      style={{ fill: "#2b8430" }}
    />
    <path
      d="M498,320c1,.2,3.7-.3,4,0s0,2.2,0,3c.2,2.2-.5,2.5,2,3.7v1.3c-2.1.2-2.9-.4-4,2-2.7-.6-2.9-1.4-2-4-.4-1.6.3-4.2,0-6Z"
      style={{ fill: "#177d2b" }}
    />
    <path
      d="M500,330c-.9,1.9-.7,4-1,6-.7,1.6-2.8,2.4-6.1,2.2l-4.9,5.8c-.7,1.8-1.9,3.8-2,4l-.5-1c-.9.9-2.6,1.3-3.5,2-2.6.3-1.5-8.9-1-10.1l3.4-1.9c-.3-2.7.4-4.2,2.1-4.6,3.7,1.5,7.7,1,9.5-.4.1-2.4-.8-3.5,2-4.7v-1.3c.1.5,2.6,2.3,2,4Z"
      style={{ fill: "#267c33" }}
    />
    <path
      d="M499,336c-.1.8.4,2.4-.1,2.9-.8.8-9.4,2.2-10.9,5.1.7-1.8,2.4-6.7,4-7.5s4.8-.3,7-.5Z"
      style={{ fill: "#2a7033" }}
    />
    <path
      d="M480,327c2.4-1.1,5.3-.3,8.7,2.4,2.9,0,4.6.9,5.3,2.6.7,0,1.3,0,2,0-.2,3.7-1.5,1-4,2.5s-.9,2.3-1.5,2.5c-4.3,1.5-.7-3.9-4.5-3,2.2,6-3.7,5.2-4.1,6.7-.5,2.2.4,5.9,0,8.3-.5.4-.7,2.7-2,3-.7-3.7-6.8-6.3-9-10,0-1.8,2.2-.3,3,1,1.4-1,2.5-.4,3.7.5-.6-4.2.1-6.7,2.3-7.5-1.6-4.1-1.6-7.1,0-9Z"
      style={{ fill: "#288636" }}
    />
    <path
      d="M432,360c.2.4-.1,1.4,0,2-8.3-.7-6.2,2.9-8.8,4-3.7,1.6-10.2,1.7-14.2,2-.9-.5-1.6-.8-2.3-1.1-2.1,1.3-5.6,3.1-6.7,0v-5c4.6-2.2,10.1-2.8,16.6-1.8,5.4-1.3,10.6-1.4,15.4-.2Z"
      style={{ fill: "#076e2a" }}
    />
    <path
      d="M406,371c.7,0,1.3,0,2,0,2.9,0,2.7,2.3,0,3,0,.9-.3,1.7-1.1,2.1-6.5,1-26.4,6.3-29,9.6-.5.6-1.2,1-1.9,1.2,1.4,1.7.9,2.3-1,1-1,.2-2.8,0-4,0h-3c.8-1.5,7.2-9.3,8.3-9.7,2.3-1,5.2.3,7.2-.2s.5-3.8,2.6-4.1c1.1-1.2,5.8-3.7,7.5-4.1,2.9-.7,11.3-1.5,12.5,1.1Z"
      style={{ fill: "#0d7b2f" }}
    />
    <path
      d="M400,367c3.1-.3,9.8-2.9,9,1-3,.2-3.3-1-3,3-4.2.4-10,.5-13.3,1s-.6,2.7-6.7,2-2.3.7-2-2c0,0-.2-1.3.3-1.7,1.3-.9,3.8-.4,4.7-2.3,3.7-.2,7.3-.6,11-1Z"
      style={{ fill: "#0b712f" }}
    />
    <path
      d="M420,371c.8.1,2-.4,2,.5l-2,1.5c-3,.5-4.8.7-8,1-.7,2.9-3.2,2.8-4,0v-3c5.1-.5,6.5-.9,12,0Z"
      style={{ fill: "#097f2d" }}
    />
    <path
      d="M408,374c1.3,0,2.7.1,4,0-1.1,1.7,2.6,2.9,4,3v1c-9.1,1.4-17.9,3.6-26.5,6.6l-1.5-.6c-3.5,1.4-6.9,3-10.2,4.6l-1.8-1.6c.1-.8-.4-2.1.2-2.8s13.9-5.4,16.1-6.3c3.5-1.3,12-3.7,15.8-3.9Z"
      style={{ fill: "#158430" }}
    />
    <path
      d="M416,378c6.5,1,4.2,3,11.7-.3s1.7-2.4,5.8-2.4c6.4,0,7,3.4,11.5,4.8l-3,9c.2-1.3.2-3.7,0-5-3.3-2.5-6.6-3.7-9.6-3.7-4.8,2.1-12.7,4.7-16.4.7-2.8,1.6-4.4,1.6-5,0-.7-1.6.9-2.6,5-3Z"
      style={{ fill: "#25942e" }}
    />
    <path
      d="M420,397c-.9,4.6,2.3,2.7,6,3-13.6,5.2-11,6.1-26,4v-2c-1.9-.6-1.9-1.4,0-2,2.9,0,6-.6,9.2-1.6l1.8,1.6c1.3-1.3,3.3-1.8,5-1-.5-2.8,2-4,4-2Z"
      style={{ fill: "#3b8535" }}
    />
    <path
      d="M408,390c4-1.6,9.5-2.7,14.1-2.1,2.2.2,9.1,2.4,9.9,4.1v2c-4,.5-3.7,2.2-5.7,2.8s-4.7-.2-6.3.2-2.2,1.5-4,2c-1.9-.7-3.5-.2-5,1-1.9.4-7.1,2-7.5,2-1.5,0-2.4-1.6-3.5-2-4.3-2.6-9.5-7.2-3-10,3.3-2.7,7.6-2.7,11,0Z"
      style={{ fill: "#419439" }}
    />
    <path
      d="M438,384c-1.2,7.7,1.1,7.7-6,8-6.4.3-7.4-1.8-9.4-2.1-4.1-.5-10.1.2-14.6,0-1.7-.2-2.3-1.2-1.2-2.5,3.4-.8,6.4-1.8,9.2-3.5.5-.7,1-1.3,1.6-1.4,2.7-.4,15-1.1,17.1-.6s2.4,1.1,3.3,2Z"
      style={{ fill: "#3d9835" }}
    />
    <path
      d="M416,381c2.1,0,4.2,1.2,6.4,1,3.2-.3,8.4-4.3,12-4s7.2,3.3,7.6,6h-4c-7.2,0-14.9-.4-22,0-.9,2.2-3.2,2.4-4,0-1-1.5.3-2.5,4-3Z"
      style={{ fill: "#339a32" }}
    />
    <path
      d="M508,280c.5,1.6,2,6.4,2.1,7.5.4,4.3-3,7-1.1,11.5h-5s1-3,1-3c-1-.1-2.1.2-3,0-1.4,3.5-6.6-6-4-8,1.1-12.8,4.4-15.5,10-8Z"
      style={{ fill: "#17ac36" }}
    />
    <path
      d="M508,280c-13.3-7.9-5.3,4.3-8.1,8s-1.4,0-1.9,0c.8,2.1,1.8,7.6,2.1,8s1.4,0,1.9,0v3c-1.6-1.9-3.9-4-6-7,.5-3.5-1.4-3.4,2-4-.5-1.2-2-1.9-2-3.5s1.8-.3,2-1.2c1.1-4.1.2-11.6,8-7.8s1.5,3,2,4.5Z"
      style={{ fill: "#0fa833" }}
    />
    <path
      d="M474,307c-.8-2.7-3.1-5.2-2-9,3.6-.3,7.1,1.9,8,5-4.3-.6-4.8,0-6,4Z"
      style={{ fill: "#0fa43a" }}
    />
    <path
      d="M475,311c1.4.2,2.4,1.2,3,3,1.5,2,3.2,4.8,0,6,0,2.1-.8,1.5-2,0-.3.9-.6.9-1,0l-2-8c.8-.1,1.7,0,2-1Z"
      style={{ fill: "#1b9635" }}
    />
    <path
      d="M480,303c0,.3-.1.7,0,1l-1.6,1.9c.2,2.1.4,4.8.6,8.1-.3.9-.6.9-1,0-1.9.3-2.3-1.6-3-3-1.1-2.2-.6-2.7-1-4,.4-4.8,1.5-7.3,6-4Z"
      style={{ fill: "#19a43b" }}
    />
    <path
      d="M486,312c.9,1.4,1.1,6,4,7.5-3,.8-2,2.7-2,5.5-3.1-1.4-4.2-4.5-6-7-2.4,1.2-3,.6-2-2-1.6-3.7-1.7-6.7-.3-9.2l6.3,5.2Z"
      style={{ fill: "#2fa83f" }}
    />
    <path
      d="M494,332c-6.4-.3-8.7-2.3-14-5-1.7-.3-1.6-2.6,0-3,.3-1.9,1.1-1.8,2,0,4.9.2,5.4,5.4,11,4v-6c.7,3.2,1.6,6.4,1,10Z"
      style={{ fill: "#2c9239" }}
    />
    <path
      d="M480,316c.1.2,1.3,1,2,2-.7,2-1.5,4.1,0,6-.7,0-1.3,0-2,0-1.6,0-2.9-2.7-2-4-.4-1.6.3-4.2,0-6h1c.6.2.9.8,1,2Z"
      style={{ fill: "#269b38" }}
    />
    <path
      d="M480,304c1.4,3.2,4.1,4.9,6,8-2.8-1.2-3.7-2.1-6-4v8c-.5-.9-.7-1.4-1-2-.7-1.3-4.4-9.6-4-10,1.6.2,3.5-.3,5,0Z"
      style={{ fill: "#22a93e" }}
    />
    <path
      d="M478,320c.2.9,1.6,2,2,4s-.2,2.1,0,3c.4,2,2.1,8,2.1,8.5,0,.8-2,.5-2.1.5-.9,3.6-3.4,2.4-6.1,1.1,1.4,3.3,1.4,5.3,0,5.9l-3-1c-1.2-2.1-2.7-6.7-3-9-3-5.1-.5-7.5,7.7-7.2-.9-2.5-.8-4.5.3-5.8h2Z"
      style={{ fill: "#258a38" }}
    />
    <path
      d="M475,320h1c4.5,8.9,0,8.5-8,8,.1,1.6-.2,3.4,0,5-2.8-.8-2.7-6.4-1-7.5s6.2.6,5-5.5c1,0,2,0,3,0Z"
      style={{ fill: "#208833" }}
    />
    <path
      d="M423,356c2.7.1,1.7,1.7,2.7,2s3.2-.3,4.3,0,1.8,1.6,2,2c-5.9.2-8.7,1.5-13.5,2s-12.6-.5-18.5,0v-2c2.7-.3,1-1.7,5-2s7.4.5,10.6,0,3.9-2.2,7.4-2.1Z"
      style={{ fill: "#076528" }}
    />
    <path
      d="M254,261c2.2.2,1.7,3.7,2,4s3.2-1.3,7,0c6.4,2.3,4.3,6.2,5,7s4.6-.3,5,0c1,1-1.6,12.4-1,15.5s4.6,8,5.4,10.6,0,4.6,1.6,6c.2,1.9,1.1,3.4,2,5-.1,2.7-1.5,2.2-3.5.8-.3,3.4-3.3,8.5-5.5,4.2-.4,0-.7-.1-.8-.3-1.5-3.5-2.5-15.7.8-17.7-2.5,4.2-5.2,1.5-8-8-1.3-9-5.9-13.7-9-22s-.6-3.6-1-5Z"
      style={{ fill: "#0a6e28" }}
    />
    <path
      d="M264,288c3.1-1.2,3.1,7,4.1,7.9s3.9,0,3.9.1v18c3.5,1.2,3.7-6.8,4-7,2.2-1.5,2.9,1.4,3.6,2s1.1-.1,1.3,0c.5.4.5,2.2,1.3,2.8s2.5-.2,2.8.8c.5,1.6,0,7.1,0,9.4-6,9.7-4.5.6-5.1,0s-6.9-1.1-7.8-2.2-.2-2.8-1.2-4.8c-2.3-4.8-4.2-7.4-5-13.5s.8-4.7.6-6-2.6-2.3-3.1-3.4.6-2.8.5-4Z"
      style={{ fill: "#0c6c2a" }}
    />
    <path
      d="M294,125c2,0,2.3-.5,2,2l-3,1c4.5-1.7,6.4,2.1,8.7,2.8,7.3,2.2,20.9,2.2,28.3.2l1,3c2.8,4.4-8.4,6.3-11.5,3-4.2.4-7.7,0-10.5-1-1.1,2.6-3.9,2.5-5,0-3.3-1-6.5-2.4-9.6-4.1-6.9-1-13.7-2.3-20.4-3.9-.3,1.9-1.1,1.8-2,0l-13.3-2c-1.8-.3-2.8-.9-2.7-3-.4-.3-.4-.6,0-1,3.2,1,11.2-.5,15.5,0s11.9,3.9,15,4,4.8-1.2,7.5-1.1Z"
      style={{ fill: "#027f31" }}
    />
    <path
      d="M256,122v1c-3.1.3-6.1,1.3-8.8,3-1.5-.6-3.2-1-5.2-1-.8,3.4-5.3,4.3-8.6,4.9-3.5,3.8-6.7,5.2-9.4,4.1-.5.2-1.4-.1-2,0,.4-.6.7-1.3,1-2,2.1-4.2-.3-4.6,7-4,.6-1.3-4.2-1.1-2-3s4.2,1.8,4.5,1.8c1.9,0,2.3-4.4,2.6-4.7.9-.8,5.4.3,7.2-.1s.6-2,.7-2c3-2,10.7,1.3,13,2Z"
      style={{ fill: "#02852c" }}
    />
    <path
      d="M224,144c2.9.2,1.8,4.6,0,6l1.4,2.1c0,.7-4.6,12.1-6.4,11.9,1.1,3.4,0,8.4-3,4,0-.9,0-1.2,0-2-1-.5-2-1.2-3-2,.3-1-.6-1.9,1-3,.9-6.8,3.5-12.7,7.7-17.7l2.3.7Z"
      style={{ fill: "#009c29" }}
    />
    <path
      d="M242,125c6.3,0,7.6.9,4,3-5.2,4-11.2,7.4-18,10-2.4,2.1-3.8,2.1-4,0v-4c2.2-.9,8.2-5.9,8.7-6,6.1-1.2,5-1.4,9.3-3Z"
      style={{ fill: "#028f2d" }}
    />
    <path
      d="M228,138c1.4,2.1-1.4,6.9-4,6-.3.4-3.8,2.7-5.4,6.1s-1.7,8.9-4.6,10.9c-2.6-4.9,6-21.9,10-23s2.8.2,4,0Z"
      style={{ fill: "#019427" }}
    />
    <path
      d="M213,164c2.9,0,3-.7,3,2h-3c0,0-.6,0,0-2Z"
      style={{ fill: "#0ca331" }}
    />
    <path
      d="M218,186c3.4-.8,4.7,2,4,8.5,3,1.6,4.3,3.5,3.9,7,3.6,2.9,4.7,5.1,3.1,6.5-1,2.8-3.5,2.4-5,0-.7,0-1.3,0-2,0-.8-3.3-2.1-7-3.4-10.1s-2.2-.2-2.6-5.9.4-4.7,0-6c.6.1,1.7-.2,2,0Z"
      style={{ fill: "#02a417" }}
    />
    <path
      d="M256,227c0,1-.1,2.1,0,3s1.6,3.6,2,4c-5,0,1.5,7.7-5.5,6s.9-4.7-2.5-2c.6-3.8.5-5.6,2-9.2s2.6-1.3,4-1.8Z"
      style={{ fill: "#02801e" }}
    />
    <path
      d="M256,220c2.8.7,2.5,2.7,0,4-1.8,0-7.1.4-8,0s.1-1.1-.4-1.6-1.5,0-1.6-.9,1.4-.9,2-1.5c2.6-2,5.4-2,8,0Z"
      style={{ fill: "#01961c" }}
    />
    <path
      d="M229,208c1.9-2,3.2-.3,3,2,3.8,3.2,9.8,3.8,14.7,4l1.3,2c3.5,1.8,5.5,3.1,0,4-6,0-5.2-.6-8.8-1.9s-8.8-2.1-11.2-4.1-3.7-4.1-4-6c1.5.1,3.8-.3,5,0Z"
      style={{ fill: "#01a022" }}
    />
    <path
      d="M222,176c-.2,2.7-.9,2.7-2,0-.1,2.3-3.6,2.8-4,0-.1-2.7.1-5.3,0-8,2.6.6,2.5-2,3-4,4.1-.4,4.3,9.3,3,12Z"
      style={{ fill: "#00a326" }}
    />
    <path
      d="M225,150c2.3-1.8,3.7-.8,3,2,3.9.7.4,5.3-2.3,6.6l.2,15.7-2,1.7c-.3,2.7-1.2,2.5-2,0,2.2,2.6.8,14.7-4,10,.1-.9-.2-2.2,0-3-1.5-.7.6-3.3,2-4-2-1.2-1.8-1.7,0-3h2c-.2-3.1-1.5-9.1-3-12,1.5-5.7,3.7-9.9,5-14,.3-.9.6-.9,1,0Z"
      style={{ fill: "#01a72d" }}
    />
    <path
      d="M220,176c1,1.3.4,1.8,0,3-1,1.2-2,1.9-3,2-.2-1.4-.9-2.5-1-5h4Z"
      style={{ fill: "#07a03c" }}
    />
    <path
      d="M220,179c-.5,1.5-1.9,3.4-2,4-3.7.4-.9-1.2-1-2,.8-1.2,1.3-2.3,3-2Z"
      style={{ fill: "#2ecb61" }}
    />
    <path
      d="M331,134c.3-1.3-.2-3.5,0-5l1,4c1.1.2,1.4-1.2,2-2l3.1,1.1c4.6-2.6,9-3,13.2-1.3,3-5,9.6,2.1,7.7,6.2.9.3.9.6,0,1-1.2,2.2-4.8,2.2-6,0-6.8,0-13.7,1.2-20.5,3.7-5.4,1.4-9.8.8-13.3-1.8-3.4.5-14.2-.7-9.2-3.9,3.9.2,9-.6,12.5,0s3.2,2.2,3.5,1.9-.2-1.4.3-1.7c1.5-1,5,.7,5.8-2.3Z"
      style={{ fill: "#028432" }}
    />
    <path
      d="M334,131c4.6-6.6,2.8-3.4,9.3-5,.8-.2.7-1.8,1.4-1.9,6.3-1.5,6.9,1.5,11.4,3.4,1.2.5,3.7.5,3.8.7.3.3,0,3.8,0,4.9s0,2,0,3c.7,2.5,0,2.8-2,1-1.4-2.6-3.3-5-5.5-7l-.5,3c-2.3.5-4.5-1.6-6.5-1.6-3.9,0-9.9,6.2-11.5-.4Z"
      style={{ fill: "#027f31" }}
    />
    <path
      d="M360,136c6.6-.9,2.9,2,4.2,6.3s2.8,2.4,3.8,3.7c-.1.2.1.9-.4,1.6s-1.6.2-1.6.9c-.1,1.5,1,1.3,1,1.5.2.6,1.3,1.8,1,2-4.8-.7-2.4,1.5-3,4-1.8.7-3.2.5-5,0-1.9-2.8-2.4-6.5-1.2-11.1-1.8-2.5-2-4.8-.8-6.9-.1-.3.1-.7,0-1,.6-.2,1.4-.9,2-1Z"
      style={{ fill: "#03802c" }}
    />
    <path
      d="M356,154c-.3.6-4,5.5-4.3,5.7-1.2.8-4.9.5-5.6,1.4-1.5,2.2.3,13-1.2,14.8s-2.1,0-2.9,0c-3.3-3.6-2.7-16.7,2-19-2.7-4,2.4-6.9,6.1-7.1-.9-5.3.4-7.6,3.9-6.9,4.2-4.1,6.9,10.5,2,11Z"
      style={{ fill: "#028b2e" }}
    />
    <path
      d="M358,138c1.4,2.6,1.4,3.9,2.3,5.7s4.9,1.6-.3,2.3v1c5.3.6.3,2.2.2,2.5-.2.5,0,5.2-.2,6.5-2.7-.7-1.7-2-4-2,1.2-2.7.6-6.5-.3-9.3s-1.7-1.6-1.7-1.7l-.9-2c-2.6.5-2.8-1.1-1.1-3,2-.8,4-.8,6,0Z"
      style={{ fill: "#01852e" }}
    />
    <path
      d="M336,173c2.5.1,2.8,2.5,1,7-.7,1.7-4.7,11.4-5.2,11.8-1,.8-7.4,1.4-8,6-8.3-.7-13,8.5-19.8,12.2-2.7-.3-2.5-1.2,0-2-3-.5-2.8-3.2,0-4,2.2-6.4,13.1-13.2,20-12,1-3.8,1.7-7.5,2.2-11,3.8-2.7,3.8-5.2,3.7-9.6l2.1-1.4c-2.1-2.1-2.1-6.3,1-7v-1c4.1-1,4.6,8.1,3,11Z"
      style={{ fill: "#019b32" }}
    />
    <path
      d="M337,152c2.5-3.8,7.3,1,7,5-2.2,6.3-1.8,12.5-2,19-1,.1-3.5-.3-3.9,0s-.5,2.5-1.1,3.9c.1-2.4-.8-4.6-1-7,1.2-2.7,1.2-5.7,0-8.9-1.8-3.3-3.9-11.6,1-12.1Z"
      style={{ fill: "#019331" }}
    />
    <path
      d="M284,208c2.4-3.9,6.3-1.5,6,2.9,3.1.3,6.2-.2,7-3.7,2.2-2,6.8-2.6,7,.8v4c-2.5.6-7.7,6.1-8,8,.7,3.1-6,2.6-7.3,1.4s-6.1-12.6-4.7-13.4Z"
      style={{ fill: "#01a42a" }}
    />
    <path
      d="M284,208c1,2.1,3.9,6.3,4,6.7.8,2.8-1.6,4.3,3.3,5.5s3,.1,4.7-.2c-.2,1,.2,3.8,0,4-.4.4-6.5-.3-8,0-.6-4.1-4.2-9.1-6-13-2.9,0-2.7-2.4,0-3,0-1.7.7-1.7,2,0Z"
      style={{ fill: "#01a023" }}
    />
    <path
      d="M272,208c.6-2.9,2.8-2.7,4,0-1.4,4.4,1.2,5.2,1.9,6.8,1.3,3-.4,3.3,4.1,5.2-.1,2.6,1,4.6-2,4-.3-.3-3.7.3-4,0s.4-3.6,0-4-2.3.3-3.2,0-.8-1.8-1.5-1.9-8.9-.3-9.3,0,.2,1.6,0,2c-4.3,3.9-5.6-2.3-1-4-.9-6,3.9-14.1,11-8Z"
      style={{ fill: "#019803" }}
    />
    <path
      d="M261,216c-1,2.4-2.3,4.5,1,4-.6,1.3-2,2.3-2,4-1.3,0-2.7,0-4,0,0-1.3-.2-2.8,0-4-2.9-2-.7-4.5,2-3-1.5-1.3-.8-2,1-1,.6-2.6,1.2-2.6,2,0Z"
      style={{ fill: "#02950d" }}
    />
    <path
      d="M272,202c4.3-4.2,10.3,3.2,10,6,.3.4-.3,2.4,0,3h-2c0,0-1.3.2-1.7-.3-1.3-1.8,1.1-2.2-2.3-2.7s-2.7,0-4,0c-2.9,0-2.8-3.3,0-4-1.9-.3-1.8-1.1,0-2Z"
      style={{ fill: "#00a418" }}
    />
    <path
      d="M262,344c.3-2,1.9-4,1.8-5.4s-3.1-6.8.2-4.6,0,1.8.5,2c3.2,1.1,2.1-1.3,3.1-2.9,3.4-5.6,2.5-7.4,7.8-1.1.6,1.5,4.1,10.2,4.5,10.5,1,.8,3.8-1.5,5,.9-4.6.2-2.2,1.9-3,3.5s-1.5,1.3-2,2c-1.2-1.7-2.6-3.5-4.1-5.3-1.8-8.7-6.4-1.1-6.9,4.3-1.2-.3-3.6-.6-4.3-1-1.4-.8.4-3.9-2.7-3Z"
      style={{ fill: "#0b7329" }}
    />
    <path
      d="M269,348c-6.1-4.8-1.9-2.9,0-5.6s.6-4.3,1.1-5.9c.9-3,2.4,1.4,2.7,1.5,1.2.3,3.9-.4,4.3,0s.2,3.4.7,4.8,3.9,1.9,2.3,6.2c-.3.4,0,1.9-.9,2.5-3.1,2.2-3-1.3-4.7-2.4s-3.7-.7-5.3-1.2Z"
      style={{ fill: "#13772d" }}
    />
    <path
      d="M388,384l1,.9c-3.5,1.3-6.5,3.1-9,5.1-1,.8-1.8,2.3-3,3-1.4-1.3-3.5-3.3-2-5,.7-.2.9-.2,1-1l12-3Z"
      style={{ fill: "#20862f" }}
    />
    <path
      d="M416,378l-5,3-.5,1.4c-8.4,1.4-16.7,3.7-24.6,6.9l-1.9-1.3c-.8.4-3.5,1.6-4,2,.6-4.1,3.8-2.8,6.3-4.2s1.4-1.6,1.7-1.8c1.8-1.1,2.6-2.2,3.7-2.8,3-1.6,20.7-3.8,24.3-3.2Z"
      style={{ fill: "#218c33" }}
    />
    <path
      d="M416,381l-4,3c-.3.9-.7.9-1,0-1.3.3-2.3,1-3,2l-.2,1.4c-5.6,0-10.9.9-15.8,2.6-.4,1.9-2,2.6-5,2-1.6-.8-3.9,2-3-4,6.2-2.9,19.9-6.3,27-7s3.4,0,5,0Z"
      style={{ fill: "#2c9132" }}
    />
    <path
      d="M392,390c1.6.7,2.3,2.5,2.1,5.5,2.1,1.5,4.1,3,5.9,4.5,0,.6,0,1.3,0,2-2,.3-3.6-.3-5.4-1.1s-5.3-3.2-5.5-3.4c-1.5-1.5-1.3-5.1-2.1-5.5,1.2-2.7,2.7-1.8,5-2Z"
      style={{ fill: "#348c34" }}
    />
    <path
      d="M474,343c0,0,0-.9-.4-1.6s-1.4-.3-1.6-1.1c-.5-2.1.3-5,0-7.3,3.6,1,3,4.4,8,3-1.2,1.7,2.4,16-6,7Z"
      style={{ fill: "#2c8d3c" }}
    />
    <path
      d="M416,399c-2.1.6-3.1.6-5,1,.6-2.2,3.8-3,5-1Z"
      style={{ fill: "#398734" }}
    />
    <path
      d="M416,384c-.3,6.5-8.6,1.8-8,6-3.6,0-7.4,0-11,0-1,2.8-3.9,2.8-5,0,.5-5.5,11.4-3.1,16-4,.4-1.1,1.4-1.7,3-2,.3,0,.7,0,1,0,1.1-.2,2.7,0,4,0Z"
      style={{ fill: "#379536" }}
    />
    <path
      d="M397,390c-.8,1.7-1.4,5.7.3,6.8s2.1-1.9,2.7,3.2c-7-2.6-8.9-1.5-8-10,1.6-.1,3.3,0,5,0Z"
      style={{ fill: "#3c8f37" }}
    />
    <path
      d="M304,136c1.7,0,3.3,0,5,0-.6,3,1.5,1.9,3.4,2s5-.4,6.9,0,.3,1.8,2.1,2.1c9.5,1.2,16.5-2.9,25-4.1s5-.2,5.5.2,0,1.6,0,1.9c1.2,2.2,1.2,3,2,5-1.8,1.7-4.2,1.6-7.1-.4-6.5,2.5-17.2,5.7-22.9,1.4-2.7,3-10.3,1.6-12-2-4.2-1.4-8.5-2.9-12.7-4.7l.7-1.3c.5-1.2,1.8-1.2,4,0Z"
      style={{ fill: "#018a34" }}
    />
    <path
      d="M272,128c.7,0,1.3,0,2,0,6.2,1.5,12.5,2.8,18.9,3.8l1.1,2.2c1.9.3,3.2,1,4,2,.6-1.9,1.4-1.9,2,0,.7,1.5,3.7,3,5.1,3.4,2.6.7,7.4-.7,6.9,2.6-1,.8-2.1,1.3-3.3.9-4.2-1.2-9-3-12.7-4.9l-2,1.6c-11.9-3-24.1-5.3-36.5-7l-1.5-1.6c-2.9,0-2.7-2.3,0-3,4.5-2,9.8-2,16,0Z"
      style={{ fill: "#028f37" }}
    />
    <path
      d="M256,123l16,5c-5.3-.2-10.7,0-16,0-.5,3.1-8.7,2.2-10,0,.8-3.3-2.4-1.1-4-3,2.5-.9,3.5-.6,5.3-1s4.3-4.5,8.7-1Z"
      style={{ fill: "#028931" }}
    />
    <path
      d="M274,128c7.5.3,13.8-.2,21.2,2.1l8.8,5.9c-1.3,0-2.7,0-4,0s-1.4,0-2,0c-.8-.9-2.1-1.5-4-2-4.9-.5-8.2-2.4-13.3-2.2l-6.7-3.8Z"
      style={{ fill: "#038734" }}
    />
    <path
      d="M243,134c.6,1.8-.4,3.8-3,6l-.3,2.1-9.5,3.2c-1.3,4-3,5.6-5.1,4.6h-1c.4-1.4-.3-4.3,0-6,1.3-1.9.9-2.9,4-6,4.2-2.2,8.6-4.1,13.1-5.4l1.9,1.4Z"
      style={{ fill: "#019c30" }}
    />
    <path
      d="M256,128v3c3.5,2,1,3-2,2-.7,1.5-2.3,1.5-4.6,0-2.6,1.6-4.7,1.9-6.4.9-4,1.5-10.7,3.4-15,4,3.3-3.3,7.3-5.4,12.2-8.1s.9-1.6,1.6-1.8c1.4-.4,2.9.1,4.2,0,4.3-.5,6.4,0,10,0Z"
      style={{ fill: "#029532" }}
    />
    <path
      d="M224,176l2,1.1c-.6,5.7-.6,10.7,0,14.9l2.4.9,2.1,8.5c6.1,3.9,7.9,7.1,5.4,9.7-1,1.6-3.2.5-4-1-2.1-.5-2.8-2-3-2-.2-2.3-4.5-4.7-4.8-5.2-.6-1.2,0-2.9-.5-4.5s-3.5-2.2-3.7-3c-.4-2.1.3-4.5,0-6.6s-1.8-2.6-2-2.7c4.1,0,4.2-6.4,4-10h2Z"
      style={{ fill: "#01ab1f" }}
    />
    <path
      d="M256,214c1.9-.3,2.6,1.4,2,3-1.7,1.4-1.6.6-2,3-2.7,0-5.3,0-8,0,.6-.6,2.1-1,2-1.5-.2-.9-2.8-.1-2-2.5,1.7-1.9,4.3-2.6,8-2Z"
      style={{ fill: "#03a01b" }}
    />
    <path
      d="M236,211l2-1.7c5.4,1.2,10.7,1,15.7-.6l2.3,1.2c2.7.6,2.4,2.8,0,4-1.3,3.1-5,2-8,2-6.2,0-8-.8-13.4-3.1s-2.8-.9-2.6-2.9c1.3.3,2.7.8,4,1Z"
      style={{ fill: "#01aa1e" }}
    />
    <path
      d="M324,144c5.8.4,12.6-.3,18-1.6s4.5-3.9,9.5.6l1.5-2c-.4,1.5,1,1.9,1,2,0,.5-1.8.9-2,1.7-.4,1.9.6,4.9-.5,6.3s-5.3-.6-7.6,5.9c-2.9.7-1.4-1.7-2.7-3.1s-3.1-1.6-4.3-1.9c-.3.8-.6.7-1,0-1.4-.2-2-.9-2.2-2.2-3.6.7-14.1-1.7-9.8-5.8Z"
      style={{ fill: "#018e33" }}
    />
    <path
      d="M304,204v4c-7.6-1.3-4.9,1.5-6.6,3.1s-7.5,3-8.9,1,2.4-4.1-4.4-4c-2.9-1.1-4.5-5.1-4-8l2.2-1.1c6.7,2.7,14.7,5.6,20.4-.7l1.4.8c3.1,0,2.1,3.8,0,5Z"
      style={{ fill: "#02ab30" }}
    />
    <path
      d="M328,167c2.3-2.4,4.1.2,4,3-.4,2.9.2,6.6-1.2,9.3s-2.2,1.4-2.5,1.9c-1.8,3.2,1,7-4.3,10.7-3-.1-3.3-1.1-1-3-.3-2.9-.8-5.9-1.4-8.8,2.3-3.6,3.7-7.7,4.2-12.4l2.2-.8Z"
      style={{ fill: "#04a136" }}
    />
    <path
      d="M322,189c.3-.9.6-.9,1,0,0,1.1.5,2.1,1,3-2.4,1.7-7.7,3.2-10,4.5s-3,2.1-5.8,3.7-1.3,4.3-4.2,3.8v-5c-.9-.3-.9-.6,0-1-1-3.8,16.1-13.2,17.2-11.5l.8,2.5Z"
      style={{ fill: "#03a736" }}
    />
    <path
      d="M323,152c.7.3.7.6,0,1,6.1-.3,8.6,4.7,10,10,0,2.9-.7,4.5-1,7l-4-3-1.9-1.2c0-2.1-1-4-3.1-5.8-2.4-1.4-7.7-6.7-3-8,.8-1.9,1.9-1.6,3,0Z"
      style={{ fill: "#049e39" }}
    />
    <path
      d="M336,152c.3,0,.7,0,1,0-2.2,5.1.3,11.5.6,17s1.1,3.1-1.6,4c-.4-4.1,1.1-8-3-11l-2.3-4.7c-3.3-2.5-3.9-4.2-1.7-5.3,0-3.2,6.4-3.1,7,0Z"
      style={{ fill: "#019535" }}
    />
    <path
      d="M328,152c.2-.9.6-.9,1,0,1,4.2,3.5,5.4,4,10s0,.7,0,1c-1.6-1.5-1.4-4-2.9-5.6s-5.9-2.2-7.1-4.4c-.8-3.4,3.4-3.3,5-1Z"
      style={{ fill: "#019a3a" }}
    />
    <path
      d="M272,194l2.7,2.2c6.8.5,8.6,1.8,5.3,3.8,1.3,2.7,2.7,5.3,4,8-.7,0-1.3,0-2,0,0,0-1.4.3-1.8-.2-.8-1,.1-3.4-.3-3.8s-2.3.3-3.2,0c-1.7-.7.3-1.3-4.8-2-1.7-.8-3.2-2.3-4-4-1.7-.4-6-2.8-4-6,1.3-3.1,8-1.2,8,2Z"
      style={{ fill: "#01af25" }}
    />
    <path
      d="M272,204v4c-2.6,0-5.3,0-7.9,0,.5,3.4-2,5.4-3.1,7.9-.6.1-1.6-.2-2,0-4.4-7.6,4.9-18.4,13-12Z"
      style={{ fill: "#02a106" }}
    />
    <path
      d="M262,200c1.9-2.7,8.7-.7,10,2,0,.7,0,1.3,0,2-10.4-1.8-11.8,3.2-13,12-.2,0-.6.7-1,1-1.8-.9-1.8-1.2-2-3s0-2.7,0-4c-1.9-.6-1.9-1.4,0-2,.5-2.3,1.7-4.8,3.6-7.7l2.4-.3Z"
      style={{ fill: "#02a70e" }}
    />
    <path
      d="M411,384c-.2,0-.4,1.5-3,2-.5-2.7.6-2,3-2Z"
      style={{ fill: "#339a32" }}
    />
    <path
      d="M312,142c4,.8,7.9,1.7,12,2,.7,5.7,6.5,3.7,10.5,4s1,2.5,1.5,4c-3.7-.7-4-.3-7,0s-.7,0-1,0c-2.5-1-5.2-2-8-3-2.1,3.1-24.8-7.6-25.4-8.9s.5-1.7,1.4-2.1c5.4,1.1,10.5,2.9,16,4Z"
      style={{ fill: "#019338" }}
    />
    <path
      d="M256,131c7.7,1.3,15.6,1.6,23.2,3.1s11.3,2.8,16.8,3.9c-.3,2.8,4.4,2.6,6.7,3.7,5.8,2.8,10,5.7,17.3,7.3,4.7.3,5.7,1.3,3,3-1,0-2,0-3,0-4.8,5.9-15.3-2.2-20-6.4-3.4-1.5-7.2-2-11.4-1.5-6.1-5.1-12.9-5.8-20.7-5.3l-1-.8-12.2-3c-.7-.4-.9-1.1-.8-2,1.7.4,2.2-.4,2-2Z"
      style={{ fill: "#019a3a" }}
    />
    <path
      d="M294,134c2.5.3,4.6-1.1,4,2-1.6-.2-3.1-.5-4-2Z"
      style={{ fill: "#018a34" }}
    />
    <path
      d="M244,138c1.3,1.4-.5,4.7-2,6,.2,1.3-1.5,2-1.4,1.9,2.2-3.2,2.4,3.8-1.6,4.2-3.3-.3-4.7-.5-7,1.9-1,2.7-3,2.9-4,0-.8-.1-2.1-1.6-3-2,1.6-.8,2.9-5.6,3.2-5.8,1.6-1.2,9.9-3.3,11.8-4.2.7-1.9,2.5-4,4-2Z"
      style={{ fill: "#01a433" }}
    />
    <path
      d="M232,152l1.7,1.7c-3.4,7.1-5.4,12.5-3.7,20.6l-1.9,1.7c0,2.9-3.3,2.8-4,0,0-4.2-.8-15.2,0-18.3s4.7-2.6,4-5.7c1.2.2,2.7-.1,4,0Z"
      style={{ fill: "#02ae32" }}
    />
    <path
      d="M254,133c3.3,1.2,5.4,2.1,8.4,3s3.5,0,4.6,2c-.2.7-.8.7-2,0-1.9,1.9-4.7,2.1-8.5.9-4.2-1.8-8.6-2.9-12.5-.9-1.5.7-2.7,1.5-4,2-.5-2.7,1.7-4,3-6,2-.8,4.6-2.9,6.5-2.9s3,1.4,4.5,1.9Z"
      style={{ fill: "#02a037" }}
    />
    <path
      d="M228,192c.7-2.8,2.7-2.5,4,0l1.9.7c2.3,7.4,4.5,9.9,12.4,10.5,2.6,4.1,5,3.4,7.4-2.3l2.3,1c2.3.4,1.3,4.9,0,6,0,.7,0,1.3,0,2-6.1,2.7-13.5,2.2-20,1,.4-4.4-5.7-5.8-7.4-9.1s-1.8-9.1-2.6-9.9c.3-2.7,1.2-2.5,2,0Z"
      style={{ fill: "#01b221" }}
    />
    <path
      d="M228,176c2.9,5,2.9,11,0,16h-2c-.1-.1-1.8.5-2-.6-1-3.9,0-11.1,0-15.4h4Z"
      style={{ fill: "#01ae26" }}
    />
    <path
      d="M281,196c1.8-.8,6.7-2.4,8.2-2.5,3.6-.3,6.5,1.8,8.6,4.6,2.7-3.1,4.8-3.8,6.3-2.1,2-.2,1.6,1.2,0,2v1c-3.7,1.2-4.5,4.5-8.4,5.1s-12.4-2.3-15.6-4.1.5-1.7-.7-2c-3.4-.8-6.3,1.6-7.3-4,2-2.3,8.7-1.1,9,2Z"
      style={{ fill: "#01b033" }}
    />
    <path
      d="M323,160c4.5.3,5.7,2.6,5,7s-1.9,8.5-2.5,10.9-3.8,4.1-2.3,5.9,1.8-.4,1.8.6-2.3-.7-2,4.5h-1c-2.8-1.1-3.2-2.2-1.3-3.2.6-2.3.8-4.3-.7-5.8-2,.6-2.4-3.1-1-4-.7-4.7-1-9.5-.8-14.2l1.8-1.7c.6-2.7,1.5-2,3,0Z"
      style={{ fill: "#06a539" }}
    />
    <path
      d="M320,180c3.8-.9.7,5.9,2,9-4.2-2.8-6.2,1.6-9.1,3.4s-3,.6-4.5,1.5-1.7,4.5-4.4,4.1v-2c-2.9,0-2.8-3.3,0-4,.3-1.9,2.1-3.7,4-3,.7-5.2,5.8-5.3,10.2-5-.5-2.3.1-3.6,1.8-4Z"
      style={{ fill: "#08ad3b" }}
    />
    <path
      d="M267,138c3.6.3,10.7-.4,14.3.3s3.4,2.9,5.4,3.4c3.4.9,8,0,10.6.3s.7,1.6,1.4,1.9c2.8,1.5,13.6,7.7,15,8s4.5-.1,6.3,0c-1,3.7,2.4,4.8,3,8-1,0-2,0-3,0-5.9-1.3-11.3-4-16-8-4.3-3.5-7.2-4.8-13-4-3.1.3-4.8-.6-5.2-2.7-4.6-1.9-9.4-3.9-13.8-5.4-1.2,1.6-8.1,1-7-2,.7,0,1.3,0,2,0Z"
      style={{ fill: "#02a13c" }}
    />
    <path
      d="M320,149c1,.2,8.6-1.5,8,3-1.6.3-3.4-.2-5,1-.1-.3.1-.8,0-1-.5-.8.4-2.2-3-3Z"
      style={{ fill: "#019535" }}
    />
    <path
      d="M264,192l3.9,4.7v1.3c0,0-.1,1.3-.1,1.3-3.1-.8-4.1-1-5.8.7-1.2,3.2-3.2,3.3-3.7,4.3-.9,1.8.9,4.4-2.3,3.7,0-1.9-.2-4.1,0-6-4.5-3.3,2-8.7,6-10,.6-1.9,1.4-1.9,2,0Z"
      style={{ fill: "#01b11a" }}
    />
    <path
      d="M272,189c.9.1.9.4,0,1,2.9.5,2.7,3,0,4-3-.7-4.7-1.6-8-2s-1.3,0-2,0c-1.3,2.8-8,2.6-7.1-.2s3.6,1.3-.9.2c-1.9-2.2,0-4,3-4,2.1-4.6,11.5-.7,15,1Z"
      style={{ fill: "#02bc2d" }}
    />
    <path
      d="M268,198c1.3,0,2.7,0,4,0,0,1.3-.1,2.7,0,4-1-.1-2.2.3-3.3,0s-2.7-3.7-6.7-2c.2-.4-.2-1.8,0-2s4.9,0,6,0Z"
      style={{ fill: "#01aa17" }}
    />
    <path
      d="M256,141c-.4,1.9-2.9,2.3-4,1-4,2.3-6.5,5.6-7.7,9.7l-2.3.3c-2,3.2-8.1,3.2-10,0,.2-1-.3-3.8,0-4,.4-.4,7.6.4,8,0s-.4-3.5,0-4,1.4.1,2,0l1.6-2c.4-7.3,12-6.4,12.4-1Z"
      style={{ fill: "#01ac38" }}
    />
    <path
      d="M265,138c1.8,2,4.6,1.9,7,2,2,.2,1.9,1.2,0,2-1.6,1-4.3,1-8,0-.9,2.1-7.5,1.1-8-1-2-2.3-6.4-3.7-9.3-3s-.9,5.4-4.7,6l2-6c.4-.2.7-1.7,1.8-2,3.5-.7,14.6,1.6,19.2,2Z"
      style={{ fill: "#04a63a" }}
    />
    <path
      d="M242,152c5-4.6,4.2,6.7-1.7,6.5-2.8-.7-6-1.1-4.3,1.5-2.9,4.7-2.6,8.7-1.2,14.2l-1.8,1.8c0,3-4.5,2.8-5,0,0-3.9-.7-8.6-.5-12.1s3.6-6.5,4.5-11.9c3.3.3,6.7.2,10,0Z"
      style={{ fill: "#03b434" }}
    />
    <path
      d="M234,187c2.2-4,3.9-1.7,3.4,2.5l2.6,2.5c6,8.5,8.6,8.5,14,0l1.5-2c.8,3.3,3.6,1.8,6.5,2-2,5.7-5.2,2.6-6,10-4.2,1.6-2.4,7.3-7.3,6s-1.4-2.4-3.9-3.2-5.6-.5-7.3-1.7-6.2-8.9-5.4-11.1c0-1.8.7-3.6,2-5Z"
      style={{ fill: "#02bc26" }}
    />
    <path
      d="M233,176c1.9.9,2.5,2.8,1.8,5.7,2.7,2.6,2.5,4.3-.8,5.3,1.3,1,1,4.6-2,5s-2.7,0-4,0c0-5.3,0-10.7,0-16h5Z"
      style={{ fill: "#01b729" }}
    />
    <path
      d="M294,192c2-3,7.8-2.8,10,0v4c-3,.6-3.8,4.7-7.4,4.1s-1.8-4.4-7.4-5-1,1.8-1.9,1.9c-1.4.3-4.5-.8-6.4-1-3-6,9.5-7.7,13-4Z"
      style={{ fill: "#02b337" }}
    />
    <path
      d="M282,189c2.2-3.3,16.7-2.1,12,3-5.1.2-10.4-1.6-13,4-4.1-.6-5.5-1.2-9-2-.2-1.2.1-2.7,0-4,1.1-2.5,8.7-3.3,10-1Z"
      style={{ fill: "#04b836" }}
    />
    <path
      d="M311,160c3.2-1.4,6.2,3.7,6,6.8l1.9.9c-.5,2.9-.4,5.6,0,8.3,0,1.1.9,2.7,1,4,.8,9.2-.8,4.8-7.3,6.2s-3.7,1.9-4.7,2.8c-2.6.1-2.6-.9,0-3-2.7-.3-2.5-1.2,0-2-2.2-2.8.1-4.2,7-4.2l.3-1.9c-3-1.3-6.2-7.6-3.3-9.8-2.1-2-3.3-5.4-4-8-.3-1.7.7-1.7,3,0Z"
      style={{ fill: "#10ae3e" }}
    />
    <path
      d="M304,152c3.9,1.2,6.2,2.8,8.8,3.9s6.5-.9,7.2,4.1-1,10.8-1,16c-2-1.4-.4-6.2-1.1-6.9s-2.4.3-2.8-.1.2-3-.3-4.3-3-3.5-3.8-4.7c-2.6-1.3-9.6-5.4-7-8Z"
      style={{ fill: "#07a63f" }}
    />
    <path
      d="M283,186c7.6-1.2,17-.8,25,0v3c-1.7,1.5,1,2.7-4,3s-6.7,0-10,0c-.6-5.2-7.8-3.4-12-3-2.8-1.1-1.7-2.7,1-3Z"
      style={{ fill: "#0db83f" }}
    />
    <path
      d="M272,140c.4,0,1.5-1.1,3.2-.8s11.6,4.3,12.2,4.7c1.1.8,0,2.9,1.1,3.2,2.9.8,7.7-1.9,10.7-1.1s1.3,2.1,2.2,3c1.5,1.6,1.3-.1,2.5,3-5.4,2.3-13.8-1.4-19-4,0,1.9-11.4-3.1-12.2-3.8l-.8-2.2c5.4,5.3-7,5.4-8,0,1.2-1.3,5.9-.3,8,0,0-.7,0-1.3,0-2Z"
      style={{ fill: "#06a540" }}
    />
    <path
      d="M257,188c-3.8,1.4-2.5,1.3-3,4-1.9,9.8-8.4,11.8-13.5,2.9s-.3-2.4-.5-2.9l1.3-.8c2.5,2.5,8.7,4.9,10.7.8l.5-4.5c.5-.7,5-.6,4.5.5Z"
      style={{ fill: "#07c22c" }}
    />
    <path
      d="M268,186c5.6.4,6.9,1.4,4,3-5.6-.2-9.3-1.9-15-1s-3.5-.4-3.9,0-.4,2.5-1.1,3.9c-1.5.4-1.2-6.6-1-6.9.5-.7,8.1-3.2,10-3.1s7.9,2.5,7,4Z"
      style={{ fill: "#08c231" }}
    />
    <path
      d="M272,186c2.1-1.5,4.2-2,6,0,2-.9,3.7-.9,5,0l-1,3c-3.2.3-6.5,1.7-10,1,0-.3,0-.7,0-1-.2-2.8-.9-1.7-4-3-.4-2.1,3.3-1.6,4,0Z"
      style={{ fill: "#0ebf3b" }}
    />
    <path
      d="M256,141c1.6,0,6.3.7,8,1,2.6.5,4.7,3,8,2,0-.7,0-1.3,0-2,8.5,1.4,7.1,5.1,13,6,3.1.7,7.8,2.3,5,6-.8,2.1-2.7,2.1-5.8,0-3.4-3.9-6.5-4.8-11.6-3.5-5.4-2.7-10.9-5-16.5-6.5-1.3,1.3-3.4,2.4-4,0-2.2-.2-1.9-1,0-2,1.4-.4,1.5-1.1,4-1Z"
      style={{ fill: "#0eae43" }}
    />
    <path
      d="M252,142v2l-.2,2.2c-2.4.9-3.8,4.4-4.5,6.7-.3,5-1.6,14.7-7.4,9.1-2-.1-3.4-.8-4-2-3.3.7-2.3-2.8-1-3.6s3.4.4,5.1.1c4-.6,6.2-4.8,1.9-4.6,2.5-5.6,4.1-8.3,10-10Z"
      style={{ fill: "#05b539" }}
    />
    <path
      d="M236,160c2.2-.5,4.8-.4,4,2-2.1,3.7-3.1,7.7-3,12,1.9.6,1.9,1.4,0,2,0,2.9-3.3,2.8-4,0-.5-5.1-3.9-14.4,3-16Z"
      style={{ fill: "#08bb35" }}
    />
    <path
      d="M237,176c.3-.9.6-.9,1,0,1.1,2.1,1.5,5,1.2,8.7,1.1,2.7,1.4,5.1.8,7.3-3.5-1.7-5,.7-3-5h-3c-.2-.2-1.4.1-2,0l3-2c.4-1.7-1.7-.4-2-1.6-.5-2,.3-5.1,0-7.4h4Z"
      style={{ fill: "#07bf30" }}
    />
    <path
      d="M256,144c7.6-.4,9.9,3.6,14.9,4.5s5.2-1,8.4-.3c4.7,1.1,4.5,5.3,10.7,5.8,4.2-4.4,17.2-.1,18,6,2.8,3,2.9,4.5,4,8-1.6,2.6-3.7.8-6.2-5.6-3.4-.8-6.7-1.6-9.8-2.4-9.8-.4-21.2-4.7-30.1-9.8l-9-2.9-.9-2.3c-.9-.3-.9-.6,0-1Z"
      style={{ fill: "#14b345" }}
    />
    <path
      d="M296,160c2.1.3,10.5-.3,10.9,0s1.9,8.7,5.1,7.9c.3.8,2.3,6.7,2.5,7,.7,1.1,4,.5,2.3,5.3s-4.9,1.2-7.2,1.8-1.5,1.8-1.7,1.8c-1.5,3.9-7.7,1.4-4-2-1.9-.6-1.9-1.4,0-2,3.1-10,1.5-15.3-4.8-16-1.2-.4-2-1.1-2.2-2-1.8-.7-2.1-1.3-1-2Z"
      style={{ fill: "#1ab442" }}
    />
    <path
      d="M304,152c.7,1.7,4.7,4.9,7,8h-3c-5.4-5.8-10.9-5.5-18-6-1.9-2.6-1.5-5-5-6,7.6,1.2,11.4,1.6,19,4Z"
      style={{ fill: "#08ad41" }}
    />
    <path
      d="M304,182c.3,2.3,1.6,2.9,4,2v2c-2.5,2.8-4.4,1-7.3,1-5.4,0-10.8-.9-17.7-1s-3.3,0-5,0c-.1-.5-.2-1-.2-1.5.2-1.3,22.1-3.2,25-3.3s.8.3,1.2.8Z"
      style={{ fill: "#16bb42" }}
    />
    <path
      d="M240,176c2.9,1,2.8,4.9,0,6,2.3,3.5,4.3,6.9,6,10,.6-2.6,1.2-2.6,2,0,1.7-.4,3.1-.4,4,0-3.6,8-6.9,2.4-12,0-.5-1.1-1.7-1.8-2-3.6-.5-3,.7-9,0-12.4.4-2.7,1-2.5,2,0Z"
      style={{ fill: "#0ec433" }}
    />
    <path
      d="M261,180c1.1-.2,2.1.5,3,2,.5-2.7,7.8-.4,8,2,2.7.3,2.5,1.2,0,2h-4c-3.6-1.5-13.1-4.9-12,1-7.1-2.9-2.6,2-4,5s-3.3,2.1-3.5,2c-1.1-.2-.2-1.7-.5-2-1.3-10.8,2-13.1,13-12Z"
      style={{ fill: "#12c434" }}
    />
    <path
      d="M299,180c3.1-1.9,4.8-1.9,5,0,0,.7,0,1.3,0,2-7.2,5.3-19.1-.7-26,4-2,0-4,0-6,0v-2c.5-2.7,6.1-3.5,8-2,.6-1.9,1.4-1.9,2,0,2.1-2.2,5.5-3,10.1-2.2,3-1.8,5.4-1.7,6.9.2Z"
      style={{ fill: "#1dbe43" }}
    />
    <path
      d="M256,144v1c1,2.4-4.2,6.9-6,7-.9,4.6-1.4,9.3-1.7,14.1l-3.9,1.7c-4.8-1.1-6.7,1.1-5.9,6.6l-1.5-.4c0-5.2-3-9.7,3-12s1.8.1,3-.5c5.6-3-.3-14.6,9-17.5s2.8,0,4,0Z"
      style={{ fill: "#0fbb3b" }}
    />
    <path
      d="M250,155c1.9,1.6,1.9,3.4,0,5,.6,2.4,2.2,13,1.1,14.1-3.2,0-6.5-.9-10.1-2.8.8,2.3.5,3.9-1,4.7h-3c0-.7,0-1.3,0-2,2.2-1.3.3-5.4,1.1-6.9s9.2-.5,9.8-3.9-.4-6,0-8.8,1.7-2,2-2.5c2.4-.2,1.7,2.3,0,3Z"
      style={{ fill: "#15c139" }}
    />
    <path
      d="M256,145c.6-.1,9.8,3,10.9,3.6,3.4,1.6,2.9,3.6,8.9,4.9s13.4,5.4,20.1,6.6l1,2c-2.1,1.6-7.1,0-9-2l-1.8,1.8c-1.7.5-15.2-4.9-16.2-7.8l-2,1.3c-7.5-4.7-12.9-4.2-16.1,1.4l-1.9-1.6v-3c1.1-1.8,4-6.7,6-7Z"
      style={{ fill: "#1eba47" }}
    />
    <path
      d="M288,160c4.4.5,6.4,1.8,9,2,7.1.6,6.1-.8,11,5.5l-2,.5c2.8,2.4,1.3,11.6-2,12s-3.5-.3-5,0c2-5.7,2.6-13-4.5-14.3-6.6-1.8-8.7-3.7-6.5-5.7Z"
      style={{ fill: "#20b846" }}
    />
    <path
      d="M252,168l2.2,3.7c-.2,3.7,2.1,6.4,6.8,8.3l-10.9,3.1c-1.2,1.8,1.8,7.5-2.1,8.9-.1-.1-1.4,0-2,0l-2-1c0-3.3-1.4-6.3-4-9-.1-2,0-4,0-6s-2.6-5,.5-7l10.5,4c-1.1-4.4-.9-8.5-1-13,.3-.9.6-.9,1,0,2.1,1.6,3.8,6.1,1,8Z"
      style={{ fill: "#1bc638" }}
    />
    <path
      d="M240,182c4.1,1.7,6,5.7,6,10-1-.1-3.8.2-4,0-.3-.3.3-3.9,0-5.3s-1.6.4-2-4.7Z"
      style={{ fill: "#12c434" }}
    />
    <path
      d="M256,173c1.1-4.5,7.3.3,9.5,3.1,2.8,1.9,5.2,2.5,8.6,2.1l1.9,1.9c3.5-2.3,7.6-1.9,4,2-3.2.4-4.6,2.2-8,2s-6.9-1.6-8-2l-2.4-2.5c-6.2-1.6-8.1-3.8-5.6-6.5Z"
      style={{ fill: "#26c842" }}
    />
    <path
      d="M256,171c1.9.6,1.9,1.4,0,2,.8,7.2,6.4,2.9,8,9-2.3-.8-2.8-1.9-3-2-2.5-1-6.7-1.5-8.1-3.5s-.7-7-.9-8.5c1.8-1,3.2,0,4,3Z"
      style={{ fill: "#1ec83c" }}
    />
    <path
      d="M288,160c.2,4.1,7.2,3.5,9.3,4s3.3,4.4,5.7,4.1c-2.2,4.3,1.6,10.6-3.9,11.9-6.6,1.2-10.3,1.5-17,2-4.3-6.1,5.1-6.6,9.7-5.4,6.4-4.5-.5-10.7-6.3-10.5l-1.4-2.1c-5.2-.7-9.3-2.7-12.3-6.1-3.2,1-5.8-2.9-1.7-3.9,7.5,3.1,8.8,4.9,18,6Z"
      style={{ fill: "#26bd48" }}
    />
    <path
      d="M284,164c8.4.2,12.2,3.6,13,12-2.8-1-3.1,2.2-4.8,2.5-4.3.7-9.6-3.6-10.2,3.5-.7,0-1.4,0-2,0,.6-3-1.5-1.9-4-2-1.8-1.3-.5-2.6,4-4-2-.9-1.4-4,1.6-3.4l.7-4.2c-2.7-.3-5.7-1.4-4.4-4.4,1.2-1.6,3.2-1.6,6,0Z"
      style={{ fill: "#2dc149" }}
    />
    <path
      d="M250,155c3.4,0,3.5-4.3,3.9-4.5,2.7-1.5,8.1,0,10.9,1.2s3.3,1.5,5.2,2.3c-.6,3.1.3,2.4,1.9,3.5,3.8,2.6,8.7,3.5,12.1,6.5-2,0-4,0-6,0-.4.7-1.1,1.7-1.5,1.5-2.4-.9-9.8-6.2-9.5-7.4-2.8.1-3.7-1-5-3.2-.9,1.3-2.6,1.7-5.1,1.1-.4,2.3-1.4,3.6-2.9,3.9,0,2.9-2.3,2.7-3,0h-1c0-1.7,0-3.3,0-5Z"
      style={{ fill: "#27c248" }}
    />
    <path
      d="M254,160c1.6.8,2.3,2.1,2,4,3,.4,2.8,3.4,0,4,1.4.6,1.7,2.4,0,3-2,0-2.8-1.6-4-3-.3-2.7-.8-5.3-1-8h3Z"
      style={{ fill: "#2ac943" }}
    />
    <path
      d="M258,168c1,.6,2,1.3,3,2,3.9-.7,8.6,1,11.5,4,3-.3,4.8.3,5.5,2,.6-1.9,1.4-1.9,2,0-.5,3.7-2.5,1-4,4-2.2,0-5.4.4-7.3,0s-4.2-2.1-4.4-2.3c-2.3-1.5-3.1-6-8.3-4.7,0-.7,0-1.3,0-2,0-1,0-2,0-3,.6-1.9,1.4-1.9,2,0Z"
      style={{ fill: "#30c947" }}
    />
    <path
      d="M262,155c2.3.9,1.7,1.4,2.8,2,1.6.8,4-1.6,3.2,3.1l4-2c.5,5.4,3,4.6,6,6s2.3,3.1,5.9,2.1c1.5,3.3-1.3,6.4.1,9.9l-4-2c-.1.6,0,1.4,0,2-.7,0-1.3,0-2,0l-1.8-1.4c-1.6-6.6-6.1-11.4-13.5-14.4l-.7-2.2c-1.8-1.1-1.8-2.1,0-3Z"
      style={{ fill: "#32c54b" }}
    />
    <path
      d="M262,155l.8,2.5-.8.5c-2,6.2-4,8.2-6,6-.3-.5-2.2,1.7-2-4s2.3-7.1,8-5Z"
      style={{ fill: "#31c848" }}
    />
    <path
      d="M262,158c.2,0,4.5,1.1,4.7,1.3.5.3.7,1.7,1.3,2.1,5.4,3.5,11.2,7,9.9,14.6-8.8.8-5.6-1-10.4-3.1s-5,0-6.6-2.9c-1-.4-2-1.1-3-2-.7,0-1.3,0-2,0,0-1,.2-3.7,0-4,3.7.8.5-6.1,6-6Z"
      style={{ fill: "#38cb4c" }}
    />
    <path
      d="M258,168c4.8,0,2.2.5,3,2-2.3.1-3.5.6-3-2Z"
      style={{ fill: "#31c848" }}
    />
  </svg>
);

export default MountainsMobile;
